import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getAllocationOfCostsChartDataV2 } from './financials-client';
import { AllocationOfCostsChartRequest, AllocationOfCostsChartResponseV2ByCategory } from './financials-client.type';

export function useAllocationOfCostsMeasures(
  { portfolioId, teamId, startDate, endDate }: AllocationOfCostsChartRequest,
  options?: UseQueryOptions<AllocationOfCostsChartResponseV2ByCategory>,
  successCallback?: (data: AllocationOfCostsChartResponseV2ByCategory) => void
) {
  const query = useQuery({
    queryKey: ['allocation-of-costs', portfolioId, teamId, startDate, endDate],
    queryFn: () =>
      portfolioId && teamId && startDate && endDate
        ? getAllocationOfCostsChartDataV2(portfolioId, startDate, endDate, teamId != 'aggregate' ? teamId : undefined)
        : Promise.reject('Unable to fetch allocation of costs; missing required parameters'),
    ...options,
  });

  if (query.isSuccess && query.data && successCallback) {
    successCallback(query.data);
  }

  return { chartData: query.data, query };
}
