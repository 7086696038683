import { AdminData } from '../../api/user-client/user-client.type';

enum Roles {
  ADMIN = 'admin',
  USER = 'user',
}

type RolesData = {
  role: Roles;
  isAdmin: boolean;
  admins: AdminData[];
};

export { Roles };
export type { RolesData };
