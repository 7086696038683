import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import bfMark from '../../../assets/images/BF-Mark-Primary-Black.svg';
import { newCOLORS } from '../../../styles/colors';
import styles from './views.module.css';

export function HealthCheckSuccess() {
  return (
    <HealthCheckSuccessContainer>
      <h3>Thank you!</h3>
      <p>
        This integration was successfully started! The Bloomfilter team will take a look at the historical results for
        the project you provided and prepare insights once the integration is complete.
      </p>
      <div id={styles.successIconContainer}>
        <img alt="Bloomfilter Beta Logo" src={bfMark} width="64px" height="64px" />
        <div className={styles.successDividerContainer}>
          <div className={styles.successDivider} />
        </div>
        <Icon icon="ph:globe" width={64} height={64} color={newCOLORS.aqua} />
        <div className={styles.successDividerContainer}>
          <div className={styles.successDivider} />
        </div>
        <Icon icon="bx:server" width={64} height={64} color={newCOLORS.blue} />
      </div>
    </HealthCheckSuccessContainer>
  );
}

const HealthCheckSuccessContainer = styled.div`
  position: relative;
  padding: 10px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: ${newCOLORS.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  min-width: 117px;
`;

export function HealthCheckSuccessHeader() {
  return (
    <HealthCheckSuccessHeaderContainer>
      <header id={styles.successHeader}>
        <div id={styles.successTitleContainer}>
          <h2 id={styles.successHeaderTitle}>Integration is in progress...</h2>
        </div>
      </header>
    </HealthCheckSuccessHeaderContainer>
  );
}

const HealthCheckSuccessHeaderContainer = styled.div`
    height: 100px;
    background-color: ${newCOLORS.white}
    padding: 24px;
    row-gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;
