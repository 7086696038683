import { QueryObserverResult, UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  completeOauth,
  getBoardStatuses,
  getBoardStatusesJDC,
  getIntegrationEntries,
  getIntegrationUrl,
  getIntegrationsJDC,
  refreshIntegration,
} from './integrations-client';
import {
  ExternalService,
  GetBoardStatusesPayload,
  GetBoardStatusesResponse,
  IntegrationEntry,
  IntegrationJDC,
  IntegrationRefreshData,
} from './integrations-client.type';

/**
 * Returns the integration URL for a given service.
 *
 * @param {ExternalService | null} service - The service for which the integration URL is requested.
 * @param {UseQueryOptions<{ url: string }>} options - The options for the query.
 * @returns {{ url: string | undefined, query: QueryObserverResult<{ url: string }> }} The integration URL and the query object.
 */
const useIntegrationUrl = (
  service: ExternalService | null,
  options?: UseQueryOptions<{ url: string }>
): { url: string | undefined; query: QueryObserverResult<{ url: string }> } => {
  const query = useQuery({
    queryKey: ['integration-url', service],
    queryFn: () => (service ? getIntegrationUrl(service) : Promise.reject('Cannot get integration url')),
    ...options,
  });

  return { url: query.data?.url, query };
};

/**
 * Query manager for the OAuth flow completion using the provided parameters.
 *
 * @param {string} ExternalService - The service for which the OAuth flow is being completed.
 * @param {string | null} userId - The user ID, or null if not available.
 * @param {string | null} organizationId - The organization ID, or null if not available.
 * @param {string | null} code - The OAuth code, or null if not available.
 * @param {string | null} state - The OAuth state, or null if not available.
 * @param {UseQueryOptions<Record<string, unknown>>} options - The options for the useQuery hook.
 * @return {{ data: Record<string, unknown> | undefined; query: QueryObserverResult<unknown> }} The fetched data and the query result.
 */
const useCompleteOauth = (
  service: ExternalService,
  userId: string | null,
  organizationId: string | null,
  code: string | null,
  state: string | null,
  options?: UseQueryOptions<Record<string, unknown>>
): { data: Record<string, unknown> | undefined; query: QueryObserverResult<unknown> } => {
  const query = useQuery({
    queryKey: ['complete-oauth', service, userId, organizationId, code, state],
    queryFn: () =>
      userId && organizationId && code && state
        ? completeOauth(userId, organizationId, code, service, state)
        : Promise.reject('Cannot complete oauth'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching board statuses.
 *
 * @param {GetBoardStatusesPayload} payload - the payload for fetching board statuses
 * @param {UseQueryOptions<GetBoardStatusesResponse>} [options] - optional query options
 * @return {{data: GetBoardStatusesResponse, query: QueryObserverResult<GetBoardStatusesResponse>}} the data and the query object
 */
const useBoardStatuses = (
  payload: GetBoardStatusesPayload,
  options?: UseQueryOptions<GetBoardStatusesResponse>
): { data: GetBoardStatusesResponse | undefined; query: QueryObserverResult<GetBoardStatusesResponse> } => {
  const query = useQuery({
    queryKey: ['board-statuses', payload],
    queryFn: () => {
      const isValidPayload = () => {
        if (payload.service === ExternalService.Jira) {
          return !!payload.project_id && !!payload.board_id && !!payload.system_access_id;
        }

        if (payload.service === ExternalService.ADO) {
          return !!payload.project_id && !!payload.organization_name && !!payload.system_access_id && !!payload.team_id;
        }

        return false;
      };

      return isValidPayload() ? getBoardStatuses(payload) : Promise.reject('Cannot get board statuses');
    },
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching integration entries.
 *
 * @param {string} organizationId - The ID of the organization to retrieve integration entries for
 * @param {UseQueryOptions<IntegrationEntry[]>} options - The options for the query.
 * @returns {{ data: IntegrationEntry[], query: QueryObserverResult<IntegrationEntry[]> }} - An object containing the fetched data and the query result.
 */
const useIntegrationEntries = (
  organizationId: string | null,
  options?: UseQueryOptions<IntegrationEntry[]>
): { data: IntegrationEntry[] | undefined; query: QueryObserverResult<IntegrationEntry[]> } => {
  const query = useQuery({
    queryKey: ['integration-entries', organizationId],
    queryFn: () =>
      organizationId ? getIntegrationEntries(organizationId) : Promise.reject('Cannot get integration entries'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for refreshing integration data.
 *
 * @param {string | null} integrationId - The ID of the integration to be refreshed.
 * @param {UseQueryOptions<IntegrationRefreshData>} options - The options for the query.
 * @returns {{ data: IntegrationRefreshData | undefined; query: QueryObserverResult<IntegrationRefreshData>; }} The integration refresh data and query result.
 */
const useIntegrationRefresh = (
  integrationId: string | null,
  options?: UseQueryOptions<IntegrationRefreshData>
): {
  data: IntegrationRefreshData | undefined;
  query: QueryObserverResult<IntegrationRefreshData>;
} => {
  const query = useQuery({
    queryKey: ['integration-refresh', integrationId],
    queryFn: () => (integrationId ? refreshIntegration(integrationId) : Promise.reject('Cannot refresh integration')),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching JDC integrations.
 *
 * @param {string | null} organizationId - The ID of the organization for which integrations are being fetched.
 * @param {UseQueryOptions<IntegrationJDC[]>} options - The options for the query.
 * @return {{ data: IntegrationJDC[] | undefined; query: QueryObserverResult<IntegrationJDC[]> }} The fetched data and the query result object.
 */
const useIntegrationsJDC = (
  organizationId: string | null,
  options?: UseQueryOptions<IntegrationJDC[]>
): { data: IntegrationJDC[] | undefined; query: QueryObserverResult<IntegrationJDC[]> } => {
  const query = useQuery({
    queryKey: ['integrations-jdc', organizationId],
    queryFn: () =>
      organizationId ? getIntegrationsJDC(organizationId) : Promise.reject('Cannot get JDC integrations'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching JDC board statuses based on the board ID.
 *
 * @param {string | null} boardId - The ID of the board
 * @param {UseQueryOptions<{ statuses: string[] }>} [options] - Additional options for the query
 * @return {{ data: { statuses: string[] }, query: QueryObserverResult<{ statuses: string[] }> }} The data and query object
 */
const useBoardStatusesJDC = (
  boardId: string | null,
  options?: UseQueryOptions<{ statuses: string[] }>
): { data: { statuses: string[] } | undefined; query: QueryObserverResult<{ statuses: string[] }> } => {
  const query = useQuery({
    queryKey: ['board-statuses-jdc', boardId],
    queryFn: () => (boardId ? getBoardStatusesJDC(boardId) : Promise.reject('Cannot get JDC board statuses')),
    ...options,
  });

  return { data: query.data, query };
};

export {
  useBoardStatuses,
  useBoardStatusesJDC,
  useCompleteOauth,
  useIntegrationEntries,
  useIntegrationRefresh,
  useIntegrationUrl,
  useIntegrationsJDC,
};
