import { GlobalMessage } from './global-message.type';

/**
 * Sends a global message using the provided GlobalMessage object.
 *
 * @param {GlobalMessage} globalMessage - the global message to be sent
 * @returns {void}
 */
const sendGlobalMessage = (globalMessage: GlobalMessage): void => {
  const customEvent = new CustomEvent('globalMessagesStorage', {
    detail: globalMessage,
  });

  window.dispatchEvent(customEvent);
};

export { sendGlobalMessage };
