import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { OutOfBloom } from '../../components/out-of-bloom/out-of-bloom';
import { ProtectedRoute } from '../../components/protected-route/protected-route';
import { Setup } from '../../containers/setup/setup';
import { GlobalProvider, PortfolioProvider, SidebarProvider, TeamProvider, UserProvider } from '../../contexts';
import { GlobalFilterProvider } from '../../contexts/global-filter';
import { OrganizationProvider } from '../../contexts/scope';
import { IntegrationsProvider } from '../integrations/context/integrations.context';
import { NavigationContainer } from '../navigation/navigation';
import { ProcessProvider } from '../process/context/process.context';
import { SignIn } from '../sign-in/sign-in';
import { ViewName } from '../sign-in/sign-in.type';
import { StrategyProvider } from '../strategy/context/strategy.context';

export function App() {
  return (
    <Router>
      <ToastContainer theme="colored" />
      <MantineProvider>
        <QueryClientProvider
          client={
            new QueryClient({
              defaultOptions: {
                queries: {
                  retry: parseInt(import.meta.env.REACT_QUERY_RETRY_TIMES as string),
                  refetchOnWindowFocus: false,
                },
              },
            })
          }
        >
          <GlobalProvider>
            <UserProvider>
              <OrganizationProvider>
                <PortfolioProvider>
                  <GlobalFilterProvider>
                    <TeamProvider>
                      <IntegrationsProvider>
                        <StrategyProvider>
                          <ProcessProvider>
                            <SidebarProvider>
                              <Routes>
                                <Route path="/" element={<SignIn />} />
                                <Route path="/signup" element={<SignIn view={ViewName.SignUp} />} />
                                <Route
                                  path="/password/reset/confirm/:uid/:token/:email"
                                  element={<SignIn view={ViewName.ResetPassword} />}
                                />
                                <Route
                                  path="/application/setup"
                                  element={
                                    <ProtectedRoute>
                                      <Setup />
                                    </ProtectedRoute>
                                  }
                                />
                                <Route
                                  path="/application/*"
                                  element={
                                    <ProtectedRoute>
                                      <NavigationContainer />
                                    </ProtectedRoute>
                                  }
                                />
                                <Route path="*" element={<OutOfBloom />} />
                              </Routes>
                            </SidebarProvider>
                          </ProcessProvider>
                        </StrategyProvider>
                      </IntegrationsProvider>
                    </TeamProvider>
                  </GlobalFilterProvider>
                </PortfolioProvider>
              </OrganizationProvider>
            </UserProvider>
          </GlobalProvider>
        </QueryClientProvider>
      </MantineProvider>
    </Router>
  );
}
