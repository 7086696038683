import dayjs from 'dayjs';
import { ReactNode, createContext, useCallback, useState } from 'react';
import { Stages } from '../../../api/process-client/process-client.type';
import { CachedProcessScope, ProcessContextType, ProcessScope, ProcessWorkflow } from './process.context.type';

const ProcessContext = createContext({} as ProcessContextType);

const ProcessProvider = ({ children }: { children: ReactNode }) => {
  const [cachedScope, setCachedScope] = useState<CachedProcessScope>(
    JSON.parse(localStorage.getItem('process-scope') as string)
  );

  const [startDate, setStartDate] = useState<string | null>(
    dayjs().subtract(1, 'month').startOf('month').toISOString()
  );
  const [endDate, setEndDate] = useState<string | null>(dayjs().toISOString());
  const [stages, setStages] = useState<Stages[]>([]);
  const [workflow, setWorkflow] = useState<ProcessWorkflow | null>(null);

  const setScope = useCallback(
    (newScope: Partial<ProcessScope>) => {
      const { portfolio, team, startDate, endDate } = newScope;

      if (portfolio) {
        setCachedScope({
          portfolio,
          team: null,
        });
        localStorage.setItem(
          'process-scope',
          JSON.stringify({
            portfolio,
            team: null,
          })
        );
      }

      if (team) {
        setCachedScope({
          portfolio: cachedScope.portfolio,
          team,
        });
        localStorage.setItem(
          'process-scope',
          JSON.stringify({
            portfolio: cachedScope.portfolio,
            team,
          })
        );
      }

      if (startDate) {
        setStartDate(startDate);
      }

      if (endDate) {
        setEndDate(endDate);
      }
    },
    [cachedScope, setCachedScope]
  );

  return (
    <ProcessContext.Provider
      value={{
        scope: {
          ...cachedScope,
          startDate,
          endDate,
        },
        setScope,
        stages,
        setStages,
        workflow,
        setWorkflow,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};

export { ProcessContext, ProcessProvider };
