import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Badge, Loader, Tooltip } from '@mantine/core';
import { Projection, SprintProjections } from '../../api/sprints-client/sprints-client.type';
import { newCOLORS } from '../../styles/colors';
import { ContentTag, HeadingTag } from '../../styles/shared-styled-components';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';

type BloomcastPhaseBoxProps = {
  direction: 'start' | 'end';
  sprintActive?: boolean;
  loading?: boolean;
  dataType: ViewByType;
  projections?: SprintProjections;
};

const getForecastAcc = (projections?: SprintProjections, dataType?: ViewByType): Projection | undefined => {
  if (dataType === ViewByType.tasks) {
    return projections?.projected_carryover_tasks;
  }
  if (dataType === ViewByType.points) {
    return projections?.projected_carryover_points;
  }
};

export const BloomcastPhaseBox = ({
  direction,
  sprintActive,
  loading,
  dataType,
  projections,
}: BloomcastPhaseBoxProps) => {
  const result = getForecastAcc(projections, dataType);
  const currentSprintTip =
    'Bloomcast is a model that requires a minimum of six (6) two-week sprints. It will base predictions on the average heuristics of the sprint data if you are not using two-week sprints OR until the current two-week sprint is at least halfway complete.';
  const pastSprintTip = 'This sprint has been completed, Bloomcast is displaying the actual carryover.';
  if (Number.isFinite(result?.prediction) || loading) {
    return (
      <BloomcastPhaseBoxContainer direction={direction}>
        <BetaHeading>
          <Badge size="md" variant="light" style={{ fontFamily: 'Figtree' }}>
            Beta
          </Badge>
          <HeadingTag>Bloomcast:</HeadingTag>
        </BetaHeading>
        {loading ? (
          <Loader color="dark" size="sm" />
        ) : !loading && sprintActive ? (
          <ContentTag>
            As of today, this sprint is tracking with {result?.accuracy ? Math.round(result?.accuracy) : 0}% confidence
            towards <span style={{ fontWeight: 'bold' }}>{result?.prediction}</span> planned {dataType?.toLowerCase()}{' '}
            carrying over to the next sprint.{' '}
            <Tooltip multiline w={350} offset={30} position="top" label={currentSprintTip}>
              <Icon icon="mdi:information-outline" width={24} height={24} color={newCOLORS.blue} />
            </Tooltip>
          </ContentTag>
        ) : (
          <ContentTag>
            This sprint finished with <span style={{ fontWeight: 'bold' }}>{result?.prediction} </span>{' '}
            {dataType?.toLowerCase()} carrying over to the next sprint.{' '}
            <Tooltip multiline w={300} offset={30} position="top" label={pastSprintTip}>
              <Icon icon="mdi:information-outline" width={24} height={24} color={newCOLORS.blue} />
            </Tooltip>
          </ContentTag>
        )}
      </BloomcastPhaseBoxContainer>
    );
  }
  return null;
};

const BloomcastPhaseBoxContainer = styled.div<{ direction: 'start' | 'end' }>`
  border: 2px solid ${newCOLORS.lightGray};
  padding: 15px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const BetaHeading = styled.div`
  display: flex;
  gap: 7px;
`;
