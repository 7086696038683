import { styled } from '@linaria/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import financials from '../../assets/images/financials.svg';
import { PortfolioSelect } from '../../components/select/portfolio-select';
import { PortfolioContext } from '../../contexts';
import { newCOLORS } from '../../styles/colors';
import {
  PageHeader,
  SmallerHeading,
  StyledButton,
} from '../../styles/new-shared-styled-components/new-shared-styled-components';

const isGlobalScopeEnabled = import.meta.env.VITE_FEATURE_FLAG_GLOBAL_ORG_PORTFOLIO === 'true';

export const AddFinancialData = () => {
  const navigate = useNavigate();
  const { portfolio } = useContext(PortfolioContext);
  const navigateToSelectedPortfolio = (pf: Portfolio) => {
    navigate(`/application/financials/portfolio/${pf.id}`, { state: {} });
  };

  return (
    <AddFinancialDataContainer>
      <HeaderAndSelectContainer>
        <PageHeader>Financials</PageHeader>
        {!isGlobalScopeEnabled && (
          <PortfolioSelect hideLabel={true} handlePortfolioSelected={navigateToSelectedPortfolio} />
        )}
      </HeaderAndSelectContainer>
      <AddFinancialDataImageContainer>
        <img alt="Add Financials Icon" src={financials} width={125} height={75} />
        <SmallerHeading style={{ textAlign: 'center', fontWeight: 600, margin: 8 }}>
          Bridge the gap between software development and the financial side of the organization by understanding work
          being done in dollars.
        </SmallerHeading>
        <StyledButton
          size={'medium'}
          type={'primary'}
          firstColor={newCOLORS.indigo}
          secondColor={newCOLORS.white}
          disable={false}
          onClick={() =>
            navigate(`/application/financials/portfolio/${portfolio?.id}/add-edit-financials`, {
              state: { from: 'application' },
            })
          }
          style={{ margin: 8 }}
        >
          Add financial data
        </StyledButton>
      </AddFinancialDataImageContainer>
    </AddFinancialDataContainer>
  );
};

const AddFinancialDataContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 120px;
`;

const HeaderAndSelectContainer = styled.div`
  align-self: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const AddFinancialDataImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 760px;
  padding: 16px;
  background-color: ${newCOLORS.white};
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;
