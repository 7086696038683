import { Radio } from '@mantine/core';
import styles from '../views/views.module.css';
import { FigmaProject, FigmaState } from './figma.type';

type ProjectsProps = {
  figmaState: FigmaState;
  updateState: (update: Partial<FigmaState>) => void;
};

export const Projects = ({ figmaState, updateState }: ProjectsProps) => {
  const handleProjectSelected = (project: FigmaProject) => {
    updateState({ selectedProject: project.id });
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select a project you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(figmaState.projectOptions || []).map((project: FigmaProject) => (
          <div
            key={project.name}
            className={
              project.id === figmaState.selectedProject
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleProjectSelected(project)}
          >
            <Radio
              color="red.5"
              styles={{ label: { fontWeight: 600, marginLeft: '30px' } }}
              value={project.name}
              checked={project.id === figmaState.selectedProject}
              // onChange={() => handleProjectSelected(project)}
            />
            <span className={styles.checkboxLabel}>{project.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
