import mixpanel from 'mixpanel-browser';
import { AnalyticsDimensionsProps, AnalyticsEventType } from './analytics-event.type';

const mixpanelKey = import.meta.env.VITE_APP_MIXPANEL_KEY || false;

const setDimensionsObjectForProps = ({ userContext, project, issue, statistic, sprint }: AnalyticsDimensionsProps) => {
  const obj: any = {};
  const id: string | undefined = userContext?.id;
  if (id && id !== '') {
    obj['user_id'] = id;
  }

  const clientName: string | undefined = userContext?.clientName;
  if (clientName && clientName !== '') {
    obj['company_name'] = clientName;
  } else {
    obj['customer_email'] = userContext?.email;
  }

  if (id && userContext?.email && userContext?.name) {
    mixpanel.identify(userContext?.email);
    mixpanel.people?.set({ $distinct_id: id, $name: userContext?.name, $email: userContext?.email });
  }

  if (project) {
    obj['project_name'] = project.name;
  }

  if (issue) {
    obj['issue_title'] = issue.title;
  }

  if (statistic) {
    obj['statistic'] = statistic;
  }

  if (sprint) {
    obj['sprint_name'] = sprint;
  }
  // TODO: cd7 for initiative, not yet built
  return obj;
};

export const trackEvent = (event: AnalyticsEventType, dimensionProps: AnalyticsDimensionsProps) => {
  if (!mixpanelKey) {
    return;
  }

  const dimObj = setDimensionsObjectForProps(dimensionProps);
  mixpanel.track(event, dimObj);
};
