import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Fragment, useContext, useState } from 'react';
import { IssueDetailsResult } from '../../api/issues-client/issues-client.class';
import { Project } from '../../api/projects-client/projects-client.type';
import { ChangeRequest } from '../../api/sprints-client/sprints-client.type';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../styles/colors';
import { GithubSortType } from '../issue-details/issue-details.type';
import { MeasureRelatedDetails } from '../measure-related-details/measure-related-details';

const sortChangeRequestsByTitle = (lhs: ChangeRequest, rhs: ChangeRequest): number => {
  if (lhs.title === rhs.title) {
    return 0;
  }
  return lhs.title < rhs.title ? -1 : 1;
};

const sortChangeRequestsByCreatedDate = (lhs: ChangeRequest, rhs: ChangeRequest): number => {
  if (lhs.created_at === rhs.created_at) {
    return 0;
  }
  return lhs.created_at < rhs.created_at ? -1 : 1;
};

const sortChangeRequestsByNumber = (lhs: ChangeRequest, rhs: ChangeRequest): number => {
  if (lhs.number === rhs.number) {
    return 0;
  }
  return lhs.number < rhs.number ? -1 : 1;
};

export const sortChangeRequests = (type: string, unsortedChangeRequests: ChangeRequest[]): ChangeRequest[] => {
  const tasksCopy = [...unsortedChangeRequests] as ChangeRequest[];
  switch (type) {
    case GithubSortType.Title:
      return tasksCopy.sort(sortChangeRequestsByTitle) as ChangeRequest[];
    case GithubSortType.CreatedDate:
      return tasksCopy.sort(sortChangeRequestsByCreatedDate) as ChangeRequest[];
    case GithubSortType.Number:
      return tasksCopy.sort(sortChangeRequestsByNumber) as ChangeRequest[];
    default:
      return tasksCopy.sort(sortChangeRequestsByNumber) as ChangeRequest[];
  }
};

type RelatedTasksGroupProps = {
  changeRequests: ChangeRequest[];
  title: string;
  project: Project;
  selectedIssueDetails: IssueDetailsResult;
};

export const GithubRelatedChangeRequestGroups = ({
  title,
  changeRequests,
  project,
  selectedIssueDetails,
}: RelatedTasksGroupProps) => {
  const sortTypes = [GithubSortType.Number, GithubSortType.Title, GithubSortType.CreatedDate];
  const [sortType, setSortType] = useState<string>(sortTypes[0]);
  const { user } = useContext(UserContext);
  const LOOKBACK_PERIOD = [
    { value: GithubSortType.Number, label: GithubSortType.Number },
    { value: GithubSortType.Title, label: GithubSortType.Title },
    { value: GithubSortType.CreatedDate, label: GithubSortType.CreatedDate },
  ];
  const [opened, { toggle }] = useDisclosure(true);

  const sortClickHandler = (type: string) => {
    trackEvent(AnalyticsEventType.SortTapped, {
      userContext: user,
    });
    setSortType(type);
  };

  const sortedRelatedItems = (): ChangeRequest[] => {
    return sortChangeRequests(sortType, changeRequests);
  };

  return (
    <Fragment>
      {sortedRelatedItems() ? (
        sortedRelatedItems().length > 0 ? (
          <RelatedTasksContainer>
            <RightHeadingContainer>
              <TaskNameContainer>
                <CollapseIcon>
                  {opened ? (
                    <Icon
                      icon="icon-park-solid:down-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  ) : (
                    <Icon
                      icon="icon-park-solid:right-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  )}
                </CollapseIcon>
                <RelatedTasksTitle>{`${title} (${sortedRelatedItems().length})`}</RelatedTasksTitle>
              </TaskNameContainer>
              {opened ? (
                <DropDownContainer>
                  <SortHeaderTag>Sort</SortHeaderTag>
                  <Select
                    data={LOOKBACK_PERIOD}
                    value={sortType}
                    onChange={(type) => sortClickHandler(type as string)}
                  />
                </DropDownContainer>
              ) : null}
            </RightHeadingContainer>
            <Collapse in={opened}>
              <MeasureRelatedDetails
                changeRequests={sortedRelatedItems()}
                project={project}
                github={true}
                issue={selectedIssueDetails}
              />
            </Collapse>
          </RelatedTasksContainer>
        ) : (
          <RelatedTasksContainer>
            <RightHeadingContainer>
              <TaskNameContainer>
                <CollapseIcon>
                  {opened ? (
                    <Icon
                      icon="icon-park-solid:down-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  ) : (
                    <Icon
                      icon="icon-park-solid:right-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  )}
                </CollapseIcon>
                <RelatedTasksTitle>{`${title} (${sortedRelatedItems().length})`}</RelatedTasksTitle>
              </TaskNameContainer>
            </RightHeadingContainer>
          </RelatedTasksContainer>
        )
      ) : null}
    </Fragment>
  );
};

const RightHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const RelatedTasksContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  margin-top: 16px;
  border-radius: 4px;
  background: ${newCOLORS.white};
  box-shadow: 0px 2px 3px 0px ${newCOLORS.lightGray};
`;

const TaskNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RelatedTasksTitle = styled.h3`
  font-size: 19px;
  font-weight: 900;
  color: ${newCOLORS.darkerGray};
  padding-left: 10px;
  border-left: 1px solid RGBA(0, 0, 0, 0.2);
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SortHeaderTag = styled.div`
  font-weight: 600;
  cursor: default;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  color: ${newCOLORS.darkGray};
`;

const CollapseIcon = styled.div`
  display: flex;
  font-weight: 800;
  cursor: pointer;
  padding-bottom: 10px;
  padding-right: 12px;
  padding-left: 0px;
`;
