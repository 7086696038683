import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';

/** Formats projects data for the select input.
 *
 * @param projects: ProjectsResponse[]
 * @param selectedProjects: ProjectsResponse[]
 * @returns { label: string, value: string }[]
 */
function formatProjectsData(
  projects: ProjectsResponse[] | undefined,
  selectedProjects: ProjectsResponse[]
): { label: string; value: string }[] {
  return projects?.length
    ? projects
        .filter((p) => !selectedProjects.map((sp) => sp.id).includes(p.id))
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        .map((p) => ({ label: p.name, value: p.id }))
    : [];
}

/** Handles the selection of a project.
 *
 * @param value: string | null - the selected project id
 * @param projects: ProjectsResponse[] - the list of projects
 * @param selectedProjects: ProjectsResponse[] - the list of selected projects
 * @param setState: (value: ProjectsResponse[]) => void - the state setter function
 */
function handleSelectProject(
  value: string | null,
  projects: ProjectsResponse[],
  selectedProjects: ProjectsResponse[],
  setState: (value: ProjectsResponse[]) => void
) {
  const project = projects.find((p) => p.id === value);
  project ? setState([...selectedProjects, project]) : setState(selectedProjects);
}

/** Formats portfolios data for the select input.
 *
 * @param portfolios: Portfolio[]
 * @param selectedPortfolios: Portfolio[]
 * @returns { label: string, value: string }[]
 */
function formatPortfoliosData(
  portfolios: Portfolio[] | undefined,
  selectedPortfolios: Portfolio[]
): { label: string; value: string }[] {
  return portfolios?.length
    ? portfolios
        .filter((p) => !selectedPortfolios.map((sp) => sp.id).includes(p.id))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((p) => ({ label: p.name, value: p.id }))
    : [];
}

/** Handles the selection of a portfolio.
 *
 * @param value: string | null - the selected portfolio id
 * @param portfolios: Portfolio[] - the list of portfolios
 * @param selectedPortfolios: Portfolio[] - the list of selected portfolios
 * @param setState: (value: Portfolio[]) => void - the state setter function
 */
function handleSelectPortfolios(
  value: string | null,
  portfolios: Portfolio[],
  selectedPortfolios: Portfolio[],
  setState: (value: Portfolio[]) => void
) {
  const portfolio = portfolios.find((p) => p.id === value);
  portfolio ? setState([...selectedPortfolios, portfolio]) : setState(selectedPortfolios);
}

export { formatPortfoliosData, formatProjectsData, handleSelectPortfolios, handleSelectProject };
