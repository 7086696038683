import { Button, Chip, Divider, Group, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchProject, updateProject } from '../../api/projects-client/projects-client';
import { newCOLORS } from '../../styles/colors';
import { OuterPaddingDiv } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { SettingsState } from './project-settings.type';

const initialState = {
  name: '',
  sprint_length_in_days: '',
  pm_tool: '',
  backlog_sections: [],
  in_progress_sprint_sections: [],
  in_blocked_sprint_sections: [],
  in_review_sprint_sections: [],
  in_test_sprint_sections: [],
  ready_for_deployment_sprint_sections: [],
  active_sprint_sections: [],
  sections_done: [],
};

export function ProjectSettings() {
  const { projectId } = useParams();
  const [allProjectBoardSections, setAllProjectBoardSections] = useState([]);
  const [state, dispatch] = useReducer(settingsReducer, initialState);

  useQuery(['project', projectId], () => fetchProject(projectId as string), {
    enabled: !!projectId && !state.name,
    onSuccess: (data) => {
      dispatch({
        payload: {
          name: data.name,
          pm_tool: data.pm_tool,
          start_date: data?.start_date?.toString() as string,
        },
      });

      // assemble all possible project board sections
      setAllProjectBoardSections(Array.from(new Set([])));
    },
  });

  const handleUpdateProject = useMutation(
    ['updateProject', projectId, state],
    () => updateProject({ id: projectId as string, ...state }),
    {
      onSuccess: (data) => {
        dispatch({
          payload: {
            name: data.name,
            sprint_length_in_days: data.sprint_length_in_days,
            pm_tool: data.pm_tool,
            start_date: data.start_date,
            backlog_sections: data.backlog_sections,
            in_progress_sprint_sections: data.in_progress_sprint_sections,
            in_blocked_sprint_sections: data.in_blocked_sprint_sections,
            in_review_sprint_sections: data.in_review_sprint_sections,
            in_test_sprint_sections: data.in_test_sprint_sections,
            ready_for_deployment_sprint_sections: data.ready_for_deployment_sprint_sections,
            active_sprint_sections: data.active_sprint_sections,
            sections_done: data.sections_done,
          },
        });
        toast.success('Project settings updated successfully');
      },
      onError: () => toast.error('Error updating project settings. Please try again.'),
    }
  );

  function handleSubmit() {
    const { errors, ...rest } = state;

    const newErrors = {} as any;
    Object.entries(rest).map(([key, value]) => {
      if (!value) {
        newErrors[key] = '* required';
      }
    });

    dispatch({ type: 'errors', payload: newErrors });

    if (Object.keys(newErrors).length) {
      return;
    }

    handleUpdateProject.mutate();
  }

  function handleChangeText(type: string, event: ChangeEvent<HTMLInputElement>) {
    dispatch({
      type,
      payload: event.target.value as SettingsState,
    });
  }

  function handleChangeMultiSelect(type: string, values: string[]) {
    dispatch({
      type,
      payload: values as SettingsState,
    });
  }

  const {
    name = '',
    sprint_length_in_days = '',
    pm_tool = '',
    start_date = new Date(),
    backlog_sections = [],
    in_progress_sprint_sections = [],
    in_blocked_sprint_sections = [],
    in_review_sprint_sections = [],
    in_test_sprint_sections = [],
    ready_for_deployment_sprint_sections = [],
    active_sprint_sections = [],
    sections_done = [],
    errors = null,
  } = state;

  const boardSections = [
    { label: 'Backlog', value: backlog_sections, type: 'backlog_sections' },
    { label: 'In Progress', value: in_progress_sprint_sections, type: 'in_progress_sprint_sections' },
    { label: 'Blocked', value: in_blocked_sprint_sections, type: 'in_blocked_sprint_sections' },
    { label: 'In Review', value: in_review_sprint_sections, type: 'in_review_sprint_sections' },
    { label: 'In QA', value: in_test_sprint_sections, type: 'in_test_sprint_sections' },
    {
      label: 'Ready For Deployment',
      value: ready_for_deployment_sprint_sections,
      type: 'ready_for_deployment_sprint_sections',
    },
    { label: 'Active Sprint', value: active_sprint_sections, type: 'active_sprint_sections' },
    { label: 'Done', value: sections_done, type: 'sections_done' },
  ];

  return (
    <MenuDrivenContainer>
      <OuterPaddingDiv>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: newCOLORS.lighterGray,
          }}
        >
          <h2 style={{ fontWeight: 'bolder' }}>Project Settings</h2>
          <span style={{ padding: '2em 0', maxWidth: '20em', display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <TextInput label="Name" placeholder="Project Name" value={name} disabled />
          </span>
          <Divider />
          <span style={{ padding: '2em 0', maxWidth: '20em', display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <TextInput label="Project Management Tool" placeholder="Project Management Tool" value={pm_tool} disabled />
            <TextInput
              label="Sprint Length"
              type="number"
              placeholder="Sprint Length"
              value={sprint_length_in_days}
              onChange={(event) => handleChangeText('sprint_length_in_days', event)}
              error={errors?.sprint_length_in_days && !sprint_length_in_days ? errors.sprint_length_in_days : null}
            />
            <DatePickerInput
              label="Start Date"
              {...{ placeholder: 'Start Date' }}
              value={new Date(start_date)}
              onChange={(value) =>
                value && dispatch({ type: 'start_date', payload: value.toString() as SettingsState })
              }
              error={errors?.start_date && !start_date ? errors.start_date : null}
            />
          </span>
          <Divider />
          <>
            {boardSections.map(({ label, value, type }) => (
              <span key={type} style={{ display: 'flex', flexDirection: 'column', paddingBottom: '1em' }}>
                <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0, paddingTop: '1em' }}>{label}</p>
                <Chip.Group multiple value={value} onChange={(values) => handleChangeMultiSelect(type, values)}>
                  <Group align="left" mt={15}>
                    {allProjectBoardSections.map((section) => (
                      <Chip value={section} key={section}>
                        {section}
                      </Chip>
                    ))}
                  </Group>
                </Chip.Group>
              </span>
            ))}
          </>
          <span style={{ padding: '1em 0', width: '100%' }}>
            <Button
              style={{
                color: newCOLORS.white,
                cursor: 'pointer',
                width: 'inherit',
                height: '2.5em',
                maxWidth: '10em',
                backgroundColor: newCOLORS.blue,
                borderRadius: '3px',
                border: 'none',
                textTransform: 'uppercase',
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </span>
        </div>
      </OuterPaddingDiv>
    </MenuDrivenContainer>
  );
}

function settingsReducer(state: SettingsState, action: { type?: string; payload: SettingsState }) {
  return action.type ? { ...state, [action.type]: action.payload } : { ...state, ...action.payload };
}
