import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRef } from 'react';
import { DownloadPNGButton } from '../../../components/download-png-button.tsx';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../../styles/colors';
import { SubtitleHeadingTag } from '../../../styles/shared-styled-components';
import { InitiativeMetricType } from './initiative-performance.type';
type Props = {
  titleElement: React.ReactElement;
  title: string;
  metric: InitiativeMetricType;
  setMetric: (metric: InitiativeMetricType) => void;
  children: React.ReactNode;
};

export function CollapsibleSection({ titleElement, title, metric, setMetric, children }: Props) {
  const [opened, { toggle }] = useDisclosure(true);
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const icon = opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one';
  const handleDownload = () => canvasHtmlDownload(title, downloadRef, exceptionRef);

  return (
    <CollapsibleSectionContainer ref={downloadRef}>
      <HeadingContainer>
        <HeadingTitle>
          <CollapseIcon>
            <Icon icon={icon} width={16} height={16} color={newCOLORS.indigo} onClick={toggle} />
          </CollapseIcon>
          <HeadingTitleText>{titleElement}</HeadingTitleText>
        </HeadingTitle>
        {opened && (
          <Actions metric={metric} setMetric={setMetric} handleDownload={handleDownload} exceptionRef={exceptionRef} />
        )}
      </HeadingContainer>
      <Collapse in={opened}>
        <CardContentContainer>{children}</CardContentContainer>
      </Collapse>
    </CollapsibleSectionContainer>
  );
}

export const selectData = [
  { value: InitiativeMetricType.Tasks, label: 'Tasks' },
  { value: InitiativeMetricType.Points, label: 'Points' },
];

const Actions = ({
  metric,
  setMetric,
  handleDownload,
  exceptionRef,
}: {
  metric: InitiativeMetricType;
  setMetric: (metric: InitiativeMetricType) => void;
  handleDownload: () => void;
  exceptionRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <ActionsContainer>
      <SelectContainer>
        <SelectLabel>Value</SelectLabel>
        <Select
          label=""
          size="xs"
          style={{ width: 140 }}
          value={metric}
          data={selectData}
          onChange={(value: string | null) => {
            if (value) {
              setMetric(value as InitiativeMetricType);
            }
          }}
        />
      </SelectContainer>
      <DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />
    </ActionsContainer>
  );
};

const CollapsibleSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px 24px;
  gap: 16px;
`;

const CardContentContainer = styled.div`
  display: flex;
  gap: 48px;
  width: 100%;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const HeadingTitle = styled.div`
  display: flex;
`;

const HeadingTitleText = styled(SubtitleHeadingTag)`
  padding: 12px 12px 12px 6px;
  font-weight: 800;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 25px;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SelectLabel = styled.div`
  color: #666;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
`;
