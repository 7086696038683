import { styled } from '@linaria/react';
import { ComboboxItem, Select } from '@mantine/core';
import { generateDateStringArray } from '../../helpers/string-helpers/string-helpers';

type DaySelectProps = {
  startDate: string;
  value: string;
  lengthOfSprint: number;
  currentDay: number;
  onChange: (value: string) => void;
};

export const DaySelect = ({ startDate, lengthOfSprint, currentDay, onChange, value }: DaySelectProps) => {
  return (
    <DaySelectContainer>
      <DayLabel>Day</DayLabel>
      <SelectContainer>
        <Select
          value={value}
          data={generateDateStringArray(startDate, lengthOfSprint, currentDay)}
          onChange={(value: string | null, _: ComboboxItem) => {
            if (value) {
              onChange(value);
            }
          }}
        />
      </SelectContainer>
    </DaySelectContainer>
  );
};

const DaySelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const DayLabel = styled.div`
  display: flex;
`;

const SelectContainer = styled.div`
  display: flex;
  max-width: 150px;
`;
