const getBoundedEndDate = (dateInput: Date): Date => {
  // Get today's date
  const today = new Date();

  // Set time parts to zero for accurate date-only comparison
  const inputDateOnly = new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate());
  const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  // Compare dates and return the appropriate one
  if (inputDateOnly < todayDateOnly) {
    return inputDateOnly;
  } else {
    return todayDateOnly;
  }
};

export { getBoundedEndDate };
