import { styled } from '@linaria/react';
import { HoverCard } from '@mantine/core';
import { UserAvatar } from '../../api/auth-client/auth-client.type';
import { newCOLORS } from '../../styles/colors';

export const getInitials = (name: string) => {
  // remove any titles
  name = name.replace(/\b(Mrs?\.?|Ms\.?|Dr\.?|PhD|Mr\.?|Miss|Sir)\b/gi, '');

  // remove any periods after initials
  name = name.replace(/\b([A-Z])\./g, '$1');

  // remove suffixes and Roman numerals at end of name
  name = name.replace(/\b(Jr\.?|Sr\.?|[IVX]+)\b/gi, '').trim();

  // extract first letter of first name and each middle name
  let initials = name.match(/\b(\w|-)/g);

  // if no initials, return empty string
  if (!initials) {
    return '';
  } else if (initials.length == 1) {
    return initials.join('').toUpperCase();
  } else {
    initials = [initials.at(0) as string, initials.at(-1) as string];
    return initials.join('').toUpperCase();
  }
};

export const getUserColor = (user: UserAvatar | null): string => {
  if (user?.subscription_status === 'paid') {
    return newCOLORS.lightPurple;
  }

  if (user?.last_login) {
    const lastLogin = new Date(user?.last_login);
    const now = new Date();

    const timeDiff = now.getTime() - lastLogin.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    // Time difference is greater than a week
    if (daysDiff < 7) {
      return newCOLORS.pink;
    }
  }

  return newCOLORS.darkYellow;
};

type UserProps = {
  user: UserAvatar | null;
};

export function User({ user }: UserProps) {
  return (
    <Container>
      <HoverCard width={200} shadow="md" position="bottom-start" offset={28}>
        <HoverCard.Target>
          <AvatarBox style={{ backgroundColor: getUserColor(user) }}>
            {user && user.name ? getInitials(user.name) : '.'}
          </AvatarBox>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <TextContainer>
            <Text>{user ? user.name : 'Loading'}</Text>
            <Text>{user ? user.email : 'Loading'}</Text>
          </TextContainer>
        </HoverCard.Dropdown>
      </HoverCard>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1em;
`;

const AvatarBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 1px solid black;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  row-gap: 5px;
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
`;
