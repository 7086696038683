import dayjs from 'dayjs';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { Tab, WorkPeriodType } from '../../containers/process-analysis/process-analysis.type';
import { getRecentlyCompletedMonth } from '../../helpers/general-helpers';
import { isLoggingEnabled, logger } from '../logger/logger';
import { State } from './process-analysis-store.type';

const useProcessAnalysisStore = create<State>()(
  devtools(
    persist(
      logger((_) => ({
        initialized: false,
        availableMeasures: [],
        activeTab: Tab.Portfolios,
        portfolioIds: [],
        teamIds: [],
        boardIds: [],
        timeAllocations: [],
        workPeriodBoardId: null,
        workPeriodType: WorkPeriodType.Defined,
        workPeriods: [],
        workPeriod: null,
        startDate: dayjs(getRecentlyCompletedMonth()).startOf('month').toISOString(),
        endDate: dayjs(getRecentlyCompletedMonth()).toISOString(),
        sprintId: null,
        localMessage: null,
        isDirtyPortfolios: false,
        isDirtyTeams: false,
        isDirtyBoards: false,
        isDirtyWorkPeriods: false,
      })),
      {
        name: 'process-analysis-storage',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) =>
                ![
                  'initialized',
                  'localMessage',
                  'isDirtyPortfolios',
                  'isDirtyTeams',
                  'isDirtyBoards',
                  'isDirtyWorkPeriods',
                ].includes(key)
            )
          ),
      }
    ),
    {
      name: 'Bloomfilter - Process Analysis Store',
      enabled: isLoggingEnabled,
    }
  )
);

export { useProcessAnalysisStore };
