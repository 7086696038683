import { useCallback } from 'react';
import { ProjectView } from '../views/project-view';
import { JDCReducerAction, JDCState } from './jdc.type';

type Props = {
  state: JDCState;
  dispatch: React.Dispatch<JDCReducerAction>;
};

export function Projects({ state, dispatch }: Props) {
  const projects = (state.options?.integrations || []).map((p) => ({
    id: p.internal_id,
    name: p.name,
  }));

  const handleChange = useCallback(
    (projectId: string) => {
      const project = (state.options?.integrations || []).find((p) => p.internal_id === projectId);
      dispatch({ type: 'project', payload: project });
    },
    [dispatch, state.options?.integrations]
  );

  return (
    <ProjectView projects={projects} selectedProjectId={state.project?.internal_id || null} onChange={handleChange} />
  );
}
