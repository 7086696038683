import { IssueDescribable, IssueDetailsTask, ReplaceableStringsDict } from './issues-client.type';

class Issue implements IssueDescribable {
  id: string;
  title: string;
  summary: string;
  amount?: string;
  trend: string;
  tooltip: string;

  constructor(id: string, title: string, summary: string, trend: string, tooltip: string, amount?: string) {
    this.id = id;
    this.title = title;
    this.summary = summary;
    this.amount = amount;
    this.trend = trend;
    this.tooltip = tooltip;
  }
}

class IssueDetailsResult implements IssueDescribable {
  id: string;
  tasks: IssueDetailsTask[] = [];
  task_count: number | null;
  amount: string;
  trend: string;
  title: string;
  summary: string;
  description: string;
  solution: string;
  calculation: string | undefined;
  display_solution: boolean;
  display_schedule_meeting: boolean;
  issue_type: number;
  replacements: ReplaceableStringsDict;
  tooltip: string;

  constructor() {
    this.id = '0';
    this.tasks = [];
    this.amount = '';
    this.title = '';
    this.summary = '';
    this.description = '';
    this.solution = '';
    this.calculation = '';
    this.display_solution = true;
    this.display_schedule_meeting = true;
    this.issue_type = 0;
    this.replacements = {};
    this.trend = '';
    this.task_count = null;
    this.tooltip = '';
  }
}

export { Issue, IssueDetailsResult };
