import { styled } from '@linaria/react';
import { ErrorBoundary } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Sprint,
  SprintBurnsAndFlows,
  SprintMetrics,
  SprintMetricsCumulativeFlow,
  SprintProjections,
  SprintStatusPercentagesStatuses,
} from '../../../api/sprints-client/sprints-client.type';
import { AssessmentDetailCard } from '../../../components/assessment-detail-card/assessment-detail-card';
import { BloomcastPhaseBox } from '../../../components/bloomcast-by-phase-box/bloomcast-by-phase-box';
import { CumulativeFlowChart } from '../../../components/burn-flow/burn-flow-chart';
import { ViewByType } from '../../../components/burn-flow/burn-flow-chart.type';
import { newCOLORS } from '../../../styles/colors';
import { ContentTag, HeadingTag } from '../../../styles/shared-styled-components';
import { Mode } from '../../process-analysis/process-analysis.type';
import { ChartContainer } from '../chart-container';
import { assessmentDetails, defaultDataPoint } from '../sprint-assessment.data';
import { spliceByDay } from '../sprint-assessment.helpers';
import { useDayCalculation } from '../sprint-assessment.hooks';
import { SprintAssessmentChart } from '../sprint-assessment.type';

type FlowOfWorkByPhaseProps = {
  sprint: Sprint;
  projections?: SprintProjections;
  projectionsLoading?: boolean;
  sprintBurnsAndFlows: SprintBurnsAndFlows;
  sprintMetrics: SprintMetrics;
};

export const FlowOfWorkByPhase = ({
  sprint,
  projections,
  projectionsLoading,
  sprintBurnsAndFlows,
  sprintMetrics,
}: FlowOfWorkByPhaseProps) => {
  const [dayCount, setDayCount] = useState<number>(0);
  const [currentDataType, setCurrentDataType] = useState<ViewByType>(ViewByType.tasks);
  const [dataPoint, setDataPoint] = useState<SprintStatusPercentagesStatuses | null>(null);
  const [flowsTasks, setFlowsTasks] = useState<SprintMetricsCumulativeFlow>(sprintBurnsAndFlows.flows_tasks);
  const [flowsPoints, setFlowsPoints] = useState<SprintMetricsCumulativeFlow>(sprintBurnsAndFlows.flows_points);
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  const navigate = useNavigate();

  const { newDay, setNewDay, currentDay } = useDayCalculation(sprint, sprintBurnsAndFlows);

  useEffect(() => {
    // Set the total number of days in the sprint
    setDayCount(
      currentDataType === ViewByType.tasks
        ? sprintBurnsAndFlows.flows_tasks.data_points.length
        : sprintBurnsAndFlows.flows_points.data_points.length
    );

    const key = sprintMetrics.status_percentages
      ? Object.keys(sprintMetrics.status_percentages)[Number(newDay) - 1]
      : null;

    setDataPoint(
      key
        ? currentDataType === ViewByType.tasks
          ? sprintMetrics.status_percentages[key].tasks
          : sprintMetrics.status_percentages[key].points
        : defaultDataPoint
    );

    const sprintFlowsTasks = spliceByDay(sprintBurnsAndFlows.flows_tasks, newDay);
    setFlowsTasks(sprintFlowsTasks);

    const sprintFlowsPoints = spliceByDay(sprintBurnsAndFlows.flows_points, newDay);
    setFlowsPoints(sprintFlowsPoints);
  }, [newDay, currentDataType, sprintBurnsAndFlows, sprintMetrics]);

  const title = (
    <>
      <LargeHeadingTag>
        <LargeHeadingTagAnchor
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => navigate(`/application/project/${projectId}/sprint-assessment/${sprintId}/task-flow`)}
        >
          Flow of work, by phase
        </LargeHeadingTagAnchor>
      </LargeHeadingTag>
      <ContentTag>How each planned task is flowing through the phases of work in this sprint.</ContentTag>
    </>
  );

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <ChartContainer
        chartId={SprintAssessmentChart.Phase}
        sprint={sprint}
        sprintLength={dayCount}
        sprintCurrentDay={Number(currentDay)}
        day={newDay as string}
        onChangeDay={setNewDay}
        view={currentDataType}
        onChangeView={setCurrentDataType}
        title={title}
      >
        <DayDetailsBox>
          <LargeHeadingTag>
            {`Day ${Number(newDay)}`} of {dayCount}
          </LargeHeadingTag>
          <DetailCards>
            {assessmentDetails.map((item) => (
              <AssessmentDetailCard
                key={item.id}
                label={item.label}
                filter={item.id}
                day={Number(newDay) > 0 ? String(Number(newDay) - 1) : String(Number(newDay))}
                description={item.description}
                legend={true}
                color={item.color}
                suffix={'%'}
                value={dataPoint?.[item.id as keyof SprintStatusPercentagesStatuses] || 0}
              />
            ))}
          </DetailCards>
        </DayDetailsBox>
        <DayStatus>
          <BloomcastPhaseBox
            direction={'end'}
            dataType={currentDataType}
            sprintActive={sprint.current_sprint}
            loading={projectionsLoading}
            projections={projections}
          />
        </DayStatus>
        <CumulativeFlowChart
          mode={Mode.Sprint}
          cumulative_flow_points={flowsPoints}
          cumulative_flow_tasks={flowsTasks}
          sprint={sprint}
          sprintBurnsAndFlows={sprintBurnsAndFlows}
          legend={false}
          header={false}
          dataType={currentDataType}
        />
      </ChartContainer>
    </ErrorBoundary>
  );
};

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const LargeHeadingTagAnchor = styled.span`
  color: ${newCOLORS.indigo}};
`;

const DayDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const DetailCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DayStatus = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
