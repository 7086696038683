import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ReactFlowProvider, useNodesInitialized, useReactFlow } from 'reactflow';
import { useProcessGraph, useProcessMapping } from '../../api/process-client/process-client.hooks';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PortfolioContext, ProjectContext, SidebarContext } from '../../contexts';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { PageHeader } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { BreadcrumbContainer } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import { filterProcessMapping } from './process.helpers';
import { useProcessScope } from './process.hooks';
import { PageContainer } from './process.styled';
import { SectionGraph } from './section-graph';
import { SectionSummary } from './section-summary';

export function ProcessPage() {
  useDocumentTitle('Process Map - Bloomfilter');

  const { portfolio: portfolioFromGlobalContext } = useContext(PortfolioContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const { portfolio, team, startDate, endDate, setScope } = useProcessScope();

  const { fitView } = useReactFlow();
  const nodesInitialized = useNodesInitialized();

  const { mapping } = useProcessMapping(team?.id, { staleTime: 1000 * 60 * 5 });

  const { graphData, query } = useProcessGraph(
    {
      teamId: team?.id,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    },
    {
      enabled: Boolean(team?.id),
      staleTime: 1000 * 60 * 5,
    }
  );

  // There are two contexts that can change the portfolio: the global context and the local context
  // whenever one is updated, we need to update the other
  useEffect(() => {
    if (portfolioFromGlobalContext?.id !== portfolio?.id) {
      setScope({ portfolio: portfolioFromGlobalContext });
    }
  }, [portfolio, setScope, portfolioFromGlobalContext]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (graphData && nodesInitialized) {
      timeoutId = setTimeout(fitView);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fitView, graphData, nodesInitialized]);

  const [init, setInit] = useState<boolean>(false);
  useEffectOnce(() => setInit(true));

  return (
    <MenuDrivenContainer containerStyles={{ position: 'relative' }}>
      <BrandedLoadingOverlay visible={query.isFetching} transitionDuration={30} variant="colored" />
      <PageContainer style={{ paddingLeft: 16, paddingBottom: 16 }}>
        {portfolio?.organization && (
          <BreadcrumbContainer style={{ paddingBottom: 0, height: 40 }}>
            <Breadcrumb
              crumbItems={[
                {
                  labelName: portfolio.organization?.name || 'Portfolio',
                  href: '/application/dashboard',
                  onNavigate: () => {
                    onPortfolioBreadcrumbClick(setProject, navItems, setNavItems);
                  },
                },
                { labelName: 'Process Map' },
              ]}
            />
          </BreadcrumbContainer>
        )}
        <PageHeader>Process Map</PageHeader>
        {init && (
          <>
            <SectionGraph graphData={graphData} mapping={filterProcessMapping(mapping)} />
            <SectionSummary completedTasks={graphData?.task_count || 0} />
          </>
        )}
      </PageContainer>
    </MenuDrivenContainer>
  );
}

export function Process() {
  return (
    <ReactFlowProvider>
      <ProcessPage />
    </ReactFlowProvider>
  );
}
