import { styled } from '@linaria/react';
import { ComboboxItem, Select } from '@mantine/core';
import { useWorkPeriodDayOptions } from '../../../../store/process-analysis-store/process-analysis-store.hooks';

type DaySelectProps = {
  value: string;
  onChange: (value: string) => void;
};

export const DaySelect = ({ value, onChange }: DaySelectProps) => {
  const options = useWorkPeriodDayOptions();

  if (!options.length) {
    return null;
  }

  return (
    <DaySelectContainer>
      <DayLabel>Day</DayLabel>
      <SelectContainer>
        <Select
          value={value}
          data={options}
          onChange={(value: string | null, _: ComboboxItem) => {
            if (value) {
              onChange(value);
            }
          }}
        />
      </SelectContainer>
    </DaySelectContainer>
  );
};

const DaySelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const DayLabel = styled.div`
  display: flex;
`;

const SelectContainer = styled.div`
  display: flex;
  max-width: 150px;
`;
