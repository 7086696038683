import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Divider, Flex, Grid } from '@mantine/core';
import { useCallback } from 'react';
import { GLOBAL_MAILTO_SUPPORT_LINK } from '../../../constants';
import { newCOLORS } from '../../../styles/colors';
import {
  CollapseIcon,
  ContentContainer,
  HeadingContainer,
  HeadingTitle,
  HeadingTitleText,
  SectionContainer,
} from './integrations-list.styled';
import { Section } from './integrations-list.type';
import { proposedIntegrations, upcomingIntegrations } from './upcoming-integrations.data';
import { UpcomingIntegration } from './upcoming-integrations.types';

type Props = {
  openedSection: Section | null;
  setOpenedSection: (section: Section | null) => void;
};

export function UpcomingIntegrations({ openedSection, setOpenedSection }: Props) {
  const opened = openedSection === Section.UpcomingIntegrations;
  const icon = opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one';

  const handleCollapseIcon = useCallback(() => {
    if (opened) {
      setOpenedSection(null);
    } else {
      setOpenedSection(Section.UpcomingIntegrations);
      window.scrollTo(0, 250);
    }
  }, [opened, setOpenedSection]);

  return (
    <SectionContainer style={{ gap: 0 }}>
      <HeadingContainer>
        <HeadingTitle>
          <CollapseIcon>
            <Icon icon={icon} width={16} height={16} color={newCOLORS.darkerGray} onClick={handleCollapseIcon} />
          </CollapseIcon>
          <HeadingTitleText>Upcoming integrations</HeadingTitleText>
        </HeadingTitle>
      </HeadingContainer>
      <Collapse in={opened} style={{ width: '100%' }}>
        <ContentContainer style={{ minWidth: 1000, padding: '12px 40px' }}>
          <Divider size={1} color="#cecece" />
          <Flex direction="column" gap="24px">
            <SectionText>
              We’re working every day to bring additional integrations into Bloomfilter. Each tool helps bring new
              insight into your software development lifecycle process
            </SectionText>
            <Tiles upcomingIntegrations={upcomingIntegrations} />
            <SectionText>Proposed Future Integrations</SectionText>
            <Tiles upcomingIntegrations={proposedIntegrations} />
            <Flex direction="column" style={{ marginBottom: 16 }}>
              <SectionText>
                Don’t see an integration you need? Want to partner with us on testing something?
              </SectionText>
              <ContactLink href={GLOBAL_MAILTO_SUPPORT_LINK}>Let us know!</ContactLink>
            </Flex>
          </Flex>
        </ContentContainer>
      </Collapse>
    </SectionContainer>
  );
}

const Tiles = ({ upcomingIntegrations }: { upcomingIntegrations: UpcomingIntegration[] }) => (
  <Grid>
    {upcomingIntegrations.map((integration) => (
      <Grid.Col key={integration.title} span={2.8}>
        <Tile upcomingIntegration={integration} />
      </Grid.Col>
    ))}
  </Grid>
);

const Tile = ({ upcomingIntegration }: { upcomingIntegration: any }) => (
  <Flex gap={16} style={{ width: 250 }}>
    <img src={upcomingIntegration.icon} alt={upcomingIntegration.title} width={52} height={52} />
    <Flex direction="column" gap={4}>
      <IntegrationTitle>{upcomingIntegration.title}</IntegrationTitle>
      <IntegrationDescription>{upcomingIntegration.description}</IntegrationDescription>
    </Flex>
  </Flex>
);

const SectionText = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

const IntegrationTitle = styled.div`
  color: #595959;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const IntegrationDescription = styled.div`
  color: #595959;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
`;

const ContactLink = styled.a`
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  text-decoration-line: underline;
`;
