import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipModel,
} from 'chart.js';
import { Fragment, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { CostByInitiativeResponse } from '../../api/financials-client/financials-client.type';
import { PortfolioContext } from '../../contexts';
import { costByInitiativeTooltipHandler } from './cost-by-initiative-tooltip';
import { ShowCostByInitiativeChartBar } from './cost-by-initiative.type';
// Register chart.js plugins needed for the chart
ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

type Position = 'left' | 'right' | 'center' | 'top' | 'bottom';

const formatData = (
  data: CostByInitiativeResponse[],
  showCostByInitiativeChartBar: ShowCostByInitiativeChartBar[]
): ChartData<'bar'> => {
  const labels = Object.keys(data[0].chart_data.initiative).map((date_str) => {
    // date_str is in the format YYYY-MM
    const split_date = date_str.split('-');
    return `${new Date(0, parseInt(split_date[1]) - 1).toLocaleString('default', {
      month: 'short',
    })} ${split_date[0].slice(-2)}`;
  });

  const dataset: ChartData<'bar'> = {
    labels: labels,
    datasets: [],
  };

  for (const initiative of data) {
    const showData = showCostByInitiativeChartBar.find((item) => item.id === initiative.id);
    if (showData?.show || false) {
      dataset.datasets.push({
        label: initiative.id,
        data: Object.values(initiative.chart_data.initiative),
        backgroundColor: showData.color,
        borderColor: showData.color,
        borderWidth: 0,
        yAxisID: 'y',
      });
    }
  }

  return dataset;
};

type CostByInitiativeChartProps = {
  chartData: CostByInitiativeResponse[] | null;
  showCostByInitiativeChartBar: ShowCostByInitiativeChartBar[];
};

export const CostByInitiativeChart = ({ chartData, showCostByInitiativeChartBar }: CostByInitiativeChartProps) => {
  const { portfolio } = useContext(PortfolioContext);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },

    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        position: 'left' as Position,
        title: {
          display: true,
          text: 'USD',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          callback: (value: number | string) => {
            return Number(value) >= 1000 ? `$${Number(value) / 1000}k` : `$${value}`;
          },
        },
        stacked: true,
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        enabled: false,
        external: (context: { chart: ChartJS; tooltip: TooltipModel<'bar'> }) =>
          costByInitiativeTooltipHandler(context, chartData, portfolio?.id),
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  return (
    <Fragment>
      {chartData && chartData?.length > 0 ? (
        <Bar data={formatData(chartData, showCostByInitiativeChartBar)} options={options} />
      ) : null}
    </Fragment>
  );
};
