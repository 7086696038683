import { IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { Integration } from './integrations-list.type';

const availableIntegrations: Integration[] = [
  {
    name: IntegrationType.JIRA,
    title: 'Atlassian Jira',
    description: 'Proprietary issue tracking product that allows bug tracking and agile project management.',
  },
  {
    name: IntegrationType.GITHUB,
    title: 'GitHub',
    description: 'A platform and cloud-based service for software development and version control using Git.',
  },
  {
    name: IntegrationType.ADO,
    title: 'Azure DevOps',
    description: 'Version control, reporting, requirements management, project management, automated builds and more.',
  },
];

export { availableIntegrations };
