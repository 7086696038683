import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Background,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import { Issue } from '../../api/issues-client/issues-client.class';
import { fetchAgingWIP, fetchSprint } from '../../api/sprints-client/sprints-client';
import { AgingWIPResponse, Sprint } from '../../api/sprints-client/sprints-client.type';
import { IssueCard } from '../../components/issue-card/issue-card';
import { ProjectHealthBar } from '../../components/project-health-bar/project-health-bar';
import { HealthBarStyle } from '../../components/project-health-bar/project-health-bar.type';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsDimensionsProps, AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../styles/colors';
import {
  IssueCardWrapper,
  IssueGridContainer,
  OuterPaddingDiv,
  SubtitleHeadingTag,
  TitleHeadingTag,
} from '../../styles/shared-styled-components';
import { IssueHistoryState } from '../issue-details/issue-details.type';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { WorkInProgressHistoryState } from './work-in-progress.type';

const CHARTWIDTH = 1200;

export const WorkInProgressDetails = () => {
  const { user } = useContext(UserContext) as any;
  const navigate = useNavigate();
  const location = useLocation();
  const [agingWIP, setAgingWIP] = useState<AgingWIPResponse>();
  const [usableSprint, setUsableSprint] = useState<Sprint>();
  const { sprintId = '' } = useParams<{ sprintId: string }>();

  const historyState = location.state as WorkInProgressHistoryState;

  useQuery(
    ['agingWIP', sprintId],
    () => (sprintId ? fetchAgingWIP(sprintId) : Promise.reject('SprintId is required!')),
    {
      onSuccess: (agingWIP: AgingWIPResponse) => setAgingWIP(agingWIP),
    }
  );

  useQuery(['sprint', sprintId], () => (sprintId ? fetchSprint(sprintId) : Promise.reject('SprintId is required!')), {
    onSuccess: (sprint: Sprint) => setUsableSprint(sprint),
  });

  const issueClick = (issue: Issue) => {
    if (!historyState.project) {
      return;
    }
    const props: AnalyticsDimensionsProps = { userContext: user, project: historyState.project, issue };
    trackEvent(AnalyticsEventType.WIPIssueTapped, props);
    const state: IssueHistoryState = { project: historyState.project, selectedMenuItem: 'Project', sprintId: sprintId };
    navigate(`/application/issues/${issue.id}`, { state });
  };

  const maxXValuesPerSection = agingWIP ? Math.max(...agingWIP.data.map((value) => value.data_points.length)) : 0;

  const scatterPointForSection = (section: number) => {
    if (!agingWIP) {
      return [];
    }
    const xOffset = section * maxXValuesPerSection;
    return agingWIP.data[section].data_points.map((point, index) => {
      return { x: index + xOffset, y: point.days, label: point.days, title: point.name };
    });
  };
  const dataSet = agingWIP ? agingWIP.data.map((_data, section) => scatterPointForSection(section)) : [];
  const dataSetFlattened = dataSet.flatMap((sections) => sections.map((point) => point));

  if (!agingWIP) {
    return <MenuDrivenContainer />;
  }

  const axisStyle = () => {
    return {
      axis: { stroke: 'transparent' },
      ticks: { stroke: 'transparent' },
      tickLabels: { fill: 'transparent' },
      grid: { stroke: 'transparent' },
    };
  };

  const yAxisMax = Math.max(...dataSet.flatMap((sections) => sections.map((point) => point.y))) * 1.2;
  const yAxisMin = 0;
  const chartPadding = { top: 10, bottom: 10, right: 50, left: 150 };
  const domainXMax = maxXValuesPerSection * dataSet.length + 1;
  const barData = agingWIP
    ? agingWIP.data.map((section, index) => {
        return { x: index * maxXValuesPerSection + 1, y: yAxisMax, title: section.section };
      })
    : [];
  const lineDataForSection = (section: number) => {
    return [
      { x: section * maxXValuesPerSection - 0.5, y: 0 },
      { x: section * maxXValuesPerSection - 0.5, y: section === 0 ? 0 : yAxisMax }, // hide line if x === 0
    ];
  };
  const dotSize = 10;

  return (
    <MenuDrivenContainer project={historyState.project}>
      <OuterPaddingDiv>
        <TitleHeadingTag>Overview</TitleHeadingTag>
        <SubtitleHeadingTag>Work In Progress health, warnings, and issues</SubtitleHeadingTag>
        {historyState.project && usableSprint ? (
          <ProjectHealthBar
            health={usableSprint.health}
            style={HealthBarStyle.large}
            showNumber={true}
            shouldAnimate={true}
          />
        ) : null}
        {historyState.project && usableSprint ? (
          <IssueGridContainer>
            {usableSprint.issues.map((issue: Issue) => {
              return (
                <IssueCardWrapper key={issue.id}>
                  <IssueCard issue={issue} onClick={issueClick} />
                </IssueCardWrapper>
              );
            })}
          </IssueGridContainer>
        ) : null}
        <br />
        <br />

        {/* Cumulative Flow begin - DEPRECATED 3/2/23 */}
        {/* cumulativeFlow && (
            <CumulativeFlowChart
              cumulative_flow={cumulativeFlow}
              showToggleDropdown={false}
              project={historyState.project}
            />
          )*/}
        {/* Cumulative Flow end */}

        <br />
        <br />

        {/* Aging WIP begin */}
        <TitleHeadingTag>{agingWIP.title}</TitleHeadingTag>
        <SubtitleHeadingTag>{agingWIP.description}</SubtitleHeadingTag>
        <ChartAreaContainer>
          <VictoryChart
            key={'chart'}
            height={500}
            width={CHARTWIDTH}
            padding={chartPadding}
            domain={{ x: [-1, domainXMax], y: [0, yAxisMax] }}
            style={{
              background: { fill: `${newCOLORS.white}` },
            }}
            backgroundComponent={<Background y={0} height={500} />}
          >
            <VictoryAxis style={axisStyle()} />
            <VictoryAxis
              dependentAxis
              style={axisStyle()}
              domain={[yAxisMin, yAxisMax]}
              tickLabelComponent={<VictoryLabel renderInPortal style={{ fill: 'black', fontSize: 28 }} />}
              offsetX={100}
            />
            <VictoryBar
              data={barData}
              width={CHARTWIDTH}
              alignment="middle"
              labels={({ datum }) => datum.title}
              style={{
                labels: { fill: 'black' },
                data: {
                  fill: 'transparent',
                },
              }}
              labelComponent={<VictoryLabel style={{ fill: 'black', fontSize: 20 }} dy={510} />}
            />
            {agingWIP &&
              agingWIP.data
                .map((_section, index) => lineDataForSection(index))
                .map((points) => {
                  return (
                    <VictoryLine
                      key={`${points.map((point) => point.x + point.y)}`}
                      style={{
                        data: {
                          stroke: `${newCOLORS.lighterGray}`,
                          strokeWidth: 2,
                        },
                      }}
                      data={points}
                    />
                  );
                })}
            <VictoryScatter // hidden by next scatter, to show label above dot
              containerComponent={<VictoryContainer responsive={false} />}
              data={dataSetFlattened}
              size={dotSize * 0.75}
              style={{ labels: { fill: 'black', fontSize: 18 } }}
              labels={({ datum }: any) => datum.y}
              labelComponent={
                <VictoryLabel dy={dotSize * -1.5} renderInPortal style={{ fill: 'black', fontSize: 28 }} />
              }
            />
            <VictoryScatter // above other scatter in z-space, to enable tooltip on hover
              containerComponent={<VictoryContainer responsive={false} />}
              data={dataSetFlattened}
              size={dotSize}
              style={{ labels: { fill: 'black', fontSize: 18 } }}
              labels={({ datum }: any) => datum.y}
              labelComponent={
                <VictoryTooltip
                  renderInPortal
                  text={({ datum }) => datum.title}
                  labelComponent={<VictoryLabel renderInPortal style={{ fill: 'black', fontSize: 28 }} />}
                  flyoutHeight={100}
                  flyoutWidth={({ datum }) => 25 * datum.title.length}
                />
              }
              domainPadding={{ x: 0, y: 0 }}
            />
          </VictoryChart>
        </ChartAreaContainer>
        {/* Aging WIP end */}
      </OuterPaddingDiv>
    </MenuDrivenContainer>
  );
};

const ChartAreaContainer = styled.div`
  height: 600px;
  margin-top: 20px;
  padding-bottom: 50px;
  background-color: ${newCOLORS.white};
  min-width: 1000px;
  max-width: ${CHARTWIDTH}px;
  width: ${CHARTWIDTH}px;
`;
