import { ReactNode, createContext, useEffect, useState } from 'react';
import { useUser } from '../api/user-client/user-client.hooks';
import { UserInfo } from '../api/user-client/user-client.type';
import { getRequestToken, logout } from '../helpers/storage/storage';
import { UserContextType } from './user.type';

export const UserContext = createContext({} as UserContextType);

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserInfo | null>(null);

  const { data, query } = useUser({ enabled: !user && Boolean(getRequestToken()) });

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (query.isError) {
      logout();
    }
  }, [query]);

  return <UserContext.Provider value={{ user, setUser, query }}>{children}</UserContext.Provider>;
}
