import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryScatter, VictoryStack } from 'victory';
import { HistoricalSprintData } from '../../api/sprints-client/sprints-client.type';
import { shortDateDisplayFromDateString } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';
import { getColorByValue } from './bar-chart-with-std-dev.helpers';

export type ProjectStatisticsBarChartProps = {
  color?: string;
  historicalSprintData: HistoricalSprintData;
};

export function BarChartStdDev(props: ProjectStatisticsBarChartProps) {
  if (!props.historicalSprintData) {
    return null;
  }

  const { average, stdev, data_points, title, description, bands } = props.historicalSprintData;

  if (!average || !data_points?.length) {
    return null;
  }

  const effectiveStdev = stdev || average / 6;
  // these label managers are trivial, but they help make sense of what's happening
  const labelWithIndex = (start_date: string, end_date: string, index: number) =>
    `${index}${shortDateDisplayFromDateString(start_date)} - ${shortDateDisplayFromDateString(end_date)}`;
  const labelWithoutIndex = (label: string) => label.substring(1);
  const prettyData = data_points.map((x, index) => ({
    ...x,
    indexedLabel: labelWithIndex(x.start_date, x.end_date, index),
  }));

  const lastIndexedLabel = prettyData[prettyData.length - 1].indexedLabel;
  const maxDatapoint = Math.max(...prettyData.map((d) => d.amount));
  const minDatapoint = Math.min(...prettyData.map((d) => d.amount));

  const minDomain = Math.min(minDatapoint, average - 5.2 * effectiveStdev);
  const maxDomain = Math.max(maxDatapoint, average + 3.3 * effectiveStdev);

  return (
    <VictoryChart title={title}>
      <VictoryLabel x={12} y={12} text={title || ''} />
      <VictoryLabel
        x={12}
        y={24}
        style={{ fontSize: 12, fill: newCOLORS.darkGray }}
        text={description?.toLowerCase() || ''}
      />
      <VictoryStack
        colorScale={[
          newCOLORS.red,
          newCOLORS.orange,
          newCOLORS.darkYellow,
          newCOLORS.green,
          newCOLORS.green,
          newCOLORS.darkYellow,
          newCOLORS.orange,
          newCOLORS.red,
        ]}
        domain={{ y: [minDomain, maxDomain] }}
      >
        <VictoryBar
          horizontal={true}
          data={prettyData.map((value) => ({
            x: value.indexedLabel,
            y0: average - 4 * effectiveStdev,
            y: effectiveStdev,
          }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4, bottom: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4, bottom: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4, bottom: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ bottom: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4, bottom: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4, bottom: 4 }}
          barWidth={8}
        />
        <VictoryBar
          horizontal
          data={prettyData.map((value) => ({ x: value.indexedLabel, y: effectiveStdev }))}
          style={{ data: { fillOpacity: ({ datum }) => (datum.x !== lastIndexedLabel ? 0.3 : 1) } }}
          cornerRadius={{ top: 4, bottom: 4 }}
          barWidth={8}
        />
      </VictoryStack>
      <VictoryScatter
        data={prettyData.map((value) => ({ x: value.indexedLabel, y: value.amount }))}
        dataComponent={<DonutIndicator bands={bands} />}
      />
      <VictoryAxis
        style={{ axis: { stroke: 'transparent' } }}
        crossAxis
        offsetX={100}
        padding={{ left: 100, right: 100 }}
        tickLabelComponent={<VictoryLabel style={{ fill: newCOLORS.darkGray }} />}
        tickValues={prettyData.map((value) => value.indexedLabel)}
        tickFormat={(tick: string) => labelWithoutIndex(tick)}
      />
    </VictoryChart>
  );
}

export function DonutIndicator(props: any) {
  const { x, y, datum, bands } = props;
  const color = getColorByValue(datum.y, bands);

  return (
    <>
      <text x={x} y={y - 12} textAnchor="middle" alignmentBaseline="middle" fontSize={10}>
        {datum.y}
      </text>
      <circle cx={x} cy={y} r={8} fill={color} />
      <circle cx={x} cy={y} r={4} fill="#FFF" />
    </>
  );
}
