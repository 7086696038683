import { styled } from '@linaria/react';
import { useNavigate } from 'react-router-dom';
import { newCOLORS } from '../../../../styles/colors';
import { ContentTag, HeadingTag } from '../../../../styles/shared-styled-components';

type Props = {
  measure: string;
  label: string | React.ReactElement;
  description: string;
  borderColor: string;
  date?: string;
  prefix?: string;
  suffix: string | null;
  value?: number;
};

export const MeasureCard = ({ measure, label, description, borderColor, date, prefix, suffix, value }: Props) => {
  const navigate = useNavigate();
  return (
    <Container borderColor={borderColor}>
      <CenterHeadingTag
        onClick={() =>
          navigate(
            `/application/process-analysis/tasks/?measure=${measure.toLowerCase()}${date ? `&date=${date}` : ''}`
          )
        }
      >
        {label}
      </CenterHeadingTag>
      <SmallContentTag style={{ fontSize: '12px', textAlign: 'center', lineHeight: 1 }}>{description}</SmallContentTag>
      <CenterContentTag>
        <span style={{ fontSize: 24, fontWeight: 'bold' }}>{prefix}</span>
        <span style={{ fontSize: 28, fontWeight: 'bold' }}>
          {typeof value === 'number' ? parseFloat((value || 0)?.toFixed(1)) : 0}
        </span>
        <span>{suffix}</span>
      </CenterContentTag>
    </Container>
  );
};

const Container = styled.div<{ borderColor: string }>`
  border: 1px solid ${(props: { borderColor: string }) => props.borderColor};
  width: 100px;
  height: 120px;
  padding: 0px 5px;
  margin-bottom: 5px;
  position: relative;
`;

const CenterHeadingTag = styled(HeadingTag)`
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: ${newCOLORS.indigo};
  text-decoration: underline;
`;

const SmallContentTag = styled(ContentTag)`
  text-align: center;
  line-height: 1;
`;

const CenterContentTag = styled(ContentTag)`
  position: absolute;
  top: 40%;
  width: 100px;
  text-align: center;
`;
