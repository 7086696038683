import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { ErrorBoundary } from '@sentry/react';
import { useEffect, useState } from 'react';
import {
  Sprint,
  SprintBurnsAndFlows,
  SprintMetricsBurnFlowOfWork,
  SprintMetricsFlowOfWorkStat,
  SprintProjections,
} from '../../../api/sprints-client/sprints-client.type';
import { BloomcastPaceBox } from '../../../components/bloomcast-by-pace-box/bloomcast-by-pace-box';
import { ViewByType } from '../../../components/burn-flow/burn-flow-chart.type';
import { FlowOfWork } from '../../../components/flow-of-work/flow-of-work';
import { ProgressMeasure } from '../../../components/progress-bar/progress-bar-measure';
import { WorkOnTrack } from '../../../components/work-on-track/work-on-track';
import { newCOLORS } from '../../../styles/colors';
import { HeadingTag } from '../../../styles/shared-styled-components';
import { ChartContainer } from '../chart-container';
import { spliceByDay } from '../sprint-assessment.helpers';
import { useDayCalculation } from '../sprint-assessment.hooks';
import { SprintAssessmentChart } from '../sprint-assessment.type';

const showBloomcast: boolean = import.meta.env.VITE_FEATURE_FLAG_BLOOMCAST_BY_PACE === 'true';

type FlowOfWorkToPaceNeededProps = {
  sprint: Sprint;
  projections?: SprintProjections;
  projectionsLoading?: boolean;
  sprintBurnsAndFlows: SprintBurnsAndFlows;
};

export const FlowOfWorkToPaceNeeded = ({
  sprint,
  projections,
  projectionsLoading,
  sprintBurnsAndFlows,
}: FlowOfWorkToPaceNeededProps) => {
  const [dayCount, setDayCount] = useState<number>(0);
  const [currentDataType, setCurrentDataType] = useState<ViewByType>(ViewByType.tasks);
  const [stat, setStat] = useState<SprintMetricsFlowOfWorkStat | null>(null);
  const [flowOfWorkTasksByPace, setFlowOfWorkTasksByPace] = useState<SprintMetricsBurnFlowOfWork>(
    sprintBurnsAndFlows.flow_of_work_tasks_by_pace
  );
  const [flowOfWorkPointsByPace, setFlowOfWorkPointsByPace] = useState<SprintMetricsBurnFlowOfWork>(
    sprintBurnsAndFlows.flow_of_work_points_by_pace
  );

  const { newDay, setNewDay, currentDay } = useDayCalculation(sprint, sprintBurnsAndFlows);

  useEffect(() => {
    const { flow_of_work_tasks_by_pace: tasksByPace, flow_of_work_points_by_pace: pointsByPace } = sprintBurnsAndFlows;

    // Set the total number of days in the sprint
    setDayCount(
      currentDataType === ViewByType.tasks ? tasksByPace.data_points.length : pointsByPace.data_points.length
    );

    newDay &&
      setStat(
        currentDataType === ViewByType.tasks
          ? tasksByPace.stats[tasksByPace.labels[Number(newDay) - 1]]
          : pointsByPace.stats[pointsByPace.labels[Number(newDay) - 1]]
      );

    if (currentDataType === ViewByType.tasks) {
      const sprintFlowsTasks = spliceByDay(tasksByPace, newDay);
      setFlowOfWorkTasksByPace(sprintFlowsTasks);
    } else {
      const sprintFlowsPoints = spliceByDay(pointsByPace, newDay);
      setFlowOfWorkPointsByPace(sprintFlowsPoints);
    }
  }, [sprintBurnsAndFlows, newDay, currentDataType]);

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <ChartContainer
        chartId={SprintAssessmentChart.Pace}
        sprint={sprint}
        sprintLength={dayCount}
        sprintCurrentDay={Number(currentDay)}
        day={newDay as string}
        onChangeDay={setNewDay}
        view={currentDataType}
        onChangeView={setCurrentDataType}
        title={<LargeHeadingTag>Work pace explorer</LargeHeadingTag>}
      >
        <DataContainer>
          <SummarySideContainer>
            <ProgressMeasure
              icon="mdi:fire"
              color={newCOLORS.orange}
              title="Burnt"
              percent={stat?.burnt || 0}
              helpText="% of sprint elapsed"
            />
            <ProgressMeasure
              icon="mdi:progress-check"
              color={newCOLORS.green}
              title="Done"
              percent={stat?.done || 0}
              helpText="work finished"
            />
            {showBloomcast ? (
              <SummaryRow>
                <SummaryIconContainer>
                  <Icon icon="tabler:crystal-ball" style={{ color: newCOLORS.purple }} width="24" height="24" />
                </SummaryIconContainer>
                <Summary>
                  <HeadingTag>Bloomcast</HeadingTag>
                  <BloomcastPaceBox
                    direction={'start'}
                    sprintActive={sprint.current_sprint}
                    loading={projectionsLoading}
                    dataType={currentDataType}
                    projections={projections}
                  />
                </Summary>
              </SummaryRow>
            ) : null}
          </SummarySideContainer>
          <ChartSideContainer>
            <FlowOfWork
              burn_commitment={currentDataType === ViewByType.tasks ? flowOfWorkTasksByPace : flowOfWorkPointsByPace}
              start_date={sprint.start_date}
              data_type={currentDataType}
            />

            <WorkOnTrack
              burn_commitment={currentDataType === ViewByType.tasks ? flowOfWorkTasksByPace : flowOfWorkPointsByPace}
              start_date={sprint.start_date}
              data_type={currentDataType}
            />
          </ChartSideContainer>
        </DataContainer>
      </ChartContainer>
    </ErrorBoundary>
  );
};

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SummarySideContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: auto;
  min-width: 300px;
  gap: 20px;
`;

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SummaryIconContainer = styled.div`
  display: flex;
  max-height: 24px;
  padding-right: 5px;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ChartSideContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;
