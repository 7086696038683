import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../views/views.module.css';
import { ConfluenceSpace, ConfluenceState } from './confluence.type';

type SpacesProps = {
  confluenceState: ConfluenceState;
  updateState: (update: Partial<ConfluenceState>) => void;
};

export const Spaces = ({ confluenceState, updateState }: SpacesProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);

  const handleSpaceSelected = (space: ConfluenceSpace) => {
    if ((confluenceState.selectedSpaces || []).filter((t: ConfluenceSpace) => t.id === space.id).length > 0) {
      updateState({
        selectedSpaces: (confluenceState.selectedSpaces || []).filter((t: ConfluenceSpace) => t.id !== space.id),
      });
    } else {
      updateState({ selectedSpaces: [...(confluenceState.selectedSpaces || []), space] });
    }
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select at least one space you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(confluenceState.spaceOptions || []).map((space: ConfluenceSpace) => (
          <div
            key={space.name}
            className={
              (confluenceState.selectedSpaces || []).filter((t: ConfluenceSpace) => t.id === space.id).length > 0
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleSpaceSelected(space)}
          >
            <span className={styles.checkboxLabel}>{space.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
