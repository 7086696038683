import { DataPoints, SprintAssessmentChart } from './sprint-assessment.type';

/**
 * Splices the data points of a given object by a specified day.
 *
 * @template T - The type of the object containing data points.
 * @param {T} data - The object containing data points to be spliced.
 * @param {string | null} day - The day by which the data points should be spliced.
 * @return {T} - The object with spliced data points.
 */
const spliceByDay = <T extends { data_points: DataPoints }>(data: T, day: string | null): T => {
  return {
    ...data,
    data_points: data.data_points.toSpliced(Number(day)),
  };
};

/**
 * Retrieves the download label for a given SprintAssessmentChart.
 *
 * @param {SprintAssessmentChart} chart - The type of SprintAssessmentChart to retrieve the label for.
 * @return {string} The label corresponding to the provided SprintAssessmentChart.
 */
const getDownloadLabel = (chart: SprintAssessmentChart): string => {
  const labels = {
    [SprintAssessmentChart.Phase]: 'Flow of work, by phase',
    [SprintAssessmentChart.Pace]: 'Flow of work, to pace needed',
    [SprintAssessmentChart.Delivery]: 'Flow of work, delivery confidence',
  };

  return labels[chart];
};

export { getDownloadLabel, spliceByDay };
