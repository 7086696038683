import { Button, Checkbox, ScrollArea } from '@mantine/core';
import { Dispatch } from 'react';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../../styles/colors';
import styles from '../setup.module.css';
import { SetupUser } from '../setup.types';
import { Legal } from '../terms-of-service';

type TermsOfServiceProps = {
  dispatch: Dispatch<any>;
  onClick: Function;
  user: SetupUser;
};

export function TermsOfService({ user, dispatch, onClick }: TermsOfServiceProps) {
  return (
    <form id={styles.formContainer} style={{ width: '50%', alignItems: 'center' }}>
      <ScrollArea
        style={{
          width: '100%',
          height: 400,
          backgroundColor: newCOLORS.lighterGray,
          padding: '2em',
        }}
      >
        <Legal />
      </ScrollArea>
      <Checkbox
        checked={!!user.agreedToTerms}
        label="I agree to the terms"
        onChange={(event) => dispatch({ type: 'user', payload: { agreedToTerms: event.target.checked } })}
        style={{ alignSelf: 'flex-start' }}
      />
      <div className={styles.buttonContainer}>
        <Button className={styles.backButton} onClick={() => onClick('back')}>
          back
        </Button>
        <Button
          disabled={!user.agreedToTerms}
          className={styles.button}
          onClick={() => {
            trackEvent(AnalyticsEventType.TermsofServiceTapped, { userContext: user });
            onClick('next');
          }}
        >
          next
        </Button>
      </div>
    </form>
  );
}
