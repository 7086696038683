import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { MeasureMetadata, TimeAllocation, WorkPeriod } from '../../api/work-periods-client/work-periods-client.type';
import { Tab } from '../../containers/process-analysis/process-analysis.type';
import { useGlobalStore } from '../global-store/global-store';
import { useProcessAnalysisStore } from './process-analysis-store';
import { DateFilter, Filter, WorkPeriodFilter } from './process-analysis-store.type';

/**
 * Sets the available measures in the process analysis store.
 *
 * @param {MeasureMetadata[]} measures - The measure metadata to set.
 * @return {void}
 */
const setAvailableMeasures = (measures: MeasureMetadata[]): void => {
  useProcessAnalysisStore.setState(
    { availableMeasures: measures.toSorted((a, b) => a.measure_name.localeCompare(b.measure_name)) },
    false,
    '[Process Analysis Store] setAvailableMeasures'
  );
};

/**
 * Sets the time allocations in the process analysis store.
 *
 * @param {TimeAllocation[]} timeAllocations - The time allocations to set.
 * @return {void}
 */
const setTimeAllocations = (timeAllocations: TimeAllocation[]): void => {
  useProcessAnalysisStore.setState(
    (state) => ({ ...state, timeAllocations }),
    false,
    '[Process Analysis Store] setTimeAllocations'
  );
};

/**
 * Sets the active tab in the process analysis store.
 *
 * @param {Tab} activeTab - The tab to set as active.
 * @return {void}
 */
const setActiveTab = (activeTab: Tab): void => {
  useProcessAnalysisStore.setState({ activeTab }, false, '[Process Analysis Store] setActiveTab');
};

/**
 * Sets the portfolio IDs in the process analysis store.
 *
 * @param {string[]} portfolioIds - The array of portfolio IDs to set.
 * @return {void}
 */
const setPortfolioIds = (portfolioIds: string[]): void => {
  useProcessAnalysisStore.setState({ portfolioIds }, false, '[Process Analysis Store] setPortfolioIds');
};

/**
 * Sets the team IDs in the process analysis store.
 *
 * @param {string[]} teamIds - The array of team IDs to set.
 * @return {void}
 */
const setTeamIds = (teamIds: string[]): void => {
  useProcessAnalysisStore.setState({ teamIds }, false, '[Process Analysis Store] setTeamIds');
};

/**
 * Sets the board IDs in the process analysis store.
 *
 * @param {string[]} boardIds - The array of board IDs to set.
 * @return {void}
 */
const setBoardIds = (boardIds: string[]): void => {
  useProcessAnalysisStore.setState({ boardIds }, false, '[Process Analysis Store] setBoardIds');
};

/**
 * Sets the start date in the process analysis store.
 *
 * @param {string} startDate - The start date to set.
 * @return {void}
 */
const setStartDate = (startDate: string): void => {
  useProcessAnalysisStore.setState({ startDate }, false, '[Process Analysis Store] setStartDate');
};

/**
 * Sets the end date in the process analysis store.
 *
 * @param {string} endDate - The end date to set.
 * @return {void}
 */
const setEndDate = (endDate: string): void => {
  useProcessAnalysisStore.setState({ endDate }, false, '[Process Analysis Store] setEndDate');
};

/**
 * Sets the work period board ID in the process analysis store.
 *
 * @param {string | null} boardId - The board ID to set, or null to clear the board ID.
 * @return {void}
 */
const setWorkPeriodBoardId = (boardId: string | null): void => {
  useProcessAnalysisStore.setState(
    { workPeriodBoardId: boardId },
    false,
    '[Process Analysis Store] setWorkPeriodBoardId'
  );
};

/**
 * Sets the work periods in the process analysis store.
 *
 * @param {WorkPeriod[]} workPeriods - The work periods to set.
 * @return {void}
 */
const setWorkPeriods = (workPeriods: WorkPeriod[]): void => {
  useProcessAnalysisStore.setState({ workPeriods }, false, '[Process Analysis Store] setWorkPeriods');
};

/**
 * Sets the work period in the process analysis store.
 *
 * @param {WorkPeriod | null} workPeriod - The work period to set, or null to clear the work period.
 * @return {void}
 */
const setWorkPeriod = (workPeriod: WorkPeriod | null): void => {
  useProcessAnalysisStore.setState({ workPeriod }, false, '[Process Analysis Store] setWorkPeriod');
};

/**
 * Applies a filter to the portfolios based on the provided portfolio IDs.
 *
 * @param {Filter} filter - The filter object containing the portfolio IDs.
 * @return {void}
 */
const applyPortfoliosFilter = ({ ids }: Filter): void => {
  useProcessAnalysisStore.setState({ portfolioIds: ids }, false, '[Process Analysis Store] applyPortfolioFilter');
};

/**
 * Sets the dirty state for the portfolios in the process analysis store.
 *
 * @param {boolean} isDirty - The dirty state to set.
 * @return {void}
 */
const setIsDirtyPortfolios = (isDirty: boolean): void => {
  useProcessAnalysisStore.setState(
    { isDirtyPortfolios: isDirty },
    false,
    '[Process Analysis Store] setIsDirtyPortfolios'
  );
};

/**
 * Applies a filter to the teams based on the provided team IDs.
 *
 * @param {Filter} filter - The filter object containing the team IDs.
 * @return {void}
 */
const applyTeamsFilter = ({ ids }: Filter): void => {
  useProcessAnalysisStore.setState({ teamIds: ids }, false, '[Process Analysis Store] applyTeamsFilter');
};

/**
 * Sets the dirty state for the teams in the process analysis store.
 *
 * @param {boolean} isDirty - The dirty state to set.
 * @return {void}
 */
const setIsDirtyTeams = (isDirty: boolean): void => {
  useProcessAnalysisStore.setState({ isDirtyTeams: isDirty }, false, '[Process Analysis Store] setIsDirtyTeams');
};

/**
 * Applies a filter to the boards based on the provided board IDs.
 *
 * @param {Filter} filter - The filter object containing the board IDs.
 * @return {void}
 */
const applyBoardsFilter = ({ ids }: Filter): void => {
  useProcessAnalysisStore.setState({ boardIds: ids }, false, '[Process Analysis Store] applyBoardsFilter');
};

/**
 * Sets the dirty state for the boards in the process analysis store.
 *
 * @param {boolean} isDirty - The dirty state to set.
 * @return {void}
 */
const setIsDirtyBoards = (isDirty: boolean): void => {
  useProcessAnalysisStore.setState({ isDirtyBoards: isDirty }, false, '[Process Analysis Store] setIsDirtyBoards');
};

/**
 * Applies a filter to the work periods based on the provided filter object.
 *
 * @param {WorkPeriodFilter} filter - The filter object containing the work period settings.
 * @return {void}
 */
const applyWorkPeriodsFilter = (filter: WorkPeriodFilter): void => {
  useProcessAnalysisStore.setState(filter, false, '[Process Analysis Store] applyWorkPeriodsFilter');
};

/**
 * Sets the dirty state for the work periods in the process analysis store.
 *
 * @param {boolean} isDirty - The dirty state to set.
 * @return {void}
 */
const setIsDirtyWorkPeriods = (isDirty: boolean): void => {
  useProcessAnalysisStore.setState(
    { isDirtyWorkPeriods: isDirty },
    false,
    '[Process Analysis Store] setIsDirtyWorkPeriods'
  );
};

/**
 * Applies a date filter to update the start and end dates in the process analysis store.
 *
 * @param {DateFilter} filter - The filter object containing the start and end dates.
 * @return {void}
 */
const applyDateFilter = (filter: DateFilter): void => {
  useProcessAnalysisStore.setState(filter, false, '[Process Analysis Store] applyDateFilter');
};

/**
 * Sets a local message in the process analysis store.
 *
 * @param {string} message - The message to be set in the store.
 * @return {void}
 */
const setLocalMessage = (message: string): void => {
  useProcessAnalysisStore.setState({ localMessage: message }, false, '[Process Analysis Store] setLocalMessage');
};

/**
 * Resets the local message in the process analysis store to null.
 *
 * @return {void}
 */
const resetLocalMessage = (): void => {
  useProcessAnalysisStore.setState({ localMessage: null }, false, '[Process Analysis Store] resetLocalMessage');
};

/**
 * Initializes the process analysis state by setting the portfolio IDs, team IDs, and marking the state as initialized.
 *
 * @param {ProjectsResponse[]} teams - An array of project responses used to set the team IDs.
 * @return {void}
 */
const initProcessAnalysisState = (teams: ProjectsResponse[] = []): void => {
  const globalPortfolioId = useGlobalStore.getState().portfolio?.id;
  const localPortfolioId = useProcessAnalysisStore.getState().portfolioIds[0];
  if (globalPortfolioId && globalPortfolioId !== localPortfolioId) {
    setPortfolioIds([globalPortfolioId]);
  }

  const localTeamId = useProcessAnalysisStore.getState().teamIds[0];
  if (!localTeamId) {
    setTeamIds(teams.length ? [teams[0]?.id] : []);
  }

  const localBoardId = useProcessAnalysisStore.getState().boardIds[0];
  if (!localBoardId) {
    setBoardIds([teams[0]?.subprojects[0]?.id]);
  }

  const localWorkPeriodBoardId = useProcessAnalysisStore.getState().workPeriodBoardId;
  if (!localWorkPeriodBoardId) {
    setWorkPeriodBoardId(teams[0]?.subprojects[0]?.id);
  }

  useProcessAnalysisStore.setState({ initialized: true }, false, '[Process Analysis Store] initProcessAnalysisState');
};

export {
  applyBoardsFilter,
  applyDateFilter,
  applyPortfoliosFilter,
  applyTeamsFilter,
  applyWorkPeriodsFilter,
  initProcessAnalysisState,
  resetLocalMessage,
  setActiveTab,
  setAvailableMeasures,
  setBoardIds,
  setEndDate,
  setIsDirtyBoards,
  setIsDirtyPortfolios,
  setIsDirtyTeams,
  setIsDirtyWorkPeriods,
  setLocalMessage,
  setPortfolioIds,
  setStartDate,
  setTeamIds,
  setTimeAllocations,
  setWorkPeriod,
  setWorkPeriodBoardId,
  setWorkPeriods,
};
