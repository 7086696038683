import { ActionIcon, Tooltip } from '@mantine/core';
import { newCOLORS } from '../../styles/colors';
import { icons } from './assets';
import { ToggleCollapseValue } from './tasks-table.type';

export function CollapseAllButton({
  value,
  onClick,
}: {
  value: ToggleCollapseValue;
  onClick: (value: ToggleCollapseValue) => void;
}) {
  return (
    <ActionIcon variant="subtle" size={24} onClick={() => onClick(value)}>
      <Tooltip label={value === ToggleCollapseValue.EXPAND_ALL ? 'Expand All' : 'Collapse All'}>
        <img
          src={value === ToggleCollapseValue.EXPAND_ALL ? icons.iconExpandAll : icons.iconCollapseAll}
          width={24}
          height={24}
        />
      </Tooltip>
    </ActionIcon>
  );
}

export function BackToTopButton({ visible, onClick }: { visible: boolean; onClick: () => void }) {
  if (!visible) {
    return null;
  }

  return (
    <ActionIcon
      variant="filled"
      color={newCOLORS.indigo2}
      size={40}
      radius="xl"
      style={{ position: 'absolute', right: 33, bottom: 29 }}
      onClick={onClick}
    >
      <img src={icons.iconScrollToTop} width={24} height={24} />
    </ActionIcon>
  );
}
