import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { forwardRef } from 'react';
import { getIndicatorBgColor, getIndicatorBorderColor } from './indicator.helpers';
import { IndicatorType } from './indicator.type';

type Props = {
  value: IndicatorType;
  tooltip?: string;
  style?: React.CSSProperties;
};

const IndicatorComponent = forwardRef<HTMLDivElement, Props>(({ value, style }, ref) => (
  <DotIndicator ref={ref} value={value} style={style} />
));

export const Indicator = ({ value, tooltip, style }: Props) => {
  if (!tooltip) {
    return <DotIndicator value={value} style={style} />;
  }

  return (
    <Tooltip label={tooltip}>
      <IndicatorComponent value={value} style={style} />
    </Tooltip>
  );
};

export const DotIndicator = styled.div<{ value: IndicatorType }>`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${({ value }: { value: IndicatorType }) => getIndicatorBgColor(value)};
  border: 1px solid ${({ value }: { value: IndicatorType }) => getIndicatorBorderColor(value)};
`;
