import { newCOLORS } from '../../styles/colors';

type TripleDotLoaderProps = {
  center: { x: number; y: number };
};

export function TripleDotLoader({ center: { x, y } }: TripleDotLoaderProps) {
  return (
    <g>
      <circle fill={newCOLORS.black} stroke="none" cx={x - 15} cy={y - 50} r={4}>
        <animate
          id="triple-dot-loader"
          attributeName="opacity"
          dur="1250ms"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill={newCOLORS.black} stroke="none" cx={x} cy={y - 50} r={4}>
        <animate attributeName="opacity" dur="1250ms" values="0;1;0" repeatCount="indefinite" begin="0.2" />
      </circle>
      <circle fill={newCOLORS.black} stroke="none" cx={x + 15} cy={y - 50} r={4}>
        <animate attributeName="opacity" dur="1250ms" values="0;1;0" repeatCount="indefinite" begin="0.3" />
      </circle>
    </g>
  );
}
