import { LineScatterDataPoint } from '../line-scatter-chart/line-scatter-chart.type';

enum ViewByType {
  tasks = 'Tasks',
  points = 'Points',
}

type CumulativeFlowAggregatedDataType = {
  title: string;
  data: LineScatterDataPoint[];
  color: string;
};

export { ViewByType };
export type { CumulativeFlowAggregatedDataType };
