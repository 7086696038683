import { styled } from '@linaria/react';
import { Loader } from '@mantine/core';
import dayjs from 'dayjs';
import { SprintProjections } from '../../api/sprints-client/sprints-client.type';
import { ContentTag } from '../../styles/shared-styled-components';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';

type BloomcastPaceBoxProps = {
  direction: 'start' | 'end';
  sprintActive?: boolean;
  loading?: boolean;
  dataType: ViewByType;
  projections?: SprintProjections;
};

const getMidsprintAcc = (projections?: SprintProjections, dataType?: ViewByType) => {
  const currentDate = dayjs().format('YYYY-MM-DD');

  if (dataType === ViewByType.tasks && projections) {
    const projected_percentage = Object.entries(projections?.projected_midsprint_added_tasks[0]).find(
      ([key, _value]) => currentDate === key
    );
    return projected_percentage ? projected_percentage[1] : null;
  }

  if (dataType === ViewByType.points && projections) {
    const projected_percentage = Object.entries(projections?.projected_midsprint_added_points[0]).find(
      ([key, _value]) => currentDate === key
    );

    return projected_percentage ? projected_percentage[1] : null;
  }
};

export const BloomcastPaceBox = ({
  direction,
  sprintActive,
  loading,
  dataType,
  projections,
}: BloomcastPaceBoxProps) => {
  if (
    (projections?.projected_midsprint_added_tasks?.[0] ?? false) ||
    (projections?.projected_midsprint_added_points?.[0] ?? false)
  ) {
    const getPredictedPercent = getMidsprintAcc(projections, dataType);

    if (loading) {
      return (
        <BloomcastPaceBoxContainer direction={direction}>
          <Loader color="dark" size="sm" />
        </BloomcastPaceBoxContainer>
      );
    } else if (!loading && sprintActive && getPredictedPercent != null) {
      return (
        <BloomcastPaceBoxContainer direction={direction}>
          <ContentTag>{getPredictedPercent}% chance the plan changes prior to the close of this sprint.</ContentTag>
        </BloomcastPaceBoxContainer>
      );
    } else if (!loading && !sprintActive) {
      return (
        <BloomcastPaceBoxContainer direction={direction}>
          {dataType === ViewByType.tasks ? (
            <>
              The sprint concluded with the addition of {projections?.projected_midsprint_added_tasks[1]} tasks during
              its duration
            </>
          ) : (
            <>
              The sprint concluded with the addition of {projections?.projected_midsprint_added_points[1]} points during
              its duration
            </>
          )}
        </BloomcastPaceBoxContainer>
      );
    }
  }
  return null;
};

const BloomcastPaceBoxContainer = styled.div<{ direction: 'start' | 'end' }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props: { direction: 'start' | 'end' }) => props.direction};
`;
