import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import {
  CurrentStage,
  IntegrationEntry,
  IntegrationType,
  Project,
  Subproject,
} from '../../../api/integrations-client/integrations-client.type';
import { isIntegrationReauthCompleted } from '../integrations.helpers';
import { Integration, IntegrationProject, IntegrationStatus } from './integrations-list.type';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

/**
 * Checks if a subproject is valid based on the integration type.
 *
 * @param {Subproject} subproject - The subproject to check.
 * @param {IntegrationEntry} integration - The integration entry containing the integration type.
 * @returns {boolean} - Returns true if the subproject is valid, false otherwise.
 */
const isValidSubproject = (subproject: Subproject, integration: IntegrationEntry): boolean => {
  if (integration.integration_type === IntegrationType.JDC) {
    return subproject.configuration_state === 'initially_configured';
  }

  return true;
};

/**
 * Returns an array of subprojects that are associated with the given project and integration.
 *
 * @param {Project} project - The project object.
 * @param {IntegrationEntry} integration - The integration entry object.
 * @returns {Subproject[]} - An array of subproject objects that are associated with the given project and integration.
 */
const getSubprojects = (project: Project, integration: IntegrationEntry): Subproject[] => {
  return integration.subprojects.filter((subproject) => project.subproject_ids.includes(subproject.id));
};

/**
 * Checks if a project is valid based on the integration.
 *
 * @param {Project} project - The project to check.
 * @param {IntegrationEntry} integration - The integration entry containing the subprojects.
 * @returns {boolean} - Returns true if the project is valid, false otherwise.
 */
const isValidProject = (project: Project, integration: IntegrationEntry): boolean => {
  return getSubprojects(project, integration).some((subproject) => isValidSubproject(subproject, integration));
};

/**
 * Returns an array of IntegrationProject objects by processing the given array of IntegrationEntry objects.
 *
 * @param {IntegrationEntry[]} integrations - An array of IntegrationEntry objects.
 * @returns {IntegrationProject[]} - An array of IntegrationProject objects.
 */
const getIntegrationProjects = (integrations: IntegrationEntry[]): IntegrationProject[] => {
  return integrations.reduce((result, integration) => {
    const filteredProjects = integration.projects.filter((project) => isValidProject(project, integration));

    const formattedProjects = filteredProjects.map((project) => {
      const { projects, ...integrationData } = integration;

      return {
        ...integrationData,
        name: project.name,
        project_id: project.id,
        subprojects: getSubprojects(project, integration).filter((subproject) =>
          isValidSubproject(subproject, integration)
        ),
        status: getIntegrationStatus(integration),
      };
    });

    return [...result, ...formattedProjects];
  }, [] as IntegrationProject[]);
};

/**
 * Returns the integration status based on the given integration entry.
 *
 * @param {IntegrationEntry} integration - The integration entry to check the status for.
 * @returns {IntegrationStatus} The status of the integration.
 */
const getIntegrationStatus = ({ id, last_run_status, last_completed_at }: IntegrationEntry): IntegrationStatus => {
  if (last_run_status && ['401', '403'].includes(last_run_status) && !isIntegrationReauthCompleted(id)) {
    return IntegrationStatus.Expired;
  }

  const isStale = dayjs.duration(dayjs().diff(last_completed_at)).asHours() > 6;

  if (isStale) {
    return IntegrationStatus.Stale;
  }

  return IntegrationStatus.Operational;
};

/**
 * Returns the formatted date and time of the last refresh, or '-' if the last refresh is not valid.
 *
 * @param {string | null} lastRefresh - The date and time of the last refresh
 * @returns {string} The formatted date and time, or '-' if last refresh is not valid
 */
const getLastRefresh = (lastRefresh: string | null): string => {
  const isValid = dayjs(lastRefresh).isValid();

  if (!isValid) {
    return '-';
  }

  return dayjs(lastRefresh).format('MM/DD/YYYY h:mm a');
};

/**
 * Returns the refresh duration based on the last run time.
 *
 * @param {string | null} refreshTime - The last refresh time of the integration.
 * @returns {string} The refresh duration in minutes or seconds.
 */
const getRefreshDuration = (refreshTime: string | null): string => {
  if (!refreshTime) {
    return '-';
  }

  const duration = dayjs(refreshTime, 'HH:mm:ss');
  const minutes = duration.minute();
  const seconds = duration.second();

  if (minutes > 0) {
    return `${minutes} minute${minutes === 1 ? '' : 's'}`;
  }

  return `${seconds} second${seconds === 1 ? '' : 's'}`;
};

/**
 * Retrieves the integration unit associated with a given integration.
 *
 * @param {Integration} integration - The integration for which to retrieve the unit.
 * @returns {string} The integration unit associated with the given integration.
 */
const getIntegrationUnit = (integration: Integration): string => {
  const units = {
    [IntegrationType.JIRA]: 'Project',
    [IntegrationType.JDC]: 'Project',
    [IntegrationType.GITHUB]: 'Repository',
    [IntegrationType.ADO]: 'Board',
  };

  return units[integration.name];
};

/**
 * Retrieves a message indicating the refresh status based on the provided IntegrationProject and optional refresh status.
 *
 * @param {IntegrationProject} project - an object containing the properties manually_prioritized and current_stage
 * @param {number} refreshStatus - an optional number representing the refresh status
 * @returns {string | null} a message indicating the refresh status, or null if no message is available
 */
const getRefreshMessage = (
  { manually_prioritized, current_stage }: IntegrationProject,
  refreshStatus?: number
): string | null => {
  const isQueued = manually_prioritized && current_stage === CurrentStage.WAITING;
  const isRunning = current_stage === CurrentStage.RUNNING;

  if (refreshStatus === 200 || isQueued) {
    return 'Refresh in queue';
  }

  if (isRunning) {
    return 'Refresh in progress';
  }

  return null;
};

export {
  getIntegrationProjects,
  getIntegrationStatus,
  getIntegrationUnit,
  getLastRefresh,
  getRefreshDuration,
  getRefreshMessage,
};
