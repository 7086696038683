import { NavIconType } from '../components/nav-icon/nav-icon.type';
import { GeneralViewNames, NavItem, ViewNames } from '../containers/side-bar/side-bar.type';

const showProcessAnalysis: boolean = import.meta.env.VITE_FEATURE_FLAG_PROCESS_ANALYSIS === 'true';

export const defaultNavItems: NavItem[] = [
  {
    link: '',
    label: GeneralViewNames.Portfolio,
    icon: NavIconType.iconPortfolio,
    path: '/application/dashboard',
    general: true,
    show: true,
  },
  {
    link: '',
    label: ViewNames.Project,
    icon: NavIconType.iconProject,
    path: `/application/project/no_project_id/`,
    general: false,
    show: false,
  },
  {
    link: '',
    label: ViewNames.CurrentSprint,
    icon: NavIconType.iconCurrentSprint,
    path: `/application/project/no_project_id/sprint-assessment/`,
    general: false,
    show: false,
  },
  {
    link: '',
    label: ViewNames.History,
    icon: NavIconType.iconHistory,
    path: `/application/project/no_project_id/sprint-history/`,
    general: false,
    show: false,
  },
  {
    link: '',
    label: GeneralViewNames.Strategy,
    icon: NavIconType.iconStrategy,
    path: '/application/strategy',
    general: true,
    show: true,
  },
  {
    link: '',
    label: ViewNames.Financials,
    icon: NavIconType.iconFinancials,
    path: `/application/financials/portfolio/`,
    general: true,
    show: true,
  },
  {
    link: '',
    label: GeneralViewNames.Process,
    icon: NavIconType.iconProcess,
    path: `/application/process/`,
    general: true,
    show: true,
  },
  {
    link: '',
    label: GeneralViewNames.ProcessAnalysis,
    icon: NavIconType.iconProcessAnalysis,
    path: '/application/process-analysis',
    general: true,
    show: showProcessAnalysis,
  },
  {
    link: '',
    label: GeneralViewNames.Integrations,
    icon: NavIconType.iconIntegrations,
    path: '/application/integrations-list',
    general: true,
    show: true,
  },
];
