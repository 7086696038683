import { styled } from '@linaria/react';
import { QueryObserverResult } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spending } from '../../api/financials-client/financials-client.type';
import { AllocationOfCosts } from '../../components/allocation-of-costs/allocation-of-costs';
import { AllocationOfCosts as AllocationOfCostsV2 } from '../../components/allocation-of-costs/v2/allocation-of-costs';
import { FinancialsScope } from '../../components/financials-scope/financials-scope';
import { FinancialsScope as Scope } from '../../components/financials-scope/financials-scope.type';
import { PortfolioContext, TeamContext } from '../../contexts';
import { setFinancialsEndDate, setFinancialsStartDate } from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';
import { PageHeader, StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { CostByInitiative } from '../cost-by-initiative/cost-by-initiative';
import { CostsVsOutput } from '../costs-vs-output/costs-vs-output';
import { CostsVsOutputV2 } from '../costs-vs-output/v2/costs-vs-output-v2';
const AllocationOfCostsComponent =
  import.meta.env.VITE_FEATURE_FLAG_ALLOCATION_OF_COSTS_V2 === 'true' ? AllocationOfCostsV2 : AllocationOfCosts;

const CostsVsOutputComponent =
  import.meta.env.VITE_FEATURE_FLAG_COST_VS_OUTPUT_V2 === 'true' ? CostsVsOutputV2 : CostsVsOutput;

type FinancialsChartsProps = {
  spendingQuery: QueryObserverResult<Spending[]>;
  startDate: Date | null;
  setStartDate: (date: Date) => void;
  endDate: Date | null;
  setEndDate: (date: Date) => void;
};

export const FinancialsCharts = ({
  spendingQuery,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: FinancialsChartsProps) => {
  const navigate = useNavigate();
  const { portfolio } = useContext(PortfolioContext);
  const { team } = useContext(TeamContext);

  const [costsVsOutputQueryFinished, setCostsVsOutputQueryFinished] = useState<boolean>(false);
  const [allocationOfCostsQueryFinished, setAllocationOfCostsQueryFinished] = useState<boolean>(false);

  const updateStartDate = (date: Date) => {
    setFinancialsStartDate(date);
    setStartDate(date);
    spendingQuery.refetch();
  };

  const updateEndDate = (date: Date) => {
    setFinancialsEndDate(date);
    setEndDate(date);
    spendingQuery.refetch();
  };

  const [scope, setScope] = useState<Scope>({
    portfolio,
    team,
    startDate,
    endDate,
  });

  useEffect(() => {
    setScope({ portfolio, team, startDate, endDate });
  }, [startDate, endDate, portfolio, team]);

  return (
    <Fragment>
      <FinancialsContainer>
        <FinancialsHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Fragment>
              <PageHeader>Financials</PageHeader>
              <StyledButton
                size={'medium'}
                type={'primary'}
                firstColor={newCOLORS.indigo}
                secondColor={newCOLORS.white}
                disable={false}
                style={{ marginLeft: 16 }}
                onClick={() =>
                  navigate(`/application/financials/portfolio/${portfolio?.id}/add-edit-financials`, {
                    state: { from: 'application' },
                  })
                }
              >
                Edit financial data
              </StyledButton>
            </Fragment>
          </div>
        </FinancialsHeader>
        <div>
          <Fragment>
            <FinancialsScope
              startDate={startDate}
              setStartDate={updateStartDate}
              endDate={endDate}
              setEndDate={updateEndDate}
            />
            <div>
              {portfolio && startDate && endDate ? (
                <CostsVsOutputComponent {...scope} setQueryFinished={setCostsVsOutputQueryFinished} />
              ) : null}
            </div>
            <div>
              {portfolio && startDate && endDate ? (
                <AllocationOfCostsComponent
                  {...scope}
                  setQueryFinished={setAllocationOfCostsQueryFinished}
                  queryEnabled={costsVsOutputQueryFinished}
                />
              ) : null}
            </div>
            <div>
              {portfolio && startDate && endDate ? (
                <CostByInitiative {...scope} queryEnabled={allocationOfCostsQueryFinished} />
              ) : null}
            </div>
          </Fragment>
        </div>
      </FinancialsContainer>
    </Fragment>
  );
};

const FinancialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FinancialsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
