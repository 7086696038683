import { ProjectsResponse, Subproject } from '../api/projects-client/projects-client.type';

enum WorkPeriodType {
  Flexible = 'Flexible',
  Sprint = 'Sprint',
}

type FlexibleWorkPeriod = {
  start: Date | null;
  end: Date | null;
};

type GlobalFilterContextType = {
  team: ProjectsResponse | null;
  setTeam: React.Dispatch<React.SetStateAction<ProjectsResponse | null>>;
  workPeriodType: WorkPeriodType;
  setWorkPeriodType: React.Dispatch<React.SetStateAction<WorkPeriodType>>;
  board: Subproject | null;
  setBoard: React.Dispatch<React.SetStateAction<Subproject | null>>;
  sprint: string | null;
  setSprint: React.Dispatch<React.SetStateAction<string | null>>;
  flexibleWorkPeriod: FlexibleWorkPeriod;
  setFlexibleWorkPeriod: React.Dispatch<React.SetStateAction<FlexibleWorkPeriod>>;
};

export { WorkPeriodType };
export type { FlexibleWorkPeriod, GlobalFilterContextType };
