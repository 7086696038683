import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Divider } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { Team } from '../../api/projects-client/projects-client.type';
import { PortfolioContext, TeamContext } from '../../contexts';
import { getRecentlyCompletedMonth } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import { SmallerHeading, StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { PortfolioSelect, PortfolioSelectRef } from '../select/portfolio-select';
import { TeamSelect, TeamSelectRef } from '../select/team-select';
import { FinancialsScopeProps, ScopeSelectorProps } from './financials-scope.type';

const isGlobalScopeEnabled = import.meta.env.VITE_FEATURE_FLAG_GLOBAL_ORG_PORTFOLIO === 'true';

const ScopeSelector = ({ opened, startDate, setStartDate, endDate, setEndDate }: ScopeSelectorProps) => {
  const portfolioRef = useRef<PortfolioSelectRef>(null);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([] as Portfolio[]);
  const tempPortfolios = portfolioRef?.current?.getPortfolios();

  const teamRef = useRef<TeamSelectRef>(null);
  const tempTeams = teamRef?.current?.getTeams();
  const navigate = useNavigate();

  const { portfolio, setPortfolio } = useContext(PortfolioContext);
  const { team, setTeam } = useContext(TeamContext);
  const { portfolioId = '', teamId = '' } = useParams<{ portfolioId: string; teamId: string }>();

  useEffect(() => {
    if ((tempPortfolios || []).length > 0) {
      setPortfolios(tempPortfolios || []);
    }
  }, [tempPortfolios]);

  const navigateToSelectedPortfolio = (pf: Portfolio) => {
    const route = `/application/financials/portfolio/${pf.id}`;
    navigate(`${route}`);
  };

  const navigateToSelectedTeam = (tm: Team) => {
    const selectedPortfolioId = portfolioId || portfolio?.id;
    if (!selectedPortfolioId || selectedPortfolioId.length === 0) {
      return;
    }

    let route = `/application/financials/portfolio/${selectedPortfolioId}`;
    if (tm && tm.id !== 'aggregate') {
      route += `/team/${tm.id}`;
    }
    navigate(`${route}`);
  };

  useEffect(() => {
    if (
      tempPortfolios &&
      tempPortfolios.length > 0 &&
      portfolio &&
      portfolio.id &&
      portfolioId &&
      portfolioId.length > 0 &&
      portfolioId !== portfolio.id
    ) {
      const properPortfolio = tempPortfolios.find((pf) => pf.id === portfolioId);
      if (properPortfolio) {
        setPortfolio(properPortfolio);
      }
    }
  }, [portfolioId, portfolio, setPortfolio, tempPortfolios]);

  useEffect(() => {
    if (tempTeams && tempTeams.length > 0 && team && teamId && teamId.length > 0 && teamId !== team.id) {
      const properTeam = tempTeams.find((tm) => tm.id === teamId);
      setTeam(properTeam || null);
    }
  }, [teamId, team, setTeam, tempTeams]);

  return (
    <div style={{ display: 'flex' }}>
      {!isGlobalScopeEnabled && (
        <OpenedStyle opened={opened} style={{ display: portfolios.length > 1 ? 'flex' : 'none' }}>
          <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>Portfolio</StandardText>
          <PortfolioSelect hideLabel={true} ref={portfolioRef} handlePortfolioSelected={navigateToSelectedPortfolio} />
        </OpenedStyle>
      )}

      <OpenedStyle opened={opened}>
        <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>Team</StandardText>
        <TeamSelect
          hideLabel={true}
          includeAggregate={true}
          ref={teamRef}
          handleTeamSelected={navigateToSelectedTeam}
        />
      </OpenedStyle>

      <Divider orientation="vertical" size="xs" />

      <OpenedStyle opened={opened}>
        <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>Start Date</StandardText>
        <MonthPickerInput
          {...{ placeholder: 'Pick start date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick start date"
          value={startDate}
          onChange={(date: Date | null) => {
            if (date) {
              setStartDate(date);
            }
          }}
          mx="auto"
          maw={400}
          maxDate={endDate || new Date()}
        />
      </OpenedStyle>

      <OpenedStyle opened={opened}>
        <StandardText style={{ margin: opened ? '8px 0px' : '0px 8px' }}>End Date</StandardText>
        <MonthPickerInput
          {...{ placeholder: 'Pick end date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick end date"
          value={endDate}
          minDate={startDate as Date}
          onChange={(date: Date | null) => {
            if (date) {
              setEndDate(date);
            }
          }}
          mx="auto"
          maw={400}
          maxDate={getRecentlyCompletedMonth()}
        />
      </OpenedStyle>
    </div>
  );
};

const OpenedStyle = styled.div<{ opened: boolean }>`
  display: flex;
  flex-direction: ${(props: { opened: boolean }) => (props.opened ? 'column' : 'row')};
  justify-content: ${(props: { opened: boolean }) => (props.opened ? 'center' : 'start')};
  align-items: ${(props: { opened: boolean }) => (props.opened ? 'start' : 'center')};
  margin: 0px 16px;
`;

export const FinancialsScope = ({ startDate, setStartDate, endDate, setEndDate }: FinancialsScopeProps) => {
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <FinancialsScopeContainer>
      <CollapseHeader>
        <CollapseIcon>
          {opened ? (
            <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
          ) : (
            <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
          )}
        </CollapseIcon>
        <SmallerHeading>Scope</SmallerHeading>
        {!opened && (
          <Fragment>
            <Divider orientation="vertical" size="xs" style={{ marginLeft: 16 }} />
            <ScopeSelector
              opened={opened}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Fragment>
        )}
      </CollapseHeader>

      <Collapse in={opened}>
        <CollapseContent>
          {opened && (
            <ScopeSelector
              opened={opened}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
        </CollapseContent>
      </Collapse>
    </FinancialsScopeContainer>
  );
};

const FinancialsScopeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
`;
