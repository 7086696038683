import { styled } from '@linaria/react';
import { Radio } from '@mantine/core';
import styles from './views.module.css';
import { Board } from './views.type';

type Props = {
  boards: Board[];
  selectedBoardId: string | null;
  onChange: (boardId: string) => void;
};

export function BoardView({ boards, selectedBoardId, onChange }: Props) {
  return (
    <div id={styles.viewContainer}>
      <h4>Select the boards you would like to connect to:</h4>
      <div id={styles.grid}>
        {boards.map((board: Board) => (
          <div
            key={board.id}
            className={
              board.id === selectedBoardId
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => onChange(board.id)}
          >
            <StyledRadio
              color="green"
              styles={{ label: { fontWeight: 600, marginLeft: '30px' } }}
              value={board.id}
              checked={board.id === selectedBoardId}
              onChange={() => onChange(board.id)}
            />
            <span className={styles.checkboxLabel}>{board.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

const StyledRadio = styled(Radio)`
  .mantine-Radio-radio {
    cursor: pointer;
  }
`;
