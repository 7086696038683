import { RegressionResult } from './math-helpers.type';

function round(value: number, precision = 0): number {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

function standardDeviation(array: number[] = []): number {
  const mean = average(array);
  return array.length
    ? Math.sqrt(array.map((n) => Math.pow(n - mean, 2)).reduce((a, b) => a + b, 0) / array.length)
    : 0;
}

function average(array: number[] = []): number {
  return array.length ? array.reduce((sum: number, n: number) => sum + n, 0) / array.length : 0;
}

function simpleLinearRegression(x: number[], y: number[]): RegressionResult | undefined {
  if (x.length !== y.length) {
    console.error('Input value arrays should have the same length.');
    return undefined;
  }

  if (x.length === 0) {
    console.error('Input value arrays should not be empty.');
    return undefined;
  }

  const n = x.length;
  const xMean = x.reduce((a, b) => a + b) / n;
  const yMean = y.reduce((a, b) => a + b) / n;

  let num = 0; // numerator
  let den = 0; // denominator

  for (let i = 0; i < n; i++) {
    num += (x[i] - xMean) * (y[i] - yMean);
    den += (x[i] - xMean) ** 2;
  }

  if (den === 0) {
    console.error('There is no variation in x values.');
    return undefined;
  }

  const slope = num / den;
  const intercept = yMean - slope * xMean;

  return { slope, intercept };
}

export { average, round, simpleLinearRegression, standardDeviation };
