import {
  ActiveElement,
  CategoryScale,
  ChartData,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectMetricsDetailsEndpointDescription } from '../../api/projects-client/projects-client.type';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';
import { LineScatterDataLine } from './line-scatter-chart.type';

// Register chart.js plugins needed for the chart
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

/**
 * Format the data according to the chart.js requirements
 * @param data
 * @returns formatted data
 */
export const formatData = (data: SanitizedData, tooltipString: string): ChartData<'line'> => {
  const dataset: ChartData<'line'> = {
    labels: data.labels,
    datasets: [
      {
        label: tooltipString,
        order: 1,
        stack: 'Stack 0',
        data: data.storyPoints,
        borderColor: data.color,
        backgroundColor: `${addOpacityToColor(data.color, 0.3)}`,
        fill: true,
        pointRadius: 5,
        pointBackgroundColor: data.color,
        pointStyle: 'circle',
        tension: 0.3,
      },
    ],
  };

  return dataset;
};

type SanitizedData = {
  labels: string[];
  storyPoints: (number | null)[];
  color: string;
  sprintIds?: string[];
};

/**
 * Sanitize the raw data from the API response to be used in the chart
 * @param rawData
 * @returns sanizited data
 */
const sanitizeData = (rawData: LineScatterDataLine): SanitizedData => {
  const sanitizedData = {} as SanitizedData;
  const inputData = rawData.dataPoints;

  const labels = [];
  const storyPoints = [];
  const sprintIds = [];

  for (const i in inputData) {
    labels.push(inputData[i].x);
    storyPoints.push(inputData[i].y);
    if (inputData[i].sprintId) {
      sprintIds.push(inputData[i].sprintId as string);
    }
  }

  sanitizedData['labels'] = labels;
  sanitizedData['storyPoints'] = storyPoints;
  if (sprintIds.length === storyPoints.length) {
    sanitizedData['sprintIds'] = sprintIds;
  }
  sanitizedData['color'] = rawData.color;

  return sanitizedData;
};

type LineScatterChartProps = {
  dataline: LineScatterDataLine;
  desc: ProjectMetricsDetailsEndpointDescription;
};

export const LineScatterChart = ({ dataline, desc }: LineScatterChartProps) => {
  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth);
  const [sprintIds, setSprintIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const { projectId = '' } = useParams();
  // Chart.js options for the chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'default',
    borderWidth: 3,
    aspectRatio: 3,
    layout: {
      padding: {
        top: 50,
        right: 0,
      },
    },
    onClick: (_: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length === 0 || !sprintIds || sprintIds.length === 0) {
        return;
      }
      const sprintId = sprintIds[elements[0].index];
      navigate(`/application/project/${projectId}/sprint-assessment/${sprintId}`);
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      let cursor = 'default';
      if (elements.length > 0 && sprintIds && sprintIds.length > 0) {
        cursor = 'pointer';
      }
      if (event?.native?.target) {
        (event.native.target as HTMLElement).style.cursor = cursor;
      }
    },

    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date',
          font: {
            size: 14,
          },
        },
        ticks: {
          padding: 5,
          font: {
            size: 14,
          },
          color: [newCOLORS.lighterGray, newCOLORS.darkGray],
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: '',
          font: {
            size: 14,
          },
        },
        ticks: {
          padding: 5,
          font: {
            size: 14,
          },
        },
      },
    },

    animation: {
      delay: (context: { type: string; mode: string; dataIndex: number; datasetIndex: number }) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default') {
          delay = context.dataIndex * 60 + context.datasetIndex * 20;
        }
        return delay;
      },
    },

    interaction: {
      mode: 'nearest' as const,
      axis: 'xy' as const,
      intersect: false,
    },

    elements: {
      point: {
        hoverRadius: 9,
      },
    },

    plugins: {
      legend: {
        display: false,
        position: 'right' as const,
        labels: {
          padding: 20,
          boxWidth: 12,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        xAlign: 'center' as const,
        yAlign: 'bottom' as const,
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  const plugins: any = [
    {
      id: 'chartBackground',
      beforeDraw: (chart: {
        ctx: CanvasRenderingContext2D;
        chartArea: {
          left: number;
          top: number;
          width: number;
          height: number;
        };
      }) => {
        const {
          ctx,
          chartArea: { left, top, width, height },
        } = chart;

        ctx.save();

        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = newCOLORS.white;
        ctx.fillRect(left, top, width, height);

        ctx.restore();
      },
    },
    {
      id: 'chartAreaBorder',
      beforeDraw: (chart: {
        ctx: CanvasRenderingContext2D;
        chartArea: {
          left: number;
          top: number;
          width: number;
          height: number;
        };
      }) => {
        const {
          ctx,
          chartArea: { left, top, width, height },
        } = chart;

        ctx.save();

        ctx.strokeStyle = newCOLORS.gray;
        ctx.lineWidth = 2;
        ctx.strokeRect(left - 1, top - 1, width + 2, height + 2);

        ctx.restore();
      },
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  options.layout.padding.right = viewportWidth * 0.01;

  let tooltipString = '';

  switch (desc) {
    case ProjectMetricsDetailsEndpointDescription.Velocity:
      options.scales.y.title.text = 'Work Done (Points)';
      tooltipString = 'Points';
      break;
    case ProjectMetricsDetailsEndpointDescription.Throughput:
      options.scales.y.title.text = 'Work Done (Tasks)';
      tooltipString = 'Tasks';
      break;
    case ProjectMetricsDetailsEndpointDescription.LeadTime:
      options.scales.y.title.text = 'Days to Complete';
      tooltipString = 'Days';
      break;
    case ProjectMetricsDetailsEndpointDescription.ReactionTime:
      options.scales.y.title.text = 'Days to Start';
      tooltipString = 'Days';
      break;
    default:
      options.scales.y.title.text = 'Days to Complete';
      tooltipString = 'Days';
      break;
  }

  const data = sanitizeData(dataline);
  const formattedData = formatData(data, tooltipString);

  useEffect(() => {
    const data = sanitizeData(dataline);
    if (data.sprintIds) {
      setSprintIds(data.sprintIds);
    }
  }, [dataline]);

  return (
    <div style={{ height: '450px', display: 'flex' }}>
      <div style={{ width: '99%' }}>
        <Line options={options} data={formattedData} plugins={plugins} />
      </div>
    </div>
  );
};
