import { createContext, ReactNode, useState } from 'react';
import { ProcessAnalysisInitiativeChartData } from '../../api/portfolio-client/portfolio-client.type';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { getDefaultInitiativeSectionSelection } from './initiative-sections/initiative-sections.helpers';
import { InitiativeSectionSelectionAggregated } from './initiative-sections/initiative-sections.type';
import { getDefaultKeyMeasuresSelection } from './key-measures/key-measures-over-time.helpers';
import { KeyMeasuresSelectionAggregated } from './key-measures/key-measures-over-time.type';
import { getDefaultMeasureComparisonSelection } from './measure-comparison/measure-comparison.helpers';
import { MeasureComparisonSelectionAggregated } from './measure-comparison/measure-comparison.type';
import {
  InitiativeSectionSelectionContext,
  KeyMeasuresSelectionContext,
  MeasureComparisonSelectionContext,
  MetricType,
  ProcessAnalysisContextType,
  Section,
  Tab,
} from './process-analysis.type';

const ProcessAnalysisContext = createContext({} as ProcessAnalysisContextType);

const ProcessAnalysisProvider = ({ children }: { children: ReactNode }) => {
  const measureComparisonSelection = useMeasureComparisonSelection();
  const initiativeCompletionSelection = useInitiativeSectionSelection();
  const initiativeFocusSelection = useInitiativeSectionSelection();
  const keyMeasuresSelection = useKeyMeasuresSelection();

  return (
    <ProcessAnalysisContext.Provider
      value={{
        [Section.MeasureComparison]: measureComparisonSelection,
        [Section.InitiativeCompletion]: initiativeCompletionSelection,
        [Section.InitiativeFocus]: initiativeFocusSelection,
        [Section.KeyMeasures]: keyMeasuresSelection,
      }}
    >
      {children}
    </ProcessAnalysisContext.Provider>
  );
};

/**
 * Provides the current selection of measures, selected measures, and selected trends for the measure comparison section.
 * Also provides functions to update the selection.
 *
 * @returns {MeasureComparisonSelectionContext} - An object with the current selection and functions to update it.
 */
const useMeasureComparisonSelection = (): MeasureComparisonSelectionContext => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab as keyof MeasureComparisonSelectionAggregated);
  const [selection, setSelection] = useState<MeasureComparisonSelectionAggregated>(
    getDefaultMeasureComparisonSelection()
  );

  const selectionTabs = [Tab.Portfolios, Tab.Teams, Tab.Boards];
  const activeTabSelection = selectionTabs.includes(activeTab) ? selection[activeTab] : null;

  const setMeasures = (measures: Measure[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], measures } }));
  };

  const setSelectedMeasures = (measures: Measure[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedMeasures: measures },
    }));
  };

  const setSelectedTrends = (measures: Measure[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], selectedTrends: measures } }));
  };

  return {
    measures: activeTabSelection?.measures || [],
    selectedMeasures: activeTabSelection?.selectedMeasures || [],
    selectedTrends: activeTabSelection?.selectedTrends || [],
    setMeasures,
    setSelectedMeasures,
    setSelectedTrends,
  };
};

/**
 * Provides the current selection of initiatives, selected initiatives, and selected trends for the initiative sections.
 * Also provides functions to update the selection.
 *
 * @returns {InitiativeSectionSelectionContext} - An object with the current selection and functions to update it.
 */
const useInitiativeSectionSelection = (): InitiativeSectionSelectionContext => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab as keyof InitiativeSectionSelectionAggregated);
  const [selection, setSelection] = useState<InitiativeSectionSelectionAggregated>(
    getDefaultInitiativeSectionSelection()
  );

  const selectionTabs = [Tab.Portfolios, Tab.Teams];
  const activeTabSelection = selectionTabs.includes(activeTab) ? selection[activeTab] : null;

  const setMetric = (metric: MetricType) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], metric } }));
  };

  const setInitiatives = (initiatives: { id: string; name: string }[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], initiatives } }));
  };

  const setSelectedInitiatives = (initiatives: { id: string; name: string }[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedInitiatives: initiatives },
    }));
  };

  const setSelectedTrends = (initiatives: { id: string; name: string }[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedTrends: initiatives },
    }));
  };

  const setInitiativesData = (data: ProcessAnalysisInitiativeChartData | null) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], initiativesData: data } }));
  };

  return {
    metric: activeTabSelection?.metric || MetricType.Tasks,
    initiatives: activeTabSelection?.initiatives || [],
    selectedInitiatives: activeTabSelection?.selectedInitiatives || [],
    selectedTrends: activeTabSelection?.selectedTrends || [],
    initiativesData: activeTabSelection?.initiativesData || null,
    setMetric,
    setInitiatives,
    setSelectedInitiatives,
    setSelectedTrends,
    setInitiativesData,
  };
};

/**
 * Provides the current selection of measures, selected measures, and selected trends for the key measures section.
 * Also provides functions to update the selection.
 *
 * @returns {KeyMeasuresSelectionContext} - An object with the current selection and functions to update it.
 */
const useKeyMeasuresSelection = (): KeyMeasuresSelectionContext => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab as keyof KeyMeasuresSelectionAggregated);
  const [selection, setSelection] = useState<KeyMeasuresSelectionAggregated>(getDefaultKeyMeasuresSelection());

  const selectionTabs = [Tab.Portfolios, Tab.Teams, Tab.Boards];
  const activeTabSelection = selectionTabs.includes(activeTab) ? selection[activeTab] : null;

  const setMeasure = (measure: Measure) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], measure } }));
  };

  const setSelectedEntities = (entities: string[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedEntities: entities },
    }));
  };

  const setSelectedTrends = (trends: string[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], selectedTrends: trends } }));
  };

  return {
    measure: activeTabSelection?.measure || Measure.Complexity,
    selectedEntities: activeTabSelection?.selectedEntities || [],
    selectedTrends: activeTabSelection?.selectedTrends || [],
    setMeasure,
    setSelectedEntities,
    setSelectedTrends,
  };
};

export { ProcessAnalysisContext, ProcessAnalysisProvider };
