import { styled } from '@linaria/react';
import { ErrorBoundary } from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useHistoricalBurns } from '../../../api/sprints-client/sprints-client.hooks';
import { Sprint, SprintBurnsAndFlows } from '../../../api/sprints-client/sprints-client.type';
import { ViewByType } from '../../../components/burn-flow/burn-flow-chart.type';
import { BurnUpChart } from '../../../components/burn-up-chart/burn-up-chart';
import { ContentTag, HeadingTag } from '../../../styles/shared-styled-components';
import { Mode } from '../../process-analysis/process-analysis.type';
import { ChartContainer } from '../chart-container';
import { useDayCalculation } from '../sprint-assessment.hooks';
import { SprintAssessmentChart } from '../sprint-assessment.type';

type FlowOfWorkToDeliveryConfidenceProps = {
  sprint: Sprint;
  sprintBurnsAndFlows: SprintBurnsAndFlows;
};

export const FlowOfWorkToDeliveryConfidence = ({
  sprint,
  sprintBurnsAndFlows,
}: FlowOfWorkToDeliveryConfidenceProps) => {
  const [dayCount, setDayCount] = useState<number>(0);
  const [viewByType, setViewByType] = useState<ViewByType>(ViewByType.tasks);

  const chartRefBurnUp = useRef<HTMLDivElement>(null);

  const { query: historicalBurnsQuery } = useHistoricalBurns({ sprint, view: viewByType }, { enabled: !!sprint });

  const { newDay, setNewDay, currentDay } = useDayCalculation(sprint, sprintBurnsAndFlows);

  useEffect(() => {
    // Set the total number of days in the sprint
    setDayCount(sprintBurnsAndFlows.burns_up.data_points.length);
  }, [sprintBurnsAndFlows]);

  const title = (
    <>
      <LargeHeadingTag>Flow of work, delivery confidence</LargeHeadingTag>
      <ContentTag>
        How work in this sprint is or isn't keeping up with needed pace, based on historical performance.
      </ContentTag>
    </>
  );

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <ChartContainer
        chartId={SprintAssessmentChart.Delivery}
        sprint={sprint}
        sprintLength={dayCount}
        sprintCurrentDay={Number(currentDay)}
        day={newDay as string}
        onChangeDay={setNewDay}
        view={viewByType}
        onChangeView={setViewByType}
        title={title}
      >
        <DayDetailsBox>
          <LargeHeadingTag>
            {`Day ${Number(newDay)}`} of {dayCount}
          </LargeHeadingTag>
        </DayDetailsBox>
        <BurnUpChart
          mode={Mode.Sprint}
          chartRef={chartRefBurnUp}
          burnsUp={sprintBurnsAndFlows.burns_up.data_points}
          historicalBurnsBand={historicalBurnsQuery.isSuccess ? historicalBurnsQuery.data.historical_burns_band : []}
          viewByType={viewByType}
          dayOfSprint={Number(newDay)}
        />
      </ChartContainer>
    </ErrorBoundary>
  );
};

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const DayDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
