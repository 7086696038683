import { styled } from '@linaria/react';
import { HealthGauge } from '../../components/health-gauge/health-gauge';

export const SprintPerformanceContainer = ({
  health,
  status,
}: {
  health: number;
  status: 'loading' | 'error' | 'success';
}) => {
  return (
    <SprintPerformanceScore>
      <HealthGaugeBox
        style={{
          marginBottom: 'inherit',
        }}
      >
        <HealthGauge
          heading="Sprint Performance Score"
          tooltip="The Sprint Performance Score measures the daily difference between actual and ideal progress, considering the planned size of the sprint in points."
          health={health}
          shouldAnimate={true}
          shouldFade={true}
          status={status}
          delay={true}
        ></HealthGauge>
      </HealthGaugeBox>
    </SprintPerformanceScore>
  );
};

const SprintPerformanceScore = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HealthGaugeBox = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
