import { LoginResponse, RefreshResponse, ResetPasswordResponse, SetPasswordResponse } from './auth-client.type';

class LoginResult {
  err?: string;
  active?: boolean;
  access?: string;
  refresh?: string;
  user?: {
    id?: number;
    name?: string;
    email?: string;
    apple_user_id?: string;
  };

  constructor(response: LoginResponse) {
    this.access = response.access;
    this.refresh = response.refresh;
  }
}

class RefreshResult {
  access: string;
  refresh: string;

  constructor(response: RefreshResponse) {
    this.access = response.access;
    this.refresh = response.refresh;
  }
}

class ResetPasswordResult {
  successful: boolean;

  constructor(status: ResetPasswordResponse) {
    this.successful = status === 204;
  }
}

class SetPasswordResult {
  successful: boolean;

  constructor(status: SetPasswordResponse) {
    this.successful = status === 204;
  }
}

export { LoginResult, RefreshResult, ResetPasswordResult, SetPasswordResult };
