import { LoadingOverlay } from '@mantine/core';
import { newCOLORS } from '../../styles/colors';
import { blackMark } from './black-mark';
import { coloredMark } from './colored-mark';

type BrandedLoadingOverlayProps = {
  visible: boolean;
  transitionDuration?: number;
  variant?: 'colored' | 'black';
  darkMode?: boolean;
};

function BrandedLoadingOverlay(props: BrandedLoadingOverlayProps) {
  const spinnerVariant = props.variant === 'colored' ? coloredMark : blackMark;
  const backgroundVariant = props.variant === 'colored' ? newCOLORS.black : newCOLORS.white;
  const { transitionDuration = 0, ...restProps } = props;

  return (
    <LoadingOverlay
      loaderProps={{ children: spinnerVariant }}
      overlayProps={{ style: { backgroundColor: backgroundVariant } }}
      transitionProps={{ duration: transitionDuration }}
      style={{ position: 'fixed' }}
      {...restProps}
    />
  );
}

export { BrandedLoadingOverlay };
