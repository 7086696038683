import { styled } from '@linaria/react';
import { newCOLORS } from '../../styles/colors';

type Props = {
  showHistoricalBurns: boolean;
};

export const BurnUpChartLegend = ({ showHistoricalBurns }: Props) => {
  return (
    <ChartLegendRow>
      <ChartLegendItem>
        <ToDoLine />
        Committed Work
      </ChartLegendItem>
      <ChartLegendItem>
        <PlanIncreaseArea />
        Plan Increase
      </ChartLegendItem>
      {showHistoricalBurns && (
        <ChartLegendItem>
          <OptimalEfficiencyArea />
          Plan Confidence
        </ChartLegendItem>
      )}
      <ChartLegendItem>
        <DoneLine />
        Work Done
      </ChartLegendItem>
    </ChartLegendRow>
  );
};

const ChartLegendRow = styled.div`
  display: flex;
`;

const ChartLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  gap: 5px;
`;

const ToDoLine = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${newCOLORS.indigo};
`;

const PlanIncreaseArea = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${newCOLORS.indigo};
  opacity: 0.2;
`;

const OptimalEfficiencyArea = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${newCOLORS.aqua};
  opacity: 0.2;
`;

const DoneLine = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${newCOLORS.green};
`;
