import { styled } from 'linaria/react';
import React, { ReactElement } from 'react';
import { newCOLORS } from '../../../styles/colors';

export function Table({
  label,
  columnHeaders,
  rows,
}: {
  label: string;
  columnHeaders: string[];
  rows: ReactElement[][];
}) {
  return (
    <GridContainer cols={columnHeaders.length + 1}>
      <LabelHeader>{label}</LabelHeader>
      {columnHeaders.map((header, index) => (
        <ColumnHeader key={`${header}-${index}`}>{header}</ColumnHeader>
      ))}
      {rows.map((row, index) => (
        <React.Fragment key={index}>{row}</React.Fragment>
      ))}
    </GridContainer>
  );
}

const GridContainer = styled.div<{ cols: number }>`
  display: grid;
  background-color: ${newCOLORS.lightGray};
  grid-gap: 1px;
  grid-template-columns: ${({ cols }) => `repeat(${cols}, 1fr)`};
  border: 1px solid ${newCOLORS.lightGray};
`;

const GridHeader = styled.div`
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
`;

const LabelHeader = styled(GridHeader)`
  color: ${newCOLORS.darkGray};
`;

const ColumnHeader = styled(GridHeader)`
  color: ${newCOLORS.darkGray};
`;
