import dayjs from 'dayjs';
import { TaskFlow } from '../../../components/task-flow/task-flow';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { WorkPeriodType } from '../process-analysis.type';
import { useTaskFlowData } from './assessment-view.hooks';

export const AssessmentTaskFlow = () => {
  const { data: taskFlowData, isFetching } = useTaskFlowData();
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const { startDate, endDate } = useDateRange();

  return (
    <TaskFlow
      taskFlowData={taskFlowData}
      startDate={workPeriodType === WorkPeriodType.Defined ? dayjs(workPeriod?.start_date).toDate() : startDate}
      endDate={workPeriodType === WorkPeriodType.Defined ? dayjs(workPeriod?.end_date).toDate() : endDate}
      isFetching={isFetching}
    />
  );
};
