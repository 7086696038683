import { APIGlobalMessage } from '../../api/global-messages-client/global-messages-client.type';

enum GlobalMessageCategory {
  API_RESPONSE = 'api_response',
  APP_ERROR = 'app_error',
}

enum GlobalMessageState {
  WARNING = 'warning',
  ERROR = 'error',
}

enum ErrorSource {
  GENERIC = 'generic',
  INTEGRATIONS = 'integrations',
  JDC = 'jdc',
}

type GlobalMessage =
  | {
      type: GlobalMessageCategory.API_RESPONSE;
      body: {
        message: string;
        globalMessages: APIGlobalMessage[];
      };
      state: GlobalMessageState.WARNING;
    }
  | {
      type: GlobalMessageCategory.APP_ERROR;
      source: ErrorSource.INTEGRATIONS | ErrorSource.JDC;
      state: GlobalMessageState.ERROR;
    };

export { ErrorSource, GlobalMessageCategory, GlobalMessageState };
export type { GlobalMessage };
