import { styled } from '@linaria/react';
import { ComboboxItem, Select } from '@mantine/core';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';

type ViewSelectProps = {
  value: ViewByType.tasks | ViewByType.points;
  onChange: (value: string) => void;
};

export const ViewSelect = ({ value, onChange }: ViewSelectProps) => {
  return (
    <ViewSelectContainer>
      <ViewLabel>View</ViewLabel>
      <SelectContainer>
        <Select
          value={value}
          data={[ViewByType.tasks, ViewByType.points]}
          onChange={(value: string | null, _: ComboboxItem) => {
            if (value) {
              onChange(value);
            }
          }}
        />
      </SelectContainer>
    </ViewSelectContainer>
  );
};

const ViewSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ViewLabel = styled.div`
  display: flex;
`;

const SelectContainer = styled.div`
  display: flex;
  max-width: 100px;
`;
