import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchUserInfo } from './user-client';
import { UserInfo } from './user-client.type';

/**
 * Query manager for fetching the currently logged in user's information.
 *
 * @param {UseQueryOptions<UserInfo>} options - Options for the query.
 * @return {{ data: UserInfo | undefined; query: QueryObserverResult<UserInfo> }} An object containing the user
 * information and the query result.
 */
const useUser = (
  options?: UseQueryOptions<UserInfo>
): { data: UserInfo | undefined; query: QueryObserverResult<UserInfo> } => {
  const query = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserInfo,
    staleTime: 1000 * 60 * 5,
    ...options,
  });

  return { data: query.data, query };
};

export { useUser };
