import { styled } from '@linaria/react';
import { HistoricalSprintData, HistoricalSprintDataKind } from '../../api/sprints-client/sprints-client.type';
import { measuredBy } from '../../helpers/project-metrics-helper/project-metrics-helper';
import { newCOLORS } from '../../styles/colors';
import { ProjectStatisticsBarChart } from '../project-statistics-bar-chart/project-statistics-bar-chart';

type ProjectStatisticsGridBoxProps = {
  clickHandler: (kind: HistoricalSprintDataKind) => void;
  color: string;
  data: HistoricalSprintData;
};

export const ProjectStatisticsGridBox = (props: ProjectStatisticsGridBoxProps) => {
  const data = props.data;

  if (!data) {
    return null;
  }

  const color = props.color;
  const amount = data.average;
  const subtitle = `${measuredBy(data.title, data.average)} avg`;

  const handleClick = () => {
    props.clickHandler(data.title);
  };

  return (
    <Box onClick={handleClick}>
      <BoxHeading>
        <Title>{data.title}</Title>
      </BoxHeading>
      <div>
        <SubHeadingValueTag color={color}>{Math.round(amount)}</SubHeadingValueTag>{' '}
        <SubHeadingDescriptionTag color={color}>{subtitle}</SubHeadingDescriptionTag>
      </div>
      <ProjectStatisticsBarChart historicalSprintData={data} color={color} />
    </Box>
  );
};

export const BoxHeading = styled.div`
  font-weight: 800;
  color: gray;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.3em;
  padding-top: 0.5em;
`;

const Title = styled.div`
  color: ${newCOLORS.black};
  font-size: 18px;
  font-weight: bold;
`;
const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  background-color: white;
  min-width: 252px;
  padding: 10px;
`;

export const SubHeadingValueTag = styled.b`
  font-size: x-large;
  font-family: 'Figtree-bold';
  color: ${(props) => props.color || newCOLORS.blue};
  padding-left: 0.7em;
`;

export const SubHeadingDescriptionTag = styled.b`
  font-size: small;
  padding-left: 0.2em;
  color: ${(props) => props.color || newCOLORS.blue};
`;
