import { styled } from '@linaria/react';
import { ComboboxItem, Select } from '@mantine/core';
import { Metric } from '../assessment-view.type';

type Props = {
  value: Metric;
  onChange: (value: Metric) => void;
};

export const ViewSelect = ({ value, onChange }: Props) => {
  return (
    <ViewSelectContainer>
      <ViewLabel>View</ViewLabel>
      <SelectContainer>
        <Select
          value={value}
          data={[Metric.Tasks, Metric.Points]}
          onChange={(value: string | null, _: ComboboxItem) => {
            if (value) {
              onChange(value as Metric);
            }
          }}
        />
      </SelectContainer>
    </ViewSelectContainer>
  );
};

const ViewSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ViewLabel = styled.div`
  display: flex;
`;

const SelectContainer = styled.div`
  display: flex;
  max-width: 100px;
`;
