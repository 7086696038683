import { formatDate } from '../../helpers';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { MinifiedTask } from '../tasks-client/task-client.type';
import {
  AllocationOfCostsChartResponse,
  AllocationOfCostsChartResponseV2,
  AllocationOfCostsChartResponseV2ByCategory,
  CostByInitiativeResponse,
  CostVsBudgetChartResponse,
  CostVsBudgetChartResponseV2,
  ProjectSpending,
  Spending,
  SpendingBulkDeleteRequest,
  SpendingBulkPostRequest,
} from './financials-client.type';
const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Method to fetch the spending data for a portfolio
 * @param portfolioId portfolio id
 * @param years years for which the spending data is to be fetched
 * @returns spending data for the portfolio
 */
async function getSpending(portfolioId: string, years?: string): Promise<Spending[]> {
  let apiURL = baseURL + `/api/financials/spending/?portfolio_id=${portfolioId}`;
  if (years !== undefined && years !== null && years !== '') {
    apiURL += `&years=${years}`;
  }
  return await axiosInstance
    .get<Spending[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method for upserting the spending data for a portfolio
 * @param spending list of spending data to be upserted
 * @returns updated spending data
 */
async function bulkUpsertSpending(spending: Spending[]): Promise<Spending[]> {
  const apiURL = baseURL + `/api/financials/spending/`;
  const postData: SpendingBulkPostRequest | { [key: string]: string | number | ProjectSpending } = {};
  postData.projects = {};
  for (const item of spending) {
    postData.portfolio_id = item.portfolio_id;
    postData.year = item.year;
    postData.projects[item.project_id] = postData.projects[item.project_id] || [];
    postData.projects[item.project_id].push({
      month: item.month,
      budget: item.budget,
    });
  }
  return await axiosInstance
    .post(apiURL, postData as SpendingBulkPostRequest)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method to delete the spending data
 * @param portfolio_id portfolio id
 * @param project_id project id
 * @param year year
 * @returns deleted spending data for reference
 */
async function deleteSpendingByParams(portfolio_id: string, project_id: string, year: number): Promise<Spending[]> {
  const apiURL = baseURL + `/api/financials/spending/delete-by-params/`;
  const data: SpendingBulkDeleteRequest = {
    portfolio_id,
    project_id,
    year,
  };

  return await axiosInstance
    .delete(apiURL, { data })
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method to fetch data for cost vs output chart
 * @param portfolioId portfolio id
 * @param startDate start date
 * @param endDate end date
 * @param projectId project id
 * @returns an object of cost vs output data
 */
async function getCostVsOutputChartData(
  portfolioId: string,
  startDate: Date,
  endDate: Date,
  projectId?: string
): Promise<CostVsBudgetChartResponse> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  let apiURL =
    baseURL +
    `/api/financials/spending/cost-vs-output/?portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }
  return await axiosInstance
    .get<CostVsBudgetChartResponse>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method to fetch data for cost vs output chart
 * @param portfolioId portfolio id
 * @param startDate start date
 * @param endDate end date
 * @param projectId project id
 * @returns an object of cost vs output data
 */
async function getCostVsOutputChartDataV2(
  portfolioId: string,
  startDate: Date,
  endDate: Date,
  projectId?: string
): Promise<CostVsBudgetChartResponseV2> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  let apiURL =
    baseURL +
    `/api/work-periods/measure/?measure_name=cost_vs_output&portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }
  return await axiosInstance
    .get<CostVsBudgetChartResponseV2>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch data for allocation of costs chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param category selected category to show the spending for
 * @param projectId project id if any selected
 * @returns dictionary of spending data
 */
async function getAllocationOfCostsChartData(
  portfolioId: string,
  startDate: Date,
  endDate: Date,
  category: string,
  projectId?: string
): Promise<AllocationOfCostsChartResponse> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  let apiURL =
    baseURL +
    `/api/financials/spending/allocation-of-costs/?portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}&category=${category}`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }
  return await axiosInstance
    .get<AllocationOfCostsChartResponse>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch data for allocation of costs chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param projectId project id if any selected
 * @returns dictionary of spending data
 */
async function getAllocationOfCostsChartDataV2(
  portfolioId: string,
  startDate: Date,
  endDate: Date,
  projectId?: string
): Promise<AllocationOfCostsChartResponseV2ByCategory> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  let apiURL =
    baseURL +
    `/api/work-periods/measure/?measure_name=cost_allocation&portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }
  return await axiosInstance
    .get<AllocationOfCostsChartResponseV2>(apiURL)
    .then((response) => response.data.cost_allocation)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch data for cost by initiative chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param category selected category to show the spending for
 * @param projectId (OPTIONAL) project id if any selected
 * @returns dictionary of spending data
 */
async function getCostByInitiativeChartData(
  portfolioId: string,
  startDate: Date,
  endDate: Date,
  projectId?: string
): Promise<CostByInitiativeResponse[]> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  let apiURL =
    baseURL +
    `/api/financials/spending/cost-by-initiative/?portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }
  return await axiosInstance
    .get<CostByInitiativeResponse[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch task details for allocation of costs chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param date selected date to filter the tasks
 * @param factor selected factor to filter the tasks
 * @param factorOption selected factor option to filter the tasks
 * @param projectId (OPTIONAL) project id if any selected
 * @returns list of task details
 */
async function getAllocationOfCostsTaskDetailsV2(
  portfolioId: string,
  startDate: string,
  endDate: string,
  date: string,
  factor: string,
  factorOption: string,
  projectId?: string
): Promise<MinifiedTask[]> {
  let apiURL =
    baseURL +
    `/api/work-periods/tasks/?measure_name=cost_allocation&portfolio_id=${portfolioId}&start_date=${startDate}&end_date=${endDate}&measure_filter=${date},${factor},${factorOption}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }

  return await axiosInstance
    .get<MinifiedTask[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch task details for cost vs output chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param date selected date to filter the tasks
 * @param factor selected factor to filter the tasks
 * @param projectId (OPTIONAL) project id if any selected
 * @returns list of task details
 */
async function getCostVsOutputTaskDetailsV2(
  portfolioId: string,
  startDate: string,
  endDate: string,
  date: string,
  factor: string,
  projectId?: string
): Promise<MinifiedTask[]> {
  let apiURL =
    baseURL +
    `/api/work-periods/tasks/?measure_name=cost_vs_output&portfolio_id=${portfolioId}&start_date=${startDate}&end_date=${endDate}&measure_filter=${date},${factor}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }

  return await axiosInstance
    .get<MinifiedTask[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

export {
  bulkUpsertSpending,
  deleteSpendingByParams,
  getAllocationOfCostsChartData,
  getAllocationOfCostsChartDataV2,
  getAllocationOfCostsTaskDetailsV2,
  getCostByInitiativeChartData,
  getCostVsOutputChartData,
  getCostVsOutputChartDataV2,
  getCostVsOutputTaskDetailsV2,
  getSpending,
};
