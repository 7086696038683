import { styled } from '@linaria/react';
import { Flex, Grid } from '@mantine/core';
import { newCOLORS } from '../../../styles/colors';
import { Text } from '../process.styled';

const items = [
  {
    component: () => (
      <Pill
        style={{ width: '50px', height: '17px', borderRadius: '10px' }}
        bgColor={newCOLORS.black2}
        borderColor={newCOLORS.black2}
      />
    ),
    label: 'Expected steps',
  },
  {
    component: () => (
      <Pill
        style={{ width: '50px', height: '17px', borderRadius: '10px' }}
        bgColor={newCOLORS.purple2}
        borderColor={newCOLORS.purple2}
      />
    ),
    label: 'Skipped steps',
  },
  {
    component: () => (
      <Pill
        style={{ width: '50px', height: '17px', borderRadius: '10px' }}
        bgColor={newCOLORS.white}
        borderColor={newCOLORS.purple2}
      />
    ),
    label: 'Backwards steps',
  },
  {
    component: () => (
      <Pill
        style={{ width: '50px', height: '17px', borderRadius: '10px' }}
        bgColor={newCOLORS.gray3}
        borderColor={newCOLORS.gray3}
      />
    ),
    label: 'Blocked (unexpected)',
  },
];

export function Legend() {
  return (
    <Flex direction="column" gap={24}>
      <Text fontSize={19}>Legend</Text>
      <Flex direction="column" gap={16}>
        {items.map((item) => (
          <Grid key={item.label}>
            <Grid.Col span={3.5} style={{ display: 'flex', alignItems: 'center' }}>
              {item.component()}
            </Grid.Col>
            <Grid.Col span={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Text fontWeight={400}>{item.label}</Text>
            </Grid.Col>
          </Grid>
        ))}
      </Flex>
    </Flex>
  );
}

const Pill = styled.div<{ bgColor: string; borderColor: string }>`
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ bgColor }) => bgColor};
`;
