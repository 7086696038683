import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { ExternalBoard, ExternalProject } from '../../../api/projects-client/projects-client.type';
import { UserInfo } from '../../../api/user-client/user-client.type';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { IntegrationsContext } from '../context/integrations.context';
import { completeReauth } from '../integrations.helpers';
import { useRedirect } from '../integrations.hooks';
import { ProjectView } from '../views/project-view';
import { JiraReducerAction, JiraState } from './jira.type';

type ProjectsProps = {
  state: JiraState;
  dispatch: Dispatch<JiraReducerAction>;
  user: UserInfo | null;
};

/** The view for selecting projects during the jira itegration workflow.
 *
 * @param props: { state: JiraState, dispatch: Dispatch<JiraReducerAction> }
 * @returns JSX.Element
 */
export function Projects({ state, dispatch, user }: ProjectsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { redirect, setRedirect } = useRedirect();
  const { query: integrationsQuery } = useContext(IntegrationsContext);

  useEffect(() => {
    // If the user cancels Jira auth (or if there is an error), navigate back to the integrations page
    const error = searchParams.get('error');
    const validParams = searchParams.get('code') && searchParams.get('state');
    const notInitialized = !validParams && !state.initialized;

    if (error || notInitialized) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate, state.initialized]);

  const completeOauthQuery = useQuery(
    ['oauth', user],
    () => {
      const code = searchParams.get('code');
      const tempStates = searchParams.get('state')?.split(',');
      const state = tempStates ? tempStates[0] : '';
      // Save the selected Jira instance to the local storage for further requests
      localStorage.setItem('jiraInstance', state);
      const orgId = tempStates ? tempStates[1] : '';
      setSearchParams(searchParams);
      const userId = user?.id.toString();
      return userId && orgId && code
        ? completeOauth(userId, orgId, code, ExternalService.Jira, state as string)
        : Promise.reject(new Error(`Invalid userId: ${userId} or orgId: ${orgId} or code: ${code}`));
    },
    {
      enabled: !!searchParams.get('code') && !!user && !state.project?.id,
      onSuccess: (data) => {
        trackEvent(AnalyticsEventType.JiraProjectsViewed, { userContext: user });
        dispatch({
          type: 'options',
          payload: {
            allBoards: data.boards.sort((a: ExternalBoard, b: ExternalBoard) => a.name.localeCompare(b.name)),
            projects: data.projects.sort((a: ExternalProject, b: ExternalProject) => a.name.localeCompare(b.name)),
            systemAccessId: data.system_access_id,
          },
        });

        dispatch({ type: 'init' });
      },
      onSettled: () => {
        searchParams.delete('code');
        searchParams.delete('state');
        setSearchParams(searchParams);

        if (redirect) {
          completeReauth();
          navigate(redirect);
          integrationsQuery.refetch();
          setRedirect(null);
        }
      },
    }
  );

  /** Handles the change of the project selection.
   * Filters the boards options to only show the boards for the selected project.
   *
   * @param project: ExternalProject - the selected project
   */
  const handleChange = useCallback(
    (projectId: string) => {
      try {
        const project = (state.options?.projects || []).find((p) => p.id === projectId);
        const filteredBoards = state.options?.allBoards.filter(
          (b) => !b.location || Number(b.location.projectId) === Number(projectId)
        );
        dispatch({ type: 'options', payload: { boards: filteredBoards } });
        dispatch({ type: 'project', payload: project });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [state.options?.allBoards, state.options?.projects, dispatch]
  );

  return (
    <>
      <BrandedLoadingOverlay
        visible={!state.options?.projects?.length && completeOauthQuery.isLoading}
        variant="colored"
      />
      {!redirect && (
        <ProjectView
          projects={state.options?.projects || []}
          selectedProjectId={state.project?.id || null}
          onChange={handleChange}
        />
      )}
    </>
  );
}
