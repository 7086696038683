import { newCOLORS } from '../../styles/colors';

/**
 * Method that returns the colors based on the position of the value
 * within the standard deviation bands
 * @param value datapoint
 * @param bands array of standard deviation bands
 * @returns hex code of a color
 */
function getColorByValue(value: number, bands: number[]): string {
  if (value > bands[6]) {
    return newCOLORS.red;
  }
  if (bands[6] > value && value >= bands[5]) {
    return newCOLORS.orange;
  }
  if (bands[5] > value && value >= bands[4]) {
    return newCOLORS.darkYellow;
  }
  if (bands[4] > value && value >= bands[3]) {
    return newCOLORS.green;
  }
  if (bands[3] > value && value >= bands[2]) {
    return newCOLORS.green;
  }
  if (bands[2] > value && value >= bands[1]) {
    return newCOLORS.darkYellow;
  }
  if (value >= bands[0]) {
    return newCOLORS.orange;
  }
  return newCOLORS.red;
}

export { getColorByValue };
