import { useEffect, useState } from 'react';
import { GlobalMessage, GlobalMessageCategory } from './global-message.type';

type UseGlobalMessageReturnType = {
  globalMessage: GlobalMessage | null;
  setGlobalMessage: React.Dispatch<React.SetStateAction<GlobalMessage | null>>;
};

/**
 * Custom hook to manage global message state and listen to global message updates.
 *
 * @return {UseGlobalMessageReturnType} The global message state and its setter function.
 */
const useGlobalMessage = (): UseGlobalMessageReturnType => {
  const [globalMessage, setGlobalMessage] = useState<GlobalMessage | null>(null);

  useEffect(() => {
    const handleStorageChange = (event: Event) => {
      const message: GlobalMessage = (event as CustomEvent).detail;

      if (message.type === GlobalMessageCategory.APP_ERROR) {
        setGlobalMessage(message);
      } else if (
        message.type === GlobalMessageCategory.API_RESPONSE &&
        globalMessage?.type !== GlobalMessageCategory.APP_ERROR
      ) {
        const hasGlobalMessages = message.body.globalMessages.length > 0;
        setGlobalMessage(hasGlobalMessages ? message : null);
      }
    };

    window.addEventListener('globalMessagesStorage', handleStorageChange);

    return () => {
      window.removeEventListener('globalMessagesStorage', handleStorageChange);
    };
  }, [globalMessage, setGlobalMessage]);

  return {
    globalMessage,
    setGlobalMessage,
  };
};

export { useGlobalMessage };
