import { Button, Select, TextInput } from '@mantine/core';
import { Dispatch } from 'react';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import styles from '../setup.module.css';
import { SetupCompany, SetupUser } from '../setup.types';

export const COMPANY_SIZES = ['Less than 25', '25-50', '51-100', '101-500', '501-1000', 'More than 1000'];
export const INDUSTRIES = [
  'Accounting',
  'Advertising',
  'Agriculture',
  'Architecture',
  'Arts',
  'Automotive',
  'Banking',
  'Biotechnology',
  'Business Services',
  'Construction',
  'Consumer Goods',
  'Consumer Services',
  'Education',
  'Energy',
  'Engineering',
  'Entertainment',
  'Environmental',
  'Finance',
  'Food & Beverage',
  'Government',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Legal',
  'Manufacturing',
  'Media',
  'Not For Profit',
  'Other',
  'Recreation',
  'Retail',
  'Technology',
  'Telecommunications',
  'Transportation',
];

type CompanyInformationProps = {
  company: SetupCompany;
  user: SetupUser;
  dispatch: Dispatch<any>;
  onClick: Function;
};

export function CompanyInformation({ user, company, dispatch, onClick }: CompanyInformationProps) {
  const handleChange = (e: any, type: any, fieldName: any) =>
    dispatch({ type, payload: { [fieldName]: e.target.value } });

  const { name = '', industry = null, size = null } = company;

  const inputStyle = { input: { height: '3.5em' } };
  return (
    <>
      <form id={styles.formContainer}>
        <div className={styles.formSection}>
          <TextInput
            autoFocus
            label="What&#39;s your company&#39;s name?"
            placeholder="Company Name"
            value={name}
            onChange={(e: any) => handleChange(e, 'company', 'name')}
            styles={inputStyle}
            required
          />
        </div>
        <div className={styles.formSection}>
          <Select
            label="What industry best fits your company?"
            placeholder="Industry"
            data={INDUSTRIES.map((industry) => ({ value: industry, label: industry }))}
            value={industry}
            onChange={(value) => dispatch({ type: 'company', payload: { industry: value } })}
            styles={{ input: { height: '3.5em' } }}
            searchable
            required
          />
        </div>
        <div className={styles.formSection}>
          <Select
            label="How many employees are in the company?"
            placeholder="How many employees are in the company?"
            data={COMPANY_SIZES.map((size) => ({ value: size, label: size }))}
            value={size}
            onChange={(value) => dispatch({ type: 'company', payload: { size: value } })}
            styles={{ input: { height: '3.5em' } }}
            required
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.backButton} onClick={() => onClick('back')}>
            back
          </Button>
          <Button
            disabled={!name || !industry || !size}
            className={styles.button}
            onClick={() => {
              trackEvent(AnalyticsEventType.CreateCompanyTapped, { userContext: { ...user, clientName: name } });
              onClick('next');
            }}
          >
            next
          </Button>
        </div>
      </form>
    </>
  );
}
