import { useCallback } from 'react';
import { BoardView } from '../views/board-view';
import { ADOReducerAction, ADOState } from './ado.type';

type Props = {
  state: ADOState;
  dispatch: React.Dispatch<ADOReducerAction>;
};

export function Boards({ state, dispatch }: Props) {
  const handleChange = useCallback(
    (selectedBoardId: string) => {
      const selectedBoard = (state.options?.teams || []).find((board) => board.id === selectedBoardId);
      return dispatch({ type: 'team', payload: selectedBoard });
    },
    [dispatch, state.options?.teams]
  );

  return (
    <BoardView boards={state.options?.teams || []} selectedBoardId={state.team?.id || null} onChange={handleChange} />
  );
}
