import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Flex } from '@mantine/core';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IntegrationEntry, IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { icons } from '../../../assets/icons/icons';
import { Button } from '../../../components/button/button';
import { useRoles } from '../../../helpers/auth-helpers/auth.hooks';
import { newCOLORS } from '../../../styles/colors';
import { IntegrationsContext } from '../context/integrations.context';
import { AvailableIntegrationsSelect } from './available-integrations-select';
import { AvailableIntegrationsTable } from './available-integrations-table';
import { availableIntegrations } from './integrations-list.data';
import { getIntegrationUnit } from './integrations-list.helpers';
import {
  CollapseIcon,
  ContentContainer,
  HeadingContainer,
  HeadingTitle,
  HeadingTitleText,
  SectionContainer,
} from './integrations-list.styled';
import { Integration, Section } from './integrations-list.type';

type Props = {
  integrationEntries: IntegrationEntry[];
  isLoading: boolean;
  openedSection: Section | null;
  setOpenedSection: (section: Section | null) => void;
  setShowRefreshMessage: (value: boolean) => void;
};

export function AvailableIntegrations({
  integrationEntries,
  isLoading,
  openedSection,
  setOpenedSection,
  setShowRefreshMessage,
}: Props) {
  const { isAdmin } = useRoles();
  const navigate = useNavigate();
  const { activeIntegration, setActiveIntegration } = useContext(IntegrationsContext);

  const opened = openedSection === Section.AvailableIntegrations;
  const icon = opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one';

  const handleCollapseIcon = useCallback(() => {
    if (opened) {
      setOpenedSection(null);
    } else {
      setOpenedSection(Section.AvailableIntegrations);
    }
  }, [opened, setOpenedSection]);

  return (
    <SectionContainer style={{ minWidth: 1000, marginBottom: 16 }}>
      <HeadingContainer>
        <HeadingTitle>
          <CollapseIcon>
            <Icon icon={icon} width={16} height={16} color={newCOLORS.darkerGray} onClick={handleCollapseIcon} />
          </CollapseIcon>
          <HeadingTitleText>Available integrations {`(${availableIntegrations.length})`}</HeadingTitleText>
        </HeadingTitle>
        {opened && (
          <AvailableIntegrationsSelect
            activeIntegration={activeIntegration}
            setActiveIntegration={setActiveIntegration}
            integrationEntries={integrationEntries}
          />
        )}
      </HeadingContainer>
      <Collapse in={opened} style={{ width: '100%' }}>
        <ContentContainer>
          <Flex justify="space-between" align="center">
            <IntegrationDescription integration={activeIntegration} />
            {isAdmin && (
              <StyledButton onClick={() => navigate('/application/integrations')}>
                Add {getIntegrationUnit(activeIntegration)}
              </StyledButton>
            )}
          </Flex>
          {!isLoading && (
            <AvailableIntegrationsTable
              activeIntegration={activeIntegration}
              integrationEntries={filterIntegrationEntries(integrationEntries, activeIntegration)}
              setShowRefreshMessage={setShowRefreshMessage}
            />
          )}
        </ContentContainer>
      </Collapse>
    </SectionContainer>
  );
}

/**
 * Filters the given integration entries based on the active integration.
 *
 * @param {IntegrationEntry[]} integrationEntries - The list of integration entries to filter.
 * @param {Integration} activeIntegration - The active integration to use for filtering.
 * @returns {IntegrationEntry[]} The filtered list of integration entries.
 */
const filterIntegrationEntries = (
  integrationEntries: IntegrationEntry[] = [],
  activeIntegration: Integration
): IntegrationEntry[] =>
  integrationEntries.filter((entry) => {
    if (activeIntegration.name === IntegrationType.JIRA) {
      return [IntegrationType.JIRA, IntegrationType.JDC].includes(entry.integration_type);
    }

    return entry.integration_type === activeIntegration.name;
  });

const IntegrationDescription = ({ integration }: { integration: Integration }) => {
  const getIcon = useCallback(() => {
    const iconSet = {
      [IntegrationType.JIRA]: icons.iconJira,
      [IntegrationType.GITHUB]: icons.iconGithub,
      [IntegrationType.ADO]: icons.iconADO,
    };

    const integrationName = integration.name as IntegrationType.JIRA | IntegrationType.GITHUB | IntegrationType.ADO;

    return iconSet[integrationName];
  }, [integration]);

  return (
    <DescriptionContainer>
      <img src={getIcon()} alt={integration.title} height={35} width={35} />
      <Flex direction="column" gap={4}>
        <DescriptionTitleText>{integration.title}</DescriptionTitleText>
        <DescriptionBodyText>{integration.description}</DescriptionBodyText>
      </Flex>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const DescriptionTitleText = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const DescriptionBodyText = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
`;

const StyledButton = styled(Button)`
  width: 160px;
  height: 34px;
  color: #fff;
  text-align: center;
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;
