import { Tooltip } from '@mantine/core';

type Props = {
  label: string;
  tooltip?: string;
};

export function MeasureLabel({ label, tooltip }: Props) {
  if (!tooltip) {
    return <span>{label}</span>;
  }

  return (
    <Tooltip
      multiline
      w={300}
      position="bottom"
      label={tooltip}
      styles={{ tooltip: { textAlign: 'left', textTransform: 'none' } }}
    >
      <span style={{ cursor: 'pointer' }}>{label}</span>
    </Tooltip>
  );
}
