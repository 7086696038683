import {
  IntegrationEntry,
  IntegrationType,
  Subproject,
} from '../../../api/integrations-client/integrations-client.type';

type Integration = {
  name: IntegrationType;
  title: string;
  description: string;
};

enum IntegrationStatus {
  Operational = 'Operational',
  Stale = 'Data Stale',
  Expired = 'Token Expired',
}

type IntegrationProject = Omit<IntegrationEntry, 'projects'> & {
  name: string;
  project_id: string;
  subprojects: Subproject[];
  status: IntegrationStatus;
};

enum Section {
  AvailableIntegrations = 'Available Integrations',
  UpcomingIntegrations = 'Upcoming Integrations',
}

export { IntegrationStatus, Section };
export type { Integration, IntegrationProject };
