import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { ProjectsResponse } from '../projects-client/projects-client.type';
import { SummaryResponse } from './summary-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/portfolios/';

const getSummary = async (id: string): Promise<SummaryResponse> => {
  const url = baseURL + `${id}/summary/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error?.response?.data || 'Error while fetching summary');
    });
};

/**
 * Fetches a list of projects for the current user.
 * @returns ProjectsResponse[](data)
 */
const fetchPortfolioProjects = async (id: string): Promise<ProjectsResponse[]> => {
  const apiURL = baseURL + `${id}/projects/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data || 'Error while fetching portfolio projects');
    });
};

export { fetchPortfolioProjects, getSummary };
