import { styled } from '@linaria/react';
import { Progress } from '@mantine/core';
import { Fragment, useMemo, useState } from 'react';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import { Epic } from '../../../../api/tasks-client/task-client.type';
import { icons } from '../../../../assets/icons/icons';
import { newCOLORS } from '../../../../styles/colors';
import { CollapsibleSection } from '../collapsible-section';
import { CollapsibleTile } from '../collapsible-tile';
import { getColoredEntities } from '../initiative-performance.helpers';
import { ColoredEntity, InitiativeMetricType } from '../initiative-performance.type';
import { Legend } from '../legend';
import { SectionEpicsChart } from './section-epics-chart';
import { getEpicProgress } from './section-epics.helpers';

type Props = {
  initiative: ExtendedInitiative;
};

const getLegendItems = (entities: ColoredEntity[]) =>
  entities.map(({ name, color }: { name: string; color: string }) => ({ title: name, color }));

export function SectionEpics({ initiative: { epics = [], costs_to_date, contributing_epics_over_time } }: Props) {
  const [metric, setMetric] = useState<InitiativeMetricType>(InitiativeMetricType.Tasks);
  const sortedEpics = epics.sort((a, b) => {
    return getEpicProgress(b) - getEpicProgress(a);
  });
  const coloredEpics = useMemo(
    () => getColoredEntities(sortedEpics.map(({ id, name }) => ({ id, name }))),
    [sortedEpics]
  );

  return (
    <CollapsibleSection
      titleElement={<Title amountEpics={epics.length} />}
      title="Contributing Epics"
      metric={metric}
      setMetric={setMetric}
    >
      <EpicSummaryContainer>
        {(sortedEpics || []).map((epic, index) => {
          const cost =
            Object.keys(costs_to_date).length !== 0
              ? Object.keys(costs_to_date?.epics).includes(epic.id)
                ? costs_to_date.epics[epic.id]
                : undefined
              : undefined;
          // use index as a key here to update isOpened prop when the epics list changes size or order
          return <EpicProgress key={`${epic.id}-${index}`} epic={epic} cost={cost} isOpened={false} />;
        })}
      </EpicSummaryContainer>
      <ChartContainer>
        <SectionEpicsChart
          epics={sortedEpics}
          contributingEpicsData={contributing_epics_over_time}
          coloredEpics={coloredEpics}
          metric={metric}
        />
        <Legend items={getLegendItems(coloredEpics)} />
      </ChartContainer>
    </CollapsibleSection>
  );
}

const Title = ({ amountEpics = 0 }: { amountEpics?: number }) => {
  return (
    <TitleContainer>
      <img src={icons.iconContributingEpics} width={20} height={20} />
      {`Contributing Epics (${amountEpics})`}
    </TitleContainer>
  );
};

const EpicProgress = ({ epic, cost, isOpened }: { epic: Epic; cost?: number; isOpened: boolean }) => {
  const [showMore, setShowMore] = useState(false);

  const score = (
    <Fragment>
      <CompletionLabel>Completion</CompletionLabel>
      <Progress
        color={newCOLORS.darkGreen}
        value={getEpicProgress(epic)}
        style={{ width: '100%', height: '4px', marginTop: '4px' }}
      />
    </Fragment>
  );

  const getData = () => {
    const data = [
      {
        label: 'Completed tasks',
        value: <CompletedTasksValue>{`${epic.subtasks_completed_count}/${epic.subtasks_count}`}</CompletedTasksValue>,
      },
      {
        label: 'Cost to date',
        value: cost ? `$${cost.toLocaleString()}` : '-',
      },
      {
        label: 'Team',
        value: epic.project_name,
      },
    ];

    if (!epic.description) {
      return data;
    }

    return [
      ...data,
      {
        label: 'Description',
        value: (
          <DescriptionValue>
            {showMore ? epic.description : `${epic.description.substring(0, 200)}`}
            {epic.description.length > 200 && (
              <button
                style={{
                  fontSize: '12px',
                  color: newCOLORS.darkPurple,
                  outline: 'none',
                  border: 'none',
                  background: 'none',
                }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'Show less' : 'Show more'}
              </button>
            )}
          </DescriptionValue>
        ),
      },
    ];
  };

  return (
    <CollapsibleTile
      title={epic.name}
      url={epic.url}
      score={score}
      data={getData()}
      isOpened={isOpened}
      epicId={epic.id}
    />
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const EpicSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 300px;
`;

const CompletionLabel = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`;

const CompletedTasksValue = styled.div`
  border-bottom: 1px dashed ${newCOLORS.indigo};
  color: ${newCOLORS.indigo};
  font-size: 15px;
  width: fit-content;
  line-height: 21px;
`;

const DescriptionValue = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 12px;
  max-width: 325px;
  overflow: auto;
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 32px;
`;
