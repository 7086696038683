import './setup.module.css';

export function Legal() {
  return (
    <div id="termsOfService">
      <p>Welcome to Bloomfilter.</p>
      <p>
        By using this website, you <b>&quot;Customer&quot;</b> agree to accept this <b>Terms of Use Agreement</b>{' '}
        <b>&quot;Agreement&quot;</b>. This agreement is a legally binding agreement between you and Bfilter, Inc, a
        Delaware corporation <b>&quot;Company&quot;</b>; please read it carefully.
      </p>
      <h4>
        <b>
          <u>1. Software.</u>
        </b>
      </h4>
      <b>1.1 Access and Use:</b>
      <p>
        Subject to and conditioned on Customer&apos;s and its Authorized Users&apos; compliance with the terms and
        conditions of this Agreement, Company will make the Services available to Customer during the Term, solely for
        use by Authorized Users in accordance with the terms and conditions herein.
      </p>
      <b>1.2 Documentation License:</b>
      <p>
        Company hereby grants to Customer a non-exclusive, non-sublicensable, non-transferable license to use the
        Documentation during the Term solely for Customer&apos;s internal business purposes in connection with its use
        of the Software.
      </p>
      <b>1.3 Changes:</b>
      <p>
        Company reserves the right, in its sole discretion, to make any changes to the Software and Company Materials
        that it deems necessary or useful to: (a) maintain or enhance: (i) the quality or delivery of Company&apos;s
        services to its customers; (ii) the competitive strength of or market for Company&apos;s services; or (iii) the
        Software&apos;s cost efficiency or performance; or (b) to comply with applicable law.
      </p>
      <b>1.4 Suspension or Termination of Software:</b>
      <p>
        Company may, directly or indirectly, by use of any lawful means, suspend, terminate, or otherwise deny
        Customer&apos;s, any Authorized User&apos;s, or any other person&apos;s access to or use of all or any part of
        the Software or Company Materials, without incurring any resulting obligation or liability, if: (a) Company
        receives a judicial or other governmental demand or order, subpoena, or law enforcement request that expressly
        or by reasonable implication requires Company to do so; or (b) Company believes, in its sole discretion, that:
        (i) Customer or any Authorized User has failed to comply with any term of this Agreement, or accessed or used
        the Software beyond the scope of the rights granted or for a purpose not authorized under this Agreement or in
        any manner that does not comply with any instruction or requirement of the Documentation; (ii) Customer or any
        Authorized User is, has been, or is likely to be involved in any fraudulent, misleading, or unlawful activities
        relating to or in connection with the Software; or (iii) this Agreement expires or is terminated. This Section
        1.4 does not limit any of Company&apos;s other rights or remedies, whether at law, in equity, or under this
        Agreement.
      </p>
      <h4>
        <b>
          <u>2. Definitions.</u>
        </b>
      </h4>
      <b>&quot;Access Credentials&quot;:</b>
      <p>
        means any user name, identification number, password, license or security key, security token, PIN or other
        security code, method, technology or device used, alone or in combination, to verify an individual&apos;s
        identity and authorization to access and use the Services.
      </p>
      <b>&quot;Action&quot;:</b>
      <p>
        means any claim, action, cause of action, demand, lawsuit, arbitration, inquiry, audit, notice of violation,
        proceeding, litigation, citation, summons, subpoena, or investigation of any nature, civil, criminal,
        administrative, regulatory, or other, whether at law, in equity, or otherwise.
      </p>
      <b>&quot;Authorized Users&quot;:</b>
      <p>
        means Customer&apos;s employees, consultants, contractors, and agents (a) who are authorized by Customer to
        access and use the Software under the rights granted to Customer pursuant to this Agreement; and (b) for whom
        access to the Software has been purchased hereunder.
      </p>
      <b>&quot;Company Materials&quot;:</b>
      <p>
        means the Software, Documentation, Services, and all other information, data, documents, materials, works, and
        other content, devices, methods, processes, hardware, software, and other technologies and inventions, including
        any deliverables, technical or functional descriptions, requirements, plans, or reports, that are provided or
        used by Company or any subcontractor of Company in connection with the Software or Services or otherwise
        comprise or relate to the Software or Services. For the avoidance of doubt, Company Materials include Resultant
        Data and any information, data, or other content derived from Company&apos;s monitoring of Customer&apos;s
        access to or use of the Software, but do not include Customer Data.
      </p>
      <b>&quot;Customer Data&quot;:</b>
      <p>
        means non-public data provided by Customer to Company to enable Company to provide Services and/or Software,
        including but not limited to messages and content in the Software; including any integrated third-party
        applications; personally identifiable information about Authorized Users such as names, location, IP address,
        and domain; time and date from which an Authorized User accesses the Services and/or Software; and methods by
        which the Authorized User uses the Software and/or Services. Customer Data does not include Resultant Data.
      </p>
      <b>&quot;Customer&apos;s Systems&quot;:</b>
      <p>
        means all information technology infrastructure, including computers, software, databases, electronic systems
        (including database management systems), networks, and accounts, whether operated directly by Customer or
        through the use of third party services.
      </p>
      <b>&quot;Documentation&quot;:</b>
      <p>
        means any manuals, instructions, or other documents or materials that Company provides or makes available to
        Customer in any form or medium and which describe the functionality, components, features, or requirements of
        the Software or Company Materials, including any aspect of the installation, configuration, integration,
        operation, use, support, or maintenance thereof.
      </p>
      <b>&quot;Losses&quot;:</b>
      <p>
        means any and all losses, damages, deficiencies, claims, actions, judgments, settlements, interest, awards,
        penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys&apos; fees and the costs
        of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.
      </p>
      <b>&quot;Order Form&quot;:</b>{' '}
      <p>
        shall mean the document executed by Customer and Company that outlines the specific Software, quantities and
        pricing purchased by Customer under this Agreement.
      </p>
      <b>&quot;Proprietary Information&quot;:</b>{' '}
      <p>
        of a party means proprietary and confidential business, technical or financial information relating to
        Disclosing Party&rsquo;s business, technology, trade secrets, know-how, plans, strategies, customers, and
        pricing, in each case whether or not marked, designated or otherwise identified as confidential. Proprietary
        Information of Customer includes Customer Data. Proprietary Information of Company includes but is not limited
        to non-public information regarding features, functionality and performance of Software and Company Materials.
      </p>
      <b>&quot;Resultant Data&quot;:</b>
      <p>
        means data and information related to Customer&rsquo;s use of the Software that is used by Company in an
        aggregate and anonymized manner, including to compile statistical and performance information related to the
        provision and operation of the Software.
      </p>
      <b>&quot;Services&quot;:</b>
      <p>means the provision of access to the Software.</p>
      <b>&quot;Software&quot;:</b>
      <p>
        means the Bloomfilter software or other software product identified on an Order Form that permits the exchange
        of data between the parties or access thereto.
      </p>
      <b>&quot;Start Date&quot;:</b>
      <p>means the date specified in an Order Form or the date on which Company begins providing the Services.</p>
      <h4>
        <b>
          <u>3. Term</u>
        </b>
      </h4>
      <p>
        This Agreement begins on the Start Date and continues until the expiration of an applicable Order Form or the
        date on which this Agreement is otherwise terminated, as provided herein, whichever is earlier. Customer will no
        longer have access to or the right to use the Services or Software, as applicable, unless the parties enter into
        another Order Form for the continued use of the Services or Software.
      </p>
      <h4>
        <b>
          <u>4. Restrictions and Responsibilities</u>
        </b>
      </h4>
      <p>
        Customer will not, and will not permit any Authorized User or any other person to, directly or indirectly,
        access or use the Software or Company Materials except as expressly permitted by this Agreement and, in the case
        of third-party materials, the applicable third-party license agreement. For purposes of clarity and without
        limiting the generality of the foregoing, Customer shall not, except as this Agreement expressly permits:
      </p>
      <b>4.1</b>
      <p>
        reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or
        underlying structure, ideas, know-how or algorithms relevant to Software;
      </p>
      <b>4.2</b>
      <p>
        modify, copy, translate, or create derivative works or improvements of, or based on, the Software or Company
        Materials;
      </p>
      <b>4.3</b>
      <p>
        rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, use or otherwise make available the
        Software or Customer Materials to any person, including on or in connection with the internet or for timesharing
        or service bureau purposes or otherwise for the benefit of a third party; or remove any proprietary notices or
        labels;
      </p>
      <b>4.4</b>
      <p>
        bypass or breach any security device or protection used by the Software or Company Materials or access or use
        the Software or Company Materials other than by an Authorized User through the use of his or her own valid
        access credentials;
      </p>
      <b>4.5</b>
      <p>
        input, upload, transmit, or otherwise provide to or through the Software or Company&apos;s systems, any
        information or materials that are unlawful or injurious, or contain, transmit, or activate any software,
        hardware, or other technology, device, or means, including any virus, worm, malware, or other malicious computer
        code, the purpose or effect of which is to (i) permit unauthorized access to, or to destroy, disrupt, disable,
        distort, or otherwise harm or impede in any manner any (A) computer, software, firmware, hardware, system, or
        network; or (B) any application or function of any of the foregoing or the security, integrity, confidentiality,
        or use of any data processed thereby; or (ii) prevent Customer or any Authorized User from accessing or using
        the Software or Company Systems as intended by this Agreement;
      </p>
      <b>4.6</b>
      <p>
        damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or harm in any manner the
        Software, Company&apos;s systems, or Company&apos;s provision of services to any third party, in whole or in
        part;
      </p>
      <b>4.7</b>
      <p>
        access or use the Software or Company Materials in any manner or for any purpose that infringes,
        misappropriates, or otherwise violates any intellectual property right or other right of any third party
        (including by any unauthorized access to, misappropriation, use, alteration, destruction, or disclosure of the
        data of any other Company customer), or that violates any applicable law;
      </p>
      <b>4.8</b>
      <p>
        input, upload, transmit, or otherwise provide to or through the Software or Company&apos;s systems any
        personally identifiable information, personal health information, or other data that is subject to regulation
        under applicable privacy laws, other than Authorized User information associated with Access Credentials.
      </p>
      <h4>
        <b>
          <u>5. Compliance with Laws</u>
        </b>
      </h4>
      <p>
        {' '}
        Customer represents, covenants, and warrants that Customer will use the Software and related services only in
        compliance with Company&apos;s standard published policies then in effect and all applicable laws and
        regulations. Although Company has no obligation to monitor Customer&apos;s use of the Software, Company may do
        so and may prohibit any use of the Software it believes may be (or alleged to be) in violation of the foregoing.
      </p>
      <h4>
        <b>
          <u>6. Data Security</u>
        </b>
      </h4>
      <b>6.2 Customer Control and Responsibility.</b>
      <p>
        Customer has and will retain sole responsibility for: (a) all Customer Data, including its content and use; (b)
        all information, instructions and materials provided by or on behalf of Customer or any Authorized User in
        connection with the Services; (c) Customer&apos;s Systems; (d) the security and use of Customer&apos;s and its
        Authorized Users&apos; Access Credentials; and (e) all access to and use of the Services and Company Materials
        directly or indirectly by or through the Customer Systems or its or its Authorized Users&apos; Access
        Credentials, with or without Customer&apos;s knowledge or consent, including all results obtained from, and all
        conclusions, decisions and actions based on, such access or use. Customer will also be responsible for
        maintaining the security of Equipment, Customer account, passwords (including but not limited to administrative
        and user passwords) and files, and for all uses of Customer account or Equipment with or without Customer&apos;s
        knowledge or consent. Customer shall employ all physical, administrative, and technical controls, screening, and
        security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all
        access credentials to the Software and protect against any unauthorized access to or use of the Software; and
        (b) control the content and use of Customer Data, including the uploading or other provision of Customer Data
        for processing by the Software or related services.
      </p>
      <b>6.1 Company&apos;s Security Obligations.</b>
      <p>
        Company will use administrative, physical, organizational, and technical safeguards reasonably designed to
        secure Proprietary Information of Customer and shall, at a minimum, maintain and comply with commercially
        reasonable information security requirements consistent with industry standards.
      </p>
      <h4>
        <b>
          <u>7. Confidentiality; Proprietary rights</u>
        </b>
      </h4>
      <b>7.1 Proprietary Information.</b>
      <p>
        Each party (&quot;Receiving Party&quot;) understands that the other party (&quot;Disclosing Party&quot;) has
        disclosed or may disclose, directly or indirectly in connection with this Agreement, Proprietary Information of
        such party. Receiving Party agrees to protect such Proprietary Information from unauthorized use, access, or
        disclosure using at least the degree of care it uses to protect its confidential information and in no event
        less than a reasonable degree of care, and not to use (except for as necessary to exercise its rights or perform
        its obligations under and in accordance with this Agreement or as otherwise permitted herein) or divulge to any
        third person any such Proprietary Information. Notwithstanding anything to the contrary in the foregoing,
        Receiving Party may disclose Proprietary Information of Disclosing Party to Receiving Party&apos;s affiliates,
        employees, officers, directors, agents, and subcontractors who: (a) need to know such Proprietary Information
        for purposes of Receiving Party&apos;s exercise of its rights or performance of its obligations under and in
        accordance with this Agreement; (b) have been informed of the confidential nature of the Proprietary Information
        and Receiving Party&apos;s obligations under this Section 7; and (c) are bound by confidentiality and restricted
        use obligations at least as protective of the Proprietary Information as the terms set forth in this Section 7.
      </p>
      <b>7.2 Exclusions.</b>
      <p>
        Disclosing Party agrees that the foregoing will not apply with respect to any information that Receiving Party
        can document: (a) is or becomes generally available to the public other than by Receiving Party&apos;s or any of
        its representatives&apos; noncompliance with this Agreement; (b) was rightfully in its possession or known by it
        prior to receipt from Disclosing Party without any obligation to maintain its confidentiality or restriction on
        use or disclosure; (c) was rightfully disclosed to it without restriction by a third party that was not or is
        not, at the time of such receipt, under any obligation to maintain its confidentiality; (d) was independently
        developed without use of any Proprietary Information of Disclosing Party; or (e) is required to be disclosed by
        law or governmental authority. If Receiving Party or any of its representatives is compelled by applicable law
        to disclose any Proprietary Information then, to the extent permitted by applicable law, Receiving Party shall:
        (i) promptly, and prior to such disclosure, notify Disclosing Party in writing of such requirement so that
        Disclosing Party can seek a protective order or other remedy or waive its rights under Section 7; and (ii)
        provide reasonable assistance to Disclosing Party in opposing such disclosure or seeking a protective order or
        other limitations on disclosure. If Disclosing Party waives compliance or, after providing the notice and
        assistance required under this Section 7.2, Receiving Party remains required by law to disclose any Proprietary
        Information, Receiving Party shall disclose only that portion of the Proprietary Information that Receiving
        Party is legally required to disclose.
      </p>
      <h4>
        <b>
          <u>8. Intellectual Property</u>
        </b>
      </h4>
      <b>8.1 Customer Data.</b>
      <p>
        Customer will own all right, title and interest in and to Customer Data, as well as any data that is based on or
        derived from Customer Data and provided to Customer as part of the Software and Services provided hereunder.
        However, Customer hereby grants to Company a non-exclusive, non-transferable, worldwide, royalty-free, fully
        paid-up, perpetual, and irrevocable right and license to use such data (a) as is necessary or useful to Company,
        its subcontractors and personnel to enforce this Agreement and exercise Company&apos;s rights and perform its
        obligations hereunder, and (b) in aggregated or de-identified form for the purposes of providing and improving
        Software and/or Services. Customer acknowledges that the Software and/or Services collects and uses Customer
        Data. Customer agrees and consents to Company&apos;s collection and use of the Customer Data to analyze
        Customer&apos;s use of the Services and/or Software, for machine learning purposes and to improve the Services
        and/or Software.
      </p>
      <b>8.2 Company Materials.</b>
      <p>
        All right, title, and interest in and to the Company Materials, including all intellectual property rights
        therein, are and will remain with Company and, with respect to any third-party materials, the applicable
        third-party providers own all right, title, and interest, including all intellectual property rights, in and to
        the third-party materials. Customer has no right, license, or authorization with respect to any of the Company
        Materials except as expressly set forth in Section 1.2 or the applicable third-party license, in each case
        subject to Section 4. All other rights in and to the Company Materials are expressly reserved by Company. In
        furtherance of the foregoing, Customer hereby unconditionally and irrevocably grants to Company an assignment of
        all right, title, and interest in and to the Resultant Data, including all intellectual property rights relating
        thereto.
      </p>
      <h4>
        <b>
          <u>9. Termination</u>
        </b>
      </h4>
      <b>9.1 Termination for Cause.</b>
      <p>
        In addition to any other express termination right set forth elsewhere in this Agreement either party may
        terminate this Agreement, effective immediately upon on written notice to the other party, if the other party:
        (a) materially breaches this Agreement, and such breach: (i) is incapable of cure; or (ii) being capable of
        cure, remains uncured thirty (30) days after the non-breaching party provides the breaching party with written
        notice of such breach; or (b) (i) becomes insolvent or is generally unable to pay, or fails to pay, its debts as
        they become due; (ii) files, or has filed against it, a petition for voluntary or involuntary bankruptcy or
        otherwise becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign
        bankruptcy or insolvency law; (iii) makes or seeks to make a general assignment for the benefit of its
        creditors; or (iv) applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by
        order of any court of competent jurisdiction to take charge of or sell any material portion of its property or
        business.
      </p>
      <b>9.2 Effect of Termination or Expiration</b>
      <p>
        Upon any expiration or termination of this Agreement, except as expressly otherwise provided in this Agreement:
        (a) all rights, licenses, consents, and authorizations granted by either party to the other hereunder will
        immediately terminate; (b) Company shall immediately cease all use of any Customer Data or Customer&apos;s
        Proprietary Information and (i) return to Customer, or at Customer&apos;s written request destroy, all documents
        and tangible materials containing, reflecting, incorporating, or based on Customer Data or Customer&apos;s
        Proprietary Information; (ii) permanently erase all Customer Data and Customer&apos;s Proprietary Information
        from all systems Company directly or indirectly controls, provided that, for clarity, Company&apos;s obligations
        under this Section 9.2(b) do not apply to any Resultant Data; (c) Customer shall immediately cease all use of
        any Services or Company Materials and (i) promptly return to Company, or at Company&apos;s written request
        destroy, all documents and tangible materials containing, reflecting, incorporating, or based on any Company
        Materials or Company&apos;s Proprietary Information; (ii) permanently erase all Company Materials and
        Company&apos;s Proprietary Information from all systems Customer directly or indirectly controls; (iii) certify
        to Company in a signed and notarized written instrument that it has complied with the requirements of this
        Section 9.2(c); (d) notwithstanding anything to the contrary in this Agreement, with respect to information and
        materials then in its possession or control: (i) Receiving Party may retain Disclosing Party&apos;s Proprietary
        Information in its then current state and solely to the extent and for so long as required by applicable law;
        (ii) Company may also retain Customer Data in its backups, archives, and disaster recovery systems until such
        Customer Data is deleted in the ordinary course; (iii) all information and materials described in this Section
        9.2(d) will remain subject to all confidentiality, security, and other applicable requirements of this
        Agreement; (e) Company may disable all Customer and Authorized User access to the Company Materials.
      </p>
      <h4>
        <b>
          <u>10. Representations and Warranties</u>
        </b>
      </h4>
      <b>10.1 Mutual Representations and Warranties</b>
      <p>
        Each party represents and warrants to the other party that: (a) it is duly organized, validly existing, and in
        good standing as a corporation or other entity under the laws of the jurisdiction of its incorporation or other
        organization; (b) it has the full right, power, and authority to enter into and perform its obligations and
        grant the rights, licenses, consents, and authorizations it grants or is required to grant under this Agreement;
        (c) the execution of this Agreement by its representative whose signature is set forth at the end of this
        Agreement has been duly authorized by all necessary corporate or organizational action of such party; (d) when
        executed and delivered by both parties, this Agreement will constitute the legal, valid, and binding obligation
        of such party, enforceable against such party in accordance with its terms.
      </p>
      <b>10.2 Additional Company Representations, Warranties, and Covenants</b>
      <p>
        Company represents and warrants that it will use reasonable efforts consistent with prevailing industry
        standards to maintain Software in a manner which minimizes errors and interruptions in functioning of Software;
        provided that Software may be temporarily unavailable for scheduled maintenance or for unscheduled emergency
        maintenance, either by Company or by third-party providers, or because of other causes beyond Company&apos;s
        reasonable control, but Company will use reasonable efforts to provide advance notice in writing or by e-mail of
        any scheduled service disruption.
      </p>
      <b>10.3 Additional Customer Representations, Warranties, and Covenants</b>
      <p>
        Customer represents, warrants, and covenants to Company that Customer owns or otherwise has and will have the
        necessary rights and consents in and relating to the Customer Data so that, as received by Company and processed
        in accordance with this Agreement, they do not and will not infringe, misappropriate, or otherwise violate any
        intellectual property rights, or any privacy or other rights of any third party or violate any applicable law.
      </p>
      <b>10.4 Warranty Disclaimer</b>
      <p>
        Except for the express warranties set forth in Section 10.1 and Section 10.2, all Software, Services, and
        Company Materials are provided &apos;as is.&apos; Company specifically disclaims all implied warranties of
        merchantability, fitness for a particular purpose, title, and non-infringement, and all warranties arising from
        course of dealing, usage, or trade practice. Without limiting the foregoing, Company makes no warranty of any
        kind that the Software, Services, or Company Materials, or any products or results of the use thereof, will meet
        Customer&apos;s or any other person&apos;s requirements, operate without interruption, achieve any intended
        result, be compatible or work with any software, system, or other services, or be secure, accurate, complete,
        free of harmful code, or error free. All third-party materials are provided &apos;as is&apos; and any
        representation or warranty of or concerning any third-party materials is strictly between Customer and the
        third-party owner or distributor of the third-party materials.
      </p>
      <h4>
        <b>
          <u>12. Limitation of Liability; Indemnification</u>
        </b>
      </h4>
      <b>12.1 Limitation of Liability</b>
      <p>
        Notwithstanding anything to contrary, except for bodily injury of a person, Company and its suppliers (including
        but not limited to all equipment and technology suppliers), officers, affiliates, representatives, contractors
        and employees will not be responsible or liable with respect to any subject matter of this Agreement or terms
        and conditions related thereto under any contract, negligence, strict liability or other theory: (a) for error
        or interruption of use or for loss or inaccuracy or corruption of data or cost of procurement of substitute
        goods, services or technology or loss of business; (b) any indirect, exemplary, incidental, special, enhanced,
        punitive, or consequential damages; (c) for any matter beyond Company&apos;s reasonable control; or (d) for any
        amounts that, together with amounts associated with all other claims, exceed the fees paid by Customer to
        Company for the Software under this Agreement in the 12 months prior to the act that gave rise to the liability;
        in each case, whether Company has been advised of the possibility of such damages and notwithstanding the
        failure of any agreed or other remedy of its essential purpose.
      </p>
      <b>12.2 Company Indemnification:</b>
      <p>
        Company shall indemnify and defend Customer from and against any and all Losses incurred by Customer resulting
        from any Action by a third party (other than an affiliate of Customer) that Customer&apos;s use of the Software
        or Services (excluding Customer Data and third-party materials) in accordance with this Agreement (including the
        Documentation) infringes or misappropriates such third party&apos;s intellectual property rights. The foregoing
        obligation does not apply to the extent that the alleged infringement arises from: (a) third-party materials or
        Customer Data; (b) access to or use of the Company Materials in combination with any hardware, system, software,
        network, or other materials or service not provided by Company or specified for Customer&apos;s use in the
        Documentation, unless otherwise expressly permitted by Company in writing; (c) modification of the Company
        Materials other than: (i) by or on behalf of Company; or (ii) with Company&apos;s written approval in accordance
        with Company&apos;s written specification; (d) failure to timely implement any modifications, upgrades,
        replacements, or enhancements made available to Customer by or on behalf of Company; or (e) act, omission, or
        other matter described in Section 12.3(a), Section 12.3(b), Section 12.3(c), or Section 12.3(d), whether or not
        the same results in any Action against or Losses by any Company Indemnitee (as defined below).
      </p>
      <b>12.3 Customer Indemnification:</b>
      <p>
        Customer shall indemnify, defend, and hold harmless Company and its subcontractors and affiliates and each of
        their respective officers, directors, employees, agents, successors, and assigns (each, a &quot;Company
        Indemnitee&quot;) from and against any and all Losses incurred by such Company Indemnitee resulting from any
        Action by a third party (other than an affiliate of a Company Indemnitee) that arise out of or result from, or
        are alleged to arise out of or result from: (a) Customer Data, including any processing of Customer Data by or
        on behalf of Company in accordance with this Agreement; (b) any other materials or information (including any
        documents, data, specifications, software, content, or technology) provided by or on behalf of Customer or any
        Authorized User, including Company&apos;s compliance with any specifications or directions provided by or on
        behalf of Customer or any Authorized User to the extent prepared without any contribution by Company; (c)
        allegation of facts that, if true, would constitute Customer&apos;s breach of any of its representations,
        warranties, covenants, or obligations under this Agreement; or (d) negligence or more culpable act or omission
        (including recklessness or willful misconduct) by Customer, any Authorized User, or any third party on behalf of
        Customer or any Authorized User, in connection with this Agreement.
      </p>
      <b>12.4 Sole Remedy:</b>{' '}
      <p>
        THIS SECTION 12 SETS FORTH CUSTOMER&apos;S SOLE REMEDIES AND COMPANY&apos;S SOLE LIABILITY AND OBLIGATION FOR
        ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SOFTWARE AND COMPANY MATERIALS OR ANY SUBJECT MATTER OF THIS
        AGREEMENT INFRINGES, MISAPPROPRIATES, OR OTHERWISE VIOLATES ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.
      </p>
      <h4>
        <b>
          <u>13. Independent contractors.</u>
        </b>
      </h4>
      <p>
        Customer and Company are independent contractors in all matters relating to this Agreement, and this Agreement
        will not be construed to create a partnership, joint venture, agency, employment, or any other relationship
        between Customer and Company.
      </p>
      <h4>
        <b>
          <u>14. Governing law.</u>
        </b>
      </h4>
      <p>
        This Agreement is governed by the laws of the State of Ohio, without giving effect to principles of conflicts of
        law. Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted
        hereunder will be instituted exclusively in the federal courts of the United States or the courts of the State
        of Ohio, City of Cleveland, and each party irrevocably submits to the exclusive jurisdiction of such courts in
        any such suit, action, or proceeding. Service of process, summons, notice, or other document by mail to such
        party&apos;s address set forth herein shall be effective service of process for any suit, action, or other
        proceeding brought in any such court.
      </p>
      <h4>
        <b>
          <u>15. Final provisions.</u>
        </b>
      </h4>
      <b>15.1 Entire Agreement:</b>
      <p>
        This Agreement constitutes the entire agreement between the parties with respect to its subject matter and
        supersedes all prior agreements between the parties regarding the same subject matter.
      </p>
      <b>15.2 Waiver; Modifications:</b>
      <p>
        This Agreement can be modified only by a written amendment signed by the parties. Failure to enforce any
        provisions of this Agreement will not constitute a waiver.
      </p>
      <b>15.3 Severability:</b>
      <p>If any provision is unenforceable, the other provisions will remain effective.</p>
      <b>15.4 Assignment:</b>
      <p>Neither party may assign or transfer this agreement without the prior written consent of the other party.</p>
      <b>15.5 Notices:</b>
      <p>
        Any notice under this Agreement must be in writing and delivered personally or by overnight courier or sent by
        email to the addresses set forth in the Order Form. Notices sent in accordance with this Section 15.5 will be
        deemed effectively given: (a) when received, if delivered by hand, with signed confirmation of receipt; (b) when
        received, if sent by a nationally recognized overnight courier, signature required; or (c) when sent, if by
        email, with confirmation of transmission, if sent during the addressee&apos;s normal business hours, and on the
        next business day, if sent after the addressee&apos;s normal business hours.
      </p>
      <b>15.6 Headings:</b>
      <p>
        The section headings of this Agreement are for convenience only and have no value for interpretation of this
        Agreement.
      </p>
      <b>15.7 No Third-Party Beneficiaries:</b>
      <p>
        This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted
        assigns and nothing herein, express or implied, is intended to or shall confer upon any other Person any legal
        or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this Agreement.
      </p>
      <b>15.8 No Jury Trial:</b>
      <p>
        Each party irrevocably and unconditionally waives any right it may have to a trial by jury in respect of any
        legal action arising out of or relating to this Agreement or the transactions contemplated hereby.
      </p>
      <h4>
        <b>
          <u>16. Survival.</u>
        </b>
      </h4>
      <p>
        The following provisions and any right or obligation of the parties in this Agreement that, by its nature,
        should survive termination or expiration of this Agreement, will survive termination or expiration of this
        Agreement: 7 (Confidentiality; Proprietary rights), 8 (Intellectual Property), 12 (Limitations of liabilities;
        Indemnification), 14 (Governing law) and 15 (Final provisions).
      </p>
      <h4>
        <b>
          <u>17. Force Majeure.</u>
        </b>
      </h4>
      <p>
        In no event will Company be liable or responsible to Customer, or be deemed to have defaulted under or breached
        this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement when and to the
        extent such failure or delay is caused by any circumstances beyond Company&apos;s reasonable control (a
        &ldquo;Force Majeure Event&rdquo;), including (i) acts of God; (ii) flood, fire, earthquake, epidemic, pandemic,
        or explosion; (iii) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot
        or other civil unrest; (iv) government order, law, or actions; (v) embargoes or blockades in effect on or after
        the date of this Agreement; (vi) national or regional emergency; (vii) strikes, labor stoppages or slowdowns, or
        other industrial disturbances; and (viii) shortage of adequate power or transportation facilities. Either party
        may terminate this Agreement if a Force Majeure Event continues substantially uninterrupted for a period of
        sixty (60) days or more.
      </p>
      <h4>
        <b>
          <u>18. Equitable Relief.</u>
        </b>
      </h4>
      <p>
        Each party acknowledges and agrees that a breach or threatened breach by such party of any of its obligations
        under Section 6 or Section 7 or, in the case of Customer, Section 4, would cause the other party irreparable
        harm for which monetary damages would not be an adequate remedy and that, in the event of such breach or
        threatened breach, the other party will be entitled to equitable relief, including a restraining order, an
        injunction, specific performance, and any other relief that may be available from any court, without any
        requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an
        adequate remedy. Such remedies are not exclusive and are in addition to all other remedies that may be available
        at law, in equity, or otherwise.
      </p>
    </div>
  );
}
