import { Tooltip as MantineTooltip, TooltipProps } from '@mantine/core';

type Props = {
  variant: 'white' | 'black';
};

export function Tooltip(props: Props & TooltipProps) {
  return (
    <MantineTooltip
      {...props}
      styles={{
        tooltip: {
          background: props.variant === 'white' ? 'var(--base-white)' : 'var(--background-light)',
          color: props.variant === 'white' ? 'var(--base-black)' : 'var(--base-white)',
          borderRadius: 'var(--border-radius-small)',
          boxShadow: 'var(--box-shadow-big-soft)',
          padding: '8px 20px',
          fontFamily: 'var(--font-family)',
          fontSize: 'var(--font-size-regular)',
          lineHeight: 'var(--line-height-regular)',
          fontWeight: 'var(--font-weight-medium)',
        },
      }}
    />
  );
}
