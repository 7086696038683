import { QueryObserverResult } from '@tanstack/react-query';
import { IntegrationEntry } from '../../../api/integrations-client/integrations-client.type';
import { Integration } from '../integrations-list/integrations-list.type';

enum IntegrationState {
  BLANK = 'blank',
  ERROR = 'error',
  OPERATIONAL = 'operational',
  STALE = 'stale',
}

type IntegrationsContextType = {
  state: IntegrationState | null;
  projectsState: Record<string, IntegrationState>;
  query: QueryObserverResult<IntegrationEntry[]>;
  activeIntegration: Integration;
  setActiveIntegration: (integration: Integration) => void;
  initJDC: boolean;
  setInitJDC: (value: boolean) => void;
};

export { IntegrationState };
export type { IntegrationsContextType };
