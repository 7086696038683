import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { newCOLORS } from '../../styles/colors';
import { SectionContainer, Text } from './process.styled';

type Props = {
  completedTasks: number;
};

export function SectionSummary({ completedTasks }: Props) {
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <SectionContainer style={{ padding: '24px 16px' }}>
      <HeadingContainer>
        <CollapseIcon>
          <Icon
            icon={opened ? 'icon-park-solid:right-one' : 'icon-park-solid:down-one'}
            width={16}
            height={16}
            color="#5C3ECF"
            onClick={toggle}
          />
        </CollapseIcon>
        <Amount>{completedTasks}</Amount>
        <HeadingTitle>
          {/** PYG 4/18/24: The below should really say "Completed Tasks" but we currently have a backend bug (BLOOM-2533) that is skewing the count here, so we're generalizing until that is fixed */}
          <Text>Considered tasks</Text>
          <Text fontSize={12}>for the scope selected</Text>
        </HeadingTitle>
      </HeadingContainer>
      <Collapse in={!opened}>
        <ContentContainer>
          <Column>
            <ColumnTitle>What does this map do?</ColumnTitle>
            <ColumnText>
              The map details the number of tasks (stories, tasks, bugs, subtasks) that were completed by the selected
              Portfolio/Team for the selected time period.
            </ColumnText>
            <ColumnText>
              Use this map to better understand how completed tasks may or may not have taken the expected path, and if
              any process patterns are emerging that could indicate an inefficiency in the readiness of the work within
              the task.
            </ColumnText>
          </Column>
          <Column>
            <ColumnTitle>How is the map drawn?</ColumnTitle>
            <ColumnText>
              Each major box matches the project status that was mapped to Bloomfilter during onboarding.
            </ColumnText>
            <ColumnText>
              Lines connect each step vertically to complete the expected path. The thickness of each line visualizes
              the volume of completed tasks that travelled that part of the process flow for the time period selected.
            </ColumnText>
            <ColumnText>
              Task transitions can cause discrepancy from their source system when one or more customer statuses are
              used on a task in a desired time period.
            </ColumnText>
            <ColumnText>
              In future iterations, Bloomfilter will make the Process Map available to the board/filter level, but at
              this time it is only available to analyze the Portfolio and Team (project) levels.
            </ColumnText>
          </Column>
        </ContentContainer>
      </Collapse>
    </SectionContainer>
  );
}

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 16px;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const Amount = styled(Text)`
  font-size: 50px;
  font-weight: 700;
`;

const HeadingTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 56px;
  padding: 16px 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ColumnText = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 8px;
`;

const ColumnTitle = styled(ColumnText)`
  font-weight: bold;
`;
