import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory';
import { HistoricalSprintData, HistoricalSprintDataSprintTime } from '../../api/sprints-client/sprints-client.type';
import { shortDateDisplayFromDateString } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';

const chartDataSet = (data_points: HistoricalSprintDataSprintTime[]) => {
  return data_points.map(({ end_date, amount }) => {
    return {
      x: shortDateDisplayFromDateString(end_date),
      y: Math.round(amount),
    };
  });
};

type ProjectStatisticsBarChartProps = {
  historicalSprintData: HistoricalSprintData;
  color: string;
};

export const ProjectStatisticsBarChart = ({ historicalSprintData, color }: ProjectStatisticsBarChartProps) => {
  const { data_points = [] } = historicalSprintData;
  const cartesianData = chartDataSet(data_points);
  const mostRecentDataPoint = cartesianData[cartesianData.length - 1];

  return (
    <VictoryChart>
      <VictoryAxis
        style={{ axis: { stroke: 'transparent' } }}
        tickLabelComponent={<VictoryLabel style={{ fontSize: 14 }} />}
      />
      <VictoryBar
        style={{
          data: {
            fill: ({ datum }) => (datum.x === mostRecentDataPoint.x ? color : newCOLORS.gray),
            strokeWidth: 3,
            width: 90,
          },
        }}
        data={cartesianData}
        labels={({ datum }) => `${datum.y}`}
        labelComponent={<VictoryLabel style={{ fontSize: 14 }} />}
      />
    </VictoryChart>
  );
};
