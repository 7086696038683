import { isString } from '../../helpers/string-helpers/string-helpers';
import { parseRedirect } from './integrations.helpers';

/**
 * Custom hook to manage the redirect value in local storage.
 *
 * @return {{ redirect: string | null; setRedirect: (path: string | null) => void }} An object with a redirect value and a function to set the redirect value.
 */
const useRedirect = (): { redirect: string | null; setRedirect: (path: string | null) => void } => {
  const redirect = parseRedirect();
  const isValidRedirect = isString(redirect) && redirect.startsWith('/');

  const setRedirect = (path: string | null) => {
    if (!path) {
      return localStorage.removeItem('integrations-redirect');
    }

    return localStorage.setItem('integrations-redirect', path);
  };

  return {
    redirect: isValidRedirect ? redirect : null,
    setRedirect,
  };
};

export { useRedirect };
