import { mantineColors } from '../../styles/mantineColors';

function hexToRgb(hex: string): [number, number, number] {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
}

/** find closest mantine color to input hex color code
 *
 * @param inputHex: string - hex color code
 * @returns: string - closest mantine color code
 */
export function findClosestMantineColor(inputHex: string): string {
  function colorDistance(rgb1: [number, number, number], rgb2: [number, number, number]): number {
    const [r1, g1, b1] = rgb1;
    const [r2, g2, b2] = rgb2;
    return Math.sqrt(Math.pow(r2 - r1, 2) + Math.pow(g2 - g1, 2) + Math.pow(b2 - b1, 2));
  }

  const inputRgb = hexToRgb(inputHex) as [number, number, number];
  let closestColor = Object.keys(mantineColors)[0];
  let minDistance = Number.MAX_VALUE;

  for (const [name, hex] of Object.entries(mantineColors)) {
    const rgb = hexToRgb(hex) as [number, number, number];
    const distance = colorDistance(inputRgb, rgb);
    if (distance < minDistance) {
      minDistance = distance;
      closestColor = name;
    }
  }
  return closestColor;
}
