import { JiraReducerAction, JiraState } from './jira.type';

/** The reducer for the Jira integration.
 *
 * This reducer is responsible for updating the state of the Jira integration.
 * This reducer is also responsible for showing/hiding errors based on data validation.
 *
 * @param state: JiraState- the current state of the Jira integration
 * @param action: JiraReducerAction- the action to be performed on the state
 * @returns: JiraState- the updated state of the Jira integration
 */
export function jiraReducer(state: JiraState, action: JiraReducerAction) {
  switch (action.type) {
    case 'init':
      return { ...state, initialized: true };
    case 'project':
      // Reset boards and bloomfilterStatuses when project is changed
      // since projects -> boards -> statuses are linear dependencies
      return { ...state, project: action.payload, boards: [], bloomfilterStatuses: {} };
    case 'board':
      return { ...state, board: action.payload, bloomfilterStatuses: {} };
    case 'bloomfilterStatuses':
      return {
        ...state,
        bloomfilterStatuses: { ...state.bloomfilterStatuses, ...action.payload },
      };
    case 'settings':
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case 'errors':
      return { ...state, errors: { ...state.errors, ...action.payload } };
    case 'options':
      return { ...state, options: { ...state.options, ...action.payload } };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}
