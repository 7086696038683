import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Modal } from '@mantine/core';
import { APIGlobalMessage } from '../../api/global-messages-client/global-messages-client.type';
import { verboseDateRangeDisplay } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';

type Props = {
  opened: boolean;
  close: () => void;
  globalMessage: APIGlobalMessage;
};

export const GlobalMessageModal = ({ opened, close, globalMessage }: Props) => {
  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered={true}
      padding={0}
      withOverlay={false}
      size={845}
    >
      <ModalContainer>
        <ModalHeader>{globalMessage.message}</ModalHeader>

        <TextBlock>
          <TextQuestion>When?</TextQuestion>
          <TextAnswer>
            {verboseDateRangeDisplay({
              startDateString: `${globalMessage.start_datetime}Z`,
              endDateString: `${globalMessage.end_datetime}Z`,
            })}
          </TextAnswer>
        </TextBlock>

        <TextBlock>
          <TextQuestion>Why?</TextQuestion>
          <TextAnswer>{globalMessage.details}</TextAnswer>
        </TextBlock>

        <CloseTag>
          <span
            onClick={close}
            style={{
              color: newCOLORS.pink,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Close this window
          </span>
        </CloseTag>

        <ModalClose>
          <CloseButton onClick={close}>
            <Icon width="32" height="32" icon={'jam:close-rectangle'} />
          </CloseButton>
        </ModalClose>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  position: relative;
  border: 50px solid ${newCOLORS.lightGray};
  padding: 0px 60px;
`;

const ModalHeader = styled.div`
  margin: 30px 0px;
  font-size: 1.8rem;
  font-weight: bold;
`;

const TextBlock = styled.div`
  margin: 20px 0px;
`;

const TextQuestion = styled.div`
  font-weight: bold;
`;

const TextAnswer = styled.div`
  font-weight: normal;
`;

const CloseTag = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalClose = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;
