import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../views/views.module.css';
import { BitbucketRepo, BitbucketState } from './bitbucket.type';

type ReposProps = {
  bitbucketState: BitbucketState;
  updateState: (update: Partial<BitbucketState>) => void;
};

export const Repos = ({ bitbucketState, updateState }: ReposProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);

  const handleRepoSelected = (repo: BitbucketRepo) => {
    if ((bitbucketState.selectedRepos || []).filter((t: BitbucketRepo) => t.id === repo.id).length > 0) {
      updateState({
        selectedRepos: (bitbucketState.selectedRepos || []).filter((t: BitbucketRepo) => t.id !== repo.id),
      });
    } else {
      updateState({ selectedRepos: [...(bitbucketState.selectedRepos || []), repo] });
    }
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select at least one repo you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(bitbucketState.repoOptions || []).map((repo: BitbucketRepo) => (
          <div
            key={repo.name}
            className={
              (bitbucketState.selectedRepos || []).filter((t: BitbucketRepo) => t.id === repo.id).length > 0
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleRepoSelected(repo)}
          >
            <span className={styles.checkboxLabel}>{repo.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
