import { Button } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setPassword as setNewPassword } from '../../../api/auth-client/auth-client';
import { Brand } from '../../../components/brand/brand';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../../styles/colors';
import { Password } from '../sign-in';
import styles from '../sign-in.module.css';
import { ViewName } from '../sign-in.type';

export function ResetPasswordView({ handleChangeView }: { handleChangeView: (view: ViewName) => void }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { email, token } = useParams();

  const canResetPassword = email && token && password && confirmPassword && password === confirmPassword;

  const handleResetPassword = useMutation(
    ['resetPassword', { email, token, password }],
    () => {
      return canResetPassword ? setNewPassword(email, token, password) : Promise.reject('Unable to reset password');
    },
    {
      onSuccess: () => handleChangeView(ViewName.ResetPasswordSuccessful),
      onError: () => handleChangeView(ViewName.ResetPassword),
    }
  );

  useEffect(
    () => setError(!!confirmPassword && confirmPassword !== password ? 'Passwords do not match' : ''),
    [password, confirmPassword]
  );

  return (
    <Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Brand style={{ minWidth: '60%' }} darkMode={true} clickable={false} />
        <h3 style={{ textAlign: 'center', color: newCOLORS.white }}>Reset Password</h3>
      </div>
      <div id={styles.formContainer}>
        <Password formLabel="New Password" password={password} setPassword={setPassword} error={!!error} />
        <Password
          formLabel="Confirm Password"
          password={confirmPassword}
          setPassword={setConfirmPassword}
          error={error}
        />
        <Button
          className={styles.button}
          onClick={() => {
            trackEvent(AnalyticsEventType.ResetPasswordTapped, { userContext: { email } });
            handleResetPassword.mutate();
          }}
        >
          Reset
        </Button>
      </div>
    </Fragment>
  );
}
