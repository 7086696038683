import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { getStoredAuthenticationObject, isTokenExpired } from '../../helpers/storage/storage';

type ProtectedRouteProps = {
  children?: ReactNode;
};

export function ProtectedRoute({ children }: ProtectedRouteProps): any {
  const { access } = getStoredAuthenticationObject();

  if (!access || isTokenExpired(access)) {
    return <Navigate to="/" />;
  }
  return children;
}
