import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../components/button/button';
import styles from '../views/views.module.css';
import { GithubState } from './github.type';

/** The view for summary of all the steps during the github itegration workflow.
 *
 * @param props: { state: GithubState, dispatch: Dispatch<GithubReducerAction> }
 * @returns JSX.Element
 */
export function Summary({
  state,
  setActiveStep,
}: {
  state: GithubState;
  setActiveStep: Dispatch<SetStateAction<number>>;
}) {
  const { projects, options } = state;
  const selectedProject = projects?.find((project) => project.id === options.selectedProject);

  return (
    <div id={styles.viewContainer}>
      <h3>Review your Selections: </h3>
      <div id={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Organization</h4>
            <Button variant="subtle" onClick={() => setActiveStep(0)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <span className={styles.summaryValue}>{state.organization || ''}</span>
        </div>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Repository: </h4>
            <Button variant="subtle" onClick={() => setActiveStep(1)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <div className={styles.flexRowSpaceBetween}>
            <span className={styles.summaryValue}>{state.repository?.name || ''}</span>
          </div>
        </div>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Project/Repository Mapping: </h4>
            <Button variant="subtle" onClick={() => setActiveStep(2)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <div className={styles.flexRowSpaceBetween}>
            <span className={styles.summaryValue}>{selectedProject?.name || ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
