import iconCollapseAll from './iconCollapseAll.svg';
import iconExpandAll from './iconExpandAll.svg';
import iconGroupingClose from './iconGroupingClose.svg';
import iconGroupingCollapseDown from './iconGroupingCollapseDown.svg';
import iconGroupingCollapseUp from './iconGroupingCollapseUp.svg';
import iconGroupingDefault from './iconGroupingDefault.svg';
import iconGroupingEpic from './iconGroupingEpic.svg';
import iconGroupingInitiative from './iconGroupingInitiative.svg';
import iconGroupingPoints from './iconGroupingPoints.svg';
import iconGroupingSelectArrowDown from './iconGroupingSelectArrowDown.svg';
import iconGroupingSelectArrowUp from './iconGroupingSelectArrowUp.svg';
import iconGroupingType from './iconGroupingType.svg';
import iconGroupingUnmapped from './iconGroupingUnmapped.svg';
import iconInformationCircle from './iconInformationCircle.svg';
import iconScrollToTop from './iconScrollToTop.svg';
import iconShare from './iconShare.svg';
import iconSortingArrowDown from './iconSortingArrowDown.svg';
import iconSortingArrowDownActive from './iconSortingArrowDownActive.svg';
import iconSortingArrowUpActive from './iconSortingArrowUpActive.svg';

export const icons = {
  iconSortingArrowDown,
  iconSortingArrowDownActive,
  iconSortingArrowUpActive,
  iconShare,
  iconGroupingClose,
  iconGroupingDefault,
  iconGroupingEpic,
  iconGroupingInitiative,
  iconGroupingPoints,
  iconGroupingType,
  iconGroupingUnmapped,
  iconGroupingSelectArrowDown,
  iconGroupingSelectArrowUp,
  iconGroupingCollapseDown,
  iconGroupingCollapseUp,
  iconInformationCircle,
  iconScrollToTop,
  iconCollapseAll,
  iconExpandAll,
};
