import { Epic } from '../../../../api/tasks-client/task-client.type';
import { InitiativeMetricType } from '../initiative-performance.type';

/**
 * Returns the chart title based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the chart title for.
 * @return {string} The chart title corresponding to the provided metric type.
 */
const getChartTitle = (metric: InitiativeMetricType): string => {
  const titles = {
    [InitiativeMetricType.Tasks]: 'Completed tasks',
    [InitiativeMetricType.Points]: 'Completed points',
    [InitiativeMetricType.Tasks_Added]: 'Tasks added',
    [InitiativeMetricType.Bugs]: 'Bugs',
  };

  return `${titles[metric]} from contributing epics (monthly)`;
};

/**
 * Returns the label for the y-axis based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the y-axis label for.
 * @return {string} The y-axis label corresponding to the provided metric type.
 */
const getYAxisLabel = (metric: InitiativeMetricType): string => {
  const labels = {
    [InitiativeMetricType.Tasks]: 'Tasks',
    [InitiativeMetricType.Points]: 'Points',
    [InitiativeMetricType.Tasks_Added]: 'Tasks added',
    [InitiativeMetricType.Bugs]: 'Bugs',
  };

  return `${labels[metric]} per month`;
};

/**
 * Calculates the progress of an epic based on the number of completed subtasks and the total number of subtasks.
 *
 * @param {Epic} epic - The epic object containing the subtasks information.
 * @return {number} The progress of the epic as a percentage.
 */
const getEpicProgress = (epic: Epic): number => {
  const subtasksCompleted = epic?.subtasks_completed_count || 0;
  const subtasksTotal = epic?.subtasks_count || 0;

  if (subtasksTotal > 0) {
    return Math.floor((subtasksCompleted / subtasksTotal) * 100);
  }

  return 0;
};

export { getChartTitle, getEpicProgress, getYAxisLabel };
