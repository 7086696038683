import {
  ActiveElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useRef } from 'react';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Metric } from '../../../../api/initiative-client/initiative-client.type';
import { extractYearMonth } from '../../../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../../../styles/colors';
import { getDateLabels } from '../initiative-performance.helpers';
import { InitiativeMetricType } from '../initiative-performance.type';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const getData = (metric: InitiativeMetricType, total_tasks: Metric, completed_tasks: Metric) => {
  const hasTasks = Object.keys(total_tasks).length > 0;
  const labels = hasTasks ? getDateLabels(Object.keys(total_tasks.tasks)) : [];

  const getTotalTasksData = () => {
    if (!hasTasks) {
      return {};
    }

    const dataset = metric === InitiativeMetricType.Points ? total_tasks.points : total_tasks.tasks;

    return Object.values(dataset);
  };

  return {
    labels,
    datasets: [
      {
        label: 'total_tasks',
        data: getTotalTasksData(),
        borderColor: newCOLORS.blue,
        backgroundColor: newCOLORS.blue,
      },
      {
        label: 'completed_tasks',
        data: hasTasks ? Object.values(completed_tasks[metric]) : {},
        borderColor: newCOLORS.green,
        backgroundColor: newCOLORS.green,
      },
    ],
  };
};

const getOptions = (metric: InitiativeMetricType): any => ({
  responsive: true,
  maintainAspectRatio: false,
  pointStyle: true,
  cubicInterpolationMode: 'default',
  borderWidth: 1,
  onHover: (event: ChartEvent, elements: ActiveElement[]) => {
    let cursorStyle = 'default';

    const isOnElement = elements.length > 0;
    if (isOnElement) {
      const isHoverDisabled =
        [InitiativeMetricType.Tasks_Added, InitiativeMetricType.Bugs].includes(metric) &&
        elements[0]?.datasetIndex === 1;

      if (!isHoverDisabled) {
        cursorStyle = 'pointer';
      }
    }
    if (event?.native?.target) {
      (event.native.target as HTMLElement).style.cursor = cursorStyle;
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Month',
        font: {
          size: 12,
        },
      },
      ticks: {
        font: {
          size: 12,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      position: 'left' as const,
      title: {
        display: true,
        text: metric === InitiativeMetricType.Points ? 'Points' : 'Tasks',
        font: {
          size: 12,
        },
        padding: {
          bottom: 0,
        },
      },
      ticks: {
        font: {
          size: 12,
        },
        padding: 10,
      },
      border: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Progress on initiative (cumulative)',
      font: {
        size: 16,
        weight: '500',
      },
      padding: {
        bottom: 20,
      },
    },
    annotation: {
      common: {
        drawTime: 'afterDraw',
      },
    },
    tooltip: {
      enabled: false,
    },
    filler: {
      propagate: true,
      drawTime: 'beforeDatasetsDraw' as const,
    },
  },
});

type Props = {
  metric: InitiativeMetricType;
  total_tasks: Metric;
  completed_tasks: Metric;
};

export function SectionStatusChart({ metric, total_tasks, completed_tasks }: Props) {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const data = getData(metric, total_tasks, completed_tasks);
  const navigate = useNavigate();

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!chartRef.current) {
      return;
    }

    const pointElement = getElementAtEvent(chartRef.current, event)[0];

    if (!pointElement) {
      return;
    }

    const isClickDisabled =
      [InitiativeMetricType.Tasks_Added, InitiativeMetricType.Bugs].includes(metric) && pointElement.datasetIndex === 1;

    if (isClickDisabled) {
      return;
    }

    const { year, month } = extractYearMonth(data.labels[pointElement.index]);
    const { label } = data.datasets[pointElement.datasetIndex];

    if (label && year && month) {
      let filter = 'completed';
      if (label.includes('total')) {
        filter = 'total';
      }
      navigate(`tasks/?filter=${filter}&date=${year}-${month}`);
    }
  };

  return (
    <div style={{ width: '75%', flex: '0 0 auto', height: 352 }}>
      <Line ref={chartRef} options={getOptions(metric)} data={data} onClick={onClick} />
    </div>
  );
}
