import { JDCReducerAction, JDCState } from './jdc.type';

/**
 * A function that acts as a reducer for JDCState based on JDCReducerAction types.
 *
 * @param {JDCState} state - the current state of the application
 * @param {JDCReducerAction} action - the action to be performed on the state
 * @return {JDCState} the updated state based on the action type
 */
export function JDCReducer(state: JDCState, action: JDCReducerAction): JDCState {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.payload, initialized: true };
    case 'project':
      return { ...state, project: action.payload, board: null, bloomfilterStatuses: {} };
    case 'board':
      return { ...state, board: action.payload, bloomfilterStatuses: {} };
    case 'bloomfilterStatuses':
      return {
        ...state,
        bloomfilterStatuses: { ...state.bloomfilterStatuses, ...action.payload },
      };
    case 'settings':
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case 'errors':
      return { ...state, errors: { ...state.errors, ...action.payload } };
    case 'options':
      return { ...state, options: { ...state.options, ...action.payload } };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}
