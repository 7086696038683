import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { SprintSummary } from '../../api/sprints-client/sprints-client.type';
import { getHealthScoreGrade } from '../../helpers/project-metrics-helper/project-metrics-helper';
import { shortDateDisplayFromDateString } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';
import { ProjectHealthBar } from '../project-health-bar/project-health-bar';
import { HealthBarStyle } from '../project-health-bar/project-health-bar.type';

export type SprintHistoryListItemProps = {
  summary: SprintSummary;
  clickHandler: (summary: SprintSummary) => void;
};

export const SprintHistoryListItem = ({ summary, clickHandler }: SprintHistoryListItemProps) => {
  const startDate = shortDateDisplayFromDateString(summary.start_date);
  const endDate = shortDateDisplayFromDateString(summary.end_date);
  const handleSummaryClick = () => {
    clickHandler(summary);
  };

  return (
    <ItemContainer onClick={handleSummaryClick}>
      <ItemContentContainer>
        <TitleWrapper>
          <TitleTextContainer>{summary.name ? summary.name : 'Sprint'}</TitleTextContainer>
        </TitleWrapper>
        <TextContainer>
          {startDate} - {endDate}
        </TextContainer>
        <DetailsLink>
          Details <Icon icon="dashicons:arrow-right-alt2" />
        </DetailsLink>
        <BarRow>
          <ProjectHealthBar
            health={summary.health}
            showNumber={false}
            style={HealthBarStyle.vertical}
            shouldAnimate={true}
          />
        </BarRow>
        <TitleTextContainer>{getHealthScoreGrade(summary.health)}</TitleTextContainer>
      </ItemContentContainer>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  border-radius: 10px;
  cursor: pointer;
  padding: 1em;
  max-width: 150px;
  &: hover {
    box-shadow: 0px 0px 5px ${newCOLORS.black};
  }
`;

const ItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const DetailsLink = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${newCOLORS.darkPurple};
  font-family: 'Figtree-light';
  font-size: 0.8em;
  font-weight: 900;
`;

const BarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 175px;
  transform: rotate(270deg);
  display: flex;
  align-items: center;
  min-width: max-content;
`;

const TextContainer = styled.div`
  padding-bottom: 1em;
  font-size: 10px;
  text-align: center;
`;

const TitleTextContainer = styled.div`
  font-family: 'Figtree-light';
  padding-bottom: 1em;
  font-size: 15px;
  text-align: center;
  font-weight: 1000;
  max-width: 100%;
  max-height: calc(1.2em * 3);
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`;

const TitleWrapper = styled.div`
  max-width: 100%;
  min-height: calc(1.5em * 3);
  padding-bottom: 5px;
`;
