import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../components/button/button';
import styles from '../views/views.module.css';
import { GitlabState } from './gitlab.type';

type SummaryProps = {
  gitlabState: GitlabState;
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export const Summary = ({ gitlabState, setActiveStep }: SummaryProps) => {
  return (
    <div id={styles.viewContainer}>
      <h3>Review your Selections: </h3>
      <div id={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Backlog</h4>
            <Button variant="subtle" onClick={() => setActiveStep(0)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          {(gitlabState.selectedProjects || []).map((project) => (
            <span key={project.id} className={styles.summaryValue}>
              {project.name || ''}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
