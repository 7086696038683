import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Project } from '../../api/projects-client/projects-client.type';
import { newCOLORS } from '../../styles/colors';
import { ProjectHealthBar } from '../project-health-bar/project-health-bar';
import { HealthBarStyle } from '../project-health-bar/project-health-bar.type';

type ProjectCardProps = {
  project: Project;
  onClick: (project: Project) => void;
};

export function ProjectCard({ project, onClick }: ProjectCardProps) {
  const projectManagerDisplay = project.pm || 'No PM specified';
  const handleProjectClick = () => onClick(project);

  return (
    <ProjectCardContainer onClick={handleProjectClick}>
      <InfoContainer>
        <div>
          <ProjectCardHeading>{project && project.name ? project.name : 'No Project Found'}</ProjectCardHeading>
        </div>
        <div>
          <ProjectCardSubHeading>Team Lead: {projectManagerDisplay}</ProjectCardSubHeading>
        </div>
      </InfoContainer>
      <DetailsLink>
        Details <Icon icon="dashicons:arrow-right-alt2" />
      </DetailsLink>
      <HealthbarContainer>
        <ProjectHealthBar health={project.health} style={HealthBarStyle.squared} />
      </HealthbarContainer>
    </ProjectCardContainer>
  );
}

const ProjectCardContainer = styled.div`
  height: 200px;
  max-width: 240px;
  background-color: ${newCOLORS.white};
  border-radius: 7px;
  aspect-ratio: 1;
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`;

const ProjectCardHeading = styled.div`
  margin: 0;
  margin-top: 20px;
  font-size: x-large;
  font-weight: 900;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const ProjectCardSubHeading = styled.div`
  margin: 0;
  font-family: 'Figtree-light';
  font-size: small;
  font-weight: 200;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  gap: 1em;
`;

const DetailsLink = styled.button`
  display: inline-block;
  color: ${newCOLORS.darkPurple};
  font-family: 'Figtree-light';
  font-size: 1em;
  font-weight: 900;
  background: ${newCOLORS.white};
  border: 0px;
  display: block;
`;

const HealthbarContainer = styled.div``;
