import { styled } from '@linaria/react';
import { Button, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { icons } from '../../../assets/icons/icons';
import { PortfolioMultiselect } from '../../../components/multiselect/portfolio-multiselect';
import { setIsDirtyPortfolios } from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { usePortfolioForm } from './scope-explorer.hooks';

export const PortfolioForm = () => {
  const { portfolioForm, handleSubmitPortfolioForm } = usePortfolioForm();

  return (
    <form onSubmit={portfolioForm.onSubmit(() => handleSubmitPortfolioForm(portfolioForm))}>
      <Panel>
        <PortfolioMultiselect
          required
          {...portfolioForm.getInputProps('portfolios')}
          onChange={(ids) => {
            portfolioForm.setFieldValue('portfolios', ids);
            setIsDirtyPortfolios(true);
          }}
        />
        <Divider orientation="vertical" />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...portfolioForm.getInputProps('startDate')}
          maxDate={portfolioForm.values.endDate}
          pointer
          miw="fit-content"
        />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...portfolioForm.getInputProps('endDate')}
          minDate={portfolioForm.values.startDate}
          pointer
          miw="fit-content"
        />
        <Button
          disabled={!portfolioForm.isDirty() || !portfolioForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
};

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
