import { Dispatch, SetStateAction, useCallback } from 'react';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { Button } from '../../../components/button/button';
import { convertTo12HourFormat } from '../../../helpers/timezone/timezone';
import { ADOState } from '../ado/ado.type';
import { JDCState } from '../jdc/jdc.type';
import { JiraState } from '../jira/jira.type';
import { bloomfilterStatusOptions } from './views.data';
import styles from './views.module.css';

type Props = {
  integration: ExternalService.Jira | ExternalService.ADO;
  state: JiraState | ADOState | JDCState;
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export function SummaryView({ integration, state, setActiveStep }: Props) {
  const getBoardName = useCallback(() => {
    if (integration === ExternalService.Jira) {
      return 'board' in state ? state.board?.name : '';
    }

    if (integration === ExternalService.ADO) {
      return 'team' in state ? state.team?.name : '';
    }

    return '';
  }, [integration, state]);

  return (
    <div id={styles.viewContainer}>
      <h3>Review your Selections: </h3>
      <div id={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Project:</h4>
            <Button variant="subtle" onClick={() => setActiveStep(0)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <span className={styles.summaryValue}>{state.project?.name || ''}</span>
        </div>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Boards:</h4>
            <Button variant="subtle" onClick={() => setActiveStep(1)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <div className={styles.flexRowSpaceBetween}>
            <span className={styles.summaryValue}>{getBoardName()}</span>
          </div>
        </div>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Status Mapping:</h4>
            <Button variant="subtle" onClick={() => setActiveStep(2)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          {bloomfilterStatusOptions.map((bloomfilterStatus) => (
            <div key={bloomfilterStatus.name} className={`${styles.flexRowSpaceBetween} ${styles.bottomBorder}`}>
              <b className={styles.summaryValue}>{bloomfilterStatus.label}</b>
              <div className={styles.flexRowGap}>
                {state.bloomfilterStatuses?.[bloomfilterStatus.name]?.map((statue) => (
                  <span key={statue}>{statue}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Settings:</h4>
            <Button variant="subtle" onClick={() => setActiveStep(3)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <div className={`${styles.flexRowSpaceBetween} ${styles.bottomBorder}`}>
            <b>Team Size: </b>
            {state.settings.devTeamSize}
          </div>
          <div className={`${styles.flexRowSpaceBetween} ${styles.bottomBorder}`}>
            <b>Point Bugs?: </b>
            {state.settings.pointBugs ? 'Yes, we point bugs.' : 'Nope. We leave them un-pointed.'}
          </div>
          <div className={`${styles.flexRowSpaceBetween} ${styles.bottomBorder}`}>
            <b>Require Labels?: </b>
            {state.settings.requireLabels ? 'Yes, we label cards' : "No, we don't expect cards to be labeled."}
          </div>
          <div className={`${styles.flexRowSpaceBetween} ${styles.bottomBorder}`}>
            <b>Working Hours Start: </b>
            {convertTo12HourFormat(state.settings.workingHoursStart)}
          </div>
          <div className={`${styles.flexRowSpaceBetween} ${styles.bottomBorder}`}>
            <b>Working Hours End: </b>
            {convertTo12HourFormat(state.settings.workingHoursEnd)}
          </div>
        </div>
      </div>
    </div>
  );
}
