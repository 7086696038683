import {
  Metric,
  WorkPeriodBurnsAndFlowsByPace,
  WorkPeriodPointsPaceDataPoint,
  WorkPeriodTasksPaceDataPoint,
} from './assessment-view.type';
import { DataPoint } from './section-pace.types';

/**
 * Takes the flow of work data for a given work period and transforms it into a form
 * that can be used to render a chart. The data is sliced to only include the first
 * `day` number of days.
 *
 * @param {data} -  The flow of work data for the work period.
 * @param {metric} - The metric to use for the chart.
 * @param {day} - The number of days to include in the chart.
 * @returns {DataPoint[]} -  The transformed data.
 */
const transformFlowByPaceData = (data: WorkPeriodBurnsAndFlowsByPace, metric: Metric, day: number): DataPoint[] => {
  const { flow_of_work_tasks_by_pace, flow_of_work_points_by_pace } = data;

  if (metric === Metric.Tasks) {
    return transformTasksData(flow_of_work_tasks_by_pace.data_points.toSpliced(day));
  } else {
    return transformPointsData(flow_of_work_points_by_pace.data_points.toSpliced(day));
  }
};

/**
 * Transforms the given array of `WorkPeriodTasksPaceDataPoint`s into an array of
 * `DataPoint`s that can be used to render a chart.
 *
 * @param {data} - The array of `WorkPeriodTasksPaceDataPoint`s to transform.
 * @returns {DataPoint[]} - The transformed array of `DataPoint`s.
 */
const transformTasksData = (data: WorkPeriodTasksPaceDataPoint[]): DataPoint[] => {
  return data.map((dataPoint) => {
    return {
      date: dataPoint.date,
      weekend_or_holiday: dataPoint.weekend_or_holiday,
      complete: dataPoint.tasks_complete,
      doing: dataPoint.tasks_doing,
      ideal: dataPoint.tasks_ideal,
      original_plan: dataPoint.tasks_original_plan,
      remaining: dataPoint.tasks_remaining,
      status: dataPoint.tasks_status,
      total: dataPoint.tasks_total,
    };
  });
};

/**
 * Transforms the given array of `WorkPeriodPointsPaceDataPoint`s into an array of
 * `DataPoint`s that can be used to render a chart.
 *
 * @param {data} - The array of `WorkPeriodPointsPaceDataPoint`s to transform.
 * @returns {DataPoint[]} - The transformed array of `DataPoint`s.
 */
const transformPointsData = (data: WorkPeriodPointsPaceDataPoint[]): DataPoint[] => {
  return data.map((dataPoint) => {
    return {
      date: dataPoint.date,
      weekend_or_holiday: dataPoint.weekend_or_holiday,
      complete: dataPoint.points_complete,
      doing: dataPoint.points_doing,
      ideal: dataPoint.points_ideal,
      original_plan: dataPoint.points_original_plan,
      remaining: dataPoint.points_remaining,
      status: dataPoint.points_status,
      total: dataPoint.points_total,
    };
  });
};

/**
 * Returns an array of strings that can be used as labels for a chart. The labels
 * are the dates of the data points in the given `WorkPeriodBurnsAndFlowsByPace` object,
 * filtered by the given `Metric`.
 *
 * @param {data} - The `WorkPeriodBurnsAndFlowsByPace` object to get the labels from.
 * @param {metric} - The `Metric` to filter the labels by.
 * @returns {string[]} - The array of labels.
 */
const getChartLabels = (data: WorkPeriodBurnsAndFlowsByPace, metric: Metric): string[] => {
  const { flow_of_work_tasks_by_pace, flow_of_work_points_by_pace } = data;

  const labels =
    metric === Metric.Tasks
      ? flow_of_work_tasks_by_pace.data_points.map((point) => point.date)
      : flow_of_work_points_by_pace.data_points.map((point) => point.date);

  return labels;
};

/**
 * Calculates the percentage of the work period that has passed, given the current active day.
 *
 * @param {activeDay} - The current active day in the work period.
 * @param {workPeriodLength} - The total length of the work period.
 * @returns {number} - The percentage of the work period that has passed.
 */
const getBurntMeasure = (activeDay: number, workPeriodLength: number): number => {
  return calculatePercentage(activeDay, workPeriodLength);
};

/**
 * Calculates the percentage of the total work that has been completed, given the current progress data.
 *
 * @param {data} - The current progress data.
 * @returns {number} - The percentage of the total work that has been completed.
 */
const getDoneMeasure = (data: DataPoint[]): number => {
  if (data.length === 0) {
    return 0;
  }

  const { complete, ideal } = data[data.length - 1];

  return calculatePercentage(complete, ideal);
};

/**
 * Calculates the percentage of a value given a numerator and denominator.
 *
 * @param {numerator} - The number to calculate the percentage of.
 * @param {denominator} - The total value to calculate the percentage against.
 * @returns {number} - The percentage of the numerator to the denominator.
 */
const calculatePercentage = (numerator: number, denominator: number): number => {
  if (denominator === 0) {
    return 0;
  }

  return (numerator / denominator) * 100;
};

export { getBurntMeasure, getChartLabels, getDoneMeasure, transformFlowByPaceData };
