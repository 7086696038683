import { styled } from '@linaria/react';
import {
  ActiveElement,
  CategoryScale,
  ChartData,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import {
  SprintMetricsBurnFlowOfWork,
  SprintMetricsFlowOfWorkPointDataPoint,
  SprintMetricsFlowOfWorkTaskDataPoint,
} from '../../api/sprints-client/sprints-client.type';
import { createWeekendBackgroundPlugin } from '../../helpers/chart-plugins/weekend-background-element';
import { newCOLORS } from '../../styles/colors';
import { HeadingTag } from '../../styles/shared-styled-components';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';
import { SanitizedData } from './flow-of-work.type';

// Global variables
let weekendOrHolidays: boolean[] = [];

// Helper Functions
const getWeekendOrHolidayStatus = (burn_commitment: SprintMetricsBurnFlowOfWork) => {
  weekendOrHolidays = burn_commitment.data_points.map((point) => point.weekend_or_holiday);
};

const sanitizeData = (
  rawData: SprintMetricsBurnFlowOfWork,
  start_date: string,
  data_type: ViewByType
): SanitizedData => {
  const sanitizedData = {} as SanitizedData;
  const inputData = rawData.data_points;
  const plan = [];
  const actualWork = [];

  // grammar-check disable-next-line COMMA_PARENTHESIS_WHITESPACE UNLIKELY_OPENING_PUNCTUATION
  const today = new Date().toLocaleString('en-US', { month: '2-digit', day: '2-digit' }).replace(',', '/');
  const currentYear = new Date().getFullYear();
  const sprintStartYear = new Date(start_date).getFullYear();

  const taskDataPoints = inputData as SprintMetricsFlowOfWorkTaskDataPoint[];
  const pointDataPoints = inputData as SprintMetricsFlowOfWorkPointDataPoint[];

  for (const i in data_type === ViewByType.tasks ? taskDataPoints : pointDataPoints) {
    let doneWork = 0;
    plan.push(
      data_type === ViewByType.tasks ? taskDataPoints[i].tasks_complete_ideal : pointDataPoints[i].points_complete_ideal
    );

    if (
      currentYear > sprintStartYear ||
      (currentYear === sprintStartYear &&
        (data_type === ViewByType.tasks ? taskDataPoints[i].date : pointDataPoints[i].date) <= today)
    ) {
      doneWork = data_type === ViewByType.tasks ? taskDataPoints[i].tasks_complete : pointDataPoints[i].points_complete;
      actualWork.push(doneWork);
    } else {
      actualWork.push(null);
    }
  }

  sanitizedData['labels'] = rawData.labels;
  sanitizedData['plan'] = plan;
  sanitizedData['actualWork'] = actualWork;

  return sanitizedData;
};

// Main Component
export type FlowOfWorkProps = {
  burn_commitment: SprintMetricsBurnFlowOfWork;
  start_date: string;
  data_type: ViewByType;
};

export const FlowOfWork = ({ burn_commitment, start_date, data_type }: FlowOfWorkProps) => {
  useEffect(() => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);
  }, []);

  const navigate = useNavigate();
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    onClick: (_: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
      const isOnElement = elements.length > 0;
      const isOnPlan = isOnElement && chart?.data.datasets[elements[0].datasetIndex].label === 'Actual Work';

      if (isOnPlan) {
        const params = {
          day: `${elements[0].index}`,
          filter: 'done',
        };
        navigate({
          pathname: `/application/project/${projectId}/sprint-assessment/${sprintId}/tasks`,
          search: `?${createSearchParams(params)}`,
        });
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
      let cursorStyle = 'default';

      const isOnElement = elements.length > 0;
      const isOnPlan = isOnElement && chart?.data.datasets[elements[0].datasetIndex].label === 'Actual Work';
      if (isOnPlan) {
        cursorStyle = 'pointer';
      }
      if (event?.native?.target) {
        (event.native.target as HTMLElement).style.cursor = cursorStyle;
      }
    },

    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Day of Sprint',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: '',
          font: {
            size: 16,
          },
        },
        afterFit: function (scaleInstance: any) {
          scaleInstance.width = scaleInstance.width + 40;
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          boxWidth: 2,
          boxHeight: 12,
          pointStyle: 'rect',
        },
      },

      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {},
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  const formatData = (data: SanitizedData): ChartData<'line'> => {
    const dataset: ChartData<'line'> = {
      labels: data.labels,
      datasets: [
        {
          label: 'Actual Work',
          order: 1,
          stack: 'Stack 0',
          data: data.actualWork,
          hidden: false,
          borderColor: newCOLORS.magenta,
          backgroundColor: newCOLORS.magenta,
          pointStyle: 'circle',
          pointRadius: 4,
          pointHoverRadius: 5,
          borderWidth: 2,
        },
        {
          label: 'Plan',
          order: 2,
          stack: 'Stack 1',
          data: data.plan,
          hidden: false,
          borderColor: newCOLORS.blue,
          pointRadius: 4,
          pointStyle: 'circle',
          backgroundColor: newCOLORS.blue,
          borderWidth: 2,
        },
      ],
    };
    return dataset;
  };

  const [options, setOptions] = useState(defaultChartOptions);

  useEffect(() => {
    setOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
      if (data_type === ViewByType.tasks) {
        updatedOptions.scales.y.title.text = 'Tasks';
      } else {
        updatedOptions.scales.y.title.text = 'Points';
      }
      return updatedOptions;
    });
  }, [data_type]);

  getWeekendOrHolidayStatus(burn_commitment);

  const data = sanitizeData(burn_commitment, start_date, data_type);
  const formattedData = formatData(data);

  return (
    <ChartContainer>
      <CenterHeadingTag>Flow of work</CenterHeadingTag>
      <div style={{ height: '350px', display: 'flex' }}>
        <div style={{ width: '99%' }}>
          <Line
            options={options}
            data={formattedData}
            plugins={[createWeekendBackgroundPlugin(weekendOrHolidays) as any]}
          />
        </div>
      </div>
    </ChartContainer>
  );
};

// Styled Components
const ChartContainer = styled.div`
  margin-bottom: 25px;
`;
const CenterHeadingTag = styled(HeadingTag)`
  text-align: center;
`;
