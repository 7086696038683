import { Button as MantineButton, ButtonProps as MantineButtonProps } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { newCOLORS } from '../../styles/colors';

interface ButtonProps extends MantineButtonProps {
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler;
}

export function Button({ type = 'button', ...props }: ButtonProps) {
  const color = props.color || newCOLORS.indigo;

  return (
    <MantineButton {...props} type={type} color={color} loaderProps={{ size: 'xs' }}>
      {props.children}
    </MantineButton>
  );
}
