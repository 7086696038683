import { Project, Subproject } from '../../api/projects-client/projects-client.type';
import { Sprint } from '../../api/sprints-client/sprints-client.type';

enum SortType {
  Default = 'Default',
  Name = 'Name',
  CreatedDate = 'Created Date',
  Points = 'Points',
}

enum GithubSortType {
  Default = 'Default',
  Title = 'Title',
  CreatedDate = 'Created Date',
  Number = 'Number',
}

type IssueHistoryState = {
  project: Project;
  sprint?: Sprint;
  subproject?: Subproject;
  selectedMenuItem: string;
  sprintName?: string;
  sprintId?: string;
};

export { GithubSortType, SortType };
export type { IssueHistoryState };
