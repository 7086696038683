import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { VictoryLabel, VictoryPie } from 'victory';
import { getHealthScoreGrade } from '../../helpers/project-metrics-helper/project-metrics-helper';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';
import { ContentTag } from '../../styles/shared-styled-components';
import { TripleDotLoader } from '../triple-dot-loader/triple-dot-loader';

export type HealthGaugeProps = {
  heading: string;
  tooltip: string;
  health: number;
  percentage?: number;
  showNumber?: boolean;
  explicitColor?: string;
  customUnitDescription?: string;
  shouldAnimate?: boolean;
  shouldFade?: boolean;
  status: 'loading' | 'error' | 'success';
  delay?: boolean;
  ratio?: number;
};
export function HealthGauge({
  heading,
  tooltip,
  health,
  showNumber,
  shouldAnimate,
  shouldFade,
  status = 'loading',
  delay,
  ratio = 1,
}: HealthGaugeProps) {
  const paddingX = 30;
  const paddingY = 20;
  const paddingTitle = 0;
  const gaugeSize = 180;
  const center = {
    x: gaugeSize + paddingX,
    y: gaugeSize + paddingY + paddingTitle,
  };
  const radius = gaugeSize - 40;

  const labelText = showNumber ? health : getHealthScoreGrade(health);
  const coordinates = healthScoreToCartesian({ health, center, radius });

  let label =
    status === 'loading' ? (
      <TripleDotLoader center={{ x: center.x, y: center.y }} />
    ) : (
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 72, fontWeight: 600 }}
        x={center.x}
        y={center.y - 50}
        text={labelText}
      />
    );

  if (status === 'error') {
    label = (
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 48, fontWeight: 600 }}
        x={center.x}
        y={center.y - 50}
        text="oops!"
      />
    );
  }

  return (
    <HealthGaugeContainer gaugeSize={gaugeSize} ratio={ratio}>
      <Tooltip multiline w={300} position="right" label={tooltip}>
        <HealthGaugeText>
          {heading} <Icon icon="mdi:information-outline" width={24} height={24} color={newCOLORS.blue} />
        </HealthGaugeText>
      </Tooltip>

      <svg
        viewBox={`0 0 ${gaugeSize * 2 + paddingX * 2} ${gaugeSize + paddingY * 2 + paddingTitle}`}
        data-testid="health-gauge"
      >
        <VictoryPie
          standalone={false}
          startAngle={270}
          endAngle={450}
          padAngle={3}
          labels={() => null}
          innerRadius={gaugeSize - 25}
          width={gaugeSize * 2}
          height={gaugeSize * 2}
          cornerRadius={20}
          origin={{ x: gaugeSize + paddingX, y: gaugeSize + paddingX + paddingTitle }}
          data={[
            { key: '', y: 50 },
            { key: '', y: 30 },
            { key: '', y: 20 },
          ]}
          colorScale={
            health === -1
              ? [newCOLORS.darkGray, newCOLORS.gray, newCOLORS.lightGray]
              : shouldFade
              ? [
                  0 < health && health < 50 ? newCOLORS.red : addOpacityToColor(newCOLORS.red, 0.2),
                  50 <= health && health < 80 ? newCOLORS.orange : addOpacityToColor(newCOLORS.orange, 0.2),
                  80 <= health && health <= 100 ? newCOLORS.green : addOpacityToColor(newCOLORS.green, 0.2),
                ]
              : [newCOLORS.red, newCOLORS.orange, newCOLORS.green]
          }
        />
        {status === 'success' && health !== -1 ? (
          <DonutIndicator
            health={health}
            center={center}
            radius={radius}
            coordinates={coordinates}
            shouldAnimate={shouldAnimate}
            delay={delay}
          />
        ) : null}
        {label}
      </svg>
    </HealthGaugeContainer>
  );
}

const HealthGaugeContainer = styled.div<{ gaugeSize: number; ratio: number }>`
  position: relative;
  width: ${(props) => props.gaugeSize * 2 * props.ratio}px;
  height: ${(props) => props.gaugeSize * props.ratio}px;
`;

const HealthGaugeText = styled(ContentTag)`
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
`;

type DonutIndicatorProps = {
  health: number;
  center: {
    x: number;
    y: number;
  };
  radius: number;
  coordinates: { x: number; y: number };
  shouldAnimate?: boolean;
  delay?: boolean;
};

export function DonutIndicator({ health, center, radius, coordinates, shouldAnimate = false }: DonutIndicatorProps) {
  const color = healthScoreToColor(health);

  const { x, y } = coordinates;

  return shouldAnimate ? (
    <>
      <circle cx={x} cy={y + 8} r={20} fill={color}>
        <DonutIndicatorAnimation health={health} center={center} radius={radius} withFill />
      </circle>
      <circle cx={x} cy={y + 8} r={10} fill="#FFF">
        <DonutIndicatorAnimation health={health} center={center} radius={radius} />
      </circle>
    </>
  ) : (
    <>
      <circle cx={x} cy={y + 8} r={16} fill={color} />
      <circle cx={x} cy={y + 8} r={8} fill="#FFF" />
    </>
  );
}

/**
 * Method to convert health score to its corresponding color in the gauge
 * @param health health score
 * @returns color string based on the health score
 */
function healthScoreToColor(health: number): string {
  let color = newCOLORS.green;
  if (health < 80) {
    color = newCOLORS.orange;
  }
  if (health < 50) {
    color = newCOLORS.red;
  }
  return color;
}

type DonutIndicatorAnimationProps = {
  health: number;
  center: {
    x: number;
    y: number;
  };
  radius: number;
  withFill?: boolean;
  delay?: boolean;
};

function DonutIndicatorAnimation({
  health,
  center,
  radius,
  withFill = false,
  delay = false,
}: DonutIndicatorAnimationProps) {
  const xValues = [];
  const yValues = [];
  const colorValues = [];

  // get values for 0 -> 100
  for (let i = 0; i < 96; i++) {
    const { x, y } = healthScoreToCartesian({ health: i, center, radius });
    const color = healthScoreToColor(i);

    xValues.push(x);
    yValues.push(y);
    colorValues.push(color);
  }

  // get values for 100 -> health
  for (let i = 96; health <= i; i--) {
    const { x, y } = healthScoreToCartesian({ health: i, center, radius });
    const color = healthScoreToColor(i);

    xValues.push(x);
    yValues.push(y);
    colorValues.push(color);
  }

  return (
    <>
      <animate attributeName="cx" values={xValues.join(';')} dur="750ms" begin={delay ? '400ms' : '0s'} />
      <animate attributeName="cy" values={yValues.join(';')} dur="750ms" begin={delay ? '400ms' : '0s'} />
      {withFill && (
        <animate attributeName="fill" values={colorValues.join(';')} dur="750ms" begin={delay ? '400ms' : '0s'} />
      )}
    </>
  );
}

type healthScoreToCartesianProps = {
  health: number;
  center: {
    x: number;
    y: number;
  };
  radius: number;
};

function healthScoreToCartesian({ health, center, radius }: healthScoreToCartesianProps) {
  // shift the coordinates up 5 degrees at the edges for aesthetics when necessary
  if (96 < health) {
    health = 96;
  }
  if (health < 4) {
    health = 4;
  }

  // find the polar angle of the health score in radians
  const theta = (health / 100) * Math.PI;

  // translate the polar angle and radius to cartesian coordinates
  // and adjust for the center of the gauge
  const x = center.x - radius * Math.cos(theta);
  const y = center.y - radius * Math.sin(theta);

  return {
    x: x,
    y: y,
  };
}
