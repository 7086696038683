import { Chart as ChartJS } from 'chart.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AllocationOfCostsChartDataV2, ViewBy } from '../../../api/financials-client/financials-client.type';
import { formatAllocationOfCostTitle } from '../../../helpers';
import { newCOLORS } from '../../../styles/colors';

dayjs.extend(customParseFormat);

let hideTooltipTimeout: NodeJS.Timeout | undefined;

const getOrCreateTooltip = (chart: ChartJS) => {
  if (!chart.canvas.parentNode) {
    return;
  }
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 1)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.addEventListener('mouseenter', () => {
      clearTimeout(hideTooltipTimeout);
      if (tooltipEl) {
        tooltipEl.style.pointerEvents = 'auto';
      }
    });

    tooltipEl.addEventListener('mouseleave', () => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    });
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const allocationOfCostsTooltipHandler = (
  context: { chart: ChartJS; tooltip: any },
  chartData: AllocationOfCostsChartDataV2 | null,
  viewBy: ViewBy,
  category: string,
  portfolioId: string | undefined
) => {
  const isFinancialUnit = viewBy === ViewBy.POINTS_IN_USD || viewBy === ViewBy.TASKS_IN_USD;
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  clearTimeout(hideTooltipTimeout);

  const activePointExists = !!chart.getActiveElements().length;

  if (tooltip.opacity === 0 || !activePointExists) {
    hideTooltipTimeout = setTimeout(() => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    }, 3000);
    return;
  }

  const currentDataIndex = tooltip.dataPoints[0]?.dataIndex;
  if (currentDataIndex === undefined || chartData === null) {
    return;
  }

  const currentDataLabel = tooltip.dataPoints[0].dataset.label;
  const rawDateString = Object.keys(chartData)[currentDataIndex];
  const currentChartData = chartData[rawDateString][currentDataLabel];

  if (tooltip.body) {
    const tableHead = document.createElement('thead');

    const dataField: string = tooltip.dataPoints[0].dataset.label;
    const titleString = formatAllocationOfCostTitle(dataField);
    const titleRow = document.createElement('tr');
    titleRow.style.borderWidth = '0';

    const titleTH = document.createElement('td');
    titleTH.style.borderWidth = '0';
    titleTH.style.fontSize = '17px';

    const text = document.createTextNode(titleString);
    titleTH.appendChild(text);
    titleRow.appendChild(titleTH);
    tableHead.appendChild(titleRow);

    const tableBody = document.createElement('tbody');

    const monthYearRow = document.createElement('tr');
    monthYearRow.style.fontSize = '12px';
    const monthYearData = document.createElement('td');
    monthYearData.style.minWidth = '130px';
    monthYearData.style.paddingRight = '50px';
    const mydText = document.createTextNode(dataField.toUpperCase());

    monthYearData.appendChild(mydText);
    monthYearRow.appendChild(monthYearData);

    let taskDataTextContent = '';
    if (isFinancialUnit) {
      taskDataTextContent = 'Cost';
    } else if (viewBy === ViewBy.POINTS) {
      taskDataTextContent = 'Points';
    } else if (viewBy === ViewBy.TASKS) {
      taskDataTextContent = 'Tasks';
    }
    const tasksData = document.createElement('td');
    const tasksDataText = document.createTextNode(taskDataTextContent.toUpperCase());
    tasksData.appendChild(tasksDataText);
    monthYearRow.appendChild(tasksData);

    tableHead.appendChild(monthYearRow);

    const tr = document.createElement('tr');
    tr.style.backgroundColor = 'inherit';
    tr.style.borderWidth = '0';

    const th = document.createElement('td');
    th.style.borderWidth = '0';
    const thText = document.createTextNode('Total');

    const td = document.createElement('td');
    th.style.borderWidth = '0';
    let totalText = `${new Intl.NumberFormat().format(Math.round(tooltip.dataPoints[0].raw))}`;

    const [year, month] = rawDateString.split('-');
    const yearMonthString = `${year}-${String(month).padStart(2, '0')}`;

    const totalLinkUrl = `/application/financials/portfolio/${portfolioId}/tasks/?measure=cost_allocation&date=${yearMonthString}&factor=${category}&factorOption=${titleString}`;
    const totalAnchor = document.createElement('a');
    totalAnchor.setAttribute('href', totalLinkUrl);
    totalAnchor.style.color = newCOLORS.lightPurple;
    totalAnchor.style.textDecoration = 'underline';
    totalAnchor.style.textDecorationStyle = 'dashed';
    if (tooltip.dataPoints[0].dataset.yAxisID === 'y') {
      totalText = isFinancialUnit ? `$${totalText}` : `${totalText}`;
    }
    const tdText = document.createTextNode(totalText);
    th.appendChild(thText);
    totalAnchor.appendChild(tdText);
    td.appendChild(totalAnchor);
    tr.appendChild(th);
    tr.appendChild(td);
    tableBody.appendChild(tr);

    Object.entries(currentChartData.teams).forEach(([teamName, teamData]) => {
      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const th = document.createElement('td');
      th.style.borderWidth = '0';
      const thText = document.createTextNode(teamName);

      const td = document.createElement('td');
      th.style.borderWidth = '0';

      let value: number | null = 0;
      if (viewBy === ViewBy.TASKS) {
        value = teamData.tasks;
      } else if (viewBy === ViewBy.POINTS) {
        value = teamData.points;
      } else if (viewBy === ViewBy.TASKS_IN_USD) {
        value = teamData.tasks_in_usd;
      } else if (viewBy === ViewBy.POINTS_IN_USD) {
        value = teamData.points_in_usd;
      }

      let valueText = `${value}`;
      let linkableValue = valueText !== '0';
      if (value === Infinity || value === null || isNaN(value)) {
        valueText = ' -';
        linkableValue = false;
      } else {
        valueText = `${isFinancialUnit ? '$' : ''}${new Intl.NumberFormat().format(Math.round(value))}`;
      }

      th.appendChild(thText);
      const tdText = document.createTextNode(valueText);

      const linkUrl = totalLinkUrl + `&projectId=${teamData.id}`;
      if (linkableValue) {
        const anchor = document.createElement('a');
        anchor.setAttribute('href', linkUrl);
        anchor.style.color = newCOLORS.lightPurple;
        anchor.style.textDecoration = 'underline';
        anchor.style.textDecorationStyle = 'dashed';
        anchor.appendChild(tdText);
        td.appendChild(anchor);
      } else {
        td.appendChild(tdText);
      }
      tr.appendChild(th);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    if (tooltipEl) {
      const tableRoot = tooltipEl.querySelector('table');

      if (tableRoot) {
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    }
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const tooltipOffsetPadding = 10;
  if (tooltipEl) {
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.left = tooltip.caretX + positionX - tooltipEl.offsetWidth / 2 - tooltipOffsetPadding + 'px';
    tooltipEl.style.top = tooltip.caretY + positionY - tooltipEl.offsetHeight + tooltipOffsetPadding + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    tooltipEl.style.zIndex = '400';
  }
};
