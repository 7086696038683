const newCOLORS = {
  black: '#000000',
  black2: '#212529',
  darkestGray: '#f1f1f0',
  darkerGray: '#101820',
  darkerGray2: '#2C333A',
  darkGray: '#595959',
  darkGray2: '#586674',
  gray: '#C4C4C4',
  gray2: '#8B99A7',
  gray3: '#868E96',
  lightGray: '#E5E5E5',
  lightGray2: '#e2e5e9',
  lightGray3: '#e6e6e6',
  lighterGray: '#F8F8F8',
  lighterGray2: '#f2f2f2',
  lighterGray3: '#fcfcfc',
  lightishGray: '#F5F5F5',
  white: '#FFFFFF',
  lighterPurple: '#E9E8FC',
  lighterPurple2: '#eeedfd',
  lightPurple: '#9194FF',
  purple: '#9154E2',
  purple2: '#5C3ECF',
  darkPurple: '#31006F',
  violet: '#CD48D7',
  magenta: '#D91EA1',
  indigo: '#5541D3',
  indigo2: '#4f46e5',
  darkerIndigo: '#312e81',
  darkIndigo: '#2A1D7C',
  lightBlue: '#E5EFFF',
  lightBlue2: '#e6f2ff',
  lighterBlue: '#93c5fd',
  blue: '#2B70E4',
  darkBlue: '#1E3A8A',
  aqua: '#00CDE9',
  teal: '#00EEC3',
  darkerGreen: '#18623F',
  darkGreen: '#339F00',
  green: '#66CC33',
  lighterGreen: '#DBF229',
  darkYellow: '#FFB340',
  yellow: '#FFE000',
  lightYellow: '#FFF399',
  lightOrange: '#f7e3bb',
  lightOrange2: '#FCF6E8',
  orange: '#FF671D',
  coral: '#FF8771',
  tangerine: '#FE7583',
  pink: '#FF64B0',
  darkPink: '#FF1A88',
  orangeRed: '#FF4646',
  red: '#E7004C',
  brown: '#533e14',
  pinkishPurple: '#C24AD9',
};

export { newCOLORS };
