import { Divider, Flex } from '@mantine/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { completeHandshake, completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { UserContext } from '../../../contexts/user';
import { StepProps } from '../integrations.type';
import { SuccessView } from '../views/success-view';
import styles from '../views/views.module.css';
import { BitbucketResponse, BitbucketState } from './bitbucket.type';
import { Repos } from './repos';
import { Summary } from './summary';

const initialState: BitbucketState = {
  repoOptions: null,
  selectedRepos: null,
};

export const Bitbucket = ({ activeStep = 0, setActiveStep }: StepProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const [bitbucketState, setBitbucketState] = useState(initialState);

  const updateState = useCallback((update: Partial<BitbucketState>) => {
    setBitbucketState((prevState) => Object.assign({}, prevState, update));
  }, []);

  useEffect(() => {
    const code = searchParams.get('code');
    const userId = user?.id.toString();
    const orgId = user?.organizations[0].toString();

    if (code && userId && orgId) {
      updateState({ code, userId, orgId });
      searchParams.delete('code');
      setSearchParams(searchParams);
    }
  }, [searchParams, user, updateState, setSearchParams]);

  useEffect(() => {
    if (!bitbucketState.repoOptions && bitbucketState.userId && bitbucketState.orgId && bitbucketState.code) {
      completeOauth(
        bitbucketState.userId,
        bitbucketState.orgId,
        bitbucketState.code,
        ExternalService.Bitbucket,
        ''
      ).then((data: BitbucketResponse) => {
        updateState({ repoOptions: data.repos });
      });
    }
  }, [bitbucketState.userId, bitbucketState.orgId, bitbucketState.code, bitbucketState.repoOptions, updateState]);

  const views = [
    <Repos bitbucketState={bitbucketState} updateState={updateState} key="repos" />,
    <Summary bitbucketState={bitbucketState} setActiveStep={setActiveStep} key="summary" />,
    <SuccessView key="success" />,
  ];

  const finalizeIntegration = () => {
    const orgId = user?.organizations[0]?.toString();
    if (orgId && (bitbucketState.selectedRepos || []).length > 0) {
      completeHandshake('bitbucket', {
        org_id: orgId,
        repos: bitbucketState.selectedRepos,
      }).then(() => {
        nextStep();
      });
    }
  };

  const isNextButtonDisabled = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (bitbucketState.selectedRepos || []).length === 0;
      default:
        return false;
    }
  };

  const nextStep = () => setActiveStep((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => setActiveStep((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      <BrandedLoadingOverlay visible={!bitbucketState.repoOptions} />
      {views[activeStep]}
      <Divider my="sm" />
      <div id={styles.backNextContainer}>
        {activeStep < 2 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {activeStep !== 0 ? (
              <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                Back
              </Button>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isNextButtonDisabled(activeStep)}
                onClick={activeStep < 1 ? nextStep : finalizeIntegration}
                size="lg"
              >
                {activeStep < 2 ? 'Next' : "Let's go!"}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Flex>
  );
};
