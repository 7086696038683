import { Measure, MeasureValuesOverTime } from '../../../api/work-periods-client/work-periods-client.type';
import { Tab } from '../process-analysis.type';

type MeasureDataExtended = {
  average: number;
  last_month: number;
  months: MeasureValuesOverTime;
};

type MeasureDataAggregated = Record<string, MeasureDataExtended>;

type MeasuresWithColor = Record<Measure, string>;

type MeasureComparisonSelection = {
  measures: Measure[];
  selectedMeasures: Measure[];
  selectedTrends: Measure[];
};
type MeasureComparisonSelectionAggregated = {
  [Tab.Portfolios]: MeasureComparisonSelection;
  [Tab.Teams]: MeasureComparisonSelection;
  [Tab.Boards]: MeasureComparisonSelection;
};

enum MeasureComparisonType {
  Default = 'default',
  Transformer = 'transformer',
}

export { MeasureComparisonType };
export type {
  MeasureComparisonSelection,
  MeasureComparisonSelectionAggregated,
  MeasureDataAggregated,
  MeasureDataExtended,
  MeasuresWithColor,
};
