import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { APIGlobalMessage } from './global-messages-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

const dismissGlobalMessage = async (globalMessageId: string): Promise<APIGlobalMessage> => {
  const apiURL = baseURL + `/api/dismiss-global-message?message_id=${globalMessageId}`;
  return await axiosInstance
    .post(apiURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export { dismissGlobalMessage };
