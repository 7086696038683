import { Radio } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { Dispatch, useEffect, useState } from 'react';
import styles from '../views/views.module.css';
import { GithubReducerAction, GithubState } from './github.type';
import { Repository } from './repositories.type';

type RepositoriesProps = {
  state: GithubState;
  dispatch: Dispatch<GithubReducerAction>;
};

/** The view for selecting repositories during the github itegration workflow.
 *
 * @param props: { state: GithubState, dispatch: Dispatch<GithubReducerAction> }
 * @returns JSX.Element
 */
export function Repositories({ state, dispatch }: RepositoriesProps) {
  const { organization } = state;
  const { organizationWithRepos } = state.options;

  const [repos, setRepos] = useState<Repository[]>([]);

  useEffect(() => {
    setRepos(organization && organizationWithRepos ? organizationWithRepos[organization] : []);
  }, [organization, organizationWithRepos]);

  /** Handles the change of the repository selection.
   *
   * @param repository: string - the selected repository
   */
  function handleChange(repository: Repository) {
    try {
      dispatch({ type: 'repository', payload: repository });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return (
    <div id={styles.viewContainer}>
      <h4>Select a repository you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(repos || []).map((repository: Repository) => (
          <div
            key={repository.name}
            className={
              repository === state.repository
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleChange(repository)}
          >
            <Radio
              color="red.5"
              styles={{ label: { fontWeight: 600, marginLeft: '30px' } }}
              value={repository.name}
              checked={repository === state.repository}
              onChange={() => handleChange(repository)}
            />
            <span className={styles.checkboxLabel}>{repository.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
