import dayjs from 'dayjs';
import {
  SprintMetricsCumulativeFlow,
  SprintMetricsCumulativeFlowDataPoint,
} from '../../api/sprints-client/sprints-client.type';
import { LineScatterDataPoint } from '../../components/line-scatter-chart/line-scatter-chart.type';
import { CumulativeFlowDataPoints } from './sprint-metrics-chart-data-factory.type';

const CumulativeFlowDataPointsForPoints = (
  points: SprintMetricsCumulativeFlowDataPoint[],
  key: keyof SprintMetricsCumulativeFlowDataPoint
): LineScatterDataPoint[] => {
  return points.map((point) => {
    const fullDate = dayjs(point.date).startOf('day').toDate();
    const displayDate = dayjs(point.date).format('MM/DD');
    const today = new Date();

    if (fullDate <= today) {
      return { x: displayDate, y: point[key] as number };
    }
    return { x: displayDate, y: null };
  });
};

export const cumulativeFlowData = (data?: SprintMetricsCumulativeFlow): CumulativeFlowDataPoints => {
  if (!data) {
    return {
      deployed: [],
      ready_for_deploy: [],
      in_test: [],
      in_review: [],
      in_progress: [],
      blocked: [],
      current_sprint: [],
    };
  }
  const points = data.data_points;
  return {
    deployed: CumulativeFlowDataPointsForPoints(points, 'done'),
    ready_for_deploy: CumulativeFlowDataPointsForPoints(points, 'deployable'),
    in_test: CumulativeFlowDataPointsForPoints(points, 'test'),
    in_review: CumulativeFlowDataPointsForPoints(points, 'review'),
    in_progress: CumulativeFlowDataPointsForPoints(points, 'wip'),
    blocked: CumulativeFlowDataPointsForPoints(points, 'blocked'),
    current_sprint: CumulativeFlowDataPointsForPoints(points, 'backlog'),
  };
};
