import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { Project } from '../../api/projects-client/projects-client.type';
import { Sprint, SprintMetrics } from '../../api/sprints-client/sprints-client.type';
import { PillarCard } from '../../components/pillar-card/pillar-card';
import { newCOLORS } from '../../styles/colors';
import { ContentTag } from '../../styles/shared-styled-components';
import { MEASURES } from './sprint-assessment-descritpion.data';

export const ProcessPillarsContainer = ({
  viewAreaWidth,
  sprint,
  project,
  sprintMetrics,
}: {
  viewAreaWidth: number;
  sprint: Sprint;
  project: Project;
  sprintMetrics: SprintMetrics;
}) => {
  return (
    <ProcessPillars viewAreaWidth={viewAreaWidth}>
      <CenterContentTag>Key Process Measures</CenterContentTag>
      <PillarCardsBox>
        <PillarCard
          label={MEASURES.STRATEGY.label}
          description={MEASURES.STRATEGY.description}
          sprint={sprint}
          project={project}
          issueProps={{
            amount: parseFloat((sprintMetrics.tasks_linked_to_epic_share || 0)?.toFixed(1)).toString() + '%',
            title: MEASURES.STRATEGY.issueProps.title,
            measure: MEASURES.STRATEGY.issueProps.measure,
            summary: MEASURES.STRATEGY.issueProps.summary,
            description: MEASURES.STRATEGY.issueProps.description,
            calculation: MEASURES.STRATEGY.issueProps.calculation,
            solution: MEASURES.STRATEGY.issueProps.solution,
          }}
          suffix={MEASURES.STRATEGY.suffix}
          value={sprintMetrics.tasks_linked_to_epic_share}
          borderColor={newCOLORS.darkerGray}
        />
        <PillarCard
          label={<ComplexityLabel />}
          description={MEASURES.COMPLEXITY.description}
          sprint={sprint}
          project={project}
          issueProps={{
            amount: parseFloat((sprintMetrics.complexity || 0)?.toFixed(1)).toString() || '0',
            title: MEASURES.COMPLEXITY.issueProps.title,
            measure: MEASURES.COMPLEXITY.issueProps.measure,
            summary: MEASURES.COMPLEXITY.issueProps.summary,
            description: MEASURES.COMPLEXITY.issueProps.description,
            calculation: MEASURES.COMPLEXITY.issueProps.calculation,
            solution: MEASURES.COMPLEXITY.issueProps.solution,
          }}
          value={sprintMetrics.complexity}
          borderColor={newCOLORS.darkerGray}
        />
        <PillarCard
          label={MEASURES.QUALITY.label}
          description={MEASURES.QUALITY.description}
          sprint={sprint}
          project={project}
          issueProps={{
            amount: parseFloat((sprintMetrics.bug_or_tech_debt_share || 0)?.toFixed(1)).toString() + '%',
            title: MEASURES.QUALITY.issueProps.title,
            measure: MEASURES.QUALITY.issueProps.measure,
            summary: MEASURES.QUALITY.issueProps.summary,
            description: MEASURES.QUALITY.issueProps.description,
            calculation: MEASURES.QUALITY.issueProps.calculation,
            solution: MEASURES.QUALITY.issueProps.solution,
          }}
          suffix={MEASURES.QUALITY.suffix}
          value={sprintMetrics.bug_or_tech_debt_share}
          borderColor={newCOLORS.darkerGray}
        />
        <PillarCard
          label={MEASURES.SCOPE.label}
          description={MEASURES.SCOPE.description}
          sprint={sprint}
          project={project}
          issueProps={{
            amount: parseFloat((sprintMetrics.memoized_scope_increase || 0)?.toFixed(1)).toString() + '%',
            title: MEASURES.SCOPE.issueProps.title,
            measure: MEASURES.SCOPE.issueProps.measure,
            summary: MEASURES.SCOPE.issueProps.summary,
            description: MEASURES.SCOPE.issueProps.description,
            calculation: MEASURES.SCOPE.issueProps.calculation,
            solution: MEASURES.SCOPE.issueProps.solution,
          }}
          suffix={MEASURES.SCOPE.suffix}
          value={sprintMetrics.memoized_scope_increase}
          borderColor={newCOLORS.darkerGray}
        />
        <PillarCard
          label={<ReadinessLabel />}
          description={MEASURES.READINESS.description}
          sprint={sprint}
          project={project}
          issueProps={{
            amount: parseFloat((sprintMetrics.ready_tasks_share || 0)?.toFixed(1)).toString() + '%',
            title: MEASURES.READINESS.issueProps.title,
            measure: MEASURES.READINESS.issueProps.measure,
            summary: MEASURES.READINESS.issueProps.summary,
            description: MEASURES.READINESS.issueProps.description,
            calculation: MEASURES.READINESS.issueProps.calculation,
            solution: MEASURES.READINESS.issueProps.solution,
          }}
          suffix={MEASURES.READINESS.suffix}
          value={sprintMetrics.ready_tasks_share}
          borderColor={newCOLORS.darkerGray}
        />
        <PillarCard
          label={MEASURES.INDEPENDENCE.label}
          description={MEASURES.INDEPENDENCE.description}
          sprint={sprint}
          project={project}
          issueProps={{
            amount: parseFloat((sprintMetrics.dependency_share || 0)?.toFixed(1)).toString() + '%',
            title: MEASURES.INDEPENDENCE.issueProps.title,
            measure: MEASURES.INDEPENDENCE.issueProps.measure,
            summary: MEASURES.INDEPENDENCE.issueProps.summary,
            description: MEASURES.INDEPENDENCE.issueProps.description,
            calculation: MEASURES.INDEPENDENCE.issueProps.calculation,
            solution: MEASURES.INDEPENDENCE.issueProps.solution,
          }}
          suffix={MEASURES.INDEPENDENCE.suffix}
          value={sprintMetrics.dependency_share}
          borderColor={newCOLORS.darkerGray}
        />
        {sprintMetrics.average_days_open_for_change_requests !== undefined ||
        sprintMetrics.average_days_open_for_change_requests !== null ? (
          <PillarCard
            label={MEASURES.REVIEWTIME.label}
            description={MEASURES.REVIEWTIME.description}
            sprint={sprint}
            project={project}
            issueProps={{
              amount: parseFloat((sprintMetrics.average_days_open_for_change_requests || 0)?.toFixed(1)).toString(),
              title: MEASURES.REVIEWTIME.issueProps.title,
              measure: MEASURES.REVIEWTIME.issueProps.measure,
              summary: MEASURES.REVIEWTIME.issueProps.summary,
              description: MEASURES.REVIEWTIME.issueProps.description,
              calculation: MEASURES.REVIEWTIME.issueProps.calculation,
              solution: MEASURES.REVIEWTIME.issueProps.solution,
            }}
            value={sprintMetrics.average_days_open_for_change_requests}
            borderColor={newCOLORS.darkerGray}
          />
        ) : null}
        {sprintMetrics.percentage_of_declined_change_requests !== undefined ||
        sprintMetrics.percentage_of_declined_change_requests !== null ? (
          <PillarCard
            label={MEASURES.ABANDONED.description}
            description={MEASURES.ABANDONED.description}
            sprint={sprint}
            project={project}
            issueProps={{
              amount:
                parseFloat((sprintMetrics.percentage_of_declined_change_requests || 0)?.toFixed(1)).toString() + '%',
              title: MEASURES.ABANDONED.issueProps.title,
              measure: MEASURES.ABANDONED.issueProps.measure,
              summary: MEASURES.ABANDONED.issueProps.description,
              description: MEASURES.ABANDONED.issueProps.description,
              calculation: MEASURES.ABANDONED.issueProps.calculation,
              solution: MEASURES.ABANDONED.issueProps.solution,
            }}
            suffix={MEASURES.ABANDONED.suffix}
            value={sprintMetrics.percentage_of_declined_change_requests}
            borderColor={newCOLORS.darkerGray}
          />
        ) : null}
      </PillarCardsBox>
    </ProcessPillars>
  );
};

function ReadinessLabel() {
  return (
    <ReadinessLabelContainer>
      <span>Readiness</span>
      <Tooltip
        multiline
        w={200}
        position="top"
        label="Tasks which have a name, description, and estimation. Note: tasks intentionally pointed 0 are considered estimated."
      >
        <Icon icon="mdi:information-outline" width={16} height={16} color={newCOLORS.blue} />
      </Tooltip>
    </ReadinessLabelContainer>
  );
}

function ComplexityLabel() {
  return (
    <ReadinessLabelContainer>
      <span>Complexity</span>
      <Tooltip
        multiline
        w={200}
        position="top"
        label="Complexity is evaluated only for tasks with points and is calculated for the point value assigned to each task on the latest day of the sprint."
      >
        <Icon icon="mdi:information-outline" width={16} height={16} color={newCOLORS.blue} />
      </Tooltip>
    </ReadinessLabelContainer>
  );
}

const ReadinessLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const CenterContentTag = styled(ContentTag)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: auto;
`;

const ProcessPillars = styled.div<{ viewAreaWidth: number }>`
  width: ${(props) => (props.viewAreaWidth >= 992 ? '1000px' : '350px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.viewAreaWidth >= 1500 ? '0px' : '20px')};
`;

const PillarCardsBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
