import { PaceStatus } from '../../../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../../../styles/colors';

/**
 * Maps a given PaceStatus to a corresponding color.
 *
 * @param {PaceStatus} status - The status to map to a color.
 * @returns {string} The color associated with the given status.
 */
const getStatusColor = (status: PaceStatus): string => {
  switch (status) {
    case PaceStatus.OnTrack:
      return newCOLORS.green;
    case PaceStatus.AtRisk:
      return newCOLORS.lightYellow;
    default:
      return newCOLORS.coral;
  }
};

export { getStatusColor };
