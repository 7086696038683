import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { newCOLORS } from '../../styles/colors';
import { ContentTag, HeadingTag } from '../../styles/shared-styled-components';
export type AssessmentDetailCardProps = {
  label: string;
  filter: string;
  day?: string;
  description: string;
  legend: boolean;
  color?: string;
  prefix?: string;
  suffix?: string;
  value: number;
};

export const AssessmentDetailCard = ({
  label,
  filter,
  day,
  description,
  legend,
  color = 'inherit',
  prefix,
  suffix,
  value,
}: AssessmentDetailCardProps) => {
  const navigate = useNavigate();
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  return (
    <AssessmentDetailCardContainer
      onClick={() =>
        filter &&
        day &&
        navigate(`/application/project/${projectId}/sprint-assessment/${sprintId}/tasks?day=${day}&filter=${filter}`)
      }
    >
      <CenterHeadingTag style={{ textDecoration: 'underline' }}>{label}</CenterHeadingTag>
      <SmallContentTag style={{ textAlign: 'center', fontSize: '12px' }}>{description}</SmallContentTag>
      <Tooltip
        label={`${prefix || ''}${parseFloat((value || 0)?.toFixed(2))}${suffix || ''}`}
        position="bottom"
        transitionProps={{
          transition: 'fade',
          duration: 200,
        }}
      >
        <CenterContentTag style={{ textAlign: 'center' }}>
          <span style={{ fontSize: legend ? 24 : 36, fontWeight: 'bold' }}>{prefix}</span>
          <span style={{ fontSize: legend ? 28 : 42, fontWeight: 'bold' }}>{parseFloat((value || 0)?.toFixed(1))}</span>
          <span style={{ fontSize: legend ? 16 : 24 }}>{suffix}</span>
        </CenterContentTag>
      </Tooltip>
      {legend && (
        <LegendBox>
          <Legend color={color} />
        </LegendBox>
      )}
    </AssessmentDetailCardContainer>
  );
};

const AssessmentDetailCardContainer = styled.div`
  border: 2px solid ${newCOLORS.lightGray};
  width: 100px;
  height: 100px;
  margin-right: 30px;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
`;

const CenterHeadingTag = styled(HeadingTag)`
  font-size: 14px;
  text-align: center;
  color: ${newCOLORS.indigo}};
`;

const SmallContentTag = styled(ContentTag)``;

const CenterContentTag = styled(ContentTag)`
  position: absolute;
  top: 35%;
  width: 100%;
`;

const LegendBox = styled.div`
  position: absolute;
  bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Legend = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: ${(props: { color: string }) => props.color};
`;
