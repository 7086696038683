import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { newCOLORS } from '../../styles/colors';
import { HeadingTag } from '../../styles/shared-styled-components';
import { ProgressBar } from '../progress-bar/progress-bar';

export type ProgressMeasureProps = {
  title: string;
  percent: number;
  icon: string;
  color: string;
  helpText: string;
};

export const ProgressMeasure = ({ title, percent, icon, color, helpText }: ProgressMeasureProps) => {
  return (
    <Row>
      <IconContainer>
        <Icon icon={icon} style={{ color }} width="24" height="24" />
      </IconContainer>
      <Measure>
        <HeadingTag>
          {title} - {Math.round(percent)}%
        </HeadingTag>
        <HelpText>{helpText}</HelpText>
        <ProgressBar percent={percent} color={color} />
      </Measure>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconContainer = styled.div`
  display: flex;
  max-height: 24px;
  padding-right: 5px;
`;

const Measure = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const HelpText = styled.div`
  font-size: 12px;
  color: ${newCOLORS.darkGray};
`;
