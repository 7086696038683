import { styled } from '@linaria/react';
import { DatePickerInput } from '@mantine/dates';
import { Fragment, useEffect, useState } from 'react';
import {
  BloomfilterStatuses,
  TaskFlowHistoryOnDate,
  TaskFlowHistoryType,
} from '../../api/sprints-client/sprints-client.type';
import { formatDate } from '../../helpers';
import { newCOLORS } from '../../styles/colors';
import { getBoundedEndDate } from './task-flow-history-board.helper';
import { Card } from './task-flow-history-card';

type TaskFlowHistoryBoardProps = {
  history: TaskFlowHistoryType;
  startDate: Date;
  endDate: Date;
};

export const TaskFlowBoard = ({ history, startDate, endDate }: TaskFlowHistoryBoardProps) => {
  const [currentDate, setCurrentDate] = useState(startDate);
  const [currentHistory, setCurrentHistory] = useState<TaskFlowHistoryOnDate>(history[formatDate(currentDate)]);

  useEffect(() => {
    setCurrentHistory(history[formatDate(currentDate)]);
  }, [currentDate, history]);

  const statuses = ['backlog', 'blocked', 'wip', 'review', 'test', 'deployable', 'done'];
  const statusColors = {
    backlog: newCOLORS.indigo,
    blocked: newCOLORS.coral,
    wip: newCOLORS.darkYellow,
    review: newCOLORS.lightYellow,
    test: newCOLORS.pink,
    deployable: newCOLORS.aqua,
    done: newCOLORS.green,
  } as {
    [key: string]: string;
  };
  const properTitles = {
    backlog: 'Backlog',
    blocked: 'Blocked',
    wip: 'In Progress',
    review: 'In Review',
    test: 'In Test',
    deployable: 'Ready to Deploy',
    done: 'Done',
  } as {
    [key: string]: string;
  };

  return (
    <Fragment>
      <CalendarContainer>
        <DatePickerInput
          label={'Date'}
          value={currentDate}
          onChange={(value) => value && setCurrentDate(value)}
          minDate={startDate}
          maxDate={getBoundedEndDate(endDate)}
        />
      </CalendarContainer>

      <SprintView>
        {statuses.map((statusName) => (
          <StatusColumn key={statusName}>
            <ColumnHeader>
              {properTitles[statusName]}
              <Legend color={statusColors[statusName]} />
            </ColumnHeader>
            {currentHistory && currentHistory[statusName as BloomfilterStatuses] ? (
              <Tasks>
                {currentHistory[statusName as BloomfilterStatuses].map((task) => (
                  <Card key={task.code} task={task} />
                ))}
              </Tasks>
            ) : null}
          </StatusColumn>
        ))}
      </SprintView>
    </Fragment>
  );
};

const CalendarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  margin-left: 20px;
  margin-top: 10px;
`;

const SprintView = styled.div`
  display: flex;
  overflow-x: hidden;
  gap: 16px;
  padding: 20px;
`;

const StatusColumn = styled.div`
  flex: 1;
  max-width: 300px;
  overflow: hidden;
  border: 1px solid ${newCOLORS.gray};
  border-radius: 3px;
  background-color: ${newCOLORS.lightGray};
`;
const ColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background: ${newCOLORS.white};
`;

const Tasks = styled.div`
  padding: 10px;
`;

const Legend = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;
  background-color: ${(props: { color: string }) => props.color};
`;
