import { upcomingIntegrationsIcons as icons } from '../../../assets/icons/upcoming-integrations';
import { UpcomingIntegration } from './upcoming-integrations.types';

const upcomingIntegrations: UpcomingIntegration[] = [
  {
    icon: icons.iconFigma,
    title: 'Figma',
    description: 'Collaborative web application for interface design.',
  },
  {
    icon: icons.iconCircleCI,
    title: 'CircleCI',
    description: 'Continuous integration and delivery platform.',
  },
];

const proposedIntegrations: UpcomingIntegration[] = [
  {
    icon: icons.iconAdobeXD,
    title: 'Adobe XD',
    description: 'Experience design platform from Adobe.',
  },
  {
    icon: icons.iconAha,
    title: 'Aha!',
    description: 'Suite covers every step of the product development lifecycle, so teams can create real value.',
  },
  {
    icon: icons.iconBitbucket,
    title: 'BitBucket',
    description: 'Git-based source code repository hosting service owned by Atlassian.',
  },
  {
    icon: icons.iconConfluence,
    title: 'Confluence',
    description: 'Web-based corporate wiki developed by Atlassian.',
  },
  {
    icon: icons.iconGoRetro,
    title: 'GoRetro',
    description: 'Data-driven and engaging retrospective meetings, sprint planning events, and sprint monitoring.',
  },
  {
    icon: icons.iconInVision,
    title: 'InVision',
    description: 'Online whiteboard and productivity platform powering the future of work.',
  },
  {
    icon: icons.iconJenkins,
    title: 'Jenkins',
    description: 'Open source automation server.',
  },
  {
    icon: icons.iconMiro,
    title: 'Miro',
    description: 'Digital collaboration platform designed to facilitate remote and distributed team communication.',
  },
  {
    icon: icons.iconMonday,
    title: 'Monday',
    description: 'Cloud-based platform to create their applications and project management software',
  },
  {
    icon: icons.iconMural,
    title: 'Mural',
    description: 'Teams work together in a flexible, inclusive collaboration space.',
  },
  {
    icon: icons.iconNewRelic,
    title: 'New Relic',
    description: 'All-in-one observability platform for engineers to monitor, debug, and improve their entire stack.',
  },
  {
    icon: icons.iconNotion,
    title: 'Notion',
    description: 'Connected workspace where better, faster work happens.',
  },
  {
    icon: icons.iconParabol,
    title: 'Parabol',
    description: 'Agile meeting tool for remote teams.',
  },
  {
    icon: icons.iconProductBoard,
    title: 'ProductBoard',
    description:
      'Product management software tools that help product managers understand customer needs and prioritize features.',
  },
  {
    icon: icons.iconRoadmunk,
    title: 'Roadmunk',
    description: 'Roadmapping tool for building and communicating your product strategy.',
  },
  {
    icon: icons.iconServiceNow,
    title: 'ServiceNow',
    description: 'Cloud computing platform to help manage digital workflows for enterprise operations.',
  },
  {
    icon: icons.iconGitLab,
    title: 'GitLab',
    description: 'DevOps software package which can develop, secure, and operate software.',
  },
  {
    icon: icons.iconAsana,
    title: 'Asana',
    description:
      'Web and mobile "work management" platform designed to help teams organize, track, and manage their work.',
  },
  {
    icon: icons.iconTrello,
    title: 'Trello',
    description: 'Project management tool that works for any type of project, workflow, or team.',
  },
];

export { proposedIntegrations, upcomingIntegrations };
