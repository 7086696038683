import { Button, Chip, Group, TextInput } from '@mantine/core';
import { Dispatch, MouseEventHandler } from 'react';
import styles from '../setup.module.css';
import { SetupUser } from '../setup.types';
import { PROFESSIONS } from './create-profile';

type TeamInformationProps = {
  user: SetupUser;
  dispatch: Dispatch<any>;
  onClick: Function;
  onSubmit: MouseEventHandler;
};

export function TeamInformation({ user, dispatch, onClick, onSubmit }: TeamInformationProps) {
  const handleChangeTeamMembers = (value: string[]) => dispatch({ type: 'user', payload: { team: value } });

  const { friends = {} } = user;

  const inputStyle = { input: { height: '3.5em' } };
  return (
    <>
      <form id={styles.formContainer} style={{ alignItems: 'center' }}>
        <div id={styles.chipsContainer}>
          <span className="formSectionLabel" style={{ textAlign: 'center' }}>
            Which team members should have access to Bloomfilter?
          </span>
          <Chip.Group multiple onChange={handleChangeTeamMembers}>
            <Group gap="md" align="center">
              {PROFESSIONS.map((profession) => (
                <Chip value={profession} key={profession}>
                  {profession}
                </Chip>
              ))}
            </Group>
          </Chip.Group>
        </div>
        <div className={styles.formSection}>
          <span className="formSectionLabel">Have any friends who would benefit from Bloomfilter?</span>
          <TextInput
            placeholder="colleague@other-company.com"
            type="email"
            styles={inputStyle}
            value={friends[0] || ''}
            onChange={(e: any) => dispatch({ type: 'user', payload: { friends: { ...friends, [0]: e.target.value } } })}
            required
          />
          <TextInput
            placeholder="colleague@other-company.com"
            type="email"
            styles={inputStyle}
            value={friends[1] || ''}
            onChange={(e: any) => dispatch({ type: 'user', payload: { friends: { ...friends, [1]: e.target.value } } })}
            required
          />
          <TextInput
            placeholder="colleague@other-company.com"
            type="email"
            styles={inputStyle}
            value={friends[2] || ''}
            onChange={(e: any) => dispatch({ type: 'user', payload: { friends: { ...friends, [2]: e.target.value } } })}
            required
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.backButton} onClick={() => onClick('back')}>
            back
          </Button>
          <Button className={styles.button} onClick={onSubmit}>
            submit
          </Button>
        </div>
      </form>
    </>
  );
}
