import { useEffect } from 'react';

/**
 * Sets the document title to the specified title and restores it to the
 * global title when the component is unmounted.
 *
 * @param {string} title - The new title for the document.
 * @returns {void}
 */
const useDocumentTitle = (title: string): void => {
  const globalTitle = 'Bloomfilter';

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = globalTitle;
    };
  }, [title]);
};

/**
 * Method to get the date of the last day of the previous month.
 *
 * @returns the date of the last day of the previous month.
 */
const getRecentlyCompletedMonth = (): Date => {
  const currentDate = new Date();
  const firstDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  return new Date(firstDayCurrentMonth.getTime() - 24 * 60 * 60 * 1000);
};

export { getRecentlyCompletedMonth, useDocumentTitle };
