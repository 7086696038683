import { Button, Select, TextInput } from '@mantine/core';
import { Dispatch } from 'react';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import styles from '../setup.module.css';
import { SetupUser } from '../setup.types';

export const TIMEZONES = ['Atlantic', 'Eastern', 'Central', 'Mountain', 'Pacific', 'Alaskan', 'Hawaii-Aleutian'];
export const PROFESSIONS = [
  'Developer Advocate',
  'Engineering Manager',
  'Executive',
  'Product Manager',
  'Product Owner',
  'Project Manager',
  'Scrum Master',
  'Software Developer',
  'Team Lead',
  'Technical Lead',
];

type CreateProfileProps = {
  user: SetupUser;
  dispatch: Dispatch<any>;
  onClick: Function;
};

export function CreateProfile({ user, dispatch, onClick }: CreateProfileProps) {
  const handleChange = (e: any, fieldName: any) => dispatch({ type: 'user', payload: { [fieldName]: e.target.value } });
  const { name = '', phoneNumber = '', timezone = null, profession = null } = user;
  const inputStyle = { input: { height: '3.5em' } };
  return (
    <>
      <form id={styles.formContainer}>
        <div className={styles.formSection}>
          <TextInput
            autoFocus
            placeholder="First Name Last Name"
            value={name}
            onChange={(e: any) => handleChange(e, 'name')}
            styles={inputStyle}
            label="What&#39;s your name?"
            required
          />
        </div>
        <div className={styles.formSection}>
          <TextInput
            type="tel"
            placeholder="(555) 555-5555"
            value={phoneNumber}
            onChange={(e: any) => handleChange(e, 'phoneNumber')}
            styles={inputStyle}
            label="Phone number (optional)"
          />
        </div>
        <div className={styles.formSection}>
          <Select
            label="What time zone are you in?"
            required
            placeholder="Choose one"
            data={TIMEZONES.map((timezone) => ({ label: timezone, value: timezone }))}
            value={timezone}
            onChange={(value: any) => dispatch({ type: 'user', payload: { timezone: value } })}
            comboboxProps={{ position: 'bottom', middlewares: { flip: true, shift: true } }}
            styles={inputStyle}
          />
        </div>
        <div className={styles.formSection}>
          <Select
            label="What do you do?"
            required
            placeholder="Choose one that best fits your role"
            data={PROFESSIONS.map((profession) => ({ label: profession, value: profession }))}
            value={profession}
            onChange={(value: any) => dispatch({ type: 'user', payload: { profession: value } })}
            comboboxProps={{ position: 'bottom', middlewares: { flip: true, shift: true } }}
            styles={inputStyle}
          />
        </div>
        <div className={styles.formSection}>
          <Button
            disabled={!name || !timezone || !profession}
            className={styles.button}
            onClick={() => {
              trackEvent(AnalyticsEventType.CreateProfileTapped, { userContext: user });
              onClick('next');
            }}
          >
            next
          </Button>
        </div>
      </form>
    </>
  );
}
