import { QueryObserverResult, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Initiative } from '../initiative-client/initiative-client.type';
import { ProcessAnalysisInitiativeChartData } from '../portfolio-client/portfolio-client.type';
import {
  fetchProject,
  fetchProjectBoardSprints,
  fetchProjects,
  getInitiatives,
  getInitiativesCompletion,
  getInitiativesFocus,
} from './projects-client';
import { Project, ProjectBoardSprints, ProjectsResponse, SubProjectSprints } from './projects-client.type';

/**
 * Query manager for fetching a project by its ID
 *
 * @param projectId project id to fetch
 * @param options UseQueryOptions
 * @returns a object containing the project and the query manager
 */
function useProject(
  { projectId }: { projectId: string | undefined },
  options?: UseQueryOptions<Project>
): {
  project: Project | undefined;
  query: QueryObserverResult<Project>;
} {
  const query = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => (projectId ? fetchProject(projectId) : Promise.reject('Unable to fetch project without an ID')),
    ...options,
  });

  return { project: query.data, query };
}

/**
 * Query manager for fetching a list of projects for the current user.
 *
 * @param orgId organization Id in case we wanna filter projects per org
 * @param options UseQueryOptions
 * @returns ProjectsResponse[]
 */
const useProjects = (
  orgId?: string | null,
  options?: UseQueryOptions<ProjectsResponse[]>
): {
  projects: ProjectsResponse[] | undefined;
  query: QueryObserverResult<ProjectsResponse[]>;
} => {
  const query = useQuery({
    queryKey: ['projects'],
    queryFn: () => (orgId ? fetchProjects(orgId) : fetchProjects()),
    ...options,
  });

  return { projects: query.data, query };
};

/**
 * Query manager for fetching a list of initiatives for the given portfolio
 *
 * @param projectId project id to fetch initiative completions for
 * @param options UseQueryOptions
 * @returns a object of initiatives along with the query manager
 */
const useProjectInitiatives = (
  projectId: string,
  options?: UseQueryOptions<Initiative[]>
): {
  initiatives: Initiative[] | undefined;
  query: QueryObserverResult<Initiative[]>;
} => {
  const query = useQuery({
    queryKey: ['projectInitiatives', projectId],
    queryFn: () => getInitiatives(projectId),
    ...options,
  });

  return { initiatives: query.data, query };
};

/**
 * Query manager for fetching a list of initiative completions for the given portfolio
 *
 * @param projectId project id to fetch initiative completions for
 * @param startDate start date to filter the initiative completions
 * @param endDate end date to filter the initiative completions
 * @param options UseQueryOptions
 * @returns a object of initiative completions along with the query manager
 */
const useProjectInitiativesCompletions = (
  projectId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisInitiativeChartData>
): {
  initiativeCompletions: ProcessAnalysisInitiativeChartData | undefined;
  query: QueryObserverResult<ProcessAnalysisInitiativeChartData>;
} => {
  const query = useQuery({
    queryKey: ['projectInitiativesCompletions', projectId, startDate, endDate],
    queryFn: () => getInitiativesCompletion(projectId, startDate, endDate),
    ...options,
  });

  return { initiativeCompletions: query.data, query };
};

/**
 * Query manager for fetching a list of initiative focus for the given portfolio
 *
 * @param projectId project id to fetch initiative focus for
 * @param startDate start date to filter the initiative focus
 * @param endDate end date to filter the initiative focus
 * @param options UseQueryOptions
 * @returns a object of initiative focus along with the query manager
 */
const useProjectInitiativesFocus = (
  projectId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisInitiativeChartData>
): {
  initiativeFocus: ProcessAnalysisInitiativeChartData | undefined;
  query: QueryObserverResult<ProcessAnalysisInitiativeChartData>;
} => {
  const query = useQuery({
    queryKey: ['projectInitiativesFocus', projectId, startDate, endDate],
    queryFn: () => getInitiativesFocus(projectId, startDate, endDate),
    ...options,
  });

  return { initiativeFocus: query.data, query };
};

/**
 * Query manager for fetching an object that lists the sprints by subproject for the given project
 *
 * @param projectId - project id to fetch board sprints for
 * @param options - UseQueryOptions
 * @returns an object containing the subproject sprints and the query manager
 */
function useBoardSprints(
  projectId: string | undefined,
  options?: UseQueryOptions<ProjectBoardSprints>
): { subproject_sprints: SubProjectSprints | undefined; query: QueryObserverResult<ProjectBoardSprints> } {
  const query = useQuery({
    queryKey: [`projectBoardSprints-${projectId}`],
    queryFn: () =>
      projectId
        ? fetchProjectBoardSprints(projectId)
        : Promise.reject('Unable to fetch board sprints without a project ID.'),
    ...options,
  });

  return { subproject_sprints: query.data?.subproject_sprints, query };
}

export {
  useBoardSprints,
  useProject,
  useProjectInitiatives,
  useProjectInitiativesCompletions,
  useProjectInitiativesFocus,
  useProjects,
};
