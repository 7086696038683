import { Divider, Flex } from '@mantine/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { completeHandshake, completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { UserContext } from '../../../contexts/user';
import { StepProps } from '../integrations.type';
import { SuccessView } from '../views/success-view';
import styles from '../views/views.module.css';
import { ConfluenceResponse, ConfluenceState } from './confluence.type';
import { Spaces } from './spaces';
import { Summary } from './summary';

const initialState: ConfluenceState = {
  spaceOptions: null,
  selectedSpaces: null,
};

export const Confluence = ({ activeStep = 0, setActiveStep }: StepProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const [confluenceState, setConfluenceState] = useState(initialState);

  const updateState = useCallback((update: Partial<ConfluenceState>) => {
    setConfluenceState((prevState) => Object.assign({}, prevState, update));
  }, []);

  useEffect(() => {
    const code = searchParams.get('code');
    const authState = searchParams.get('state');
    const userId = user?.id.toString();
    const orgId = user?.organizations[0].toString();

    if (code && authState && userId && orgId) {
      updateState({ code, authState, userId, orgId });
      searchParams.delete('code');
      searchParams.delete('state');
      setSearchParams(searchParams);
    }
  }, [searchParams, user, updateState, setSearchParams]);

  useEffect(() => {
    if (
      !confluenceState.spaceOptions &&
      confluenceState.userId &&
      confluenceState.orgId &&
      confluenceState.code &&
      confluenceState.authState
    ) {
      completeOauth(
        confluenceState.userId,
        confluenceState.orgId,
        confluenceState.code,
        ExternalService.Confluence,
        confluenceState.authState
      ).then((data: ConfluenceResponse) => {
        updateState({ spaceOptions: data.spaces });
      });
    }
  }, [
    confluenceState.userId,
    confluenceState.orgId,
    confluenceState.code,
    confluenceState.authState,
    confluenceState.spaceOptions,
    updateState,
  ]);

  const views = [
    <Spaces confluenceState={confluenceState} updateState={updateState} key="spaces" />,
    <Summary confluenceState={confluenceState} setActiveStep={setActiveStep} key="summary" />,
    <SuccessView key="success" />,
  ];

  const finalizeIntegration = () => {
    const orgId = user?.organizations[0]?.toString();
    if (orgId && (confluenceState.selectedSpaces || []).length > 0) {
      completeHandshake('confluence', {
        org_id: orgId,
        spaces: confluenceState.selectedSpaces,
      }).then(() => {
        nextStep();
      });
    }
  };

  const isNextButtonDisabled = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (confluenceState.selectedSpaces || []).length === 0;
      default:
        return false;
    }
  };

  const nextStep = () => setActiveStep((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => setActiveStep((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      <BrandedLoadingOverlay visible={!confluenceState.spaceOptions} />
      {views[activeStep]}
      <Divider my="sm" />
      <div id={styles.backNextContainer}>
        {activeStep < 2 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {activeStep !== 0 ? (
              <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                Back
              </Button>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isNextButtonDisabled(activeStep)}
                onClick={activeStep < 1 ? nextStep : finalizeIntegration}
                size="lg"
              >
                {activeStep < 2 ? 'Next' : "Let's go!"}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Flex>
  );
};
