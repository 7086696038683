import { Radio } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { Dispatch } from 'react';
import { fetchProjects } from '../../../api/projects-client/projects-client';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { UserInfo } from '../../../api/user-client/user-client.type';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import styles from '../views/views.module.css';
import { GithubReducerAction, GithubState } from './github.type';

type ProjectMappingsProps = {
  state: GithubState;
  dispatch: Dispatch<GithubReducerAction>;
  user: UserInfo | null;
};

/** The view for selecting project<->repo mapping during the github itegration workflow.
 *
 * @param props: { state: GithubState, dispatch: Dispatch<GithubReducerAction> }
 * @returns JSX.Element
 */
export function ProjectMappings({ state, dispatch }: ProjectMappingsProps) {
  const { projects, options } = state;
  const orgId = useOrganizationId();

  useQuery(['projects'], orgId ? () => fetchProjects(orgId) : () => fetchProjects(), {
    enabled: !!projects,
    onSuccess: (data: ProjectsResponse[]) => {
      dispatch({ type: 'projects', payload: data });
    },
  });

  const handleChange = (value: string) => {
    const selectedObject = (projects || []).find((obj) => obj.id === value);
    const selectedId = selectedObject ? selectedObject.id : null;

    dispatch({ type: 'options', payload: { selectedProject: selectedId } });
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select the project that you want to associate the repository with:</h4>
      <div id={styles.grid}>
        {(projects || []).map((project: ProjectsResponse) => (
          <div
            key={project.name}
            className={
              project.id === options.selectedProject
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleChange(project.id)}
          >
            <Radio
              color="red.5"
              styles={{ label: { fontWeight: 600, marginLeft: '30px' } }}
              value={project.name}
              checked={project.id === options.selectedProject}
              onChange={() => handleChange(project.id)}
            />
            <span className={styles.checkboxLabel}>{project.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
