import { createContext, ReactNode, useState } from 'react';
import { Section, SectionConfiguration, StrategyContextType } from './strategy.context.type';

export const StrategyContext = createContext({} as StrategyContextType);

export const StrategyProvider = ({ children }: { children: ReactNode }) => {
  const [sectionConfig, setSectionConfig] = useState<SectionConfiguration>({
    [Section.ActiveInitiatives]: true,
    [Section.InactiveInitiatives]: false,
  });

  const toggleSection = (section: Section) => {
    setSectionConfig({ ...sectionConfig, [section]: !sectionConfig[section] });
  };

  return <StrategyContext.Provider value={{ sectionConfig, toggleSection }}>{children}</StrategyContext.Provider>;
};
