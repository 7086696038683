import { useState } from 'react';
import { newCOLORS } from '../../styles/colors';
import styles from './project-health-bar.module.css';
import { HealthBarStyle } from './project-health-bar.type';

type ProjectHealthBarProps = {
  health: number;
  percentage?: number;
  showNumber?: boolean;
  style: HealthBarStyle;
  explicitColor?: string;
  customUnitDescription?: string;
  shouldAnimate?: boolean;
};

export function ProjectHealthBar(props: ProjectHealthBarProps) {
  const getLabelStyles = () => {
    switch (props.style) {
      case HealthBarStyle.small:
        return styles.labelStyles;
      case HealthBarStyle.medium:
        return [styles.labelStyles, styles.mediumLabelStyles].join(' ');
      case HealthBarStyle.large:
        return [styles.largeLabelStyles, styles.largeStyles, styles.labelStyles].join(' ');
    }
  };

  const getHeightStyles = () => {
    switch (props.style) {
      case HealthBarStyle.squared:
        return styles.squaredStyles;
      case HealthBarStyle.vertical:
        return styles.verticalStyles;
      case HealthBarStyle.small:
        return styles.smallStyles;
      case HealthBarStyle.medium:
        return styles.mediumStyles;
      case HealthBarStyle.large:
        return styles.largeStyles;
    }
  };

  const usablePercentage = props.percentage || props.health;
  const [width, setWidth] = useState(props.shouldAnimate ? 100 : usablePercentage);

  if (usablePercentage != width) {
    setWidth(usablePercentage);
  }

  const unit = props.customUnitDescription || '%';

  const usableFillColor = () => {
    if (props.health === -1) {
      return undefined;
    } else if (props.explicitColor) {
      return props.explicitColor;
    } else if (props.health < 50) {
      return newCOLORS.red;
    } else if (props.health < 75) {
      return newCOLORS.darkYellow;
    } else {
      return newCOLORS.green;
    }
  };

  const fillerStyles = {
    height: '100%',
    width: `${width}%`,
    minWidth: '5%',
    backgroundColor: usableFillColor(),
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    textAlign: 'right' as 'right',
    transition: 'width 1s ease-in-out',
  };

  return (
    <div>
      <div className={[getHeightStyles(), styles.containerStyles].join(' ')}>
        <div style={fillerStyles} className={getHeightStyles()}>
          {props.showNumber && <span className={getLabelStyles()}>{`${props.health}${unit}`}</span>}
        </div>
      </div>
    </div>
  );
}
