import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { Initiative, InitiativeSource } from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { iconsIntegrations } from '../../integrations/assets';

dayjs.extend(isBetween);
dayjs.extend(utc);

/**
 * Checks if the current date is between the start and end dates of an initiative.
 *
 * @param {Initiative} start_date - The start date of the initiative.
 * @param {Initiative} end_date - The end date of the initiative.
 * @return {boolean} Returns true if the current date is between the start and end dates, otherwise false.
 */
const isActiveInitiative = ({ start_date, end_date }: Initiative): boolean => {
  const localStartDate = toLocalDate(start_date);
  const localEndDate = toLocalDate(end_date);

  return dayjs().isBetween(localStartDate.startOf('day'), localEndDate.endOf('day'));
};

/**
 * Returns the icon corresponding to the provided source.
 *
 * @param {InitiativeSource} source - The source of the initiative.
 * @return {string} The icon corresponding to the provided source.
 */
const getSourceIcon = (source: InitiativeSource): string => {
  const sourceIcons = {
    [InitiativeSource.Bloomfilter]: icons.iconBloomfilter,
    [InitiativeSource.Jira]: iconsIntegrations.iconJiraCloud,
    [InitiativeSource.JDC]: iconsIntegrations.iconJiraDataCenter,
    [InitiativeSource.ADO]: iconsIntegrations.iconADO,
    [InitiativeSource.Github]: icons.iconGithub,
  };

  return sourceIcons[source];
};

/**
 * Returns the label corresponding to the provided source.
 *
 * @param {InitiativeSource} source - The source of the initiative.
 * @return {string} The label corresponding to the provided source.
 */
const getSourceLabel = (source: InitiativeSource): string => {
  const sourceLabels = {
    [InitiativeSource.Bloomfilter]: 'Bloomfilter',
    [InitiativeSource.Jira]: 'Atlassian Jira Software Cloud',
    [InitiativeSource.JDC]: 'Atlassian Jira Software Data Center',
    [InitiativeSource.ADO]: 'Microsoft Azure',
    [InitiativeSource.Github]: 'GitHub',
  };

  return sourceLabels[source];
};

/**
 * Converts a date string to a localized dayjs object.
 *
 * @param {string | undefined} date - The date string to convert.
 * @return {dayjs.Dayjs} The localized dayjs object.
 */
const toLocalDate = (date: string | undefined): dayjs.Dayjs => {
  return dayjs(date).utc(true).local();
};

export { getSourceIcon, getSourceLabel, isActiveInitiative, toLocalDate };
