import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import React, { forwardRef } from 'react';
import { newCOLORS } from '../styles/colors';

type DownloadPNGButtonProps = {
  handleDownload: () => void;
  exceptionRef: React.RefObject<HTMLDivElement>;
};

export const DownloadPNGButton = forwardRef<HTMLDivElement, DownloadPNGButtonProps>((props, ref) => (
  <StyledButton onClick={props.handleDownload} ref={ref}>
    <Icon
      icon="humbleicons:download"
      color={newCOLORS.darkGray}
      style={{ cursor: 'pointer', width: '36px', height: '36px', margin: '0 8px' }}
    />
  </StyledButton>
));

const StyledButton = styled(
  forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => <div {...props} ref={ref} />)
)`
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
`;
