import { newCOLORS } from '../../../../styles/colors';
import { InitiativeMetricType, LegendItem } from '../initiative-performance.type';

/**
 * Returns an array of legend items based on the given metric type.
 *
 * @param {InitiativeMetricType} metric - The type of metric to generate legend items for.
 * @return {LegendItem[]} An array of legend items with titles, descriptions, and colors.
 */
const getLegendItems = (metric: InitiativeMetricType): LegendItem[] => {
  const metricLabels = {
    [InitiativeMetricType.Tasks]: 'Completed tasks',
    [InitiativeMetricType.Points]: 'Completed points',
    [InitiativeMetricType.Tasks_Added]: 'Tasks added',
    [InitiativeMetricType.Bugs]: 'Bugs',
  };

  return [
    {
      title: `Total ${metric === InitiativeMetricType.Points ? 'points' : 'tasks'}`,
      description: 'cumulative for initiative',
      color: newCOLORS.blue,
    },
    {
      title: metricLabels[metric],
      description: 'cumulative for initiative',
      color: newCOLORS.green,
    },
  ];
};

export { getLegendItems };
