import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ViewByType } from '../../components/burn-flow/burn-flow-chart.type';
import { fetchHistoricalBurnsData, fetchProjectBoardSprints } from '../projects-client/projects-client';
import { HistoricalBurnsResult, ProjectBoardSprints } from '../projects-client/projects-client.type';
import {
  fetchSprint,
  fetchSprintBurnsAndFlows,
  fetchSprintMetrics,
  fetchSprintProjections,
  fetchSubprojectSprints,
} from './sprints-client';
import { Sprint, SprintAssessmentData, SubprojectSprints } from './sprints-client.type';

/**
 * Get the most recent 16 sprints for the subproject
 *
 * @param {string} subprojectId - the ID of the subproject
 * @return {SubprojectSprints} - the most recent 16 sprints for the subproject
 */
function useSubprojectSprints(subprojectId: string | null): SubprojectSprints {
  const query = useQuery({
    queryKey: ['subproject-sprints', subprojectId],
    queryFn: () =>
      subprojectId
        ? fetchSubprojectSprints(subprojectId)
        : Promise.reject('Cannot get sprints for a subproject without an ID'),
  });
  const sprints = query.data?.sprints || [];
  return { sprints };
}

/**
 * Get the sprint by its ID
 *
 * @param {string} sprintId - the ID of the sprint
 * @param {UseQueryOptions<Sprint | undefined>} options - the query options
 * @returns @returns an object containing the project and the query manager
 */
function useSprint(
  { sprintId }: { sprintId: string | null },
  options?: UseQueryOptions<Sprint>
): {
  sprint: Sprint | undefined;
  query: QueryObserverResult<Sprint>;
} {
  const query = useQuery({
    queryKey: ['sprint', sprintId],
    queryFn: () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to fetch sprint without an ID')),
    ...options,
  });
  return { sprint: query.data, query };
}

/**
 * Retrieves sprint assessment data including burns and flows, metrics, and projections based on the provided sprintId.
 *
 * @param {string | null} sprintId - The ID of the sprint
 * @param {UseQueryOptions} [options] - The query options
 * @return {{ data: SprintAssessmentData; isFetching: boolean }} An object containing the sprint assessment data and fetch status
 */
function useSprintAssessment(
  { sprintId }: { sprintId: string | null },
  options?: UseQueryOptions
): { data: SprintAssessmentData; isFetching: boolean } {
  const burnsQuery = useQuery({
    queryKey: ['sprint-burns', sprintId],
    queryFn: () => (sprintId ? fetchSprintBurnsAndFlows(sprintId) : Promise.reject('SprintId is required')),
    ...options,
  });

  const metricsQuery = useQuery({
    queryKey: ['sprint-metrics', sprintId],
    queryFn: () => (sprintId ? fetchSprintMetrics(sprintId) : Promise.reject('SprintId is required')),
    ...options,
  });

  const projectionsQuery = useQuery({
    queryKey: ['sprint-projections', sprintId],
    queryFn: () => (sprintId ? fetchSprintProjections(sprintId) : Promise.reject('SprintId is required')),
    ...options,
  });

  const data = {
    burns: burnsQuery.data,
    metrics: metricsQuery.data,
    projections: projectionsQuery.data,
  } as SprintAssessmentData;

  const isFetching = burnsQuery.isFetching || metricsQuery.isFetching || projectionsQuery.isFetching;

  return { data, isFetching };
}

/**
 * Retrieves historical burns data based on the provided sprint and view.
 *
 * @param {Sprint | null} sprint - The sprint for which historical burns data is requested.
 * @param {ViewByType} view - The type of view for the historical burns data.
 * @param {UseQueryOptions<HistoricalBurnsResult>} [options] - Additional options for the query.
 * @return {data: HistoricalBurnsResult | undefined, query: QueryObserverResult<HistoricalBurnsResult>} The historical burns data and query result.
 */
function useHistoricalBurns(
  { sprint, view }: { sprint: Sprint | null; view: ViewByType },
  options?: UseQueryOptions<HistoricalBurnsResult>
): { data: HistoricalBurnsResult | undefined; query: QueryObserverResult<HistoricalBurnsResult> } {
  const query = useQuery({
    queryKey: ['historical-burns', sprint?.project_id, sprint?.subproject_id, view],
    queryFn: () =>
      sprint
        ? fetchHistoricalBurnsData(sprint?.project_id, sprint?.subproject_id, view)
        : Promise.reject('Sprint is required'),
    ...options,
  });

  return { data: query.data, query };
}

/**
 * Retrieves project board sprints based on the provided project ID.
 *
 * @param {string | undefined} projectId - The ID of the project to fetch board sprints for.
 * @param {UseQueryOptions<ProjectBoardSprints>} [options] - Additional options for the query.
 * @return {data: ProjectBoardSprints | undefined; query: QueryObserverResult<ProjectBoardSprints>} The project board sprints data and query result.
 */
function useProjectBoardSprints(
  projectId: string | undefined,
  options?: UseQueryOptions<ProjectBoardSprints>
): { data: ProjectBoardSprints | undefined; query: QueryObserverResult<ProjectBoardSprints> } {
  const query = useQuery({
    queryKey: ['project-board-sprints', projectId],
    queryFn: () => (projectId ? fetchProjectBoardSprints(projectId) : Promise.reject('Unable to fetch board sprints')),
    ...options,
  });

  return { data: query.data, query };
}

export { useHistoricalBurns, useProjectBoardSprints, useSprint, useSprintAssessment, useSubprojectSprints };
