import { Button } from '@mantine/core';
import { Brand } from '../../../components/brand/brand';
import { newCOLORS } from '../../../styles/colors';
import styles from '../sign-in.module.css';

export function SentResetPasswordLinkView({
  email,
  handleChangeView,
}: {
  email: string;
  handleChangeView: () => void;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '3em' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Brand style={{ minWidth: '60%' }} darkMode={true} clickable={false} />
        <h3 style={{ textAlign: 'center', color: newCOLORS.white }}>Thanks! Keep an eye on your inbox.</h3>
      </div>
      <div id={styles.formContainer}>
        <p>
          If an account exists for <b>{`${email}`}</b>, you will receive an email with instructions on how to set a new
          password.
        </p>
        <p>Didn&#39;t get our email? Check your spam folder or resend.</p>
        <Button className={styles.button} onClick={handleChangeView}>
          Return to Sign In
        </Button>
      </div>
    </div>
  );
}
