import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../views/views.module.css';
import { FigmaState } from './figma.type';

type TeamsProps = {
  updateState: (update: Partial<FigmaState>) => void;
};

export const Teams = ({ updateState }: TeamsProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);

  const teamEntered = (text: string) => {
    updateState({
      teamOptions: [text],
      selectedTeam: text.split('/').reverse()[0],
    });
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Enter the link of the Team you would like to integrate with:</h4>
      <ol>
        <li>
          Navigate to{' '}
          <a href="https://www.figma.com/" target="_blank">
            Figma
          </a>
          .
        </li>
        <li>
          Below <b>Teams</b>, select the team you would like to integrate.
        </li>
        <li>Click the chevron pointing down found to the right of the team name.</li>
        <li>
          In the menu, select <code>Copy Link</code>.
        </li>
      </ol>
      <h4></h4>

      <div>
        <input type="text" onChange={(e: React.ChangeEvent<HTMLInputElement>) => teamEntered(e.target.value)} />
      </div>
    </div>
  );
};
