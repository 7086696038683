import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse } from '@mantine/core';
import { useContext } from 'react';
import { Initiative } from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { newCOLORS } from '../../../styles/colors';
import { StrategyContext } from '../context/strategy.context';
import { Section } from '../context/strategy.context.type';
import { isActiveInitiative } from './initiatives-list.helpers';
import { InitiativesTable } from './initiatives-table';

type Props = {
  initiatives: Initiative[];
  portfolioId: string;
};

export function InitiativesList({ initiatives, portfolioId }: Props) {
  return (
    <PageContainer>
      <CollapsibleSection
        id={Section.ActiveInitiatives}
        title="Active Initiatives"
        initiatives={initiatives.filter((initiative) => isActiveInitiative(initiative))}
        portfolioId={portfolioId}
      />
      <CollapsibleSection
        id={Section.InactiveInitiatives}
        title="Inactive Initiatives"
        initiatives={initiatives.filter((initiative) => !isActiveInitiative(initiative))}
        portfolioId={portfolioId}
      />
    </PageContainer>
  );
}

const CollapsibleSection = ({ id, title, initiatives, portfolioId }: Props & { id: Section; title: string }) => {
  const { sectionConfig, toggleSection } = useContext(StrategyContext);
  const opened = sectionConfig[id];

  const collapsibleIcon = opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one';
  const titleIcon = id === Section.InactiveInitiatives ? icons.iconCompassGrey : icons.iconCompass;

  return (
    <SectionContainer>
      <HeaderTitle onClick={() => toggleSection(id)}>
        <CollapseIcon>
          <Icon
            icon={collapsibleIcon}
            width={16}
            height={16}
            color={newCOLORS.indigo}
            style={{ pointerEvents: 'none' }}
          />
        </CollapseIcon>
        <img src={titleIcon} alt={title} width={24} height={24} />
        <HeaderTitleText>{title}</HeaderTitleText>
        <HeaderTitleCount>({initiatives.length})</HeaderTitleCount>
      </HeaderTitle>
      <Collapse in={opened} style={{ width: '100%' }}>
        <InitiativesTable initiatives={initiatives} portfolioId={portfolioId} />
      </Collapse>
    </SectionContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  margin-top: 8px;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background-color: ${newCOLORS.white};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  user-select: none;
`;

const HeaderText = styled.span`
  font-family: Figtree;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const HeaderTitleText = styled(HeaderText)`
  color: ${newCOLORS.darkerGray};
`;

const HeaderTitleCount = styled(HeaderText)`
  color: ${newCOLORS.darkGray};
`;
