import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { Team } from '../api/projects-client/projects-client.type';
import { useGlobalStore } from '../store/global-store/global-store';
import { setOrganization, setPortfolio } from '../store/global-store/global-store.actions';
import { cachePortfolio as cachePortfolioHelper, getCachedPortfolioId, invalidatePortfolio } from './scope.helpers';
import { OrganizationContextType, PortfolioContextType, TeamContextType } from './scope.type';

export const OrganizationContext = createContext({} as OrganizationContextType);

export const OrganizationProvider = ({ children }: { children: ReactNode }) => {
  const organization = useGlobalStore((state) => state.organization);

  return (
    <OrganizationContext.Provider value={{ organization, setOrganization }}>{children}</OrganizationContext.Provider>
  );
};

export const PortfolioContext = createContext({} as PortfolioContextType);

export const PortfolioProvider = ({ children }: { children: ReactNode }) => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const portfolios = useGlobalStore((state) => state.portfolios);

  const cachePortfolio = useCallback(() => {
    if (portfolio) {
      cachePortfolioHelper(portfolio.id);
    }
  }, [portfolio]);

  useEffect(() => {
    // This if clause is a special case for the redirect to external system flow where we need to
    // remember the selected portfolio before the redirect and restore it after the redirect.
    const cachedPortfolioId = getCachedPortfolioId();

    if (cachedPortfolioId && portfolios.length) {
      const cachedPortfolio = portfolios.find((p) => p.id === cachedPortfolioId) || null;
      setOrganization(cachedPortfolio?.organization.name || null);
      setPortfolio(cachedPortfolio);

      // Remove portfolio from local storage, so that it won't overwrite portfolio in a context
      // on subsequent page refreshes
      invalidatePortfolio();
    }
  }, [portfolio, portfolios]);

  return (
    <PortfolioContext.Provider value={{ portfolio, setPortfolio, cachePortfolio }}>
      {children}
    </PortfolioContext.Provider>
  );
};

export const TeamContext = createContext({} as TeamContextType);

export const TeamProvider = ({ children }: { children: ReactNode }) => {
  const [team, setTeam] = useState<Team | null>(null);

  return <TeamContext.Provider value={{ team, setTeam }}>{children}</TeamContext.Provider>;
};
