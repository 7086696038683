import { styled } from '@linaria/react';
import { SubtitleHeadingTag } from '../../../styles/shared-styled-components';

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const HeadingTitle = styled.div`
  display: flex;
  padding-left: 6px;
`;

const HeadingTitleText = styled(SubtitleHeadingTag)`
  padding: 12px 12px 12px 6px;
  color: #212529;
  font-family: Figtree;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 40px;
`;

export { CollapseIcon, ContentContainer, HeadingContainer, HeadingTitle, HeadingTitleText, SectionContainer };
