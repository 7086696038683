import { styled } from '@linaria/react';
import { IssueDescribable } from '../../api/issues-client/issues-client.type';
import { newCOLORS } from '../../styles/colors';

type IssueCardProps = {
  issue: IssueDescribable;
  onClick: (issue: IssueDescribable) => void;
};

type ColorsDict = {
  [key: string]: string;
};

/**
 * Based on the trend value, this function returns the border color
 * @param trend : string value that contains values as positive,negative or neutral based on the matrix in BLOOM-1154
 * @param colors : a dictionary that contains different colors and it's associated hexcode
 * @returns border color based on the trend value
 */
const getBorderColor = (trend: string, colors: ColorsDict): string => {
  switch (trend) {
    case 'positive':
      return colors.green;
    case 'negative':
      return colors.red;
    case 'neutral':
      return colors.yellow;
    default:
      return '';
  }
};

export function IssueCard(props: IssueCardProps) {
  const handleIssueClick = () => {
    props.onClick(props.issue);
  };

  return (
    <IssueCardContainer trend={props?.issue?.trend} onClick={handleIssueClick}>
      <IssueCardAmount>{props?.issue?.amount}</IssueCardAmount>
      <IssueTextContainer>
        <IssueCardHeading>{props?.issue?.title}</IssueCardHeading>
        <IssueCardSubHeading>{props?.issue?.summary}</IssueCardSubHeading>
      </IssueTextContainer>
    </IssueCardContainer>
  );
}

const IssueCardContainer = styled.div<{ trend: string }>`
  position: relative;
  padding: 10px;
  background-color: ${newCOLORS.white};
  border-radius: 5px;
  display: flex;
  text-align: center;
  flex-direction: column;
  min-height: 150px;
  min-width: 117px;
  justify-content: center;
  align-items: center;
  border: 3px solid ${(props) => getBorderColor(props.trend, newCOLORS)};
`;

const IssueCardAmount = styled.div`
  margin: 0;
  padding-right: 15px;
  padding-left: 5px;
  padding-bottom: 10px;
  font-family: 'Figtree-bold';
  font-size: xxx-large;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  align-items: center;
`;

const IssueTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// shared style for text
const IssueCardText = styled.div`
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const IssueCardHeading = styled(IssueCardText)`
  font-size: medium;
  font-weight: 900;
`;

const IssueCardSubHeading = styled(IssueCardText)`
  font-size: small;
  font-weight: 200;
  padding-top: 5px;
`;
