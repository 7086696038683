import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { FileUploadResult } from './files-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Posts an Excel file to the Files API
 * @returns FileUploadResult
 */
const uploadFile = async (fileData: File) => {
  const apiURL = baseURL + '/api/files/uploads';
  const formData = new FormData();
  formData.append('file', fileData);
  const fileDisposition = 'attachment; filename="' + fileData.name + '"';

  return await axiosInstance
    .post<FileUploadResult>(apiURL, formData, {
      headers: {
        'Content-Disposition': fileDisposition,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

/**
 * Downloads a file with the given id
 * @param {string} id - The id of the file to download
 * @param {string} filename - The name of the file to download
 * @returns {Promise<Blob>} - A promise that resolves to the downloaded file data as a Blob
 */
async function downloadFile(id: string, filename?: string): Promise<Blob> {
  const apiURL = `${baseURL}/api/files/downloads/${id}`;
  try {
    const response = await axiosInstance.get(apiURL, { responseType: 'blob' });

    const a = document.createElement('a');
    const url = window.URL.createObjectURL(response.data);
    a.href = url;
    a.download = filename || id;
    a.click();
    window.URL.revokeObjectURL(url);

    return response.data;
  } catch (error: any) {
    return Promise.reject(error?.response || `Error downloading ${filename}`);
  }
}

export { downloadFile, uploadFile };
