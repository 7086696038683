import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CrumbItem } from '../../../components/breadcrumb/breadcrumb.type';
import { ProjectContext, SidebarContext } from '../../../contexts';
import { onPortfolioBreadcrumbClick } from '../../side-bar/side-bar.helper';
import { makeTitle } from './initiative-tasks.helpers';
import { InitiativeScope } from './initiative-tasks.type';

const useBreadcrumbs = ({ filter, projectId, initiative, epicId, year, month }: InitiativeScope): CrumbItem[] => {
  const { navItems, setNavItems } = useContext(SidebarContext);
  const { setProject } = useContext(ProjectContext);

  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { initiativeId } = useParams<{ initiativeId: string }>();

  const breadcrumbs: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
  ];

  if (filter === 'completed_monthly') {
    // means we need to have a financial context rather than initiative
    breadcrumbs.push({
      labelName: 'Financials',
      href: `/application/financials/portfolio/${portfolioId}`,
    });
  } else {
    // means we need to have an initiative context
    breadcrumbs.push({ labelName: 'Strategy', href: '/application/strategy' });
    breadcrumbs.push({
      labelName: `${initiative ? initiative.name : 'Initiative'}`,
      href: `/application/strategy/${portfolioId}/${initiativeId}`,
    });
  }

  breadcrumbs.push({
    labelName: makeTitle(filter, projectId, initiative, epicId, year, month),
  });

  return breadcrumbs;
};

export { useBreadcrumbs };
