import { styled } from '@linaria/react';
import { Combobox, Flex, Input, InputBase, useCombobox } from '@mantine/core';
import { icons } from './assets';
import { groupingData } from './tasks-table.data';
import { Grouping } from './tasks-table.type';

type Props = {
  grouping: Grouping | null;
  setGrouping: (grouping: Grouping | null) => void;
};

export function GroupingSelect({ grouping, setGrouping }: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const chevron = combobox.dropdownOpened ? icons.iconGroupingSelectArrowUp : icons.iconGroupingSelectArrowDown;

  const selectedGrouping = groupingData.find((item) => item.value === grouping);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        if (value === 'clear') {
          setGrouping(null);
        } else {
          setGrouping(value as Grouping);
        }

        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <StyledInputBase
          component="button"
          type="button"
          pointer
          rightSection={<img src={chevron} width={12} height={12} />}
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedGrouping ? (
            <Placeholder style={{ gap: 8 }}>
              <Label icon={icons.iconGroupingDefault} text="Group by" />
              <Label icon={selectedGrouping.icon} text={selectedGrouping.label} />
            </Placeholder>
          ) : (
            <Placeholder style={{ width: 80 }}>
              <Label icon={icons.iconGroupingDefault} text="Group by" />
            </Placeholder>
          )}
        </StyledInputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options style={{ width: 'auto' }}>
          <Options grouping={grouping} />
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

const Options = ({ grouping }: { grouping: Grouping | null }) => {
  const fullGroupingData = [
    ...groupingData,
    { label: 'Clear grouping', value: 'clear', icon: icons.iconGroupingClose },
  ];

  const data = grouping ? fullGroupingData : groupingData;

  return data.map(({ value, label, icon }) => (
    <StyledComboboxOption
      value={value}
      key={value}
      style={{ borderTop: value === 'clear' ? '1px solid #E2E5E9' : 'none' }}
    >
      <img src={icon} width={16} height={16} />
      <LabelText>{label}</LabelText>
    </StyledComboboxOption>
  ));
};

const Placeholder = ({ style, children }: { style?: React.CSSProperties; children: React.ReactNode }) => (
  <Input.Placeholder component="div" style={{ display: 'flex', alignItems: 'center', ...style }}>
    {children}
  </Input.Placeholder>
);

const Label = ({ icon, text }: { icon: string; text: string }) => (
  <Flex align="center" gap={4}>
    <img src={icon} width={16} height={16} />
    <LabelText>{text}</LabelText>
  </Flex>
);

const StyledInputBase = styled(InputBase)`
  .mantine-Input-input {
    border: none;
    background: #f3f5f6;
  }
`;

const StyledComboboxOption = styled(Combobox.Option)`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  padding: 10px;
`;

const LabelText = styled.div`
  color: #8b99a7;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;
