import arrowCorner from './arrowCorner.svg';
import iconCheckCircle from './iconCheckCircle.svg';
import infoCircle from './iconInfoCircle.svg';
import validationSuccess from './ValidationCheck.svg';
import validationFail from './ValidationFail.svg';

export const icons = {
  arrowCorner,
  iconCheckCircle,
  infoCircle,
  validationSuccess,
  validationFail,
};
