import ReactPlayer from 'react-player/lazy';

import { styled } from '@linaria/react';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { newCOLORS } from '../../styles/colors';
import { ContentTag, HeadingTag } from '../../styles/shared-styled-components';

export const SprintReviewContainer = ({ sprint }: { sprint: Sprint | null }) => {
  return (
    <SprintReviewOuter>
      {sprint && sprint.sprint_review_video_link ? (
        <SprintReviewBox>
          <LargeHeadingTag>Sprint Review</LargeHeadingTag>
          <ReactPlayerContainer>
            <StyledReactPlayer url={sprint.sprint_review_video_link} controls width="100%" height="100%" />
          </ReactPlayerContainer>
        </SprintReviewBox>
      ) : (
        <></>
      )}
    </SprintReviewOuter>
  );
};

const SprintReviewOuter = styled.div`
  text-align: center;
`;

const SprintReviewBox = styled.div`
  text-align: center;
  background-color: ${newCOLORS.white};
  padding: 16px 32px;
`;

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
  padding-bottom: 8px;
`;

const ReactPlayerContainer = styled(ContentTag)`
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
