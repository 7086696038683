import { isString } from '../../helpers/string-helpers/string-helpers';

/**
 * Sets the integration ID for re-authentication in the local storage.
 *
 * @param {string} id - The ID of the integration to be stored
 * @returns {void}
 */
const setIntegrationForReauth = (id: string): void => {
  localStorage.setItem('reauth-integration-active', JSON.stringify(id));
};

/**
 * Retrieves current active integration for re-authentication from local storage, if available.
 *
 * @returns {string | null} The integration for re-authentication, or null if not available.
 */
const getIntegrationForReauth = (): string | null => {
  const cachedValue = localStorage.getItem('reauth-integration-active');

  if (cachedValue) {
    return JSON.parse(cachedValue);
  }

  return null;
};

/**
 * Removes the current active integration for re-authentication from local storage.
 *
 * @returns {void}
 */
const removeIntegrationForReauth = (): void => {
  localStorage.removeItem('reauth-integration-active');
};

/**
 * Completes re-authentication process for the integration.
 *
 * @returns {void}
 */
const completeReauth = (): void => {
  const id = getIntegrationForReauth();

  if (!id) {
    return;
  }

  removeIntegrationForReauth();
  setIntegrationReauthCompleted(id);
};

/**
 * Marks the given integration as re-authentication completed.
 *
 * @param {string} id - The ID of the integration reauthorization
 * @returns {void}
 */
const setIntegrationReauthCompleted = (id: string): void => {
  const ids = getIntegrationsReauthCompleted();

  localStorage.setItem('reauth-integration-completed', JSON.stringify([...ids, id]));
};

/**
 * Checks if the integration re-authentication is completed for the given ID.
 *
 * @param {string} id - The ID to check for re-authentication completion
 * @returns {boolean} Whether the re-authentication is completed for the given ID
 */
const isIntegrationReauthCompleted = (id: string): boolean => {
  const ids = getIntegrationsReauthCompleted();

  return ids.includes(id);
};

/**
 * Retrieves the array of integration IDs that have been completed re-authentication.
 *
 * @returns {string[]} The array of integration IDs.
 */
const getIntegrationsReauthCompleted = (): string[] => {
  const cachedIds = localStorage.getItem('reauth-integration-completed');

  if (cachedIds) {
    return JSON.parse(cachedIds);
  }

  return [];
};

/**
 * A function that parses the redirect value from local storage.
 *
 * @return {string | null} The parsed redirect value or null if not valid.
 */
const parseRedirect = (): string | null => {
  const cachedValue = localStorage.getItem('integrations-redirect');

  if (isString(cachedValue) && isJsonString(cachedValue)) {
    return JSON.parse(cachedValue);
  }

  return cachedValue;
};

/**
 * Checks if the input string is a valid JSON string by attempting to parse it.
 *
 * @param {string} str - The input string to be checked for JSON validity.
 * @return {boolean} true if the input string is a valid JSON string, false otherwise.
 */
const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export {
  completeReauth,
  getIntegrationForReauth,
  getIntegrationsReauthCompleted,
  isIntegrationReauthCompleted,
  isJsonString,
  parseRedirect,
  removeIntegrationForReauth,
  setIntegrationForReauth,
  setIntegrationReauthCompleted,
};
