import axios from 'axios';
import { sendGlobalMessage } from '../../containers/global-message/global-message.helpers';
import { GlobalMessageCategory, GlobalMessageState } from '../../containers/global-message/global-message.type';
import {
  clearAuthenticationStore,
  getRefreshToken,
  getRequestToken,
  storeAuthenticationObject,
} from '../storage/storage';

const AxiosInstance = (options?: Object) => {
  const axiosInstanceObject = axios.create(
    Object.assign(
      {},
      {
        timeout: 360000,
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        withCredentials: true,
      },
      options || {}
    )
  );

  axiosInstanceObject.interceptors.request.use(function (config) {
    const access = getRequestToken();
    if (access !== undefined && config.headers != undefined) {
      config.headers.Authorization = `JWT ${getRequestToken()}`;
    }
    return config;
  });

  axiosInstanceObject.interceptors.response.use(
    (response) => {
      if (response) {
        sendGlobalMessage({
          type: GlobalMessageCategory.API_RESPONSE,
          body: {
            message: 'global messages storage modified',
            globalMessages: response.data?.global_messages || [],
          },
          state: GlobalMessageState.WARNING,
        });
      }
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      // CASE 1: No error specified Most likely to be server error
      if (typeof error.response === 'undefined') {
        return Promise.reject(error);
      }

      // CASE 2: Refresh token is expired. User must login again
      if (error.response.status === 401 && originalRequest.url.includes('/auth/jwt/refresh/')) {
        clearAuthenticationStore();
        window.location.href = '/?token=expired';
        return Promise.reject(error);
      }

      // CASE 3: Access token is expired. We should try to refresh it
      if (
        !originalRequest._retry &&
        !originalRequest.url.includes('/auth/') &&
        (error.response.status === 401 ||
          (error.response.status === 403 &&
            'code' in error.response.data &&
            error.response.data.code === 'token_not_valid'))
      ) {
        originalRequest._retry = true;
        const baseURL = import.meta.env.VITE_APP_API_URL;
        const refreshToken = getRefreshToken();
        const response = await axiosInstanceObject.post(baseURL + '/auth/jwt/refresh/', {
          refresh: refreshToken,
        });

        // CASE 3A: Refresh token is expired. User must login again

        if (response.status === 401) {
          clearAuthenticationStore();
          window.location.href = '/?token=expired';
          return Promise.reject(error);
        }

        if (response.status === 200) {
          storeAuthenticationObject(response.data);
          return axiosInstanceObject(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstanceObject;
};

export default AxiosInstance;
