import { CostVsBudgetChartData } from '../../api/financials-client/financials-client.type';

const enum CostsVsOutputChartItemLabels {
  cost = 'Cost',
  costTrend = 'CostTrend',
  dailyBurn = 'DailyBurn',
  dailyBurnTrend = 'DailyBurnTrend',
  throughPut = 'ThroughPut',
  throughPutTrend = 'ThroughPutTrend',
  tasksCommited = 'TasksCommited',
  tasksCommitedTrend = 'TasksCommitedTrend',
  costPerTask = 'CostPerTask',
  costPerTaskTrend = 'CostPerTaskTrend',
  velocity = 'Velocity',
  velocityTrend = 'VelocityTrend',
  pointsCommitted = 'PointsCommitted',
  pointsCommittedTrend = 'PointsCommittedTrend',
  costPerPoint = 'CostPerPoint',
  costPerPointTrend = 'CostPerPointTrend',
}

export { CostsVsOutputChartItemLabels };

type ShowCostsVsOutputChartLines = {
  showCost: boolean;
  showCostTrend: boolean;
  showDailyBurn: boolean;
  showDailyBurnTrend: boolean;
  showThroughPut: boolean;
  showThroughPutTrend: boolean;
  showTasksCommited: boolean;
  showTasksCommitedTrend: boolean;
  showCostPerTask: boolean;
  showCostPerTaskTrend: boolean;
  showVelocity: boolean;
  showVelocityTrend: boolean;
  showPointsCommitted: boolean;
  showPointsCommittedTrend: boolean;
  showCostPerPoint: boolean;
  showCostPerPointTrend: boolean;
};
type Position = 'left' | 'right' | 'center' | 'top' | 'bottom';

type SanitizedData = {
  labels: string[];
  cost: number[];
  dailyBurn: number[];
  costPerTask: number[];
  costPerPoint: number[];
  throughput: number[];
  tasksComitted: number[];
  velocity: number[];
  pointsCommitted: number[];
};

type CostsVsOutputChartProps = {
  showCostsVsOutputChartLines: ShowCostsVsOutputChartLines;
  chartData: CostVsBudgetChartData[] | null;
};

export type { CostsVsOutputChartProps, Position, SanitizedData, ShowCostsVsOutputChartLines };
