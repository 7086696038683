import { styled } from '@linaria/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { MenuDrivenContainer } from '../../containers/menu-driven-container/menu-driven-container';
import { TasksTable } from '../../containers/tasks-table/tasks-table';
import { TaskRecord } from '../../containers/tasks-table/tasks-table.type';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { BreadcrumbContainer, TitleHeadingTag } from '../../styles/shared-styled-components';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { BrandedLoadingOverlay } from '../loader/branded-loader';
import { PageHeaderMessage } from '../page-header-message/page-header-message';
import { formatTasks, makeTitle, useFinancialsTasksScope } from './financials-tasks.helpers';
import { useAllocationOfCostsTasks, useBreadcrumbs, useCostVsOutputTasks } from './financials-tasks.hooks';

export const FinancialsTasks = () => {
  useDocumentTitle('Task Details - Bloomfilter');
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');

  const useTasksQuery = measure === 'cost_allocation' ? useAllocationOfCostsTasks : useCostVsOutputTasks;

  const scope = useFinancialsTasksScope(portfolioId);
  const { projectId, date, factor, factorOption, startDate, endDate } = scope;
  const breadcrumbs = useBreadcrumbs(scope);

  const { data: tasksQueryData = [], query: tasksQuery } = useTasksQuery(
    { portfolioId, projectId, date, factor, factorOption, startDate, endDate },
    {
      enabled: !!(portfolioId && startDate && endDate && date && factor),
    }
  );

  const showLocalError = !tasksQuery.isFetching && !tasksQueryData.length;
  return (
    <MenuDrivenContainer contentStyles={{ height: '100%', boxSizing: 'border-box' }}>
      <BrandedLoadingOverlay visible={tasksQuery.isLoading} transitionDuration={30} variant="colored" />
      <TasksContainer>
        <BreadcrumbContainer data-testid="breadcrumb">
          <Breadcrumb crumbItems={breadcrumbs} />
        </BreadcrumbContainer>
        <HeaderContainer>
          <TitleHeadingTag>{makeTitle(scope)}</TitleHeadingTag>
        </HeaderContainer>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
  padding-left: 16px;
`;
