import { CostsToDate } from '../../../../api/initiative-client/initiative-client.type';
import { ProjectsResponse as Team } from '../../../../api/projects-client/projects-client.type';
import { Epic } from '../../../../api/tasks-client/task-client.type';
import { InitiativeMetricType } from '../initiative-performance.type';

/**
 * Method that determines teams ordering. The first one should be a team
 * with the most tasks in the related epics
 * @param teams - Array of teams
 * @param epics - Array of epics
 * @returns Array of sorted teams
 */
const sortTeams = (teams: Team[], epics: Epic[]): Team[] => {
  const firstTeamAmountData = teams.reduce(
    (result: { id: string | null; amount: number }, team: Team) => {
      const tasksAmount = getTasksAmountForTeam(team.id, epics);

      if (tasksAmount > result.amount) {
        return {
          id: team.id,
          amount: tasksAmount,
        };
      }

      return result;
    },
    { id: null, amount: 0 }
  );

  const firstTeam = teams.find((team: Team) => team.id === firstTeamAmountData.id);

  if (!firstTeam) {
    return teams;
  }

  return [firstTeam, ...teams.filter((team: Team) => team.id !== firstTeamAmountData.id)];
};

/**
 * Calculates amount of team's tasks in the related epics
 * @param teamId - Id of the team
 * @param epics - Array of epics
 * @returns Amount of tasks for the team
 */
const getTasksAmountForTeam = (teamId: string, epics: Epic[]) => {
  const relatedEpics = epics.filter((epic: Epic) => epic.project_id === teamId);

  return relatedEpics.reduce((result, epic) => result + epic.subtasks_count, 0);
};

/**
 * Method to determine cost for the team
 *
 * @param teamId string - teamd id
 * @param costs_to_date object - costs to date objects for all the teams
 * @returns cost for the team
 */
function getCost(teamId: string, costs_to_date: CostsToDate): number | undefined {
  return Object.keys(costs_to_date).length !== 0
    ? Object.keys(costs_to_date?.teams).includes(teamId)
      ? costs_to_date?.teams?.[teamId]
      : undefined
    : undefined;
}

/**
 * Returns the chart title based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the chart title for.
 * @return {string} The chart title corresponding to the provided metric type.
 */
const getChartTitle = (metric: InitiativeMetricType): string => {
  const titles = {
    [InitiativeMetricType.Tasks]: 'Completed tasks',
    [InitiativeMetricType.Points]: 'Completed points',
    [InitiativeMetricType.Tasks_Added]: 'Tasks added',
    [InitiativeMetricType.Bugs]: 'Bugs',
  };

  return `${titles[metric]} from contributing teams (monthly)`;
};

/**
 * Returns the label for the y-axis based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the y-axis label for.
 * @return {string} The y-axis label corresponding to the provided metric type.
 */
const getYAxisLabel = (metric: InitiativeMetricType): string => {
  const labels = {
    [InitiativeMetricType.Tasks]: 'Tasks',
    [InitiativeMetricType.Points]: 'Points',
    [InitiativeMetricType.Tasks_Added]: 'Tasks added',
    [InitiativeMetricType.Bugs]: 'Bugs',
  };

  return `${labels[metric]} per month`;
};

export { getChartTitle, getCost, getTasksAmountForTeam, getYAxisLabel, sortTeams };
