import { styled } from '@linaria/react';

type FileUploadButtonProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function FileUploadButton({ onChange }: FileUploadButtonProps) {
  return (
    <UploadButton>
      <input type="file" onChange={onChange} />
    </UploadButton>
  );
}

const UploadButton = styled.div``;
