import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Fragment } from 'react';
import { newCOLORS } from '../../styles/colors';
import { TaskItem } from './task-item';
import { TaskListHeader, TaskListHeaderProps } from './task-list-header';

export type TaskListProps = TaskListHeaderProps;

export const TaskList = ({ tasks, setTasks, projectName }: TaskListProps) => {
  const [opened, { toggle }] = useDisclosure(true);

  if (!tasks || tasks.length === 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <CollapseHeader>
        <CollapseIcon>
          {opened ? (
            <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
          ) : (
            <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
          )}
        </CollapseIcon>
        <TaskListHeader tasks={tasks} setTasks={setTasks} projectName={projectName} />
      </CollapseHeader>
      <Collapse in={opened}>
        <CollapseContent>{opened && tasks.map((task) => <TaskItem task={task} key={task.id} />)}</CollapseContent>
      </Collapse>
    </Fragment>
  );
};
const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;
const CollapseContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
`;
