import { styled } from '@linaria/react';
import { Flex, Tooltip, TooltipProps } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { newCOLORS } from '../../styles/colors';
import { TaskRecord } from './tasks-table.type';

export const EpicCell = ({ epic, parentWidth }: { epic: TaskRecord['epic']; parentWidth: number }) => {
  const badgeStyle = {
    backgroundColor: epic ? newCOLORS.lightBlue2 : newCOLORS.lighterGray2,
    color: epic ? newCOLORS.darkerIndigo : newCOLORS.darkGray2,
  };
  const tooltipProps = { dividerColor: newCOLORS.lighterBlue };

  return (
    <ListDataCell
      data={epic ? [epic] : []}
      parentWidth={parentWidth}
      style={badgeStyle}
      tooltipProps={tooltipProps}
      defaultText="No related epic"
    />
  );
};

export const InitiativeCell = ({
  initiatives,
  parentWidth,
}: {
  initiatives: TaskRecord['initiatives'];
  parentWidth: number;
}) => {
  const badgeStyle = {
    backgroundColor: initiatives.length > 0 ? newCOLORS.lightOrange : newCOLORS.lighterGray2,
    color: initiatives.length > 0 ? newCOLORS.brown : newCOLORS.darkGray2,
  };
  const tooltipProps = {
    dividerColor: newCOLORS.darkYellow,
  };

  return (
    <ListDataCell
      data={initiatives}
      parentWidth={parentWidth}
      style={badgeStyle}
      tooltipProps={tooltipProps}
      defaultText="No related initiative"
    />
  );
};

const ListDataCell = ({
  data,
  parentWidth,
  style,
  tooltipProps,
  defaultText,
}: {
  data: { id: string; name: string }[];
  parentWidth: number;
  style?: React.CSSProperties;
  tooltipProps?: { dividerColor?: string; backgroundColor?: string };
  defaultText?: string;
}) => {
  const { ref: primaryBadgeRef, width: primaryBadgeWidth } = useElementSize();
  const { ref: secondaryBadgeRef, width: secondaryBadgeWidth } = useElementSize();

  const tooltipStyle = {
    ...style,
    backgroundColor: tooltipProps?.backgroundColor || style?.backgroundColor,
    maxWidth: 200,
  };

  if (data.length === 0) {
    const showTooltip = parentWidth - primaryBadgeWidth <= 16;

    return (
      <CustomTooltip label={defaultText} disabled={!showTooltip} style={tooltipStyle}>
        <EllipsisBadge ref={primaryBadgeRef} style={{ ...style, cursor: showTooltip ? 'pointer' : 'default' }}>
          {defaultText}
        </EllipsisBadge>
      </CustomTooltip>
    );
  }

  const defaultValue = data[0].name;

  if (data.length === 1) {
    const showTooltip = parentWidth - primaryBadgeWidth <= 16;

    return (
      <CustomTooltip label={defaultValue} disabled={!showTooltip} style={tooltipStyle}>
        <EllipsisBadge ref={primaryBadgeRef} style={{ ...style, cursor: showTooltip ? 'pointer' : 'default' }}>
          {defaultValue}
        </EllipsisBadge>
      </CustomTooltip>
    );
  } else {
    const showTooltip = Math.floor(parentWidth - (primaryBadgeWidth + secondaryBadgeWidth + 6)) <= 32;

    return (
      <Flex direction="row" gap={6}>
        <CustomTooltip label={defaultValue} disabled={!showTooltip} style={tooltipStyle}>
          <EllipsisBadge ref={primaryBadgeRef} style={{ ...style, cursor: showTooltip ? 'pointer' : 'default' }}>
            {defaultValue}
          </EllipsisBadge>
        </CustomTooltip>
        <CustomTooltip
          label={<ListDataTooltipLabel data={data} dividerColor={tooltipProps?.dividerColor} />}
          style={{ ...tooltipStyle, padding: 0 }}
        >
          <Badge ref={secondaryBadgeRef} style={{ ...style, cursor: 'pointer' }}>{`+${data.length - 1}`}</Badge>
        </CustomTooltip>
      </Flex>
    );
  }
};

const CustomTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      position="bottom-start"
      multiline
      style={{
        padding: '12px',
        borderRadius: 4,
        border: `1px solid ${newCOLORS.lightGray2}`,
        backgroundColor: newCOLORS.white,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.2)',
        color: newCOLORS.darkGray2,
        fontFamily: 'Figtree',
        fontWeight: 400,
        lineHeight: '140%',
        maxWidth: 300,
        ...props.style,
      }}
    />
  );
};

const ListDataTooltipLabel = ({
  data,
  style,
  dividerColor,
}: {
  data: { id: string; name: string }[];
  style?: React.CSSProperties;
  dividerColor?: string;
}) => {
  return (
    <Flex direction="column" style={style}>
      {data.map((item, index) => (
        <div
          key={item.id}
          style={{
            borderBottom: index !== data.length - 1 ? `1px solid ${dividerColor}` : 'none',
            padding: '12px',
          }}
        >
          {item.name}
        </div>
      ))}
    </Flex>
  );
};

const Badge = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 165%;
  width: fit-content;
`;

const EllipsisBadge = styled(Badge)`
  text-overflow: ellipsis;
  overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-clip-margin: content-box;
  overflow-clip-margin: 0px;
  word-break: break-all;
`;
