import dayjs from 'dayjs';
import { useInitiativeCompletions } from '../../../api/portfolio-client/portfolio-client.hooks';
import { ProcessAnalysisInitiativeChartData } from '../../../api/portfolio-client/portfolio-client.type';
import { useProjectInitiativesCompletions } from '../../../api/projects-client/projects-client.hooks';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { Tab } from '../process-analysis.type';

/**
 * Fetches initiative completion data for either the selected portfolios or teams based on the active tab.
 *
 * @returns {{ data: ProcessAnalysisInitiativeChartData | undefined; isFetching: boolean }} - Object containing the initiative completion data and a boolean indicating whether the data is being fetched
 */
const useInitiativeCompletionData = (): {
  data: ProcessAnalysisInitiativeChartData | undefined;
  isFetching: boolean;
} => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const portfolioIds = useProcessAnalysisStore((state) => state.portfolioIds);
  const teamIds = useProcessAnalysisStore((state) => state.teamIds);
  const { startDate, endDate } = useDateRange();

  const { initiativeCompletions: portfolioInitiativeCompletions, query: portfolioInitiativeCompletionsQuery } =
    useInitiativeCompletions(
      portfolioIds[0],
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD'),
      {
        enabled: activeTab === Tab.Portfolios && portfolioIds.length > 0,
        staleTime: 1000 * 60 * 5,
      }
    );

  const { initiativeCompletions: projectInitiativeCompletions, query: projectInitiativeCompletionsQuery } =
    useProjectInitiativesCompletions(
      teamIds[0],
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD'),
      {
        enabled: activeTab === Tab.Teams && teamIds.length > 0,
        staleTime: 1000 * 60 * 5,
      }
    );

  const data = activeTab === Tab.Portfolios ? portfolioInitiativeCompletions : projectInitiativeCompletions;
  const isFetching = portfolioInitiativeCompletionsQuery.isFetching || projectInitiativeCompletionsQuery.isFetching;

  return { data, isFetching };
};

export { useInitiativeCompletionData };
