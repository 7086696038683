import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { AppShell, HoverCard } from '@mantine/core';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Brand } from '../../components/brand/brand';
import { User } from '../../components/user/user';
import { GlobalContext } from '../../contexts';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { logout } from '../../helpers/storage/storage';
import { mantineColors } from '../../styles/mantineColors';
import { GlobalMessageComponent } from '../global-message/global-message';
import { Insights } from '../insights/insights';

export function HeaderBar() {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { globalMessage } = useContext(GlobalContext);

  return (
    <AppShell.Header h={90 + (globalMessage ? 50 : 0)}>
      <ContentContainer>
        {/* NOTE: we are using paid as a proxy for an ill-defined concept here
            It's a combination of "is the integration done (ie is there something to see in the app)"
            and "do we want you there (you've paid or we think your data is ready to be viewed)"*/}
        <Brand
          style={{ paddingLeft: '10px', maxWidth: '250px' }}
          height={90}
          onClick={() => (user?.subscription_status === 'paid' ? navigate(`/application/dashboard`) : null)}
        />
        <ProfileContainer>
          <Insights />
          <IconBox onClick={() => navigate(`/application/help`)}>
            <LinkIcon width="32" height="32" icon="uil:question" alt-text="Help" />
          </IconBox>
          <HoverCard width={280} shadow="md" position="bottom-start" offset={28}>
            <HoverCard.Target>
              <IconBox>
                <LinkIcon width="24" height="24" icon="octicon:gear-24" />
              </IconBox>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <HoverCardContent>
                <LinkButton
                  onClick={() => {
                    trackEvent(AnalyticsEventType.TermsAndConditionsViewed, { userContext: user });
                    navigate('/application/legal/terms-and-conditions');
                  }}
                >
                  <LinkIcon width="24" height="24" icon="mdi:legal" />
                  <HoverCardItemText>Terms and Conditions</HoverCardItemText>
                </LinkButton>
                <LinkButton
                  onClick={() => {
                    trackEvent(AnalyticsEventType.LogoutTapped, { userContext: user });
                    logout();
                  }}
                >
                  <LinkIcon width="24" height="24" icon="uil:signout" />
                  <HoverCardItemText>Sign Out</HoverCardItemText>
                </LinkButton>
              </HoverCardContent>
            </HoverCard.Dropdown>
          </HoverCard>
          <User user={user} />
        </ProfileContainer>
      </ContentContainer>
      {globalMessage && <GlobalMessageComponent globalMessage={globalMessage} />}
    </AppShell.Header>
  );
}

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5em;
  height: 90px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1em;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 1px solid gray;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

const LinkIcon = styled(Icon)`
  color: ${mantineColors['gray.6']};

  &:hover {
    cursor: pointer;
  }
`;

const HoverCardContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
`;

const LinkButton = styled.button`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.875rem;
  color: ${mantineColors['gray.7']};
  background-color: transparent;
  border-radius: 0.25rem;
  border: none;
  font-weight: 500;

  &:hover {
    background-color: ${mantineColors['gray.0']};
    color: #000;
  }
`;

const HoverCardItemText = styled.span`
  margin-left: 1em;
`;
