import { ReactNode, createContext } from 'react';
import { useDataInitializer } from '../containers/app/app.hooks';
import { useGlobalMessage } from '../containers/global-message/global-message.hooks';
import { GlobalMessage } from '../containers/global-message/global-message.type';

type GlobalContextType = {
  globalMessage: GlobalMessage | null;
  setGlobalMessage: React.Dispatch<React.SetStateAction<GlobalMessage | null>>;
};

export const GlobalContext = createContext({} as GlobalContextType);

export function GlobalProvider({ children }: { children: ReactNode }) {
  useDataInitializer();
  const { globalMessage, setGlobalMessage } = useGlobalMessage();

  return <GlobalContext.Provider value={{ globalMessage, setGlobalMessage }}>{children}</GlobalContext.Provider>;
}
