import {
  SprintMetricsCumulativeFlowDataPoint,
  SprintMetricsFlowOfWorkPointDataPoint,
  SprintMetricsFlowOfWorkTaskDataPoint,
} from '../../api/sprints-client/sprints-client.type';

type DropDownData = {
  value: string;
  label: string;
};

type PillarIssueInput = {
  amount: string;
  title: string;
  measure: string;
  summary: string;
  description: string;
  calculation?: string;
  solution?: string;
};

enum SprintAssessmentChart {
  Phase = 'Phase',
  Pace = 'Pace',
  Delivery = 'Delivery',
}

type DataPoints =
  | SprintMetricsCumulativeFlowDataPoint[]
  | SprintMetricsFlowOfWorkTaskDataPoint[]
  | SprintMetricsFlowOfWorkPointDataPoint[];

export { SprintAssessmentChart };
export type { DataPoints, DropDownData, PillarIssueInput };
