import iconAdobeXD from './iconAdobeXD.svg';
import iconAha from './iconAha.svg';
import iconAsana from './iconAsana.svg';
import iconBitbucket from './iconBitbucket.svg';
import iconCircleCI from './iconCircleCI.svg';
import iconConfluence from './iconConfluence.svg';
import iconFigma from './iconFigma.svg';
import iconGitLab from './iconGitLab.svg';
import iconGoRetro from './iconGoRetro.svg';
import iconInVision from './iconInVision.svg';
import iconJenkins from './iconJenkins.svg';
import iconMiro from './iconMiro.svg';
import iconMonday from './iconMonday.svg';
import iconMural from './iconMural.svg';
import iconNewRelic from './iconNewRelic.svg';
import iconNotion from './iconNotion.svg';
import iconParabol from './iconParabol.svg';
import iconProductBoard from './iconProductBoard.svg';
import iconRoadmunk from './iconRoadmunk.svg';
import iconServiceNow from './iconServiceNow.svg';
import iconTrello from './iconTrello.svg';

export const upcomingIntegrationsIcons = {
  iconAdobeXD,
  iconAha,
  iconAsana,
  iconBitbucket,
  iconCircleCI,
  iconConfluence,
  iconFigma,
  iconGitLab,
  iconGoRetro,
  iconInVision,
  iconJenkins,
  iconMiro,
  iconMonday,
  iconMural,
  iconNewRelic,
  iconNotion,
  iconParabol,
  iconProductBoard,
  iconRoadmunk,
  iconServiceNow,
  iconTrello,
};
