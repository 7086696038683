import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { WorkPeriod } from '../../api/work-periods-client/work-periods-client.type';
import { Tab, WorkPeriodType } from './process-analysis.type';

const formatTitle = (
  measure: string,
  workPeriodType: WorkPeriodType,
  workPeriod: WorkPeriod | null,
  currentBoardName: string,
  activeTab: Tab
): string => {
  if (!measure) {
    return 'Process Analysis Tasks';
  }

  let result = '';

  measure = measure
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  result = result.concat(`${measure} - Tasks`);

  if (activeTab === Tab.WorkPeriods && workPeriodType === WorkPeriodType.Defined) {
    result = result.concat(` - ${workPeriod?.name}`);
  } else if (activeTab === Tab.WorkPeriods) {
    result = result.concat(` - ${currentBoardName}`);
  }

  return result;
};

/**
 * Maps subproject IDs to their corresponding names from an array of ProjectsResponse objects.
 *
 * @param {ProjectsResponse[]} projects - An array of ProjectsResponse objects containing project and subproject information.
 * @returns {Record<string, string>} An object where keys are subproject IDs and values are their corresponding names.
 *
 * This function iterates through the provided projects and their subprojects, creating a mapping
 * of subproject IDs to names. It's useful for quickly looking up subproject names by their IDs.
 * Only subprojects with both an ID and a name are included in the resulting map.
 */

function mapSubprojectIdsToNames(projects: ProjectsResponse[]): Record<string, string> {
  return projects.reduce((acc, project) => {
    project.subprojects.forEach((subproject) => {
      if (subproject.id && subproject.name) {
        acc[subproject.id] = subproject.name;
      }
    });
    return acc;
  }, {} as Record<string, string>);
}

export { formatTitle, mapSubprojectIdsToNames };
