import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipModel,
} from 'chart.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import { AllocationOfCostsChartDataV2, ViewBy } from '../../../api/financials-client/financials-client.type';
import { allocationOfCostsTooltipHandler } from './allocation-of-costs-tooltip';
import { ShowAllocationOfCostsChartLine } from './allocation-of-costs.type';

dayjs.extend(customParseFormat);

// Register chart.js plugins needed for the chart
ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

type Position = 'left' | 'right' | 'center' | 'top' | 'bottom';

/**
 * Format the data according to the chart.js requirements
 * @param data
 * @returns formatted data
 */
const formatData = (
  data: SanitizedData,
  showAllocationOfCostsChartLines: ShowAllocationOfCostsChartLine[]
): ChartData<'bar'> => {
  const dataset: ChartData<'bar'> = {
    labels: data.labels,
    datasets: [],
  };
  const chartDataByField: { [id: string]: number[] } = {};
  for (const [index, chartData] of data.chartData.entries()) {
    for (const [field, value] of Object.entries(chartData)) {
      const curr = chartDataByField[field] || Array.from({ length: data.labels.length }).fill(0);
      curr[index] = value;
      chartDataByField[field] = curr;
    }
  }
  for (const field in chartDataByField) {
    const fieldData = showAllocationOfCostsChartLines.find((line) => (line.field === field ? line.show : false));
    if (fieldData?.show || false) {
      dataset.datasets.push({
        label: field,
        data: chartDataByField[field],
        backgroundColor: fieldData.color,
        borderColor: fieldData.color,
        borderWidth: 0,
        yAxisID: 'y',
      });
    }
  }
  return dataset;
};

const getChartData = (data: AllocationOfCostsChartDataV2, viewBy: ViewBy): { [option: string]: number }[] => {
  const chartData: { [K in ViewBy]: Array<{ [key: string]: number }> } = {
    tasks_in_usd: [],
    points_in_usd: [],
    tasks: [],
    points: [],
  };

  Object.values(data).forEach((timeAllocation) => {
    const tasksInUsd: { [key: string]: number } = {};
    const pointsInUsd: { [key: string]: number } = {};
    const tasks: { [key: string]: number } = {};
    const points: { [key: string]: number } = {};
    Object.entries(timeAllocation).forEach(([factorOption, factorData]) => {
      tasksInUsd[factorOption] = factorData.total.tasks_in_usd;
      pointsInUsd[factorOption] = factorData.total.points_in_usd;
      tasks[factorOption] = factorData.total.tasks;
      points[factorOption] = factorData.total.points;
    });
    chartData.tasks_in_usd.push(tasksInUsd);
    chartData.points_in_usd.push(pointsInUsd);
    chartData.tasks.push(tasks);
    chartData.points.push(points);
  });

  return chartData[viewBy] || [];
};

const mapToSanitizedData = (data: AllocationOfCostsChartDataV2, viewBy: ViewBy): SanitizedData => {
  const dataSortedByYearAndMonth = Object.entries(data).sort((a, b) =>
    dayjs(a[0], 'YYYY-MM').diff(dayjs(b[0], 'YYYY-MM'))
  );

  const labels = dataSortedByYearAndMonth.map(([key, _value]) => {
    return `${dayjs(key, 'YYYY-MM').format('MMM YY')}`;
  });

  const chartData = getChartData(data, viewBy);

  return {
    labels,
    chartData,
  };
};

type SanitizedData = {
  labels: string[];
  chartData: { [id: string]: number }[];
};

type AllocationOfCostsChartProps = {
  showAllocationOfCostsChartLines: ShowAllocationOfCostsChartLine[];
  chartData: AllocationOfCostsChartDataV2 | null;
  viewBy: ViewBy;
  category: string;
  portfolioId: string | undefined;
};

export const AllocationOfCostsChart = ({
  showAllocationOfCostsChartLines,
  chartData,
  viewBy,
  category,
  portfolioId,
}: AllocationOfCostsChartProps) => {
  const isCost = viewBy.includes('in_usd');
  const yAxisLabel = isCost ? 'USD' : 'Units';
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },

    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        position: 'left' as Position,
        title: {
          display: true,
          text: yAxisLabel,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          callback: (value: number | string) => {
            return Number(value) >= 1000
              ? `${isCost ? '$' : ''}${Number(value) / 1000}k`
              : `${isCost ? '$' : ''}${value}`;
          },
        },
        stacked: true,
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        enabled: false,
        external: (context: { chart: ChartJS; tooltip: TooltipModel<'bar'> }) =>
          allocationOfCostsTooltipHandler(context, chartData, viewBy, category, portfolioId),
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  return (
    <Fragment>
      {chartData ? (
        <Bar
          data={formatData(mapToSanitizedData(chartData, viewBy), showAllocationOfCostsChartLines)}
          options={options}
        />
      ) : null}
    </Fragment>
  );
};
