import { Measure } from '../../../api/work-periods-client/work-periods-client.type';

/**
 * Splits an array of measures into multiple groups of roughly equal size.
 *
 * @param {Measure[]} measures - Array of measures to split into groups
 * @param {number} [numGroups=2] - Number of groups to split the measures into (defaults to 2)
 * @returns {Measure[][]} Array of measure groups, where each group is an array of measures
 */
const splitMeasures = (measures: Measure[], numGroups = 2): Measure[][] => {
  const groupSize = Math.ceil(measures.length / numGroups);
  const groups: Measure[][] = [];

  for (let i = 0; i < numGroups; i++) {
    const start = i * groupSize;
    const end = Math.min(start + groupSize, measures.length);
    groups.push(measures.slice(start, end));
  }

  return groups;
};

export { splitMeasures };
