enum NavIconType {
  iconCaretLeft = 'iconCaretLeft',
  iconCaretRight = 'iconCaretRight',
  iconDoubleArrowLeft = 'iconDoubleArrowLeft',
  iconDoubleArrowRight = 'iconDoubleArrowRight',
  iconCurrentSprint = 'iconCurrentSprint',
  iconFinancials = 'iconFinancials',
  iconHistory = 'iconHistory',
  iconIntegrations = 'iconIntegrations',
  iconPortfolio = 'iconPortfolio',
  iconStrategy = 'iconStrategy',
  iconProject = 'iconProject',
  iconProcess = 'iconProcessMap',
  iconProcessAnalysis = 'iconProcessAnalysis',
  iconBeta = 'iconBeta',
}

export { NavIconType };
