import { Sprint, SprintBurnsAndFlows } from '../../api/sprints-client/sprints-client.type';
import { DayCount } from '../../helpers';
import { generateDateStringArray } from '../../helpers/string-helpers/string-helpers';

/**
 * Method to generate the breadcrumb title
 *
 * @param sprint selected sprint
 * @param day selected day
 * @param sprintBurnsAndFlows selected sprint burns and flows
 * @param filter selected filter
 * @returns string - the breadcrumb title
 */
const makeBreadcrumbTitle = (
  sprint: Sprint | null,
  day: string | null,
  sprintBurnsAndFlows: SprintBurnsAndFlows | null,
  filter: string | null
): string => {
  if (sprint && day && sprintBurnsAndFlows) {
    const dayCount = DayCount(sprint, sprintBurnsAndFlows);

    const filterTextMap: { [key: string]: string } = {
      backlog: 'Ready',
      blocked: 'Blocked',
      wip: 'WIP',
      review: 'In Review',
      test: 'In Test',
      deployable: 'To Deploy',
      done: 'Done',
    };

    const dateString = generateDateStringArray(sprint.start_date, dayCount, dayCount)[Number(day)]?.label;
    if (filter) {
      return `"${filterTextMap[filter]}" tasks on day ${dateString}`;
    }
    return `Tasks on day ${dateString}`;
  }
  return '';
};

export { makeBreadcrumbTitle };
