enum InitiativeMetricType {
  Tasks = 'tasks',
  Points = 'points',
  Tasks_Added = 'tasks_added',
  Bugs = 'bugs',
}

type ColoredEntity = {
  id: string;
  name: string;
  color: string;
};

type LegendItem = {
  title: string;
  description?: string;
  color: string;
};

export { InitiativeMetricType };
export type { ColoredEntity, LegendItem };
