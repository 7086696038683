import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { IssueDetailsResult } from './issues-client.class';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Fetches an instance of IssueDetailsResult
 * @param id - The id of the issue to fetch
 * @returns IssueDetailsResult(data)
 */
const fetchIssueDetailsWithId = async (id: string): Promise<IssueDetailsResult> => {
  const apiURL = baseURL + `/api/issues/${id}/`;
  return await axiosInstance
    .get<IssueDetailsResult>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || 'There was an error in fetching the issue details'));
};

export { fetchIssueDetailsWithId };
