import { ADOReducerAction, ADOState } from './ado.type';

/**
 * Reducer function for managing the state of the ADO integration flow.
 *
 * @param {ADOState} state - the current state of the ADO integration flow
 * @param {ADOReducerAction} action - the action to be performed on the state
 * @returns {ADOState} the new state after applying the action
 */
export function adoReducer(state: ADOState, action: ADOReducerAction): ADOState {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.payload, initialized: true };
    case 'project':
      return { ...state, project: action.payload, team: null, bloomfilterStatuses: {} };
    case 'organization':
      return { ...state, organization: action.payload };
    case 'team':
      return { ...state, team: action.payload, bloomfilterStatuses: {} };
    case 'bloomfilterStatuses':
      return {
        ...state,
        bloomfilterStatuses: { ...state.bloomfilterStatuses, ...action.payload },
      };
    case 'settings':
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case 'errors':
      return { ...state, errors: { ...state.errors, ...action.payload } };
    case 'options':
      return { ...state, options: { ...state.options, ...action.payload } };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}
