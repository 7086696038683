import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../views/views.module.css';
import { AhaProduct, AhaState } from './aha.type';

type ProductsProps = {
  ahaState: AhaState;
  updateState: (update: Partial<AhaState>) => void;
};

export const Products = ({ ahaState, updateState }: ProductsProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);

  const handleProductSelected = (product: AhaProduct) => {
    if ((ahaState.selectedProducts || []).filter((t: AhaProduct) => t.id === product.id).length > 0) {
      updateState({
        selectedProducts: (ahaState.selectedProducts || []).filter((t: AhaProduct) => t.id !== product.id),
      });
    } else {
      updateState({ selectedProducts: [...(ahaState.selectedProducts || []), product] });
    }
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select at least one product you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(ahaState.productOptions || []).map((product: AhaProduct) => (
          <div
            key={product.name}
            className={
              (ahaState.selectedProducts || []).filter((t: AhaProduct) => t.id === product.id).length > 0
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleProductSelected(product)}
          >
            <span className={styles.checkboxLabel}>{product.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
