import dayjs from 'dayjs';
import { HistoricalBurnsDataOverTime } from '../../../api/work-periods-client/work-periods-client.type';
import { Metric } from './assessment-view.type';

/**
 * Takes in historical burn data, a metric, and an active day
 * and returns an array of data points for the historical burn band.
 *
 * The returned array is filtered to only include data points up to the
 * active day, and the x values are set to the index of the data point.
 *
 * @param {data} - The historical burn data
 * @param {metric} - The metric to use (tasks or points)
 * @param {activeDay} - The active day
 * @returns {HistoricalBurnsResultDataPoint} - An array of data points for the historical burn band
 */
const getHistoricalBurnsBand = (data: HistoricalBurnsDataOverTime | undefined, metric: Metric, activeDay: number) => {
  if (!data) {
    return [];
  }

  return Object.entries(data)
    .sort(([a], [b]) => dayjs(a).diff(dayjs(b)))
    .map(([, value], index) => {
      const { tasks, points } = value;
      const metricData = metric === Metric.Tasks ? tasks : points;
      return {
        x: index,
        y: metricData.y,
        y0: metricData.y0,
        average: metricData.average,
      };
    })
    .slice(0, activeDay);
};

export { getHistoricalBurnsBand };
