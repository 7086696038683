import { Dispatch, SetStateAction } from 'react';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { Button } from '../../../components/button/button';
import styles from '../views/views.module.css';
import { FigmaState } from './figma.type';

type SummaryProps = {
  figmaState: FigmaState;
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export const Summary = ({ figmaState, setActiveStep }: SummaryProps) => {
  const selectedProject = figmaState.projectOptions?.find((project) => project.id === figmaState.selectedProject);
  return (
    <div id={styles.viewContainer}>
      <h3>Review your Selections: </h3>
      <div id={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Team</h4>
            <Button variant="subtle" onClick={() => setActiveStep(0)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <span className={styles.summaryValue}>{figmaState.selectedTeam || ''}</span>
        </div>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Project: </h4>
            <Button variant="subtle" onClick={() => setActiveStep(1)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          <div className={styles.flexRowSpaceBetween}>
            <span className={styles.summaryValue}>{(selectedProject as ProjectsResponse).name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
