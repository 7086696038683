import AxiosInstance from '../../helpers/axios-instance/axios-instance';

const mailchimpRequestHeader = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const axiosInstance = AxiosInstance(mailchimpRequestHeader);
const mailchimpU = import.meta.env.VITE_VITE_APP_MAILCHIMP_U;
const mailchimpID = import.meta.env.VITE_APP_MAILCHIMP_ID;
const mailchimpVID = import.meta.env.VITE_APP_MAILCHIMP_V_ID;
const mailchimpFID = import.meta.env.VITE_APP_MAILCHIMP_F_ID;

/**
 * Method for passing sign up user's email to mailchimp
 *
 * @param email - the email given by the user
 * @returns Response data or error information
 */
async function collectEmailMailchimp(email: string): Promise<any> {
  const mailchimpData = new URLSearchParams();
  mailchimpData.append('EMAIL', email);

  const mailchimpEndpoint = `https://thebloomfilter.us21.list-manage.com/subscribe/post?u=${mailchimpU}&id=${mailchimpID}&v_id=${mailchimpVID}&f_id=${mailchimpFID}`;

  return await axiosInstance
    .post(mailchimpEndpoint, mailchimpData.toString())
    .then((response) => response.data)
    .catch((error) => error.response);
}

export { collectEmailMailchimp };
