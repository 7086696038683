import { styled } from '@linaria/react';
import { LoadingOverlay, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useInitiatives } from '../../../api/initiative-client/initiative-client.hooks';
import { Initiative } from '../../../api/initiative-client/initiative-client.type';
import { useProjectInitiatives } from '../../../api/projects-client/projects-client.hooks';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useEntities } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { Pills } from '../pills/pills';
import { getPills } from '../process-analysis.helpers';
import { Tab } from '../process-analysis.type';
import { InitiativeOverviewTable } from './initiative-overview-table';

export function InitiativeOverview() {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const portfolioIds = useProcessAnalysisStore((state) => state.portfolioIds);
  const teamIds = useProcessAnalysisStore((state) => state.teamIds);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  const [initiatives, setInitiatives] = useState<Initiative[]>();

  const { initiatives: portfolioInitiatives, query: portfolioInitiativeQuery } = useInitiatives(portfolioIds[0], {
    enabled: activeTab === Tab.Portfolios && portfolioIds.length > 0,
  });

  const { initiatives: projectInitiatives, query: projectInitiativeQuery } = useProjectInitiatives(teamIds[0], {
    enabled: activeTab === Tab.Teams && teamIds.length > 0,
  });

  useEffect(() => {
    if (activeTab === Tab.Portfolios && portfolioIds.length > 0) {
      setInitiatives(portfolioInitiatives);
    } else if (activeTab === Tab.Teams && teamIds.length > 0) {
      setInitiatives(projectInitiatives);
    }
  }, [portfolioInitiatives, projectInitiatives, activeTab, portfolioIds.length, teamIds.length]);

  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  return (
    <DropShadowContainer>
      <LoadingOverlay
        visible={portfolioInitiativeQuery.isLoading && projectInitiativeQuery.isLoading}
        overlayProps={{ blur: 2 }}
        style={{ zIndex: 200 }}
      />
      <ContentContainer>
        <HeaderContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Pills pillText={pills} />
          </div>
          <Title>Initiative Overview</Title>
        </HeaderContainer>
        <TableContainer>
          <InitiativeOverviewTable initiatives={initiatives} />
        </TableContainer>
      </ContentContainer>
    </DropShadowContainer>
  );
}

export const DropShadowContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 500px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 12px;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
`;
