import { styled } from '@linaria/react';
import { Modal } from '@mantine/core';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { Initiative, PreSaveInitiative } from '../../../api/initiative-client/initiative-client.type';
import { InitiativeForm } from './initiative-form';

type InitiativeModalProps = {
  opened: boolean;
  title: string;
  portfolioId: string;
  initiative?: Initiative;
  handleClose: () => void;
  handleSubmit: UseMutateAsyncFunction<Initiative, unknown, PreSaveInitiative, unknown>;
  setMessage: (message: string) => void;
};

export function InitiativeModal({
  opened,
  title,
  portfolioId,
  initiative,
  handleClose,
  handleSubmit,
  setMessage,
}: InitiativeModalProps) {
  const wrappedHandleClose = (forceClose = false) => {
    const requiredFieldsEmpty = !initiative?.name || !initiative?.description || !initiative?.end_date;
    const formDirty = initiative !== undefined;

    if (!forceClose) {
      if (formDirty || requiredFieldsEmpty) {
        const shouldClose = window.confirm('You have unsaved changes. Are you sure you want to close without saving?');
        if (!shouldClose) {
          return;
        }
      }
    }
    handleClose();
  };
  return (
    <CustomModal
      size={'45rem'}
      opened={opened}
      onClose={wrappedHandleClose}
      title={title}
      centered
      data-testid="initiative-modal"
    >
      <InitiativeForm
        portfolioId={portfolioId}
        initiative={initiative}
        mode={initiative ? 'edit' : 'create'}
        handleClose={wrappedHandleClose}
        handleSubmit={handleSubmit}
        setMessage={setMessage}
      />
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .mantine-Modal-header {
    padding: 24px 24px 0 24px;
    h2 {
      font-size: 1.5em;
    }
  }

  .mantine-Modal-body {
    padding: 24px;
  }
`;
