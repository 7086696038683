import { useContext } from 'react';
import { ProcessContext } from './context/process.context';

/**
 * Returns the process scope from the ProcessContext.
 *
 * @returns {Object} The process scope object.
 */
const useProcessScope = () => {
  const { scope, setScope } = useContext(ProcessContext);

  return {
    ...scope,
    setScope,
  };
};

export { useProcessScope };
