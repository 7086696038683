import { styled } from '@linaria/react';
import { useEffect, useState } from 'react';
import { FlowByPaceDataOverTime } from '../../../api/work-periods-client/work-periods-client.type';
import { ProgressMeasure } from '../../../components/progress-bar/progress-bar-measure';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDefaultDay, useWorkPeriodLength } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { HeadingTag } from '../../../styles/shared-styled-components';
import { WorkPeriodType } from '../process-analysis.type';
import { formatFlowByPaceData } from './assessment-view.helpers';
import { AssessmentChart, Metric } from './assessment-view.type';
import { ChartContainer } from './chart-container/chart-container';
import { FlowOfWork } from './charts/flow-of-work';
import { WorkOnTrack } from './charts/work-on-track';
import { getBurntMeasure, getChartLabels, getDoneMeasure, transformFlowByPaceData } from './section-pace.helpers';

type Props = {
  data?: FlowByPaceDataOverTime;
};

export const SectionPace = ({ data }: Props) => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriodLength = useWorkPeriodLength();
  const defaultDay = useDefaultDay();
  const [metric, setMetric] = useState<Metric>(Metric.Tasks);
  const [activeDay, setActiveDay] = useState<string | null>(defaultDay);

  useEffect(() => {
    setActiveDay(defaultDay);
  }, [defaultDay]);

  const formattedData = formatFlowByPaceData(data);
  const chartData = transformFlowByPaceData(formattedData, metric, Number(activeDay));
  const labels = getChartLabels(formattedData, metric);

  return (
    <ChartContainer
      chartId={AssessmentChart.Pace}
      title={<LargeHeadingTag>Work pace explorer</LargeHeadingTag>}
      day={activeDay}
      setDay={setActiveDay}
      showDaySelect={workPeriodType === WorkPeriodType.Defined}
      metric={metric}
      setMetric={setMetric}
    >
      <DataContainer>
        <SummarySideContainer>
          <ProgressMeasure
            icon="mdi:fire"
            color={newCOLORS.orange}
            title="Burnt"
            percent={getBurntMeasure(Number(activeDay), workPeriodLength)}
            helpText="% of work period elapsed"
          />
          <ProgressMeasure
            icon="mdi:progress-check"
            color={newCOLORS.green}
            title="Done"
            percent={getDoneMeasure(chartData)}
            helpText="work finished"
          />
        </SummarySideContainer>
        <ChartSideContainer>
          <FlowOfWork data={chartData} labels={labels} metric={metric} />
          <WorkOnTrack data={chartData} labels={labels} />
        </ChartSideContainer>
      </DataContainer>
    </ChartContainer>
  );
};

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SummarySideContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: auto;
  min-width: 300px;
  gap: 20px;
`;

const ChartSideContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;
