import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { isLoggingEnabled, logger } from '../logger/logger';
import { State } from './global-store.type';

const useGlobalStore = create<State>()(
  devtools(
    persist(
      logger((_) => ({
        initialized: false,
        organization: null,
        organizations: [],
        portfolio: null,
        portfolios: [],
        teams: [],
      })),
      {
        name: 'global-storage',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !['initialized'].includes(key))),
      }
    ),
    {
      name: 'Bloomfilter - Global Store',
      enabled: isLoggingEnabled,
    }
  )
);

export { useGlobalStore };
