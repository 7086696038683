/**
 * Caches the given portfolio ID in the browser's local storage.
 *
 * @param {string} portfolioId - The ID of the portfolio to be cached.
 * @returns {void}
 */
const cachePortfolio = (portfolioId: string): void => {
  localStorage.setItem('selectedPortfolioBeforeRedirectToExternalSystem', portfolioId);
};

/**
 * Retrieves the ID of the selected portfolio from the local storage.
 *
 * @returns {string | null} The ID of the selected portfolio, or null if it is not found.
 */
const getCachedPortfolioId = (): string | null =>
  localStorage.getItem('selectedPortfolioBeforeRedirectToExternalSystem');

/**
 * Invalidates the cached portfolio by removing it from the local storage.
 *
 * @returns {void}
 */
const invalidatePortfolio = (): void => {
  localStorage.removeItem('selectedPortfolioBeforeRedirectToExternalSystem');
};

export { cachePortfolio, getCachedPortfolioId, invalidatePortfolio };
