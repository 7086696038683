import dayjs from 'dayjs';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useProcessTasks } from '../../../api/process-client/process-client.hooks';
import { ProcessTask, WorkflowStatus } from '../../../api/process-client/process-client.type';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { PortfolioContext, ProjectContext, SidebarContext } from '../../../contexts';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { BreadcrumbContainer } from '../../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../../side-bar/side-bar.helper';
import { TasksTable } from '../../tasks-table/tasks-table';
import { ProcessWorkflow } from '../context/process.context.type';
import { getStageTitle } from '../process.helpers';
import { useProcessScope } from '../process.hooks';
import { PageContainer, PageHeading } from '../process.styled';
import { formatTasks } from './task-details.helpers';

export function TaskDetails() {
  useDocumentTitle('Task Details - Bloomfilter');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { portfolio: processScopePortfolio } = useProcessScope();
  const { portfolio: contextPortfolio, setPortfolio } = useContext(PortfolioContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);

  const portfolio = processScopePortfolio || contextPortfolio;
  const teamId = searchParams.get('teamId');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const workflowString = searchParams.get('workflow');
  const workflow: ProcessWorkflow | null = workflowString ? JSON.parse(decodeURIComponent(workflowString)) : null;

  const { taskData: tasks = [], isFetching = false } = useProcessTasks(
    {
      teamId: teamId,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      mapping: workflow?.mapping,
    },
    { enabled: Boolean(teamId) }
  );

  const deduplicateTasks = (tasks: ProcessTask[]) => {
    const uniqueIds: { [key: string]: boolean } = {};
    return tasks.filter((task: ProcessTask) => {
      if (!(task.id in uniqueIds)) {
        uniqueIds[task.id] = true;
        return true;
      } else {
        return false;
      }
    });
  };
  const getHeading = useCallback(() => {
    if (!workflow) {
      return 'Task Details';
    }

    if (workflow.status === WorkflowStatus.Completed) {
      return 'Completed Task Paths';
    }

    return `${workflow.status} from ${getStageTitle(workflow.source)} to ${getStageTitle(workflow.destination)}`;
  }, [workflow]);

  useEffect(() => {
    if (!workflow) {
      navigate('/application/process');
    }
  }, [navigate, workflow]);
  const filteredTasks = deduplicateTasks(tasks);

  const showLocalError = !isFetching && !filteredTasks.length;

  return (
    <MenuDrivenContainer contentStyles={{ height: '100%', boxSizing: 'border-box' }}>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      <PageContainer style={{ paddingLeft: 16 }}>
        {portfolio?.organization && (
          <BreadcrumbContainer style={{ paddingBottom: 0, height: 40 }}>
            <Breadcrumb
              crumbItems={[
                {
                  labelName: portfolio.organization?.name || 'Portfolio',
                  href: '/application/dashboard',
                  onNavigate: () => {
                    setPortfolio(portfolio);
                    onPortfolioBreadcrumbClick(setProject, navItems, setNavItems);
                  },
                },
                { labelName: 'Process Map', href: '/application/process' },
                { labelName: 'Task Details' },
              ]}
            />
          </BreadcrumbContainer>
        )}
        <PageHeading>{getHeading()}</PageHeading>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(filteredTasks)} />
      </PageContainer>
    </MenuDrivenContainer>
  );
}
