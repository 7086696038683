import { Button, TextInput } from '@mantine/core';
import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { Brand } from '../../../components/brand/brand';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../../styles/colors';
import styles from '../sign-in.module.css';

type SendResetPasswordLinkViewProps = {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  handleSendResetPasswordLink: MouseEventHandler;
  error?: string;
};

export function SendResetPasswordLinkView({
  email,
  setEmail,
  handleSendResetPasswordLink,
  error,
}: SendResetPasswordLinkViewProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '3em' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Brand style={{ minWidth: '60%' }} darkMode={true} clickable={false} />
        <h3 style={{ textAlign: 'center', color: newCOLORS.white }}>Forgot your password?</h3>
      </div>
      <div id={styles.formContainer}>
        <TextInput
          autoFocus
          label="Enter your email address"
          type="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          error={!!error}
          classNames={{ input: styles.textInput, label: styles.textInputLabel }}
        />
        <Button
          className={styles.button}
          onClick={(event) => {
            trackEvent(AnalyticsEventType.ForgotPasswordTapped, { userContext: { email } });
            handleSendResetPasswordLink(event);
          }}
        >
          Send Link
        </Button>
      </div>
    </div>
  );
}
