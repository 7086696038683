import { newCOLORS } from '../../../styles/colors';

/**
 * Returns the background color based on the active and grayed parameters.
 *
 * @param {boolean} active - Indicates if the node is active.
 * @param {boolean} grayed - Indicates if the node is grayed.
 * @returns {string} The background color.
 */
const getBackground = (active: boolean, grayed: boolean): string => {
  if (active && grayed) {
    return newCOLORS.gray3;
  }

  if (active && !grayed) {
    return newCOLORS.purple2;
  }

  return newCOLORS.white;
};

/**
 * Returns the border color based on the active and grayed parameters.
 *
 * @param {boolean} active - Indicates if the node is active.
 * @param {boolean} grayed - Indicates if the node is grayed.
 * @returns {string} The border color.
 */
const getBorder = (active: boolean, grayed: boolean): string => {
  if (grayed) {
    return newCOLORS.gray3;
  }

  if (active) {
    return newCOLORS.purple2;
  }

  return newCOLORS.black2;
};

export { getBackground, getBorder };
