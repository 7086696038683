import { Icon } from '@iconify/react';
import { Divider, LoadingOverlay, MultiSelect } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { Dispatch, useContext } from 'react';
import { generateStatusMapping } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { Button } from '../../../components/button/button';
import { PortfolioContext } from '../../../contexts';
import { newCOLORS } from '../../../styles/colors';
import { ADOReducerAction, ADOState } from '../ado/ado.type';
import { JDCReducerAction, JDCState } from '../jdc/jdc.type';
import { JiraReducerAction, JiraState } from '../jira/jira.type';
import { bloomfilterStatusOptions } from './views.data';
import styles from './views.module.css';

type StatusesProps = {
  integration: ExternalService.Jira | ExternalService.ADO;
  state: JiraState | ADOState | JDCState;
  dispatch: Dispatch<JiraReducerAction> | Dispatch<ADOReducerAction> | Dispatch<JDCReducerAction>;
};

export function StatusView({ integration, state, dispatch }: StatusesProps) {
  const { bloomfilterStatuses, options } = state;
  const { portfolio } = useContext(PortfolioContext);

  const externalStatuses = options?.statuses || [];

  const mappedStatuses = Object.values(bloomfilterStatuses || {})
    .map((status) => status)
    .flat();

  const generateStatusMappingMutation = useMutation(
    ['generateStatusMapping', externalStatuses],
    () => generateStatusMapping(externalStatuses),
    {
      onSuccess: (data) => {
        const bloomfilterStatuses = Object.entries(data || {}).reduce(
          (map: { [key: string]: string[] }, [statusLabel, mappedStatuses]) => {
            const bloomfilterStatus = bloomfilterStatusOptions.find((s) => s.label === statusLabel);
            if (bloomfilterStatus) {
              map[bloomfilterStatus.name] = mappedStatuses;
            }
            return map;
          },
          {}
        );
        dispatch({ type: 'bloomfilterStatuses', payload: bloomfilterStatuses });
      },
    }
  );

  return (
    <div id={styles.viewContainer}>
      <LoadingOverlay visible={!state.options?.statuses?.length} />
      <h4>Status Mapping</h4>
      <div className={styles.flexRowSpaceBetween}>
        <p>Help us understand your {integration === ExternalService.Jira ? 'Jira' : 'ADO'} statuses</p>
        {portfolio?.organization.allow_llm_use ? (
          <div className={styles.flexColumn}>
            <Button
              loading={generateStatusMappingMutation.isLoading}
              color={generateStatusMappingMutation.isError ? newCOLORS.red : newCOLORS.indigo}
              styles={{ root: { width: '200px' } }}
              onClick={() => generateStatusMappingMutation.mutate()}
              size="lg"
            >
              {generateStatusMappingMutation.isLoading ? 'Generating...' : 'Auto Map'}
            </Button>
            {generateStatusMappingMutation.isError ? (
              <>
                <p className={styles.autoMappingError}>service currently unavailable</p>
                <p className={styles.autoMappingError}>try again later</p>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <div style={{ display: 'flex' }}>
        <div className={`${styles.statusListContainer} ${styles.statusBloomfilter}`}>
          <b>Bloomfilter</b>
          {bloomfilterStatusOptions.map((bloomfilterStatus) => (
            <div className={styles.multiselectContainer} key={bloomfilterStatus.name}>
              <b>{bloomfilterStatus.label}</b>
              <div className={styles.dropdownContainer}>
                <b className={styles.equalsContainer}>=</b>
                <MultiSelect
                  value={bloomfilterStatuses?.[bloomfilterStatus.name]}
                  onChange={(value: string[]) =>
                    dispatch({ type: 'bloomfilterStatuses', payload: { [bloomfilterStatus.name]: value } })
                  }
                  data={externalStatuses}
                  styles={{ root: { width: '300px' } }}
                />
              </div>
            </div>
          ))}
        </div>
        <Divider orientation="vertical" />
        <div className={`${styles.statusListContainer} ${styles.statusExternal}`}>
          <b>{integration === ExternalService.Jira ? 'Jira' : 'ADO'}</b>
          {externalStatuses.map((jiraStatus) => (
            <div key={jiraStatus} className={styles.externalStatusRow}>
              <div className={styles.dropdownContainer}>
                <b>{jiraStatus}</b>
                {mappedStatuses.includes(jiraStatus) ? (
                  <Icon icon="material-symbols:check-small-rounded" color={newCOLORS.tangerine} width="48" />
                ) : null}
              </div>
              <Divider size={1} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
