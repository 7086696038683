import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { PortfolioContext, TeamContext } from '../../contexts';
import { refactorDropdownData } from '../../helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';

export type PortfolioSelectRef = {
  getPortfolios: () => Portfolio[];
};

export type PortfolioSelectProps = {
  handlePortfolioSelected?: (portfolio: Portfolio) => void;
  hideLabel?: boolean;
};

export const PortfolioSelect = forwardRef<PortfolioSelectRef, PortfolioSelectProps>(
  ({ hideLabel, handlePortfolioSelected }, ref) => {
    const { portfolioId } = useParams();
    const { portfolio, setPortfolio } = useContext(PortfolioContext);
    const { setTeam } = useContext(TeamContext);
    const [portfolios, setPortfolios] = useState([] as Portfolio[]);

    const portfoliosData = useGlobalStore((state) => state.portfolios);

    useEffect(() => {
      if (portfoliosData && portfoliosData.length > 0) {
        setPortfolios(portfoliosData);

        if (portfolioId) {
          const portfolio = portfoliosData.find((portfolio) => portfolio.id == portfolioId);
          setPortfolio(portfolio as Portfolio);
        } else if (!portfolio) {
          setPortfolio(portfoliosData[0]);
        }
      }
    }, [portfoliosData, portfolioId, portfolio, setPortfolio]);

    useImperativeHandle(ref, () => ({
      getPortfolios: () => portfoliosData,
    }));

    const portfolioSelected = (portfolio: Portfolio) => {
      setPortfolio(portfolio);
      // Reset team to null when portfolio changes, so that we can display all teams
      setTeam(null);
      handlePortfolioSelected && handlePortfolioSelected(portfolio);
    };

    return (
      portfolios.length > 1 && (
        <Fragment>
          {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Portfolio</StandardText>}
          <Select
            data={refactorDropdownData(portfolios)}
            value={portfolio?.id}
            onChange={(value) => {
              portfolioSelected(portfolios.find((portfolio) => portfolio.id == value) as Portfolio);
            }}
            allowDeselect={false}
          />
        </Fragment>
      )
    );
  }
);
