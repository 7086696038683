import { QueryObserverResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../api/organization-client/organization-client.hooks';
import { usePortfolios } from '../../api/portfolio-client/portfolio-client.hooks';
import { usePortfolioProjects } from '../../api/summary-client/summary-client.hooks';
import { getRequestToken } from '../../helpers/storage/storage';
import { useGlobalStore } from '../../store/global-store/global-store';
import { initGlobalState, setTeams } from '../../store/global-store/global-store.actions';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { initProcessAnalysisState } from '../../store/process-analysis-store/process-analysis-store.actions';

/**
 * Initializes the data for the application.
 *
 * @return {void}
 */
const useDataInitializer = (): void => {
  const initializedGlobalState = useGlobalStore((state) => state.initialized);

  const isValidAccess = Boolean(getRequestToken());

  const { organizations = [], query: organizationsQuery } = useOrganizations({
    enabled: isValidAccess && !initializedGlobalState,
  });
  const { portfolios = [], query: portfoliosQuery } = usePortfolios({
    enabled: isValidAccess && !initializedGlobalState,
  });

  // Initialize global state
  useEffect(() => {
    if (organizations.length && portfolios.length && !initializedGlobalState) {
      initGlobalState(organizations, portfolios);
    }
  }, [organizations, portfolios, initializedGlobalState]);

  useHandleErrors(organizationsQuery);
  useHandleErrors(portfoliosQuery);

  useTeamsInitializer();
  useProcessAnalysisInitializer();
};

/**
 * Initializes the teams for the selected portfolio.
 *
 * @return {void}
 */
const useTeamsInitializer = (): void => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const isValidAccess = Boolean(getRequestToken());

  const { projects: teams, query } = usePortfolioProjects(portfolio?.id, {
    enabled: Boolean(portfolio?.id) && isValidAccess,
    staleTime: 1000 * 60 * 5,
  });

  // Initialize portfolio teams
  useEffect(() => {
    if (teams) {
      setTeams(teams);
    }
  }, [teams]);

  useHandleErrors(query);
};

/**
 * Initializes the process analysis state by setting the information based on teams and marking the state as initialized.
 *
 * @return {void}
 */
const useProcessAnalysisInitializer = (): void => {
  const initializedProcessAnalysisState = useProcessAnalysisStore((state) => state.initialized);
  const teams = useGlobalStore((state) => state.teams);

  const shouldInitialize = teams.length > 0 && !initializedProcessAnalysisState;

  // Initialize process analysis state
  useEffect(() => {
    if (shouldInitialize) {
      initProcessAnalysisState(teams);
    }
  }, [shouldInitialize, teams]);
};

/**
 * Handles errors from a query by redirecting to the out-of-bloom page with the error state.
 *
 * @param {QueryObserverResult<T>} query - The result of the query.
 * @return {void}
 */
const useHandleErrors = <T>(query: QueryObserverResult<T>): void => {
  const navigate = useNavigate();

  useEffect(() => {
    if (query.isError) {
      const error = query.error as { status: number; statusText: string };
      const errorState = {
        errorMessage: error?.statusText,
        errorStatus: error?.status,
      };
      navigate(`/application/out-of-bloom`, { state: errorState });
    }
  }, [query, navigate]);
};

export { useDataInitializer };
