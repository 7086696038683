import { UnstyledButton } from '@mantine/core';
import { styled } from 'linaria/react';
import React, { useCallback, useState } from 'react';
import { Initiative } from '../../../api/initiative-client/initiative-client.type';
import { newCOLORS } from '../../../styles/colors';
import { toLocalDate } from '../../strategy/initiatives-list/initiatives-list.helpers';
import { Status } from '../../strategy/initiatives-list/initiatives-table';
import { icons } from '../../tasks-table/assets';
import { sortInitiatives } from './initiative-overview-table.helpers';

type SortedColumn = 'name' | 'status' | 'completion' | 'businessValue' | 'startDate' | 'endDate';

export function InitiativeOverviewTable({ initiatives }: { initiatives: Initiative[] | undefined }) {
  const [sortBy, setSortBy] = useState<SortedColumn>('name');
  const [sortDesc, setSortDesc] = useState<boolean>(false);

  const setSorting = useCallback(
    (field: SortedColumn) => {
      const desc = field === sortBy ? !sortDesc : false;
      setSortDesc(desc);
      setSortBy(field);
    },
    [sortBy, sortDesc]
  );

  const sortedInitiatives = sortInitiatives(initiatives || [], sortBy, sortDesc);

  return (
    <TableContainer>
      <GridContainer cols={6}>
        <LabelHeader>
          <SortingTableHeader onSort={() => setSorting('name')} isActive={sortBy === 'name'} desc={sortDesc}>
            Initiatives
          </SortingTableHeader>
        </LabelHeader>
        <ColumnHeader>
          <SortingTableHeader onSort={() => setSorting('status')} isActive={sortBy === 'status'} desc={sortDesc}>
            Status
          </SortingTableHeader>
        </ColumnHeader>
        <ColumnHeader>
          <SortingTableHeader
            onSort={() => setSorting('completion')}
            isActive={sortBy === 'completion'}
            desc={sortDesc}
          >
            Completion
          </SortingTableHeader>
        </ColumnHeader>
        <ColumnHeader>
          <SortingTableHeader
            onSort={() => setSorting('businessValue')}
            isActive={sortBy === 'businessValue'}
            desc={sortDesc}
          >
            Business Value
          </SortingTableHeader>
        </ColumnHeader>
        <ColumnHeader>
          <SortingTableHeader onSort={() => setSorting('startDate')} isActive={sortBy === 'startDate'} desc={sortDesc}>
            Start Date
          </SortingTableHeader>
        </ColumnHeader>
        <ColumnHeader>
          <SortingTableHeader onSort={() => setSorting('endDate')} isActive={sortBy === 'endDate'} desc={sortDesc}>
            End Date
          </SortingTableHeader>
        </ColumnHeader>
        {sortedInitiatives.map((initiative) => (
          <React.Fragment key={initiative.id}>
            <GridCell>{initiative.name}</GridCell>
            <GridCell>
              <Status status={initiative.status?.status} />
            </GridCell>
            <GridCell>{initiative.percent_progress}%</GridCell>
            <GridCell>{initiative.relative_business_value}</GridCell>
            <GridCell>{toLocalDate(initiative.start_date).format('MM/DD/YYYY')}</GridCell>
            <GridCell>{toLocalDate(initiative.end_date).format('MM/DD/YYYY')}</GridCell>
          </React.Fragment>
        ))}
      </GridContainer>
    </TableContainer>
  );
}

const SortingTableHeader = ({
  children,
  isActive,
  onSort,
  desc,
}: {
  children: React.ReactNode;
  isActive: boolean;
  onSort: () => void;
  desc: boolean;
}) => (
  <UnstyledButton onClick={onSort} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
    {children}
    <SortableColumnIndicator isActive={isActive} desc={desc} />
  </UnstyledButton>
);

const SortableColumnIndicator = ({ isActive, desc }: { isActive: boolean; desc: boolean }) => {
  if (!isActive) {
    return <img src={icons.iconSortingArrowDown} width={16} height={16} />;
  }
  return <img src={desc ? icons.iconSortingArrowDownActive : icons.iconSortingArrowUpActive} width={16} height={16} />;
};

const TableContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  border: 1px solid ${newCOLORS.lightGray};
`;

const GridContainer = styled.div<{ cols: number }>`
  display: grid;
  background-color: ${newCOLORS.lightGray};
  grid-gap: 1px;
  grid-template-columns: ${({ cols }) => `repeat(${cols}, 1fr)`};
`;

const GridHeader = styled.div`
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const LabelHeader = styled(GridHeader)`
  color: ${newCOLORS.darkGray};
`;

const ColumnHeader = styled(GridHeader)`
  color: ${newCOLORS.darkGray};
`;

const GridCell = styled.div`
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  padding: 10px;
  color: ${newCOLORS.darkGray};
`;
