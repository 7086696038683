import * as Sentry from '@sentry/browser';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { Epic, MinifiedTask, Task, TaskAuditLogEntry } from './task-client.type';
const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Posts a comment to a given task.
 * @returns comment data
 */
async function postTaskComment(taskId: string, comment: string) {
  const apiURL = baseURL + `/api/tasks/${taskId}/comment/`;
  const postBody = { comment: comment, tag_assignee: true };
  return await axiosInstance
    .post<{ redirect_url: string }>(apiURL, postBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorData = error?.response?.data;
      Sentry.captureException(errorData);
      return errorData;
    });
}

const fetchTasks = async ({
  sprintId,
  filter,
  day,
}: {
  sprintId: string;
  filter: string;
  day: string;
}): Promise<Task[]> => {
  const url = new URL(baseURL + `/api/tasks/`);
  const params = new URLSearchParams(url.search);
  params.append('sprint_id', sprintId);
  params.append('filter', filter);
  params.append('day', day);
  url.search = params.toString();

  return await axiosInstance
    .get<Task[]>(url.toString())
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the tasks');
    });
};

const fetchProjectsTasks = async ({
  portfolioId,
  projectId,
  filter,
  startDate,
  endDate,
  category,
}: {
  portfolioId: string | undefined;
  projectId: string | undefined;
  category: string | undefined;
  filter: string;
  startDate: string;
  endDate: string;
}): Promise<MinifiedTask[]> => {
  const url = new URL(baseURL + `/api/tasks/`);
  const params = new URLSearchParams(url.search);
  if (portfolioId) {
    params.append('portfolio_id', portfolioId);
  }
  if (projectId) {
    params.append('project_id', projectId);
  }
  if (category) {
    params.append('category', category);
  }
  params.append('filter', filter);
  params.append('start_date', startDate);
  params.append('end_date', endDate);
  url.search = params.toString();

  return await axiosInstance
    .get<MinifiedTask[]>(url.toString())
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the tasks');
    });
};

/**
 * Method to fetch the epics belonging to the given project IDs
 *
 * @param projectIds list of project ids
 * @returns a list of epics
 */
async function getEpicsForProjects(projectIds: string[]): Promise<Epic[]> {
  const url = new URL(baseURL + `/api/epics/`);
  const params = new URLSearchParams(url.search);
  params.append('project_ids', projectIds.join(','));
  url.search = params.toString();

  return await axiosInstance
    .get(url.toString())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching epics'));
}

/**
 * Fetches task audit log for a given task ID.
 * @param taskId Task ID to get the measures for
 * @returns Array of TaskAuditLogEntry objects for the task
 */
const fetchTaskAuditLog = async (taskId: string): Promise<TaskAuditLogEntry[]> => {
  const apiURL = baseURL + `/api/tasks/${taskId}/audit-log/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || 'There was a problem fetching the sprint audit log'));
};

export { fetchProjectsTasks, fetchTaskAuditLog, fetchTasks, getEpicsForProjects, postTaskComment };
