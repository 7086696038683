import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { dismissGlobalMessage } from '../../api/global-messages-client/global-messages-client';
import { GLOBAL_MAILTO_SUPPORT_LINK } from '../../constants';
import { GlobalContext, UserContext } from '../../contexts';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { useRoles } from '../../helpers/auth-helpers/auth.hooks';
import { newCOLORS } from '../../styles/colors';
import { GlobalMessageModal } from './global-message-modal';
import { ErrorSource, GlobalMessage, GlobalMessageCategory, GlobalMessageState } from './global-message.type';

type Props = {
  globalMessage: GlobalMessage;
};

export function GlobalMessageComponent({ globalMessage }: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useContext(UserContext);
  const { setGlobalMessage } = useContext(GlobalContext);

  const closeHandler = useCallback(() => {
    if (globalMessage.type === GlobalMessageCategory.API_RESPONSE) {
      dismissGlobalMessage(globalMessage.body.globalMessages[0].id);
      trackEvent(AnalyticsEventType.GlobalMessageBarClosed, { userContext: user });
    }

    setGlobalMessage(null);
  }, [globalMessage, setGlobalMessage, user]);

  return (
    <ContentContainer state={globalMessage.state}>
      <GlobalMessageText globalMessage={globalMessage} openModal={open} />
      <CloseButton onClick={closeHandler}>
        <Icon
          width="32"
          height="32"
          icon={'jam:close-rectangle'}
          color={globalMessage.state === GlobalMessageState.ERROR ? newCOLORS.white : newCOLORS.black}
        />
      </CloseButton>
      {globalMessage.type === GlobalMessageCategory.API_RESPONSE && (
        <GlobalMessageModal opened={opened} close={close} globalMessage={globalMessage.body.globalMessages[0]} />
      )}
    </ContentContainer>
  );
}

const GlobalMessageText = ({ globalMessage, openModal }: { globalMessage: GlobalMessage; openModal: () => void }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { isAdmin, admins } = useRoles();

  if (globalMessage.type === GlobalMessageCategory.API_RESPONSE) {
    return (
      <div>
        {globalMessage.body.message}{' '}
        <span onClick={() => trackEvent(AnalyticsEventType.GlobalMessageModalOpened, { userContext: user })}>
          <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openModal}>
            Learn more
          </span>
        </span>
      </div>
    );
  }

  const supportLink = (
    <ContactSupportLink href={GLOBAL_MAILTO_SUPPORT_LINK}>
      <LinkText>contact Customer Success</LinkText>
    </ContactSupportLink>
  );

  if (globalMessage.source === ErrorSource.INTEGRATIONS) {
    if (!isAdmin) {
      const adminName = admins[0]?.name;
      const contactText = adminName ? `Please contact ${adminName}.` : '';

      return <ErrorText>One or more of your integrations has an issue! {contactText}</ErrorText>;
    }

    const proceedLink = <LinkText onClick={() => navigate('/application/integrations-list')}>click here</LinkText>;

    return (
      <ErrorText>
        One or more of your integrations has an issue! To resolve, {proceedLink}, or {supportLink}.
      </ErrorText>
    );
  }

  if (globalMessage.source === ErrorSource.JDC) {
    return (
      <ErrorText>
        There are no projects to configure via Jira Data Center. Please try Jira Cloud as a possible option or{' '}
        {supportLink}.
      </ErrorText>
    );
  }
};

const ContentContainer = styled.div<{ state: GlobalMessageState }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: ${(props) => (props.state === GlobalMessageState.ERROR ? newCOLORS.red : newCOLORS.yellow)};
  padding: 0em 5em;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: #fff;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const LinkText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const ContactSupportLink = styled.a`
  color: #fff;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
