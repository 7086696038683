import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  Measure,
  MeasureDataByEntity,
  TimeAllocationType,
  Transformer,
  UseWorkPeriodsMeasurePayloadMultiple,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useAvailableMeasureNames,
  useDateRange,
  useEntities,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { getEntityType } from '../process-analysis.helpers';
import { splitMeasures } from './comparison-view.helpers';

const timeAllocationTransformerMeasures = [Measure.DeclinedChangeRequests, Measure.ReviewTime, Measure.Readiness];

/**
 * Custom hook that fetches the key measures over time data. It splits the measures into 6 groups and fetches the data for each group.
 *
 * @param {TimeAllocationType} overwriteTimeAllocation - The time allocation to overwrite the default time allocation.
 * @returns {{ data: MeasureDataByEntity | undefined; isFetching: boolean }} - An object containing the fetched data and an isFetching flag.
 */
const useKeyMeasuresOverTimeData = (
  overwriteTimeAllocation?: TimeAllocationType
): { data: MeasureDataByEntity | undefined; isFetching: boolean } => {
  const { defaultMeasures, transformerMeasures } = useMeasureFilter();
  const timeAllocation = useTimeAllocation();
  const timeAllocationToUse = overwriteTimeAllocation || timeAllocation;

  const groups = splitMeasures(defaultMeasures, 6);

  const { data: defaultDataFirst, isFetching: isFetchingDefaultDataFirst } = useWorkPeriodsMeasureMultiple(
    usePayload(groups[0], timeAllocationToUse)
  );

  const { data: defaultDataSecond, isFetching: isFetchingDefaultDataSecond } = useWorkPeriodsMeasureMultiple(
    usePayload(groups[1], timeAllocationToUse)
  );

  const { data: defaultDataThird, isFetching: isFetchingDefaultDataThird } = useWorkPeriodsMeasureMultiple(
    usePayload(groups[2], timeAllocationToUse)
  );

  const { data: defaultDataFourth, isFetching: isFetchingDefaultDataFourth } = useWorkPeriodsMeasureMultiple(
    usePayload(groups[3], timeAllocationToUse)
  );

  const { data: defaultDataFifth, isFetching: isFetchingDefaultDataFifth } = useWorkPeriodsMeasureMultiple(
    usePayload(groups[4], timeAllocationToUse)
  );

  const { data: defaultDataSixth, isFetching: isFetchingDefaultDataSixth } = useWorkPeriodsMeasureMultiple(
    usePayload(groups[5], timeAllocationToUse)
  );

  const transformerPayload = usePayload(transformerMeasures, timeAllocationToUse);
  const { data: transformerData, isFetching: isFetchingTransformerData } = useWorkPeriodsMeasureMultiple({
    ...transformerPayload,
    transformer: Transformer.TimeAllocationValuesOnly,
  });

  const data = Object.entries(defaultDataFirst || {})
    .concat(Object.entries(defaultDataSecond || {}))
    .concat(Object.entries(defaultDataThird || {}))
    .concat(Object.entries(defaultDataFourth || {}))
    .concat(Object.entries(defaultDataFifth || {}))
    .concat(Object.entries(defaultDataSixth || {}))
    .concat(Object.entries(transformerData || {}))
    .reduce((acc, [key, value]) => {
      acc[key] = { ...acc[key], ...value };
      return acc;
    }, {} as MeasureDataByEntity);

  // const data = useAggregatedData(defaultData, transformerData);
  const isFetching =
    isFetchingDefaultDataFirst ||
    isFetchingDefaultDataSecond ||
    isFetchingDefaultDataThird ||
    isFetchingDefaultDataFourth ||
    isFetchingDefaultDataFifth ||
    isFetchingDefaultDataSixth ||
    isFetchingTransformerData;

  return { data, isFetching };
};

/**
 * Custom hook that returns a payload for fetching work period measures.
 *
 * @param {TimeAllocationType} timeAllocation - The time allocation for the payload.
 * @return {UseWorkPeriodsMeasurePayloadMultiple} - The payload for fetching work period measures.
 */
const usePayload = (measures: Measure[], timeAllocation: TimeAllocationType): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
  };
};

/**
 * Custom hook that filters measures into two categories:
 *   - `defaultMeasures`: measures that don't have a transformer
 *   - `transformerMeasures`: measures that have a transformer
 *
 * @returns {{ defaultMeasures: Measure[]; transformerMeasures: Measure[] }} - An object containing both types of measures.
 */
const useMeasureFilter = (): { defaultMeasures: Measure[]; transformerMeasures: Measure[] } => {
  const measures = useAvailableMeasureNames();

  return measures.reduce(
    (acc, measure) => {
      const isTransformerMeasure = timeAllocationTransformerMeasures.includes(measure);

      if (isTransformerMeasure) {
        return { ...acc, transformerMeasures: [...acc.transformerMeasures, measure] };
      }

      return { ...acc, defaultMeasures: [...acc.defaultMeasures, measure] };
    },
    { defaultMeasures: [] as Measure[], transformerMeasures: [] as Measure[] }
  );
};

export { useKeyMeasuresOverTimeData, useMeasureFilter };
