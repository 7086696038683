import { Modal } from '@mantine/core';

type PopUpWindowProps = {
  content: React.ReactNode;
  title: string;
  show: boolean;
  closePopUp: () => void;
};

//TODO: Style the Modal here

export function PopUpWindow({ content, title, show, closePopUp }: PopUpWindowProps) {
  return (
    <Modal opened={show} onClose={closePopUp} title={title} size="auto">
      {content}
    </Modal>
  );
}
