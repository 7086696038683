import { Chart, Plugin } from 'chart.js';
import { newCOLORS } from '../../styles/colors';
import { addOpacityToColor } from '../string-helpers/string-helpers';

export const createWeekendBackgroundPlugin = (weekendOrHolidays: boolean[] = []): Plugin<'line'> => {
  return {
    id: 'weekendBackgroundElement',
    beforeDraw: (chart: Chart<'line'>) => {
      const {
        ctx,
        chartArea: { top, height },
        scales: { x },
      } = chart;

      ctx.save();
      const weekendHolidayPeriods: number[][] = [];
      let onePeriod: number[] = [];
      let beforeDayStatus = false;
      weekendOrHolidays.forEach((weekendOrHoliday: boolean, index: number) => {
        if (weekendOrHoliday === true) {
          onePeriod.push(index);
        }
        if (weekendOrHoliday === false && beforeDayStatus === true) {
          onePeriod.push(index);
          weekendHolidayPeriods.push(onePeriod);
          onePeriod = [];
        }
        beforeDayStatus = weekendOrHoliday;
      });
      if (onePeriod.length) {
        weekendHolidayPeriods.push(onePeriod);
      }

      weekendHolidayPeriods.map((period: number[]) => {
        bgColors(period[0], period[period.length - 1], addOpacityToColor(newCOLORS.lighterGray, 0.6));
      });

      function bgColors(bracketLeftIndex: number, bracketRightIndex: number, color: string) {
        ctx.fillStyle = color;
        ctx.fillRect(
          x.getPixelForTick(bracketLeftIndex),
          top,
          x.getPixelForTick(bracketRightIndex) - x.getPixelForTick(bracketLeftIndex),
          height
        );
        ctx.restore();
      }
    },
  };
};
