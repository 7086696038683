import { styled } from '@linaria/react';
import { MouseEventHandler } from 'react';
import betaLogoLight from '../../assets/images/BF-Logo-Primary-RGB-Color_Black.svg';
import betaLogoDark from '../../assets/images/BF-Logo-Primary-RGB-Color_White.svg';

type BrandProps = {
  style: any;
  width: number;
  height: number;
  onClick: MouseEventHandler;
  darkMode?: boolean;
  clickable?: boolean;
};

export function Brand({
  style,
  width = 200,
  height = 90,
  onClick,
  darkMode = false,
  clickable = true,
}: Partial<BrandProps>) {
  return (
    <BrandContainer style={{ ...style, width, height }}>
      <img
        alt="Bloomfilter Logo"
        src={darkMode ? betaLogoDark : betaLogoLight}
        width="100%"
        height="100%"
        onClick={onClick}
        style={clickable ? { cursor: 'pointer' } : { cursor: 'default' }}
      />
    </BrandContainer>
  );
}

const BrandContainer = styled.div`
  margin-left: 10;
  display: flex;
  flex: 0.98;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
