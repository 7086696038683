import dayjs from 'dayjs';
import moment, { Moment } from 'moment-timezone';
import { DateTemplate } from './timezone.type';

/**
 * Method to convert the date string to the given template and timezone
 * @param dateString datetime string
 * @param timezone target timezone
 * @param template date template
 * @returns formatted datetime string with timezone info
 */
const formatDate = (dateString: string, timezone: string, template: DateTemplate): string => {
  const date: Moment = moment.tz(dateString, timezone);
  const formattedDate: string = date.locale('en').format(template);

  return formattedDate;
};

/**
 * Method to convert the 24 hours time string to 12 hours format
 * @param timeString 24-hours time string
 * @returns 12-hours time string
 */
const convertTo12HourFormat = (timeString: string): string => {
  const [hoursStr, minutesStr] = timeString.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Create a Date object with the current date and the given time
  const dateObj = new Date();
  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);

  // Use the date.toLocaleTimeString method with the desired options
  const formattedTimeString = dateObj.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return formattedTimeString;
};

/**
 * Method to convert the time string to UTC time
 * @param timeString selected time string
 * @returns UTC equivalent time string
 */
const getUTCTime = (timeString: string): string => {
  // grammar-check disable-next-line UNLIKELY_OPENING_PUNCTUATION
  const [hours, minutes] = timeString.split(':').map(Number);
  const currentDate = dayjs();
  const newDate = currentDate.set('hour', hours).set('minute', minutes);
  return newDate.utc().format('HH:mm');
};

export { convertTo12HourFormat, formatDate, getUTCTime };
