import { styled } from '@linaria/react';
import { Group, Modal } from '@mantine/core';
import { Button } from '../../../components/button/button';

type Props = {
  opened: boolean;
  initiativeName: string;
  handleClose: () => void;
  handleSubmit: () => void;
};

export function InitiativeDeleteModal({ opened, initiativeName, handleClose, handleSubmit }: Props) {
  return (
    <StyledModal
      size={540}
      opened={opened}
      onClose={handleClose}
      title="Delete Initiative"
      centered
      data-testid="initiative-delete-modal"
    >
      <ModalBody>
        <ModalBodyText>
          This will delete the {initiativeName} initiative from Bloomfilter. It does not impact any integrated source
          systems.
        </ModalBodyText>
        <StyledDivider />
        <Group>
          <Button onClick={handleSubmit}>Continue</Button>
          <Button onClick={handleClose} variant="outline">
            Cancel
          </Button>
        </Group>
      </ModalBody>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .mantine-Modal-content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .mantine-Modal-header {
    display: flex;
    height: 58px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    padding: 0;
  }

  .mantine-Modal-title {
    color: #444;
    font-family: Figtree;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mantine-Modal-body {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    align-self: stretch;
    padding: 0;
  }

  .mantine-Button-root {
    padding: 8px 12px
    font-size: 16px;
    font-weight: 400;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const ModalBodyText = styled.section`
  height: 105px;
  color: #000;
  font-size: 15px;
`;

const StyledDivider = styled.hr`
  width: 100%;
  height: 1px;
  background: #cecece;
  margin: 0;
`;
