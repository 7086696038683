import dayjs from 'dayjs';
import { WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';

export const calculateDate = (
  workPeriod: WorkPeriod | null,
  activeDay: string | null,
  workPeriodLength: number
): string => {
  if (!workPeriod || !activeDay) {
    return '';
  }

  return dayjs(workPeriod.start_date)
    .add(Number(activeDay) - (1 % workPeriodLength), 'day')
    .format('YYYY-MM-DD');
};
