import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../views/views.module.css';
import { NotionDatabase, NotionState } from './notion.type';

type DatabasesProps = {
  notionState: NotionState;
  updateState: (update: Partial<NotionState>) => void;
};

export const Databases = ({ notionState, updateState }: DatabasesProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);

  const handleDatabaseSelected = (database: NotionDatabase) => {
    if ((notionState.selectedDatabases || []).filter((t: NotionDatabase) => t.id === database.id).length > 0) {
      updateState({
        selectedDatabases: (notionState.selectedDatabases || []).filter((t: NotionDatabase) => t.id !== database.id),
      });
    } else {
      updateState({ selectedDatabases: [...(notionState.selectedDatabases || []), database] });
    }
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select at least one database you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(notionState.databaseOptions || []).map((database: NotionDatabase) => (
          <div
            key={database.name}
            className={
              (notionState.selectedDatabases || []).filter((t: NotionDatabase) => t.id === database.id).length > 0
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleDatabaseSelected(database)}
          >
            <span className={styles.checkboxLabel}>{database.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
