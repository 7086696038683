import { styled } from '@linaria/react';
import { memo, useCallback, useContext } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { Stages } from '../../../api/process-client/process-client.type';
import { ProcessContext } from '../context/process.context';
import { Text } from '../process.styled';
import { getBackground, getBorder } from './stage-node.helpers';

const StageNode = memo(({ data }: NodeProps) => {
  return (
    <>
      <StageNodeComponent data={data} />
      <Handle type="target" position={Position.Top} id="top" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="target" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Left} id="left" />
      <Handle type="target" position={Position.Left} id="left" />
    </>
  );
});

const StageNodeComponent = ({ data }: { data: NodeProps['data'] }) => {
  const { stages, setStages } = useContext(ProcessContext);

  const onClick = useCallback(
    (stage: Stages) => {
      if (stages.includes(stage)) {
        setStages(stages.filter((s) => s !== stage));
      } else {
        setStages([...stages, stage]);
      }
    },
    [stages, setStages]
  );

  const isActive = stages.includes(data.stage);
  const isGrayed = data.stage === Stages.Blocked;

  return (
    <NodeContainer active={isActive} grayed={isGrayed} onClick={() => onClick(data.stage)}>
      <Text fontSize={12} active={isActive} grayed={isGrayed}>
        {data.title}
      </Text>
      {data.customerStages.map((stage: string, index: number) => (
        <Text
          key={stage}
          fontSize={14}
          fontWeight={700}
          active={isActive}
          grayed={isGrayed}
          style={{ wordWrap: 'break-word' }}
        >
          {`${stage}${index === data.customerStages.length - 1 ? '' : ','}`}
        </Text>
      ))}
    </NodeContainer>
  );
};

export { StageNode, StageNodeComponent };

const NodeContainer = styled.div<{ active: boolean; grayed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  border-radius: 6px;
  border: 1px solid ${(props: { active: boolean; grayed: boolean }) => getBorder(props.active, props.grayed)};
  background: ${(props: { active: boolean; grayed: boolean }) => getBackground(props.active, props.grayed)};
  padding: 12px 6px;
  cursor: pointer;
`;
