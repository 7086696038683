import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { getProcessGraph, getProcessMapping, getProcessTasks } from './process-client';
import {
  GraphData,
  ProcessGraphConfig,
  ProcessMapping,
  ProcessTask,
  ProcessTasksConfig,
  ProcessTasksData,
  Stages,
} from './process-client.type';

/**
 * Query manager for fetching process map graph data
 *
 * @param {ProcessGraphConfig} config - Configuration object for the query
 * @param {UseQueryOptions<GraphData>} options - Additional options for the query (optional)
 * @return {{ graphData: GraphData, query: UseQueryResult<GraphData> }} - An object containing the graph data and the query
 */
const useProcessGraph = (
  { teamId, graphType, startDate, endDate }: ProcessGraphConfig,
  options?: UseQueryOptions<GraphData>
) => {
  const query = useQuery({
    queryKey: ['process-graph', teamId, graphType, startDate, endDate],
    queryFn: () =>
      teamId ? getProcessGraph(teamId, graphType, startDate, endDate) : Promise.reject('Cannot get process graph data'),
    ...options,
  });

  return { graphData: query.data, query };
};

/**
 * Query manager for fetching bf/customer stages mapping data
 *
 * @param {string | undefined} teamId - the ID of the team
 * @param {UseQueryOptions<ProcessMapping>} options - additional options for the query (optional)
 * @return {{ mapping: ProcessMapping, query: UseQueryResult<ProcessMapping> }} - the mapping data and the query object.
 */
const useProcessMapping = (teamId: string | undefined, options?: UseQueryOptions<ProcessMapping>) => {
  const query = useQuery({
    queryKey: ['process-mapping', teamId],
    queryFn: () => (teamId ? getProcessMapping(teamId) : Promise.reject('Cannot get process mapping')),
    ...options,
  });

  return { mapping: query.data, query };
};

/**
 * Query manager for fetching process tasks
 *
 * @param {ProcessTasksConfig} config - Configuration object for the query
 * @param {UseQueryOptions<ProcessTasksData>} options - Additional options for the query (optional)
 * @return {{ taskData: ProcessTasksData | undefined, query: UseQueryResult<ProcessTasksData> }} - An object containing the tasks and the query result
 */
const useProcessTasks = (
  { teamId, startDate, endDate, mapping }: ProcessTasksConfig,
  options?: UseQueryOptions<ProcessTasksData>
) => {
  const permutations = getCustomerStagesPermutations(mapping || ({} as Record<Stages, string[]>));

  const queries = useQueries({
    queries: permutations.map((permutation) => ({
      queryKey: ['process-tasks', teamId, startDate, endDate, permutation],
      queryFn: () =>
        teamId ? getProcessTasks(teamId, startDate, endDate, permutation) : Promise.reject('Cannot get process tasks'),
      ...options,
    })),
  });

  const taskData = queries.reduce((acc, queryResult) => {
    const { data: { tasks = [] } = {} } = queryResult;

    if (tasks.length > 0) {
      const filteredTasks = tasks.filter((task) => !acc.find((t) => t.id === task.id));

      return [...acc, ...filteredTasks];
    }

    return acc;
  }, [] as ProcessTask[]);

  const isFetching = queries.some((query) => query.isFetching);

  return { taskData, isFetching };
};

/**
 * Generates an array of permutations between source and destination stages based on a given mapping.
 *
 * @param {Record<Stages, string[]>} mapping - The mapping of stages.
 * @returns {string[]} - An array of permutations between source and destination stages.
 */
const getCustomerStagesPermutations = (mapping: Record<Stages, string[]>): string[] => {
  const customerStagesMappings = Object.values(mapping);
  const [sourceStages = [], destinationStages = []] = customerStagesMappings;

  return sourceStages.reduce((acc, sourceStage) => {
    destinationStages.forEach((destinationStage) => {
      acc.push(`${sourceStage},${destinationStage}`);
    });

    return acc;
  }, [] as string[]);
};

export { useProcessGraph, useProcessMapping, useProcessTasks };
