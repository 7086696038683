const MEASURES = {
  STRATEGY: {
    label: 'Strategy',
    description: '% tied to an epic',
    issueProps: {
      title: 'Strategy',
      measure: 'strategy',
      summary: 'Percent tied to an epic',
      description:
        'The issue is mapped to an Epic. This does not happen by default. The system of record user must create the mapping. It is a conscious decision to map an issue to an epic. Epics *should* only be used to describe features or capabilities that are significant strategic investments.',
      calculation:
        'Bloomfilter calculates this by totaling the number of issues in the sprint that are mapped to an Epic, and then dividing by total number of issues in the sprint.',
      solution:
        'If you want to allocate more capacity to strategic work, map your cards to the epics that they roll-up to in your system of record. If you aren’t currently using epics, here is a great [article](https://www.atlassian.com/agile/project-management/epics).\n\nBloomfilter considers rolling tasks up into epics, when appropriate, to be an industry best practice for reporting and understanding how resources are utilized within an organization.',
    },
    suffix: '%',
  },

  COMPLEXITY: {
    label: 'Complexity',
    description: 'avg. estimate for pointed tasks',
    issueProps: {
      title: 'Complexity',
      measure: 'complexity',
      summary: 'Average estimate for pointed tasks',
      // grammar-check disable-next-line ENGLISH_WORD_REPEAT_BEGINNING_RULE
      description:
        'Points should describe the product’s team estimate of how complex an issue is. Points give the team a sense of the uncertainty, labor, or skill needed to complete the card. Points are often confused to mean a time estimate. Even when using a 1:1 point-to-days ratio, points are rarely a predictable indicator of how much a team can do, but rather how many challenging things they are planning to do in the cycle. The Fibonacci scale is the most frequently used point scale for complexity.',
      calculation:
        'Our system calculates this by totaling the number of points in the sprint then dividing by total number of pointed issues in the sprint',
      solution:
        'By breaking down your stories into small units of work, thus reducing the complexity of any given unit of work in a sprint, your stories will be much simpler to comprehend and easier to complete to specification. This will also lower the overall complexity of your sprint.\n\nBloomfilter considers small units of independent work to be an industry best practice for creating predictable and efficient outcomes for software teams.',
    },
  },

  QUALITY: {
    label: 'Quality',
    description: 'bug/debt share',
    issueProps: {
      title: 'Quality',
      measure: 'quality',
      summary: 'Bug and Tech Debt share',
      description:
        'This measure indicates how much of the sprint is made up of bug or technical debt tasks. There is no estimate of complexity associated with these cards, which makes quality critical to manage and ensure progress is happening that moves the product forward.',
      calculation:
        'This is calculated by totaling the number of bug issues and the total number of tasks labeled tech debt in the sprint, then dividing by the total number of issues in the sprint.',
      solution:
        'If you wish to increase the amount of work labeled “quality”, increase the amount of bugs and technical debt tasks within a sprint. This will lower commitment to the number of strategic and tactical tasks in a sprint. \n\nIf you wish to decrease the amount of work labeled “quality”, increase the number of cards attached to an epic or tactical features, and decrease the number of bugs and technical debt items in a sprint.\n\nThis number is contextual based on the goals of the team. If your goal is to ship more features, you should aim to keep this number lower. If your goal is sustainability and maintenance of the system, this number should be higher. It is important to set the context in which a team is operating so that they understand their goals.',
    },
    suffix: '%',
  },

  SCOPE: {
    label: 'Scope',
    description: 'tasks +/- to plan',
    issueProps: {
      title: 'Scope',
      measure: 'scope',
      summary: 'Tasks added or removed to plan',
      description:
        'This measure indicates how many issues were added or removed from the sprint after it was planned. Starting a sprint in the system of record is a one-time thing. Once you start a sprint, you can’t pause or stop it. You can only pull things into and out of it.\n\nNote: It’s not totally uncommon to accidentally add an issue to an active sprint instead of putting in the backlog as the system of record user intended. In those moments, we can’t tell that it’s an accident.',
      calculation:
        'Let: \n * n = the total number of issues planned for the sprint \n * a = the number of issues added to the sprint prior to the sprint close date \n * r = the number of issues removed from the sprint prior to sprint close date \n\nThis is calculated as the percentage of change: <br> (a - r) / n',
      solution:
        'Unless absolutely critical, the tasks that were added mid-sprint should be moved to the backlog and prioritized for completion in the following sprint. Focus on prioritizing more thoughtfully, and consider velocity trends when creating a new sprint.',
    },
    suffix: '%',
  },

  READINESS: {
    label: 'Readiness',
    description: 'defined tasks',
    issueProps: {
      title: 'Readiness',
      measure: 'readiness',
      summary: 'Defined tasks',
      description:
        'An issue card really only has value if it has a description (specification or requirements) and a refined complexity point or time estimate (it is refined when developers review the description, ask questions, and potentially break the issue out further). The issue isn’t ready if you’re just doing the minimum in the system of record (creating a new issue with just a title). Despite how commonsense this seems, it happens quite often, due to the hectic pace of a development cycle and the frequency of issue cards that spin out of conversation.',
      calculation:
        'Our system calculates this by totaling the number of issues that are missing a name, description, or point estimate for stories and tasks, then dividing by the total number of issues in the sprint.\n\nIf Bloomfilter is configured to account for pointed bugs and bugs are pointed by the team (determined in onboarding flow), that is also added to the calculation.',
      solution:
        'Ideally, all work is scoped and ready for development before a sprint begins. This means that you’ve set all requirements, acceptance criteria, the team has estimated the effort for the work and all of this has been documented within the card in your system of record.\n\nThis is important for observability, predictability and efficiency as it allows for the team to know the exact scope and size of what they are committing to before they commit to it. If work is defined during a sprint, the odds of completing the sprint go down dramatically.',
    },
    suffix: '%',
  },

  INDEPENDENCE: {
    label: 'Independence',
    description: 'Tasks with zero dependency',
    issueProps: {
      title: 'Independence',
      measure: 'independence',
      summary: 'tasks with zero dependency',
      description:
        'Part of planning a sprint is understanding the sequence that issues need to be resolved in, and not just their priority. A frequently-used function in the system of record is to link two issue cards together when one is blocking or is blocked by the other. This alerts the team that they can’t start a particular card until work is done on the issue that blocks it.',
      calculation:
        'Bloomfilter calculates this by totaling the number issues that are blocked or are blocking another card as designated by the “Link Issue” function in your system of record, then dividing by the total number of issues in the sprint.',
      solution:
        // grammar-check disable-next-line IN_OR_WITH_REGARDS_TO_OF
        'To keep independence high, remove dependent cards from the sprint and work on independent cards each sprint.\n\nIndependence is a best practice with regards to predictability, and efficiency because work can be done in parallel. If your team’s cycle time is high, and they are working on highly dependent work, the odds of completing any given commitment goes down dramatically.',
    },
    suffix: '%',
  },

  REVIEWTIME: {
    label: 'Review Time',
    description: 'Avg. days PR is open',
    issueProps: {
      title: 'Review Time',
      measure: 'average_days_open_for_change_requests',
      summary: 'avg. days PR is open',
      description:
        'The average amount of time, in days, your developers’ pull requests remain open before they are merged into an upstream branch in your source control repositories. \n\nA "pull request" is a request from one developer to another developer to merge code into a branch of code. Branches are typically the code that is running in some environment, with a main branch of code that is running in production. Pull requests are used to review code before it gets merged.',
      calculation:
        'Bloomfilter looks at all pull requests opened during a sprint, totals the time delta between when those pull requests were opened and when they were merged, and then averages that over the number of total pull requests in the sprint.',
      solution:
        'Ideally, pull requests are opened and merged on the same day. This allows for the smooth review and flow of code into branches which reduces the chances of merge conflicts and other issues that cause slowdowns and waste within a development process. However, it is common for pull requests to be open for multiple days. \n\nIf you wish to reduce the time, you should: \n - Reduce the size of the pull requests in both lines of code and the number of files changed. \n - Assign someone to review the code, so there is accountability. \n - Set a policy around how long pull requests are allowed to be open.',
    },
  },

  ABANDONED: {
    label: 'PR closed (%)',
    description: 'PR closed (%)',
    issueProps: {
      title: 'PR closed (%)',
      measure: 'percentage_of_declined_change_requests',
      summary: 'PR closed (%)',
      description:
        'The amount of pull requests in a sprint that are opened then closed, but not merged. This usually means that the work done needs to be redone or never makes it to any user environment, signally waste in your process.',
      calculation:
        'Bloomfilter totals all pull requests opened during a sprint then totals the number of pull requests that were closed but not merged, then calculates the percentage of "abandoned" pull requests.',
      solution:
        'Ideally, pull requests are merged after being reviewed by another developer on the team. If you have a high "abandoned" rate, this may signal that developers do not fully understand the work that they are doing (e.g. the work doesn’t have a great definition) or signal that the development team is often starting and stopping tasks because of shifts in priorities (aka "thrashing"). \n\nThe two key metrics that can help identify the problem in Bloomfilter are Readiness (how ready is the work) and Strategic (how much work is tied to planned, strategic work).',
    },
    suffix: '%',
  },
};

export { MEASURES };
