import { memo } from 'react';
import { BaseEdge, EdgeProps, getStraightPath } from 'reactflow';
import { WorkflowStatus } from '../../../api/process-client/process-client.type';
import { getAnimatedDotRadius } from '../helpers/edges.helpers';

const WorkflowEdgeIn = memo(({ data, sourceX, sourceY, targetX, targetY, style = {}, markerEnd }: EdgeProps) => {
  const calculatedSourceX = data.status === WorkflowStatus.Backwards ? sourceX + 5 : sourceX - 5;
  const calculatedTargetY = data.status === WorkflowStatus.Backwards ? targetY + 15 : targetY + 5;

  const [edgePath] = getStraightPath({
    sourceX: calculatedSourceX,
    sourceY: sourceY,
    targetX: targetX,
    targetY: calculatedTargetY,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <circle r={getAnimatedDotRadius(style.strokeWidth)} fill={style.stroke}>
        <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} begin={`${Math.random() * 2}s`} />
      </circle>
    </>
  );
});

const WorkflowEdgeOut = memo(({ data, sourceX, sourceY, targetX, targetY, style = {}, markerEnd }: EdgeProps) => {
  const calculatedSourceY = data.status === WorkflowStatus.Backwards ? sourceY - 15 : sourceY - 5;
  const calculatedTargetX = data.status === WorkflowStatus.Backwards ? targetX + 3 : targetX - 3;

  const [edgePath] = getStraightPath({
    sourceX: sourceX,
    sourceY: calculatedSourceY,
    targetX: calculatedTargetX,
    targetY: targetY,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <circle r={getAnimatedDotRadius(style.strokeWidth)} fill={style.stroke}>
        <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} begin={`${Math.random() * 2}s`} />
      </circle>
    </>
  );
});

export { WorkflowEdgeIn, WorkflowEdgeOut };
