import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { Issue } from '../../api/issues-client/issues-client.class';
import { newCOLORS } from '../../styles/colors';

type IssueListCardProps = {
  issues: Issue[];
  maxToDisplay?: number;
};

export function IssueListCard({ issues }: IssueListCardProps) {
  return (
    <IssueCardContainer>
      <IssueList issues={issues} />
    </IssueCardContainer>
  );
}

export type IssueListProps = {
  issues: Issue[];
  maxToDisplay?: number;
};

export function IssueList({ issues, maxToDisplay = 10 }: IssueListProps) {
  return (
    <IssueListContainer>
      {(issues || []).slice(0, maxToDisplay).map((issue) => (
        <IssueItemContainer key={issue.id}>
          <Tooltip multiline w={300} label={issue.tooltip}>
            <FlexItem>
              <Icon icon="mdi:information-outline" width={24} height={24} color={newCOLORS.blue} />{' '}
              <IssueCardText>{issue.summary}</IssueCardText>
            </FlexItem>
          </Tooltip>
        </IssueItemContainer>
      ))}
    </IssueListContainer>
  );
}

const IssueCardContainer = styled.div`
  background-color: ${newCOLORS.white};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
`;

const IssueListContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: repeat(5, 1.5em);
  grid-auto-flow: column;
  row-gap: 1.5em;
  column-gap: 1.5em;
`;

const IssueItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 7px;
  gap: 12px;
`;

const FlexItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
`;

// shared style for text
const IssueCardText = styled.div`
  margin: 0;
  min-width: max-content;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
