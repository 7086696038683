import { IntegrationType } from '../api/integrations-client/integrations-client.type';
import { ConfigurationState, Project, ProjectsResponse, Subproject } from '../api/projects-client/projects-client.type';

/**
 * Filters the subprojects of a given project based on the integration type.
 *
 * @param {Project | ProjectsResponse} project - The project object containing subprojects.
 * @return {Project} The project object with filtered subprojects.
 */
const filterSubprojects = (project: Project | ProjectsResponse): Project => {
  const integrationType = getIntegrationType(project);

  return {
    ...project,
    subprojects: project.subprojects.filter((subproject: Subproject) => {
      if (integrationType === IntegrationType.JDC) {
        return subproject.configuration_state === ConfigurationState.InitiallyConfigured;
      }

      return subproject;
    }),
  } as Project;
};

/**
 * Returns the integration type of a project or projects response.
 *
 * @param {Project | ProjectsResponse} project - The project or projects response object.
 * @return {IntegrationType | null} The integration type of the project or null if it is not found.
 */
const getIntegrationType = (project: Project | ProjectsResponse): IntegrationType | null => {
  if ('integration_type' in project) {
    return project.integration_type;
  } else if ('pm_tool' in project) {
    return project.pm_tool as IntegrationType;
  } else {
    return null;
  }
};

export { filterSubprojects };
