import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSprint, fetchSprintTaskFlowHistory } from '../../api/sprints-client/sprints-client';
import { Sprint, TaskFlowHistoryResponse, TaskFlowHistoryType } from '../../api/sprints-client/sprints-client.type';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { TaskFlow } from '../../components/task-flow/task-flow';
import { ProjectContext, SidebarContext } from '../../contexts';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';

export const TaskFlowHistory = () => {
  const navigate = useNavigate();
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  const [sprint, setSprint] = useState<Sprint | null>(null);
  const [taskFlowHistory, setTaskFlowHistory] = useState<TaskFlowHistoryType | null>(null);
  const { project, setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);

  const breadCrumbitems: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
    { labelName: project?.name as string, href: `/application/project/${projectId}` },
    {
      labelName: sprint?.name as string,
      href: `/application/project/${projectId}/sprint-assessment/${sprintId}`,
    },
    { labelName: 'Task Flow' },
  ];

  useQuery(
    ['sprint', sprintId],
    () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to resolve SprintId or SprintID is missing')),
    {
      enabled: !!sprintId,
      onSuccess: setSprint,
      onError: () => console.error('There was an issue fetching sprint data.'),
    }
  );

  useQuery(['fetchSprintTaskFlowHistory', sprintId], () => fetchSprintTaskFlowHistory(sprintId as string), {
    enabled: !!sprintId,
    onSuccess: (data: TaskFlowHistoryResponse) => {
      setTaskFlowHistory(data.jira_history);
    },
    onError: (error: any) => {
      const errorState = { errorMessage: error?.statusText, errorStatus: error?.status };
      navigate(`/application/out-of-bloom`, { state: errorState });
    },
  });

  return (
    taskFlowHistory &&
    sprint && (
      <TaskFlow
        taskFlowData={taskFlowHistory}
        startDate={new Date(sprint.start_date)}
        endDate={new Date(sprint.end_date)}
        breadCrumbitems={breadCrumbitems}
      />
    )
  );
};
