import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Issue } from '../../api/issues-client/issues-client.class';
import { fetchProject, fetchProjectMetrics } from '../../api/projects-client/projects-client';
import {
  Project,
  ProjectMetricsResponse,
  Subproject,
  SubprojectMethodology,
  SubprojectMetricsData,
  SubprojectMetricsResponse,
} from '../../api/projects-client/projects-client.type';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { HealthGauge } from '../../components/health-gauge/health-gauge';
import { IssueCard } from '../../components/issue-card/issue-card';
import { IssueListCard } from '../../components/issue-list-card/issue-list-card';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { ProjectStatisticsGrid } from '../../components/project-statistics-grid/project-statistics-grid';
import { SubprojectSelector } from '../../components/subproject-selector/subproject-selector';
import { ProjectContext, SidebarContext, UserContext } from '../../contexts';
import { filterSubprojects } from '../../contexts/project.helpers';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsDimensionsProps, AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { useRoles } from '../../helpers/auth-helpers/auth.hooks';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import {
  BreadcrumbContainer,
  HealthBarContainer,
  IssueCardWrapper,
  IssueGridContainer,
  OuterPaddingDiv,
  SubtitleHeadingTag,
  TitleHeadingTag,
} from '../../styles/shared-styled-components';
import { IntegrationsContext } from '../integrations/context/integrations.context';
import { IntegrationState } from '../integrations/context/integrations.context.type';
import { IssueHistoryState } from '../issue-details/issue-details.type';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import styles from './project-details.module.css';

export const ProjectDetails = () => {
  useDocumentTitle('Project Details - Bloomfilter');
  const { user } = useContext(UserContext);
  const { isAdmin } = useRoles();
  const { project, subprojects, setProject, setSubprojects } = useContext(ProjectContext);
  const { viewAreaWidth, navItems, setNavItems } = useContext(SidebarContext);
  const { projectsState } = useContext(IntegrationsContext);
  const [selectedSubproject, setSelectedSubproject] = useState<Subproject>();
  const [selectedMetrics, setSelectedMetrics] = useState<SubprojectMetricsResponse>();
  const [selectedObject, setSelectedObject] = useState<Project | Subproject>();
  const [metricsResponse, setMetricsResponse] = useState<ProjectMetricsResponse>();
  const { projectId = '', subprojectId = '' } = useParams<{ projectId: string; subprojectId: string }>();
  const navigate = useNavigate();

  useQuery(
    ['projectMetrics', projectId, selectedSubproject?.id],
    projectId
      ? () => fetchProjectMetrics(projectId, selectedSubproject?.id)
      : () => Promise.reject('Project/subproject ID is required'),
    {
      enabled: !metricsResponse && Boolean(projectId && selectedSubproject?.id),
      onSuccess: (data: ProjectMetricsResponse) => {
        setMetricsResponse(data as ProjectMetricsResponse);
      },
      onError: () => setMetricsResponse({} as ProjectMetricsResponse),
    }
  );

  //NOTE: we are doing this query to solve BLOOM-1910 -
  //we expect the full Project object elsewhere, but sometimes it is only a ProjectsResponse object.
  //This is a hacky way to get the full Project object into the context, and should not persist long term.
  useQuery(['project', projectId], () => fetchProject(projectId), {
    enabled: !project || !project?.sprints,
    onSuccess: (response: Project) => {
      const project = filterSubprojects(response);

      setProject(project);
      setSubprojects(project.subprojects);
    },
    onError: (error: any) => {
      const errorState = { errorMessage: error?.statusText, errorStatus: error?.code };
      navigate(`/application/out-of-bloom`, { state: errorState });
    },
  });

  const navigateToSelectedObject = (subproject?: Subproject) => {
    if (subproject) {
      navigate(`/application/project/${projectId}/subproject/${subproject?.id}`);
    } else {
      navigate(`/application/project/${projectId}`);
    }
    setSelectedSubproject(subproject);
  };

  useEffect(() => {
    if (subprojects && subprojects.length > 0) {
      if (subprojects.length === 1) {
        setSelectedSubproject(subprojects[0]);
      } else {
        setSelectedSubproject(subprojects.find((x: Subproject) => x.id === (subprojectId || '')) ?? subprojects[0]);
      }
    }
  }, [subprojectId, subprojects]);

  useEffect(() => {
    if (metricsResponse) {
      if (!selectedSubproject) {
        setSelectedMetrics(
          Object.assign({}, metricsResponse, { health: project?.health }) as SubprojectMetricsResponse
        );
      } else {
        const metric = metricsResponse.metrics?.find(
          (x: SubprojectMetricsData) => x.subproject_id.toString() == selectedSubproject?.id
        );
        setSelectedMetrics(metric?.details as SubprojectMetricsResponse);
      }
    }
  }, [selectedSubproject, metricsResponse, project]);

  useEffect(() => {
    if (!selectedSubproject) {
      if (subprojects && subprojects.length === 1) {
        setSelectedObject(subprojects[0]);
      } else if (project) {
        setSelectedObject(project);
      }
    } else {
      setSelectedObject(selectedSubproject);
    }
  }, [project, selectedSubproject, subprojects]);

  const issueClick = (issue: Issue) => {
    const props: AnalyticsDimensionsProps = project
      ? { userContext: user, project, issue }
      : { userContext: user, issue };
    trackEvent(AnalyticsEventType.ProjectIssueTapped, props);
    if (!project) {
      return;
    }
    const state: IssueHistoryState = { project: project, selectedMenuItem: 'Project' };
    if (subprojects && subprojects.length > 1 && selectedSubproject) {
      state.subproject = selectedSubproject;
    }
    navigate(`/application/issues/${issue.id}`, { state });
  };

  if (!project) {
    return <MenuDrivenContainer />;
  }

  let statisticsDescription =
    'Trends for velocity, throughput, lead, reaction, and cycle times. Click on a chart to see its historical view and average.';
  if (selectedSubproject && selectedSubproject.methodology === SubprojectMethodology.Kanban) {
    statisticsDescription =
      'Trends for throughput, lead, reaction, and cycle times. Click on a chart to see its historical view and average.';
  }

  const breadCrumbitems = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
    { labelName: (project?.name as string) || 'Project', href: `/application/project/${project.id}` },
  ];

  if (subprojects && subprojects.length > 1 && selectedSubproject) {
    breadCrumbitems.push({
      labelName: selectedSubproject.name,
      href: `/application/project/${project.id}/subproject/${selectedSubproject.id}`,
    });
  }

  const isProjectStale = projectsState[project.id] === IntegrationState.STALE;

  return (
    <MenuDrivenContainer project={project}>
      {/* Overview Section */}
      <OuterPaddingDiv>
        <div className={styles.projectStatisticsHeader}>
          <BreadcrumbContainer data-testid="breadcrumb">
            <Breadcrumb crumbItems={breadCrumbitems} />
          </BreadcrumbContainer>
          {subprojects && subprojects.length > 1 && (
            <SubprojectSelector
              key={`subproject-selector-${subprojectId}`}
              subprojects={subprojects}
              setSelectedSubproject={navigateToSelectedObject}
              includeAggregate={true}
              defaultValue={subprojectId || ''}
            />
          )}
        </div>
        <TitleHeadingTag>{`${selectedSubproject ? selectedSubproject.name : project.name}`}</TitleHeadingTag>
        {isProjectStale && isAdmin && <IntegrationsStaleMessage />}
        <ProjectHeadlineContainer viewAreaWidth={viewAreaWidth}>
          <HealthBarContainer>
            <div style={{ width: 400, height: 225 }}>
              <HealthGauge
                heading="Product Delivery Score"
                tooltip="The Product Delivery Score is the average of the Sprint Performance Scores over a given time period (up to the last six sprints). It helps spot patterns and grading trends across sprints."
                health={selectedMetrics?.health || 0}
                shouldAnimate={!!selectedMetrics?.health}
                shouldFade={true}
                status={selectedMetrics?.health ? 'success' : 'loading'}
              />
            </div>
          </HealthBarContainer>
          <CustomIssueGridContainer viewAreaWidth={viewAreaWidth}>
            {selectedObject &&
              (selectedObject.issues || []).slice(0, 3).map((issue: Issue) => {
                return (
                  <IssueCardWrapper key={issue.id}>
                    <IssueCard issue={issue} onClick={() => issueClick(issue)} />
                  </IssueCardWrapper>
                );
              })}
            {selectedObject && selectedObject.issues.length && selectedObject.issues.length === 4 ? (
              <IssueCardWrapper>
                <IssueCard issue={selectedObject.issues[3]} onClick={() => issueClick(selectedObject.issues[3])} />
              </IssueCardWrapper>
            ) : null}
            {selectedObject && selectedObject.issues.length && selectedObject.issues.length > 4 ? (
              <IssueCardWrapper>
                <IssueListCard
                  issues={(selectedObject.issues || []).slice(3, selectedObject.issues.length)}
                ></IssueListCard>
              </IssueCardWrapper>
            ) : null}
          </CustomIssueGridContainer>
        </ProjectHeadlineContainer>
      </OuterPaddingDiv>
      {/* End Overview Section */}
      {/* Statistics Section */}
      <OuterPaddingDiv>
        {/*rethink this AND statemtent*/}
        {selectedMetrics && (
          <>
            <div>
              <TitleHeadingTag style={{ fontSize: '20px' }}>Statistics</TitleHeadingTag>
              <SubtitleHeadingTag>{statisticsDescription}</SubtitleHeadingTag>
            </div>

            <ProjectStatisticsGrid
              metricsResponse={selectedMetrics}
              project={project}
              subproject={selectedSubproject}
              navigate={navigate}
            />
          </>
        )}
      </OuterPaddingDiv>
      {/* End Statistics Section */}
    </MenuDrivenContainer>
  );
};

const IntegrationsStaleMessage = () => {
  const navigate = useNavigate();

  const link = (
    <PageHeaderMessageLink onClick={() => navigate('/application/integrations-list')}>click here</PageHeaderMessageLink>
  );
  const message = (
    <PageHeaderMessageText>This project has stale data. To ensure refreshed data, {link}.</PageHeaderMessageText>
  );

  return (
    <div style={{ margin: '10px 0 25px 0' }}>
      <PageHeaderMessage message={message} color="yellow" />
    </div>
  );
};

const ProjectHeadlineContainer = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 992 ? 'row' : 'column')};
  align-items: ${(props) => (props.viewAreaWidth < 992 ? 'center' : 'flex-start')};
  gap: 40px;
`;

const CustomIssueGridContainer = styled(IssueGridContainer)<{ viewAreaWidth: number }>`
  grid-template-rows: repeat(${(props) => (props.viewAreaWidth >= 992 ? 2 : 1)}, 1fr);
`;

const PageHeaderMessageText = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const PageHeaderMessageLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
