import { Dispatch, useCallback } from 'react';
import { BoardView } from '../views/board-view';
import { JiraReducerAction, JiraState } from './jira.type';

type BoardsProps = {
  state: JiraState;
  dispatch: Dispatch<JiraReducerAction>;
};

/** The view for selecting boards during the jira itegration workflow.
 *
 * @param props: { state: JiraState, dispatch: Dispatch<JiraReducerAction>, getJiraStatusesQuery: UseQueryResult }
 * @returns JSX.Element
 */
export function Boards({ state, dispatch }: BoardsProps) {
  const handleChange = useCallback(
    (selectedBoardId: string) => {
      const selectedBoard = (state.options?.boards || []).find((board) => board.id === selectedBoardId);
      return dispatch({ type: 'board', payload: selectedBoard });
    },
    [dispatch, state.options?.boards]
  );

  return (
    <BoardView boards={state.options?.boards || []} selectedBoardId={state.board?.id || null} onChange={handleChange} />
  );
}
