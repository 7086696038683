import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Fragment, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { fetchIssueDetailsWithId } from '../../api/issues-client/issues-client';
import { IssueDetailsResult } from '../../api/issues-client/issues-client.class';
import { fetchSprintGithubMeasures, fetchSprintMeasures } from '../../api/sprints-client/sprints-client';
import { ChangeRequest, SprintMeasureData, SprintMeasureTasks } from '../../api/sprints-client/sprints-client.type';
import { UserInfo } from '../../api/user-client/user-client.type';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { ExtendedIssueCard } from '../../components/extended-issue-card/extended-issue-card';
import { ProjectContext, SidebarContext } from '../../contexts';
import { UserContext } from '../../contexts/user';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { BreadcrumbContainer, OuterPaddingDiv } from '../../styles/shared-styled-components';
import { GithubRelatedChangeRequestGroups } from '../github-measure-details/github-measure-details';
import { RelatedTasksGroup } from '../issue-details-related-tasks-group/issue-details-related-tasks-group';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import { PillarIssueInput } from '../sprint-assessment/sprint-assessment.type';
import { breadcrumbNavigationIssueDetails } from './breadcrumb-navigation-issue-details';
import { IssueHistoryState } from './issue-details.type';

const SPRINTMEASURES = ['strategy', 'complexity', 'quality', 'scope', 'readiness', 'independence'];
const GITHUBMEASURES = ['percentage_of_declined_change_requests', 'average_days_open_for_change_requests'];

dayjs.extend(utc);

export const IssueDetailsView = () => {
  const location = useLocation();
  let historyState: IssueHistoryState;
  let props: PillarIssueInput;

  if (location.state && Object.prototype.hasOwnProperty.call(location.state, 'props')) {
    historyState = location.state.historyState as IssueHistoryState;
    props = location.state.props as PillarIssueInput;
  } else {
    historyState = location.state as IssueHistoryState;
    props = {} as PillarIssueInput;
  }

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { user } = useContext(UserContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const [selectedIssueDetails, setSelectedIssueDetails] = useState<IssueDetailsResult>();
  const [lastUpdated, setLastUpdated] = useState<string>();
  const { issueId = '' } = useParams<{ issueId: string }>();
  const [sprintMeasure, setSprintMeasures] = useState<SprintMeasureData>();
  const [sprintChangeRequests, setSprintChangeRequests] = useState<ChangeRequest[]>();
  const memoizedHealthAtLocalTZ = dayjs.utc(new Date().getTime()).local().toString();

  let allTasks = {} as SprintMeasureData;
  let githubChangeRequests = [] as ChangeRequest[];

  if (lastUpdated === undefined) {
    setLastUpdated(formatDate(memoizedHealthAtLocalTZ, timezone, DateTemplate.MonthDayYear));
  }

  useQuery(['issueDetails', issueId], issueId ? () => fetchIssueDetailsWithId(issueId) : () => null, {
    onSuccess: (data) => {
      if (data == null) {
        const temp = new IssueDetailsResult();
        temp.title = props.title;
        temp.description = props.description;
        temp.calculation = props.calculation || '';
        temp.solution = props.solution || '';
        temp.summary = props.summary;
        temp.amount = props.amount ? props.amount.toString() : '0';
        setSelectedIssueDetails(temp);
      } else {
        setSelectedIssueDetails(data);
      }
    },
  });

  const currentSprint = historyState?.sprint?.id;

  useQuery(
    ['sprint_measures', currentSprint],
    () =>
      currentSprint && SPRINTMEASURES.includes(props.measure)
        ? fetchSprintMeasures(currentSprint, props.measure)
        : Promise.reject('Unable to resolve SprintId or SprintID is missing'),
    {
      enabled: !!currentSprint && SPRINTMEASURES.includes(props.measure),
      onSuccess: setSprintMeasures,
    }
  );

  if (historyState && props.title != undefined && sprintMeasure) {
    allTasks = sprintMeasure;
  }

  useQuery(
    ['github_measures', currentSprint],
    () =>
      currentSprint && // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
      GITHUBMEASURES.includes(props.measure)
        ? fetchSprintGithubMeasures(currentSprint, props.measure)
        : Promise.reject('Unable to resolve SprintId or SprintID is missing'),
    {
      enabled:
        !!currentSprint && // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
        GITHUBMEASURES.includes(props.measure),
      onSuccess: setSprintChangeRequests,
    }
  );

  if (historyState && props.title != undefined && sprintChangeRequests) {
    githubChangeRequests = sprintChangeRequests;
  }

  if (!selectedIssueDetails || !historyState) {
    return <MenuDrivenContainer />;
  }

  const onPortfolioClick = () => {
    onPortfolioBreadcrumbClick(setProject, navItems, setNavItems);
  };

  const breadCrumbitems = breadcrumbNavigationIssueDetails(
    location,
    historyState,
    selectedIssueDetails,
    onPortfolioClick
  );

  return (
    <MenuDrivenContainer project={historyState.project}>
      <OuterPaddingDiv>
        <BreadcrumbContainer data-testid="breadcrumb">
          <Breadcrumb crumbItems={breadCrumbitems} />
        </BreadcrumbContainer>
        <HeaderContainer>
          <IssueTitle>{selectedIssueDetails.title}</IssueTitle>
        </HeaderContainer>
        <ExtendedIssueCard
          historyState={historyState}
          user={user ? user : ({} as UserInfo)}
          issue={selectedIssueDetails}
          onClick={(_issue) => {}}
        />
        {selectedIssueDetails.tasks.length > 0 ? (
          <RelatedTasksGroup
            project={historyState.project}
            selectedIssueDetails={selectedIssueDetails}
            tasks={selectedIssueDetails.tasks}
            title={'Related Tasks'}
            title_category={'task'}
          />
        ) : allTasks.tasks ? (
          allTasks.tasks.map((group: SprintMeasureTasks) => {
            return (
              <Fragment key={group.name}>
                <RelatedTasksGroup
                  project={historyState.project}
                  selectedIssueDetails={selectedIssueDetails}
                  tasks={group.tasks}
                  title={group.name}
                  title_category={props.title.toLowerCase() == 'strategy' ? 'epic' : 'task'}
                />
              </Fragment>
            );
          })
        ) : null}
        {/* Github Related things */}
        {githubChangeRequests.length > 0 ? (
          <GithubRelatedChangeRequestGroups
            title={'Related Pull Requests'}
            changeRequests={sprintChangeRequests as ChangeRequest[]}
            project={historyState.project}
            selectedIssueDetails={selectedIssueDetails}
          />
        ) : null}
      </OuterPaddingDiv>
    </MenuDrivenContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IssueTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 40px;
`;
