import { getRequestToken } from '../../helpers/storage/storage';

/**
 * Creates an agent websocket connection
 * @param organization_id: the id of the organization to connect to
 * @returns {WebSocket} - a websocket connection
 */
function createAgentWebsocket(organization_id: string): WebSocket {
  let baseURL = import.meta.env.VITE_APP_API_URL + '/ws/agents';
  baseURL = baseURL.replace('http', 'ws').replace('https', 'wss');
  const url = baseURL + '/' + organization_id + `/?token=${getRequestToken()}`;
  return new WebSocket(url);
}

/**
 * Ask the agent a question via websocket
 * @param websocket: WebSocket - an active websocket connection
 * @param message: String - the string to send to the agent
 * @param thread_id: String - an active thread id if one exists
 * @returns {void}
 */
function askAgent(webSocket: WebSocket | undefined, message: string, thread_id: string): void {
  webSocket?.send(
    JSON.stringify({
      message,
      thread_id,
    })
  );
}

export { askAgent, createAgentWebsocket };
