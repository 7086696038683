import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAllocationOfCostsTaskDetailsV2,
  getCostVsOutputTaskDetailsV2,
} from '../../api/financials-client/financials-client';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import { onPortfolioBreadcrumbClick } from '../../containers/side-bar/side-bar.helper';
import { ProjectContext, SidebarContext } from '../../contexts';
import { CrumbItem } from '../breadcrumb/breadcrumb.type';
import { makeTitle } from './financials-tasks.helpers';
import { FinancialsTasksScope } from './financials-tasks.type';

/**
 * Generates breadcrumbs based on the provided scope and project information.
 *
 * @param {FinancialsTasksScope} scope - the scope for which the breadcrumbs are being generated
 * @returns {CrumbItem[]} an array of breadcrumb items representing the navigation path
 */
const useBreadcrumbs = (scope: FinancialsTasksScope): CrumbItem[] => {
  const { projectId } = scope;
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { project, setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);

  const breadcrumbs: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
  ];

  if (projectId && projectId !== 'None') {
    breadcrumbs.push({
      labelName: project?.name || 'Project',
      href: `/application/project/${projectId}`,
    });
  }

  breadcrumbs.push({ labelName: 'Financials', href: `/application/financials/portfolio/${portfolioId}` });
  breadcrumbs.push({
    labelName: makeTitle(scope),
  });

  return breadcrumbs;
};

/**
 * Query manager for fetching cost vs output financials tasks.
 *
 * @param {FinancialsTasksConfig} config - Configuration object for fetching financials tasks
 * @param {UseQueryOptions<MinifiedTask[]>} options - Options for the useQuery hook
 * @return {{ data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> }} Object containing data and query result
 */
const useCostVsOutputTasks = (
  { portfolioId, projectId, date, factor, startDate, endDate }: FinancialsTasksScope,
  options?: UseQueryOptions<MinifiedTask[]>
): { data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> } => {
  const query = useQuery({
    queryKey: ['financials-tasks', portfolioId, projectId, date, factor, startDate, endDate],
    queryFn: () =>
      portfolioId && startDate && endDate && date && factor
        ? getCostVsOutputTaskDetailsV2(portfolioId, startDate, endDate, date, factor, projectId || undefined)
        : Promise.reject('Cannot get cost vs output tasks'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching allocation of costs financials tasks.
 *
 * @param {FinancialsTasksScope} config - Configuration object for fetching financials tasks
 * @param {UseQueryOptions<MinifiedTask[]>} options - Options for the useQuery hook
 * @return {{ data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> }} Object containing data and query result
 */
const useAllocationOfCostsTasks = (
  { portfolioId, projectId, date, factor, factorOption, startDate, endDate }: FinancialsTasksScope,
  options?: UseQueryOptions<MinifiedTask[]>
): { data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> } => {
  const query = useQuery({
    queryKey: ['financials-tasks', portfolioId, projectId, date, factor, factorOption, startDate, endDate],
    queryFn: () =>
      portfolioId && startDate && endDate && date && factor && factorOption
        ? getAllocationOfCostsTaskDetailsV2(
            portfolioId,
            startDate,
            endDate,
            date,
            factor,
            factorOption,
            projectId || undefined
          )
        : Promise.reject('Cannot get cost vs output tasks'),
    ...options,
  });

  return { data: query.data, query };
};

export { useAllocationOfCostsTasks, useBreadcrumbs, useCostVsOutputTasks };
