import { Tooltip } from '@mantine/core';
import { Fragment } from 'react';
import { icons } from '../../assets/icons/icons';
import { Indicator } from '../indicator/indicator';
import { IndicatorProps } from '../indicator/indicator.type';
import { NavIconType } from './nav-icon.type';

type NavIconProps = {
  iconType: NavIconType;
  label: string;
  hide: boolean;
  indicatorProps?: IndicatorProps | null;
  sidebarOpen?: boolean;
};

export const NavIcon = ({ iconType, label, hide, indicatorProps, sidebarOpen }: NavIconProps) => {
  const icon = (
    <div
      style={{
        position: 'relative',
        padding:
          iconType === NavIconType.iconDoubleArrowRight
            ? '8px'
            : iconType === NavIconType.iconPortfolio ||
              iconType === NavIconType.iconProject ||
              iconType === NavIconType.iconCurrentSprint ||
              iconType === NavIconType.iconHistory
            ? '0px'
            : '6px 10px',
        marginLeft: !sidebarOpen
          ? iconType === NavIconType.iconBeta
            ? '-5px'
            : iconType === NavIconType.iconFinancials ||
              iconType === NavIconType.iconStrategy ||
              iconType === NavIconType.iconProcess ||
              iconType === NavIconType.iconIntegrations
            ? '-2px'
            : '0px'
          : iconType === NavIconType.iconPortfolio ||
            iconType === NavIconType.iconProject ||
            iconType === NavIconType.iconCurrentSprint ||
            iconType === NavIconType.iconHistory
          ? '2px'
          : 'px',
      }}
    >
      <img alt="icon" src={icons[iconType]} />
      {indicatorProps && (
        <Indicator
          value={indicatorProps.type}
          tooltip={indicatorProps.tooltip}
          style={{
            position: 'absolute',
            transform: 'translate(0, -50%)',
            top: '50%',
            right: !sidebarOpen ? '-20px' : '-120px',
          }}
        />
      )}
    </div>
  );

  return (
    <Fragment>
      {hide ? (
        icon
      ) : (
        <Tooltip
          label={label}
          position="right-end"
          offset={20}
          transitionProps={{
            transition: 'fade',
            duration: 200,
          }}
        >
          {icon}
        </Tooltip>
      )}
    </Fragment>
  );
};
