import { styled } from '@linaria/react';
import { ProjectMetricsDetailsEndpointDescription } from '../../api/projects-client/projects-client.type';
import { newCOLORS } from '../../styles/colors';
import { SubtitleHeadingTag, TitleHeadingTag } from '../../styles/shared-styled-components';

type TrendsTrailingCardProps = {
  amount: number | null;
  average: number;
  months: string;
  desc: ProjectMetricsDetailsEndpointDescription;
};

export function TrendsTrailingCard({ amount, average, months, desc }: TrendsTrailingCardProps) {
  const getDescriptionColor = (amount: number | null, average: number) => {
    if (amount === null) {
      return newCOLORS.white;
    }

    if (
      desc === ProjectMetricsDetailsEndpointDescription.Velocity ||
      desc === ProjectMetricsDetailsEndpointDescription.Throughput
    ) {
      if (amount - average > 0) {
        return newCOLORS.green;
      } else if (amount - average < 0) {
        return newCOLORS.coral;
      } else {
        return newCOLORS.darkerGray;
      }
    } else {
      if (amount - average > 0) {
        return newCOLORS.coral;
      } else if (amount - average < 0) {
        return newCOLORS.green;
      } else {
        return newCOLORS.darkerGray;
      }
    }
  };

  const getTrailingCardContentForDesc = (desc: ProjectMetricsDetailsEndpointDescription): string => {
    switch (desc) {
      case ProjectMetricsDetailsEndpointDescription.Velocity:
        return 'points';
      case ProjectMetricsDetailsEndpointDescription.Throughput:
        return 'tasks';
      case ProjectMetricsDetailsEndpointDescription.LeadTime:
        return 'days';
      case ProjectMetricsDetailsEndpointDescription.ReactionTime:
        return 'days';
      default:
        return 'days';
    }
  };

  const getBoldContentForDesc = (desc: ProjectMetricsDetailsEndpointDescription, diffAmount: number): string => {
    let boldContentText = `${Math.abs(diffAmount)} ${getTrailingCardContentForDesc(desc)} `;
    if (
      desc === ProjectMetricsDetailsEndpointDescription.Velocity ||
      desc === ProjectMetricsDetailsEndpointDescription.Throughput
    ) {
      if (diffAmount > 0) {
        boldContentText += 'higher';
      } else {
        boldContentText += 'lower';
      }
    } else {
      if (diffAmount > 0) {
        boldContentText += 'slower';
      } else {
        boldContentText += 'faster';
      }
    }
    return boldContentText;
  };

  const capitalizeString = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const descriptionColor = getDescriptionColor(amount, average);

  return (
    <TrailingCardContainer>
      <TitleHeadingTag>{amount || 'N/A'}</TitleHeadingTag>
      <SubHeadingTag>Trailing {months} months</SubHeadingTag>
      <ContentHeadingTag>
        {capitalizeString(getTrailingCardContentForDesc(desc))}{' '}
        {desc === ProjectMetricsDetailsEndpointDescription.Velocity ||
        desc === ProjectMetricsDetailsEndpointDescription.Throughput
          ? 'completed'
          : 'on average'}
        <br /> per sprint
      </ContentHeadingTag>
      <ContentHeadingTag>
        {amount && (
          <span style={{ color: descriptionColor }}>
            Average is <br />
            <SpanBold>{getBoldContentForDesc(desc, amount - average)}</SpanBold>
            <br /> in this timeframe
          </span>
        )}
        {!amount && (
          <>
            <br />
            We’ll update when this data is available...
          </>
        )}
      </ContentHeadingTag>
    </TrailingCardContainer>
  );
}

const TrailingCardContainer = styled.div`
  box-sizing: border-box;
  width: fit-content;
  text-align: center;
`;

const SubHeadingTag = styled(TitleHeadingTag)`
  font-size: medium;
  margin-top: 0px;
  margin-bottom: 5px;
`;

const ContentHeadingTag = styled(SubtitleHeadingTag)`
  padding: 0px;
  font-size: small;
  margin-bottom: 5px;
`;

const SpanBold = styled.span`
  font-weight: bold;
`;
