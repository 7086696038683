enum Section {
  ActiveInitiatives = 'activeInitiatives',
  InactiveInitiatives = 'inactiveInitiatives',
}

type SectionConfiguration = {
  [Section.ActiveInitiatives]: boolean;
  [Section.InactiveInitiatives]: boolean;
};

type StrategyContextType = {
  sectionConfig: SectionConfiguration;
  toggleSection: (section: Section) => void;
};

export { Section };
export type { SectionConfiguration, StrategyContextType };
