import { ProcessTask } from '../../../api/process-client/process-client.type';
import { removeDuplicates } from '../../tasks-table/tasks-table.helpers';
import { TaskRecord, TaskType } from '../../tasks-table/tasks-table.type';

/**
 * Formats an array of process tasks into an array of task records for the table.
 *
 * @param {ProcessTask[]} tasks - The array of process tasks to be formatted
 * @return {TaskRecord[]} The formatted array of task records
 */
const formatTasks = (tasks: ProcessTask[]): TaskRecord[] => {
  return tasks.map(
    (task) =>
      ({
        id: task.id,
        name: task.name,
        title: task.title,
        url: task.url,
        created_date: task.created_date,
        points: task.points,
        type: task.type as TaskType,
        epic: task.epic,
        initiatives: removeDuplicates(task.initiatives),
      } as TaskRecord)
  );
};

export { formatTasks };
