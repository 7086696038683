import { Dialog } from '@mantine/core';
import { Button } from '../../../components/button/button';
import { newCOLORS } from '../../../styles/colors';

type DeletePortfolioConfirmationProps = {
  opened: boolean;
  close: () => void;
  onSubmit: () => void;
};

export function DeletePortfolioConfirmation({ opened, close, onSubmit }: DeletePortfolioConfirmationProps) {
  function handleSubmit() {
    onSubmit();
    close();
  }
  return (
    <Dialog
      opened={opened}
      withCloseButton
      withBorder
      onClose={close}
      shadow="m"
      p={30}
      size="lg"
      radius="md"
      position={{ top: 200, right: 20 }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3>Warning</h3>
        <p>Removing this portfolio may cause discrepancies between Bloomfilter and your systems of record.</p>
        <p>
          <strong>This cannot be undone.</strong> Are you sure you wish to continue?
        </p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '1em' }}>
        <Button color={newCOLORS.red} onClick={handleSubmit}>
          Agree and Continue
        </Button>
        <Button onClick={close} variant="outline">
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
