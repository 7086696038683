import { styled } from '@linaria/react';
import { Radio } from '@mantine/core';
import styles from './views.module.css';
import { Project } from './views.type';

type Props = {
  projects: Project[];
  selectedProjectId: string | null;
  onChange: (projectId: string) => void;
};

export function ProjectView({ projects = [], selectedProjectId, onChange }: Props) {
  return (
    <div id={styles.viewContainer}>
      <h4>Select a project you would like to connect to:</h4>
      <div id={styles.grid}>
        {projects.map((project: Project) => (
          <div
            key={project.id}
            className={
              project.id === selectedProjectId
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => onChange(project.id)}
          >
            <StyledRadio
              color="red.5"
              value={project.id}
              checked={project.id === selectedProjectId}
              onChange={() => onChange(project.id)}
            />
            <span className={styles.checkboxLabel}>
              {'key' in project ? `${project.name} - (${project.key})` : project.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

const StyledRadio = styled(Radio)`
  .mantine-Radio-radio {
    cursor: pointer;
  }
`;
