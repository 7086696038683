import { styled } from '@linaria/react';
import { IssueDetailsTask } from '../../api/issues-client/issues-client.type';
import { ChangeRequest } from '../../api/sprints-client/sprints-client.type';
import {
  CustomDimensionIssueType,
  CustomDimensionProjectType,
} from '../../helpers/analytics-event/analytics-event.type';
import { MeasureRelatedDetailsCard } from '../measure-related-details-card/measure-related-details-card';

type MeasureRelatedDetailsProps = {
  tasks?: IssueDetailsTask[];
  project: CustomDimensionProjectType;
  issue: CustomDimensionIssueType;
  changeRequests?: ChangeRequest[];
  github: boolean;
};

export const MeasureRelatedDetails = ({
  tasks,
  project,
  issue,
  changeRequests,
  github,
}: MeasureRelatedDetailsProps) => {
  return (
    <RelatedTaskCardContaner>
      {github
        ? (changeRequests || [])?.map((item: ChangeRequest) => {
            return (
              <MeasureRelatedDetailsCard
                changeRequest={item}
                key={item.number}
                project={project}
                issue={issue}
                github={github}
              />
            );
          })
        : (tasks || [])?.map((task: IssueDetailsTask) => {
            return (
              <MeasureRelatedDetailsCard task={task} key={task.id} project={project} issue={issue} github={github} />
            );
          })}
    </RelatedTaskCardContaner>
  );
};

const RelatedTaskCardContaner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
