import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Fragment, useContext, useState } from 'react';
import { IssueDetailsResult } from '../../api/issues-client/issues-client.class';
import { IssueDetailsTask } from '../../api/issues-client/issues-client.type';
import { Project } from '../../api/projects-client/projects-client.type';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../styles/colors';
import { SortType } from '../issue-details/issue-details.type';
import { MeasureRelatedDetails } from '../measure-related-details/measure-related-details';
import { sortTasks } from '../tasks/task-list-header';

type RelatedTasksGroupProps = {
  selectedIssueDetails?: IssueDetailsResult;
  project: Project;
  tasks: IssueDetailsTask[];
  title: string;
  title_category: 'epic' | 'task';
};

export const RelatedTasksGroup = ({
  selectedIssueDetails,
  project,
  tasks,
  title,
  title_category,
}: RelatedTasksGroupProps) => {
  const sortTypes = [SortType.Name, SortType.CreatedDate, SortType.Points];
  const [sortType, setSortType] = useState<string>(sortTypes[0]);
  const { user } = useContext(UserContext);
  const LOOKBACK_PERIOD = [
    { value: SortType.Name, label: SortType.Name },
    { value: SortType.CreatedDate, label: SortType.CreatedDate },
    { value: SortType.Points, label: SortType.Points },
  ];
  const [opened, { toggle }] = useDisclosure(true);

  const sortClickHandler = (type: string) => {
    trackEvent(AnalyticsEventType.SortTapped, {
      userContext: user,
      project: project,
      issue: { title: selectedIssueDetails?.title || 'test' },
    });
    setSortType(type);
  };

  const sortedRelatedItems = () => {
    return sortTasks(sortType, tasks);
  };

  return (
    <Fragment>
      {sortedRelatedItems() ? (
        sortedRelatedItems().length > 0 ? (
          <RelatedTasksContainer>
            <RightHeadingContainer>
              <TaskNameContainer>
                <CollapseIcon>
                  {opened ? (
                    <Icon
                      icon="icon-park-solid:down-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  ) : (
                    <Icon
                      icon="icon-park-solid:right-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  )}
                </CollapseIcon>
                <RelatedTasksTitle>
                  {title_category == 'epic'
                    ? `${title} Epic (${sortedRelatedItems().length} ${
                        sortedRelatedItems().length == 1 ? 'task' : 'tasks'
                      })`
                    : `${title} (${sortedRelatedItems().length})`}
                </RelatedTasksTitle>
              </TaskNameContainer>
              {opened ? (
                <DropDownContainer>
                  <SortHeaderTag>Sort</SortHeaderTag>
                  <Select
                    data={LOOKBACK_PERIOD}
                    value={sortType}
                    onChange={(type) => sortClickHandler(type as string)}
                  />
                </DropDownContainer>
              ) : null}
            </RightHeadingContainer>
            <Collapse in={opened}>
              <MeasureRelatedDetails
                tasks={sortedRelatedItems()}
                project={project}
                issue={{ title: selectedIssueDetails?.title || 'test' }}
                github={false}
              />
            </Collapse>
          </RelatedTasksContainer>
        ) : (
          <RelatedTasksContainer>
            <RightHeadingContainer>
              <TaskNameContainer>
                <CollapseIcon>
                  {opened ? (
                    <Icon
                      icon="icon-park-solid:down-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  ) : (
                    <Icon
                      icon="icon-park-solid:right-one"
                      width={16}
                      height={16}
                      color={newCOLORS.indigo}
                      onClick={toggle}
                    />
                  )}
                </CollapseIcon>
                <RelatedTasksTitle>
                  {title_category == 'epic'
                    ? `${title} Epic (${sortedRelatedItems().length} ${
                        sortedRelatedItems().length == 1 ? 'task' : 'tasks'
                      })`
                    : `${title} (${sortedRelatedItems().length})`}
                </RelatedTasksTitle>
              </TaskNameContainer>
            </RightHeadingContainer>
          </RelatedTasksContainer>
        )
      ) : null}
    </Fragment>
  );
};

const RightHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const RelatedTasksContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  margin-top: 16px;
  border-radius: 4px;
  background: ${newCOLORS.white};
  box-shadow: 0px 2px 3px 0px ${newCOLORS.lightGray};
`;

const TaskNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RelatedTasksTitle = styled.h3`
  font-size: 19px;
  font-weight: 900;
  color: ${newCOLORS.darkerGray};
  padding-left: 10px;
  border-left: 1px solid RGBA(0, 0, 0, 0.2);
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SortHeaderTag = styled.div`
  font-weight: 600;
  cursor: default;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  color: ${newCOLORS.darkGray};
`;

const CollapseIcon = styled.div`
  display: flex;
  font-weight: 800;
  cursor: pointer;
  padding-bottom: 10px;
  padding-right: 12px;
  padding-left: 0px;
`;
