import { Fragment } from 'react/jsx-runtime';
import { AuditLog } from '../../components/audit-log/audit-log';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { OuterPaddingDiv, TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { useAuditLogData } from './assessment-view/assessment-view.hooks';

export const ProcessAnalysisAuditLog = () => {
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);

  const { data, isFetching } = useAuditLogData();

  return (
    <Fragment>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={300} variant="colored" />
      <MenuDrivenContainer>
        <OuterPaddingDiv>
          <TitleHeadingTag>{workPeriod?.name} Audit Log</TitleHeadingTag>
          <AuditLog entries={data?.audit_log || []} isProcessAnalysis={true} />
        </OuterPaddingDiv>
      </MenuDrivenContainer>
    </Fragment>
  );
};
