import { styled } from '@linaria/react';
import { Fragment } from 'react/jsx-runtime';
import { TaskFlowHistoryType } from '../../api/sprints-client/sprints-client.type';
import { MenuDrivenContainer } from '../../containers/menu-driven-container/menu-driven-container';
import { newCOLORS } from '../../styles/colors';
import { BreadcrumbContainer, OuterPaddingDiv, TitleHeadingTag } from '../../styles/shared-styled-components';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { CrumbItem } from '../breadcrumb/breadcrumb.type';
import { BrandedLoadingOverlay } from '../loader/branded-loader';
import { TaskFlowBoard } from '../task-flow-history/task-flow-history-board';

type TaskFlowProps = {
  taskFlowData: TaskFlowHistoryType | undefined;
  startDate: Date;
  endDate: Date;
  breadCrumbitems?: CrumbItem[];
  isFetching?: boolean;
};

export const TaskFlow = ({ taskFlowData, startDate, endDate, breadCrumbitems, isFetching }: TaskFlowProps) => {
  return (
    <Fragment>
      <MenuDrivenContainer
        contentStyles={{ backgroundColor: newCOLORS.lighterGray }}
        containerStyles={{ backgroundColor: newCOLORS.lighterGray }}
      >
        <OuterPaddingDiv>
          <TaskFlowContainer>
            {breadCrumbitems && breadCrumbitems.length > 0 && (
              <BreadcrumbContainer data-testid="breadcrumb">
                <Breadcrumb crumbItems={breadCrumbitems} />
              </BreadcrumbContainer>
            )}

            <TaskFlowHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TitleHeadingTag>Task Flow</TitleHeadingTag>
              </div>
            </TaskFlowHeader>
            <div>
              <BrandedLoadingOverlay visible={isFetching || false} transitionDuration={30} variant="colored" />
              {taskFlowData && startDate && endDate && (
                <TaskFlowBoard history={taskFlowData} startDate={startDate} endDate={endDate} />
              )}
            </div>
          </TaskFlowContainer>
        </OuterPaddingDiv>
      </MenuDrivenContainer>
    </Fragment>
  );
};

const TaskFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskFlowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
