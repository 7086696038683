import { styled } from '@linaria/react';
import { Space } from '@mantine/core';
import dayjs from 'dayjs';
import { useContext, useRef } from 'react';
import { DownloadPNGButton } from '../../../../components/download-png-button';
import { SidebarContext } from '../../../../contexts';
import { canvasHtmlDownload } from '../../../../helpers/image-downloader/image-downloader';
import { useProcessAnalysisStore } from '../../../../store/process-analysis-store/process-analysis-store';
import { useDateRange } from '../../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../../styles/colors';
import { WorkPeriodType } from '../../process-analysis.type';
import { getDownloadLabel } from '../assessment-view.helpers';
import { AssessmentChart, Metric } from '../assessment-view.type';
import { DaySelect } from './day-select';
import { ViewSelect } from './view-select';

type Props = {
  chartId: AssessmentChart;
  title: React.ReactNode;
  day: string | null;
  setDay: (value: string) => void;
  showDaySelect: boolean;
  metric: Metric;
  setMetric: (value: Metric) => void;
  children: React.ReactNode;
};

export const ChartContainer = ({ chartId, title, day, setDay, showDaySelect, metric, setMetric, children }: Props) => {
  const { viewAreaWidth } = useContext(SidebarContext);

  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const { startDate, endDate } = useDateRange();

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const workPeriodName =
    workPeriodType === WorkPeriodType.Defined
      ? workPeriod?.name
      : `Custom work period ${dayjs(startDate).format('YYYY/MM/DD')} - ${dayjs(endDate).format('YYYY/MM/DD')}`;

  return (
    <Container ref={downloadRef}>
      <Header viewAreaWidth={viewAreaWidth}>
        <TitleBox>{title}</TitleBox>
        <DropDownContainer ref={exceptionRef}>
          {showDaySelect && day && <DaySelect value={day} onChange={setDay} />}
          <Space w={30} />
          <ViewSelect value={metric} onChange={setMetric} />
          <div ref={exceptionRef}>
            {
              <DownloadPNGButton
                handleDownload={() =>
                  canvasHtmlDownload(
                    `${workPeriodName}_${getDownloadLabel(chartId)}_${'Day' + day}`,
                    downloadRef,
                    exceptionRef
                  )
                }
                exceptionRef={exceptionRef}
              />
            }
          </div>
        </DropDownContainer>
      </Header>
      {children}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${newCOLORS.white};
  padding: 16px 32px;
`;

const Header = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1200 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${(props) => (props.viewAreaWidth >= 1200 ? 'center' : 'start')};
  margin-bottom: 32px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropDownContainer = styled.div`
  display: flex;
`;
