import { Icon } from '@iconify/react/dist/iconify.js';
import { ActionIcon, Paper, Text, TextInput, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Markdown from 'react-markdown';

import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { canUseAI } from '../../api/agents-client/agent-client';
import { UseAIResult } from '../../api/agents-client/agent-client.class';
import { askAgent, createAgentWebsocket } from '../../api/agents-client/agents-websockets';
import { icons } from '../../assets/icons/icons';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';

export function Insights() {
  // Handles the first render
  const orgId = useOrganizationId();
  const [useAI, setUseAI] = useState(false);
  const [isShowChat, { toggle: toggleShowChat }] = useDisclosure(false);
  const [isActiveQuestion, setIsActiveQuestion] = useState(false);
  const [messages, setMessages] = useState<string[]>(['']);
  const [question, setQuestion] = useState('');
  const [threadId, setThreadId] = useState('');
  const [questions, setQuestions] = useState<string[]>([]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useQuery(['canUseAI'], () => canUseAI(), {
    onSuccess: (response: UseAIResult) => setUseAI(response.canUseAI),
    onError: () => console.error('There was an issue determining if the user can use AI'),
    staleTime: 1000 * 60 * 5,
  });

  const runAgent = useCallback(
    (question: string) => {
      if (orgId && useAI && !isActiveQuestion) {
        const agentWebsocket = createAgentWebsocket(orgId as string);
        setIsActiveQuestion(true);
        agentWebsocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.is_start) {
            setThreadId(data.thread_id);
          }
          if (data.is_update) {
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1] += data.text;
              return updatedMessages;
            });
          }
          if (data.is_done) {
            setIsActiveQuestion(false);
            setMessages((m) => [...m, '']);
            agentWebsocket.close();
          }
        };
        agentWebsocket.addEventListener('open', () => {
          askAgent(agentWebsocket, question, threadId);
        });
      }
    },
    [isActiveQuestion, threadId, useAI, orgId]
  );

  const autoScroll = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    autoScroll();
  }, [messages, questions]);

  const submitQuestion = async () => {
    if (question) {
      runAgent(question);
      setQuestions([...questions, question]);
      setQuestion('');
    }
  };

  return (
    <div>
      {useAI ? (
        <UnstyledButton onClick={toggleShowChat} style={{}}>
          <img src={icons.iconBloomfilter} width={32} height={32} />
        </UnstyledButton>
      ) : null}
      {useAI && isShowChat ? (
        <div
          style={{
            position: 'fixed',
            bottom: 40,
            right: 30,
            width: 500,
            height: '83.7vh',
            maxHeight: 1000,
            overflow: 'hidden',
            border: '1px solid black',
            background: addOpacityToColor(newCOLORS.white, 0.95),
            boxShadow: `0px 40px 40px 8px ${addOpacityToColor(newCOLORS.black, 0.5)}`,
            borderRadius: 10,
            padding: 16,
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src={icons.iconBloomfilter} width={32} height={32} style={{ marginRight: 10 }} />
              <h1 style={{ fontSize: 30, fontWeight: 600, marginTop: 10 }}>Flora - AI Assistant</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <UnstyledButton
                onClick={toggleShowChat}
                style={{
                  fontSize: 21,
                  color: newCOLORS.black,
                  padding: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon="fluent:panel-right-contract-24-regular" />
              </UnstyledButton>
            </div>
          </div>
          <div style={{ flex: 1, overflow: 'auto', marginBottom: 20 }}>
            {questions.map((question, index) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <Paper
                  shadow="sm"
                  p="md"
                  style={{
                    maxWidth: '80%',
                    marginLeft: 'auto',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 10,
                    backgroundColor: newCOLORS.purple2,
                    color: 'white',
                  }}
                >
                  <Text size="md">{question}</Text>
                </Paper>
                {messages[index] && (
                  <Paper
                    shadow="sm"
                    p="md"
                    style={{
                      maxWidth: '80%',
                      marginRight: 'auto',
                      marginTop: 8,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      borderBottomLeftRadius: 0,
                      backgroundColor: newCOLORS.darkestGray,
                    }}
                  >
                    <Markdown>{messages[index]}</Markdown>
                  </Paper>
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <TextInput
            size="lg"
            style={{
              border: '0px',
            }}
            onChange={(e) => setQuestion(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isActiveQuestion) {
                submitQuestion();
              }
            }}
            value={question}
            placeholder="Ask a question about your data...."
            rightSectionWidth={42}
            leftSection={<Icon icon="material-symbols:chat" />}
            rightSection={
              <ActionIcon
                disabled={isActiveQuestion}
                size={32}
                radius="xl"
                color={newCOLORS.purple2}
                variant="filled"
                onClick={submitQuestion}
              >
                <Icon icon="material-symbols:arrow-right-alt" />
              </ActionIcon>
            }
          />
        </div>
      ) : null}
    </div>
  );
}
