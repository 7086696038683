import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { useContext } from 'react';
import { Measure } from '../../../api/work-periods-client/work-periods-client.type';
import { SidebarContext } from '../../../contexts';
import { useDefaultDay, useWorkPeriodDates } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { ContentTag } from '../../../styles/shared-styled-components';
import { measureMetadata, MEASURES_WITH_DATE } from './assessment-view.data';
import { KeyProcessMeasuresData } from './assessment-view.type';
import { MeasureCard } from './cards/measure-card';
import { ScoreCard } from './cards/score-card';

type Props = {
  score: number;
  data: KeyProcessMeasuresData | undefined;
};

export const SectionKeyMeasures = ({ score, data }: Props) => {
  const { viewAreaWidth } = useContext(SidebarContext);

  const getLabel = (measure: keyof typeof measureMetadata) => {
    switch (measure) {
      case Measure.Readiness:
        return <ReadinessLabel />;
      case Measure.Complexity:
        return <ComplexityLabel />;
      default:
        return measureMetadata[measure].label;
    }
  };

  const defaultDay = useDefaultDay();
  const { startDate } = useWorkPeriodDates();
  const dayOffset = defaultDay !== null ? parseInt(defaultDay, 10) - 1 : 0;
  const date = startDate.add(dayOffset, 'day');

  const cards = Object.keys(measureMetadata).map((key) => {
    const measure = key as keyof typeof measureMetadata;
    const shouldPassDate = MEASURES_WITH_DATE.includes(measure as (typeof MEASURES_WITH_DATE)[number]);
    return (
      <MeasureCard
        key={measure}
        measure={measure}
        label={getLabel(measure)}
        description={measureMetadata[measure].description}
        date={shouldPassDate ? date.format('YYYY-MM-DD') : undefined}
        value={data?.[measure] || 0}
        borderColor={newCOLORS.darkerGray}
        suffix={measureMetadata[measure].suffix}
      />
    );
  });

  return (
    <Container viewAreaWidth={viewAreaWidth}>
      <ScoreCard health={score} status="success" />
      <KeyMeasuresContainer viewAreaWidth={viewAreaWidth}>
        <CenterContentTag>Key Process Measures</CenterContentTag>
        <CardsBox>{cards}</CardsBox>
      </KeyMeasuresContainer>
    </Container>
  );
};

function ReadinessLabel() {
  return (
    <ReadinessLabelContainer>
      <span>Readiness</span>
      <Tooltip
        multiline
        w={200}
        position="top"
        label="Tasks which have a name, description, and estimation. Note: tasks intentionally pointed 0 are considered estimated."
      >
        <Icon
          icon="mdi:information-outline"
          width={16}
          height={16}
          color={newCOLORS.blue}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    </ReadinessLabelContainer>
  );
}

function ComplexityLabel() {
  return (
    <ReadinessLabelContainer>
      <span>Complexity</span>
      <Tooltip
        multiline
        w={200}
        position="top"
        label="Complexity is evaluated only for tasks with points and is calculated for the point value assigned to each task on the latest day of the work period."
      >
        <Icon
          icon="mdi:information-outline"
          width={16}
          height={16}
          color={newCOLORS.blue}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    </ReadinessLabelContainer>
  );
}

const Container = styled.div<{ viewAreaWidth: number }>`
  background-color: ${newCOLORS.white};
  padding: 16px 24px;
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1400 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${(props) => (props.viewAreaWidth >= 1400 ? 'flex-start' : 'center')};
`;

const ReadinessLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const CenterContentTag = styled(ContentTag)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: auto;
`;

const KeyMeasuresContainer = styled.div<{ viewAreaWidth: number }>`
  width: ${(props) => (props.viewAreaWidth >= 992 ? '1000px' : '350px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.viewAreaWidth >= 1500 ? '0px' : '20px')};
`;

const CardsBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
