import { AnalyzeDataResponse, AskAgentResponse, UseAIResponse } from './agent-client.type';

class UseAIResult {
  canUseAI: boolean;

  constructor(response: UseAIResponse) {
    this.canUseAI = response.can_use_ai;
  }
}

class AnalyzeDataResult {
  message: string;
  threadId: string;

  constructor(response: AnalyzeDataResponse) {
    this.message = response.message;
    this.threadId = response.thread_id;
  }
}

class AskAgentResult {
  message: string;
  threadId: string;

  constructor(response: AskAgentResponse) {
    this.message = response.message;
    this.threadId = response.thread_id;
  }
}

export { AnalyzeDataResult, AskAgentResult, UseAIResult };
