import { ExternalService } from '../../api/integrations-client/integrations-client.type';
import { ADO } from './ado/ado';
import { Aha } from './aha/aha';
import { Bitbucket } from './bitbucket/bitbucket';
import { Confluence } from './confluence/confluence';
import { Figma } from './figma/figma';
import { Github } from './github/github';
import { Gitlab } from './gitlab/gitlab';
import { IntegrationIcon } from './integration-icon';
import { IntegrationView } from './integrations.type';
import { JDC } from './jdc/jdc';
import { Jira } from './jira/jira';
import { Notion } from './notion/notion';

const integrationViews: IntegrationView[] = [
  {
    name: ExternalService.ADO,
    steps: ['Select a Project', 'Select your Boards', 'Map your Statuses', 'Settings'],
    component: ADO,
    icon: <IntegrationIcon service={ExternalService.ADO} width={155} height={160} />,
    showAlternateHeaderOnSteps: [],
    title: 'Microsoft Azure',
    buttonText: 'Sign-in via Microsoft',
    enabled: true,
  },
  {
    name: ExternalService.Jira,
    steps: ['Select a Project', 'Select your Boards', 'Map your Statuses', 'Settings'],
    component: Jira,
    icon: <IntegrationIcon service={ExternalService.Jira} width={155} height={160} />,
    showAlternateHeaderOnSteps: [5],
    title: 'Atlassian Jira Software Cloud',
    buttonText: 'Sign-in via Atlassian',
    enabled: true,
  },
  {
    name: ExternalService.JDC,
    steps: ['Select a Project', 'Select your Boards', 'Map your Statuses', 'Settings'],
    component: JDC,
    icon: <IntegrationIcon service={ExternalService.JDC} width={155} height={160} />,
    showAlternateHeaderOnSteps: [5],
    title: 'Atlassian Jira Software Data Center',
    buttonText: 'Configure',
    enabled: true,
  },
  {
    name: ExternalService.Github,
    steps: ['Select an Organization', 'Select a Repository', 'Map your Projects', 'Review your Selections'],
    component: Github,
    icon: <IntegrationIcon service={ExternalService.Github} size="10x" />,
    showAlternateHeaderOnSteps: [4],
    title: 'GitHub',
    buttonText: 'Sign-in via GitHub',
    enabled: true,
  },
  {
    name: ExternalService.Figma,
    steps: ['Select a Team', 'Select a project', 'Review your Selections'],
    component: Figma,
    icon: <IntegrationIcon service={ExternalService.Figma} size="10x" />,
    showAlternateHeaderOnSteps: [3],
    title: 'Figma',
    buttonText: 'Sign-in via Figma',
    enabled: import.meta.env.VITE_FEATURE_FLAG_FIGMA === 'true',
  },
  {
    name: ExternalService.Gitlab,
    steps: ['Select your Project', 'Review your Selections'],
    component: Gitlab,
    icon: <IntegrationIcon service={ExternalService.Gitlab} size="10x" />,
    showAlternateHeaderOnSteps: [2],
    title: 'GitLab',
    buttonText: 'Sign-in via GitLab',
    enabled: import.meta.env.VITE_FEATURE_FLAG_GITLAB === 'true',
  },
  {
    name: ExternalService.Aha,
    steps: ['Select your Project', 'Review your Selections'],
    component: Aha,
    icon: <IntegrationIcon service={ExternalService.Aha} size="10x" />,
    showAlternateHeaderOnSteps: [2],
    title: 'Aha!',
    buttonText: 'Sign-in via Aha!',
    enabled: import.meta.env.VITE_FEATURE_FLAG_AHA === 'true',
  },
  {
    name: ExternalService.Bitbucket,
    steps: ['Select your Project', 'Review your Selections'],
    component: Bitbucket,
    icon: <IntegrationIcon service={ExternalService.Bitbucket} size="10x" />,
    showAlternateHeaderOnSteps: [2],
    title: 'Bitbucket',
    buttonText: 'Sign-in via Bitbucket',
    enabled: import.meta.env.VITE_FEATURE_FLAG_BITBUCKET === 'true',
  },
  {
    name: ExternalService.Confluence,
    steps: ['Select your Spaces', 'Review your Selections'],
    component: Confluence,
    icon: <IntegrationIcon service={ExternalService.Confluence} size="10x" />,
    showAlternateHeaderOnSteps: [2],
    title: 'Confluence',
    buttonText: 'Sign-in via Confluence',
    enabled: import.meta.env.VITE_FEATURE_FLAG_CONFLUENCE === 'true',
  },
  {
    name: ExternalService.Notion,
    steps: ['Select your Databases', 'Review your Selections'],
    component: Notion,
    icon: <IntegrationIcon service={ExternalService.Notion} size="10x" />,
    showAlternateHeaderOnSteps: [2],
    title: 'Notion',
    buttonText: 'Sign-in via Notion',
    enabled: import.meta.env.VITE_FEATURE_FLAG_NOTION === 'true',
  },
];

export { integrationViews };
