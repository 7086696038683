import { styled } from '@linaria/react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../api/projects-client/projects-client.type';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { IssueHistoryState } from '../../containers/issue-details/issue-details.type';
import { PillarIssueInput } from '../../containers/sprint-assessment/sprint-assessment.type';
import { newCOLORS } from '../../styles/colors';
import { ContentTag, HeadingTag } from '../../styles/shared-styled-components';

export type PillarCardProps = {
  label: string | React.ReactElement;
  description: string;
  borderColor: string;
  project: Project;
  sprint: Sprint;
  issueProps?: PillarIssueInput;
  prefix?: string;
  suffix?: string;
  value?: number;
};

export const PillarCard = ({
  label,
  description,
  borderColor,
  project,
  sprint,
  issueProps,
  prefix,
  suffix,
  value,
}: PillarCardProps) => {
  const navigate = useNavigate();
  const state: IssueHistoryState = {
    project: project,
    selectedMenuItem: '',
    sprint: sprint,
    sprintId: sprint.id,
    sprintName: sprint.name,
  };
  return (
    <PillarCardContainer
      borderColor={borderColor}
      onClick={() =>
        issueProps &&
        navigate(`/application/issues/`, {
          state: { historyState: state, props: issueProps },
        })
      }
    >
      <CenterHeadingTag style={{ textDecoration: 'underline' }}>{label}</CenterHeadingTag>
      <SmallContentTag style={{ fontSize: '12px', textAlign: 'center', lineHeight: 1 }}>{description}</SmallContentTag>
      <CenterContentTag>
        <span style={{ fontSize: 24, fontWeight: 'bold' }}>{prefix}</span>
        <span style={{ fontSize: 28, fontWeight: 'bold' }}>{parseFloat((value || 0)?.toFixed(1))}</span>
        <span>{suffix}</span>
      </CenterContentTag>
    </PillarCardContainer>
  );
};

const PillarCardContainer = styled.div<{ borderColor: string }>`
  border: 1px solid ${(props: { borderColor: string }) => props.borderColor};
  width: 100px;
  height: 120px;
  padding: 0px 5px;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
`;

const CenterHeadingTag = styled(HeadingTag)`
  font-size: 14px;
  text-align: center;
  color: ${newCOLORS.indigo}};
`;

const SmallContentTag = styled(ContentTag)`
  text-align: center;
  line-height: 1;
`;

const CenterContentTag = styled(ContentTag)`
  position: absolute;
  top: 40%;
  width: 100px;
  text-align: center;
`;
