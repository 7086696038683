import { TooltipItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  Measure,
  MeasureDataByEntity,
  MeasureDataResponse,
  MeasureUnits,
} from '../../../api/work-periods-client/work-periods-client.type';
import { Position } from '../../../components/costs-vs-output/costs-vs-output.types';
import { round, simpleLinearRegression } from '../../../helpers/math-helpers/math-helpers';
import { convertOneMeasure } from '../../../helpers/work-period-measures/convert-metrics-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useAvailableMeasureUnits,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { MeasureUnitMap } from '../../../store/process-analysis-store/process-analysis-store.type';
import { newCOLORS } from '../../../styles/colors';
import { title } from '../measure-comparison/measure-comparison-chart';
import { getChartLabels, sortMeasureDataOverTime } from '../measure-comparison/measure-comparison.helpers';
import { getTitle } from '../process-analysis.helpers';
import { Entities } from '../process-analysis.type';

type Line = {
  data: (number | null)[];
  fill: boolean;
  borderColor: string;
  tension: number;
};

const timeBasedMeasures: Measure[] = [Measure.LeadTime, Measure.ReactionTime, Measure.CycleTime];

export function KeyMeasuresChart({
  tableData,
  measure,
  entitiesWithColor,
  entityTrendsWithColor,
}: {
  tableData: MeasureDataByEntity;
  measure: Measure;
  entitiesWithColor: Entities | null;
  entityTrendsWithColor: Entities | null;
}) {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const timeAllocation = useTimeAllocation();
  const unitsByMeasureName = useAvailableMeasureUnits();

  const defaultEntity = Object.keys(tableData).find(
    (entity) => Object.keys(tableData[entity][measure] || {}).length > 0
  );

  const lines: Line[] = [];

  Object.entries(entitiesWithColor || {}).forEach(([entity, color]) => {
    const entityData = tableData[entity];
    const measureData = sortMeasureDataOverTime(entityData?.[measure as Measure] || {});
    const convertedValues = convertOneMeasure(measureData, measure as Measure, timeBasedMeasures, 1);
    const line = {
      label: getTitle(portfolios, teams, activeTab, entity) || '',
      data: Object.values(convertedValues || {}),
      fill: false,
      borderColor: color,
      tension: 0.1,
      spanGaps: true,
      borderDash: [],
      pointRadius: 3,
      pointHitRadius: 5,
      pointHoverRadius: 5,
      pointBackgroundColor: newCOLORS.white,
    };
    lines.push(line);
  });

  const chartData = {
    labels: getChartLabels(defaultEntity ? tableData[defaultEntity] : ({} as MeasureDataResponse), timeAllocation),
    datasets: lines ? [...lines] : [],
  };

  Object.entries(entityTrendsWithColor || {}).forEach(([entity, color]) => {
    const entityData = tableData[entity];
    const trendData = Object.values(entityData?.[measure as Measure] || {});
    const adjustedTrendData = trendData.map((value) =>
      value !== null && timeBasedMeasures.includes(measure as Measure) ? value / 24 : value
    );
    addTrendDataset(chartData, getTitle(portfolios, teams, activeTab, entity) || '', adjustedTrendData, color);
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: true,
    cubicInterpolationMode: 'monotone',
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Dates',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'left' as Position,
        title: {
          display: true,
          text: measure in unitsByMeasureName ? unitsByMeasureName[measure] : 'Units',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
      tooltip: {
        callbacks: {
          title,
          label: (tooltipItem: TooltipItem<'line'>) => getLabel(tooltipItem, measure, unitsByMeasureName),
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
}

function addTrendDataset(
  chartData: { labels: string[]; datasets: Line[] },
  label: string,
  data: number[],
  color: string
) {
  const filteredData = data.filter((value) => value !== null);
  const xAxis = Array.from({ length: filteredData.length }, (_value, index) => index);

  if (xAxis.length > 1 && filteredData.length > 1) {
    const line = simpleLinearRegression(xAxis, filteredData);
    if (line) {
      const { slope, intercept } = line;
      const trendData = xAxis.map((x) => slope * x + intercept);

      chartData.datasets
        ? chartData.datasets.push(createTrendDataset(label, trendData, color))
        : (chartData.datasets = [createTrendDataset(label, trendData, color)]);
    }
  }
}

function createTrendDataset(label: string, trendData: number[], color: string) {
  return {
    label,
    data: trendData,
    fill: false,
    borderColor: color,
    borderDash: [10, 5],
    backgroundColor: color,
    borderWidth: 2,
    tension: 0.1,
    spanGaps: true,
    pointRadius: 0,
    pointHitRadius: 5,
    pointHoverRadius: 0,
  };
}

/**
 * Custom tooltip label function that appends the unit of the measure to the formatted value.
 *
 * @param {TooltipItem<'line'>} tooltipItem - The tooltip item containing the dataset label and formatted value.
 * @param {Measure} measure - The name of the selected measure.
 * @param {Object} measureUnitMap - An object mapping measure names to their units.
 * @returns {string} The formatted label string with the unit appended.
 */
function getLabel(tooltipItem: TooltipItem<'line'>, measure: Measure, measureUnitMap: MeasureUnitMap): string {
  const unit = measure in measureUnitMap ? measureUnitMap[measure] : '';
  const formattedValue = typeof tooltipItem.raw === 'number' ? round(tooltipItem.raw, 1) : null;

  return unit === MeasureUnits.Percentage ? `${formattedValue}%` : `${formattedValue} ${unit.toLocaleLowerCase()}`;
}
