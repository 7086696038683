import { Select } from '@mantine/core';
import { Subproject } from '../../api/projects-client/projects-client.type';
import { getSelectedSubprojectId, setSelectedSubprojectId } from '../../helpers/storage/storage';
import styles from './subproject-selector.module.css';

function getSubprojectSelectItems(subprojects: Subproject[] | null = [], includeAggregate?: boolean) {
  let selectItems = subprojects
    ? subprojects.map((subproject: Subproject) => ({
        label: subproject.name,
        value: subproject.id as string,
      }))
    : [];

  if (includeAggregate) {
    selectItems = [{ label: 'All Boards', value: '' }, ...selectItems];
  }
  return selectItems;
}

type SubprojectSelectorProps = {
  subprojects: Subproject[];
  setSelectedSubproject: (subproject?: Subproject) => void;
  defaultValue?: string;
  includeAggregate?: boolean;
};

export const SubprojectSelector = ({
  subprojects,
  setSelectedSubproject,
  includeAggregate,
  defaultValue,
}: SubprojectSelectorProps) => {
  const subprojectSelectItems = getSubprojectSelectItems(subprojects, includeAggregate);
  return (
    <>
      {subprojectSelectItems.length > 1 && (
        <Select
          data={subprojectSelectItems}
          defaultValue={
            defaultValue ??
            (subprojectSelectItems.find((x) => x.value == getSelectedSubprojectId()) ?? subprojectSelectItems[0]).value
          }
          className={styles.subprojectSelector}
          onChange={(value: string | null) => {
            if (value) {
              setSelectedSubprojectId(value);
              setSelectedSubproject(
                subprojects.find((x: Subproject) => x.id === value) ?? (includeAggregate ? undefined : subprojects[0])
              );
            }
          }}
          allowDeselect={false}
        />
      )}
    </>
  );
};
