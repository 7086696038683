import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../views/views.module.css';
import { GitlabProject, GitlabState } from './gitlab.type';

type ProjectsProps = {
  gitlabState: GitlabState;
  updateState: (update: Partial<GitlabState>) => void;
};

export const Projects = ({ gitlabState, updateState }: ProjectsProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);

  const handleProjectSelected = (project: GitlabProject) => {
    if ((gitlabState.selectedProjects || []).filter((t: GitlabProject) => t.id === project.id).length > 0) {
      updateState({
        selectedProjects: (gitlabState.selectedProjects || []).filter((t: GitlabProject) => t.id !== project.id),
      });
    } else {
      updateState({ selectedProjects: [...(gitlabState.selectedProjects || []), project] });
    }
  };

  return (
    <div id={styles.viewContainer}>
      <h4>Select at least one project you would like to integrate with:</h4>
      <div id={styles.grid}>
        {(gitlabState.projectOptions || []).map((project: GitlabProject) => (
          <div
            key={project.name}
            className={
              (gitlabState.selectedProjects || []).filter((t: GitlabProject) => t.id === project.id).length > 0
                ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                : styles.checkboxContainer
            }
            onClick={() => handleProjectSelected(project)}
          >
            <span className={styles.checkboxLabel}>{project.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
