import { createContext, ReactNode, useState } from 'react';
import { ProjectsResponse, Subproject } from '../api/projects-client/projects-client.type';
import { FlexibleWorkPeriod, GlobalFilterContextType, WorkPeriodType } from './global-filter.type';

export const GlobalFilterContext = createContext({} as GlobalFilterContextType);

export const GlobalFilterProvider = ({ children }: { children: ReactNode }) => {
  const [team, setTeam] = useState<ProjectsResponse | null>(null);
  const [workPeriodType, setWorkPeriodType] = useState<WorkPeriodType>(WorkPeriodType.Flexible);
  const [board, setBoard] = useState<Subproject | null>(null);
  const [sprint, setSprint] = useState<string | null>(null);
  const [flexibleWorkPeriod, setFlexibleWorkPeriod] = useState<FlexibleWorkPeriod>({ start: null, end: null });

  return (
    <GlobalFilterContext.Provider
      value={{
        team,
        setTeam,
        workPeriodType,
        setWorkPeriodType,
        board,
        setBoard,
        sprint,
        setSprint,
        flexibleWorkPeriod,
        setFlexibleWorkPeriod,
      }}
    >
      {children}
    </GlobalFilterContext.Provider>
  );
};
