import { styled } from '@linaria/react';
import { newCOLORS } from '../../../styles/colors';

export function Pills({ pillText }: { pillText: string[] }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
      {pillText.map((text, index) => (
        <Pill key={index}>{text}</Pill>
      ))}
    </div>
  );
}

const Pill = styled.div`
  padding: 4px;
  color: ${newCOLORS.gray};
  font-weight: 500;
  font-size: 12px;
  border: 1.5px solid ${newCOLORS.gray};
  border-radius: 4px;
  max-height: 18px;
`;
