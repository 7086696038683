import { format } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateDisplayComponents, DayArray, LinkWord } from './string-helpers.type';

dayjs.extend(customParseFormat);

/**
 * Method to capitalize the first letter of the string
 *
 * @param text text to be capitalized
 * @returns capitalized text
 */
const capitalizeFirstLetter = (text: string): string => {
  if (text.length === 0) {
    return '';
  }
  if (text.length === 1) {
    return text.toUpperCase();
  }
  return text[0].toUpperCase() + text.slice(1);
};

/**
 * Method to convert the text to title case
 *
 * @param text text to be converted to title case
 * @returns title cased text
 */
const titleCased = (text: string): string => {
  if (text.length === 0) {
    return '';
  }
  if (text.length === 1) {
    return text.toUpperCase();
  }
  return text.split(' ').map(capitalizeFirstLetter).join(' ');
};

/**
 * Method to convert the date string to short date format
 *
 * @param dateString date string to be converted
 * @returns short date format
 */
const shortDateDisplayFromDateString = (dateString: string): string => {
  const date = Date.parse(dateString);
  return format(date, 'MM/dd');
};

/**
 * Method to convert the date string to short date format
 *
 * @param date date to be converted
 * @param dateString date string to be converted
 * @returns an object with date components
 */
const dateDisplayComponents = ({ date, dateString }: { date?: Date; dateString?: string }): DateDisplayComponents => {
  // I didn't want to make too many assumptions within this method. If you're
  // passing a date in from the server, it'll probably be UTC and look
  // something like 2023-07-03T12:57:40. It'll work great with this if you
  // add a zulu to the end 2023-07-03T12:57:40Z
  const dt = dateString ? new Date(dateString) : date;

  if (!dt) {
    return {} as DateDisplayComponents;
  }

  const weekday = dt.toLocaleString('en-US', { weekday: 'long' });
  const month = dt.toLocaleString('en-US', { month: 'long' });
  const day = dt.toLocaleString('en-US', { day: 'numeric' });
  const year = dt.toLocaleString('en-US', { year: 'numeric' });
  const [hour, meridiem] = dt.toLocaleString('en-US', { hour: '2-digit' }).split(' ');
  let minute = dt.toLocaleString('en-US', { minute: '2-digit' });
  // i have no idea what the tests are doing that's allow "0" to be returned on
  // a '2-digit' format but it's happening so we'll be safe in case there's a
  // browser out there that's equally weird
  if (minute.length === 1) {
    minute = `${minute}0`;
  }
  const timeZoneName = dt.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop();
  return { weekday, month, day, year, hour, minute, meridiem, timeZoneName };
};

/**
 * Method to convert the date string to verbose date range format
 *
 * @param startDate start date
 * @param endDate end date
 * @param startDateString start date string
 * @param endDateString end date string
 * @returns verbose date range format
 */
const verboseDateRangeDisplay = ({
  startDate,
  endDate,
  startDateString,
  endDateString,
}: {
  startDate?: Date;
  endDate?: Date;
  startDateString?: string;
  endDateString?: string;
}): string => {
  // See the note in dateDisplayComponents about the date string format
  const start = startDateString ? new Date(startDateString) : startDate;
  const end = endDateString ? new Date(endDateString) : endDate;

  if (!start || !end || start > end) {
    return '';
  }

  const startComponents = dateDisplayComponents({ date: start });
  const endComponents = dateDisplayComponents({ date: end });

  // if the range happens on the same day
  // Saturday June 10, 2023 from 01:00 AM EST until 03:00 AM EST
  if (
    startComponents.year === endComponents.year &&
    startComponents.month === endComponents.month &&
    startComponents.day === endComponents.day
  ) {
    return `${startComponents.weekday} ${startComponents.month} ${startComponents.day}, ${startComponents.year} from ${startComponents.hour}:${startComponents.minute} ${startComponents.meridiem} ${startComponents.timeZoneName} until ${endComponents.hour}:${endComponents.minute} ${endComponents.meridiem} ${endComponents.timeZoneName}`;
  }

  // if it happens across days or months
  // From Saturday June 10, 2023 01:00 AM EST until Sunday June 11 at 03:00 AM EST
  if (startComponents.year === endComponents.year) {
    return `From ${startComponents.weekday} ${startComponents.month} ${startComponents.day}, ${startComponents.year} ${startComponents.hour}:${startComponents.minute} ${startComponents.meridiem} ${startComponents.timeZoneName} until ${endComponents.weekday} ${endComponents.month} ${endComponents.day} at ${endComponents.hour}:${endComponents.minute} ${endComponents.meridiem} ${endComponents.timeZoneName}`;
  }

  // if the year changes return the full display of both maybe less the trailing weekday
  // From Saturday June 10, 2023 01:00 AM EST until June 11, 2024 at 03:00 AM EST
  return `From ${startComponents.weekday} ${startComponents.month} ${startComponents.day}, ${startComponents.year} ${startComponents.hour}:${startComponents.minute} ${startComponents.meridiem} ${startComponents.timeZoneName} until ${endComponents.month} ${endComponents.day}, ${endComponents.year} at ${endComponents.hour}:${endComponents.minute} ${endComponents.meridiem} ${endComponents.timeZoneName}`;
};

/**
 * Method to generate the total number of days in the sprint for SPS page
 * @param num end day of the sprint
 * @param currentDay current day of the sprint
 * @returns dict of value and label
 */
const generateDayArray = (num: number, currentDay: string): DayArray[] => {
  const dayArray: { value: string; label: string; disabled?: boolean }[] = [];
  for (let i = 0; i < num; i++) {
    if (i + 1 <= Number(currentDay)) {
      dayArray.push({ value: `${i + 1}`, label: `Day ${i + 1}` });
    } else {
      dayArray.push({ value: `${i + 1}`, label: `Day ${i + 1}`, disabled: true });
    }
  }
  return dayArray;
};

/**
 * Method to generate the total number of days in the sprint for SPS page
 * @param num end day of the sprint
 * @param currentDay current day of the sprint
 * @returns dict of value and label
 */
const generateDateStringArray = (
  startDate: string,
  num: number,
  currentDay: number
): { value: string; label: string; disabled?: boolean }[] => {
  const date = new Date(startDate);
  const dayArray: { value: string; label: string; disabled?: boolean }[] = [];

  for (let i = 0; i < num; i++) {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const formattedDate = `${i + 1} (${month}/${day})`;
    if (i + 1 <= currentDay) {
      dayArray.push({ value: `${i + 1}`, label: formattedDate });
    } else {
      dayArray.push({ value: `${i + 1}`, label: formattedDate, disabled: true });
    }

    date.setUTCDate(date.getUTCDate() + 1);
  }
  return dayArray;
};

/**
 * Method to add opacity to the color string
 *
 * @param color original color with opacity 1
 * @param opacity opacity value between 0 to 1
 * @returns color string in '#RRGGBBAA' format
 */
const addOpacityToColor = (color: string, opacity: number): string => {
  if (!/^#[0-9A-Fa-f]{6}$/.test(color)) {
    throw new Error('Invalid color format. Expected color in the format "#RRGGBB".');
  }

  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity value must be between 0 and 1.');
  }

  const alpha = Math.floor(opacity * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0');
  return `${color}${alpha}`;
};

/**
 * Method to extract url links from a text
 * @param text original text which needs to extract the links
 * @returns array of object with link boolean flag and word string
 */
const extractLinks = (text: string): LinkWord[] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return { link: true, word: part };
    } else {
      return { link: false, word: part };
    }
  });
};

/**
 * Method to get month and year from chart dates (e.g., "Sep '24" or "Sep 24" -> { year: 2024, month: '09' })
 */
const extractYearMonth = (dateString: string): { year: string | null; month: string | null } => {
  const formats = ["MMM 'YY", 'MMM YY'];
  let parsedDate;

  for (const format of formats) {
    parsedDate = dayjs(dateString, format, true);
    if (parsedDate.isValid()) {
      return { year: parsedDate.format('YYYY'), month: parsedDate.format('MM') };
    }
  }

  return { year: null, month: null };
};

/**
 * Checks if the given value is a string.
 *
 * @param {unknown} value - the value to be checked
 * @return {boolean} true if the value is a string, false otherwise
 */
const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

/**
 * Sanitizes a string by removing all non-alphanumeric and non-whitespace characters.
 *
 * @param {string} str - The string to sanitize
 * @return {string} The sanitized string
 */
const sanitizeString = (str: string): string => {
  return str.replace(/[^\w\s]/gi, '').trim();
};

/**
 * Converts a snake case string to title case.
 *
 * @param {string} str - The snake case string to convert
 * @return {string} The title case string
 */
const snakeCaseToTitleCase = (str: string): string => {
  return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

export {
  addOpacityToColor,
  capitalizeFirstLetter,
  dateDisplayComponents,
  extractLinks,
  extractYearMonth,
  generateDateStringArray,
  generateDayArray,
  isString,
  sanitizeString,
  shortDateDisplayFromDateString,
  snakeCaseToTitleCase,
  titleCased,
  verboseDateRangeDisplay,
};
