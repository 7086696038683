import { ReactNode, createContext, useEffect, useState } from 'react';
import { NavItem } from '../containers/side-bar/side-bar.type';
import { defaultNavItems } from './sidebar.constants';

type SidebarContextType = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navItems: NavItem[];
  setNavItems: React.Dispatch<React.SetStateAction<NavItem[]>>;
  windowViewportWidth: number;
  setWindowViewportWidth: React.Dispatch<React.SetStateAction<number>>;
  viewAreaWidth: number;
  setViewAreaWidth: React.Dispatch<React.SetStateAction<number>>;
};

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

export function SidebarProvider({ children }: { children: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [navItems, setNavItems] = useState<NavItem[]>(defaultNavItems);
  const [windowViewportWidth, setWindowViewportWidth] = useState<number>(window.innerWidth || 1200);
  const [viewAreaWidth, setViewAreaWidth] = useState<number>(window.innerWidth - 80 || 1120);

  useEffect(() => {
    const handleResize = () => {
      setWindowViewportWidth(window.innerWidth);
      sidebarOpen ? setViewAreaWidth(window.innerWidth - 300) : setViewAreaWidth(window.innerWidth - 80);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sidebarOpen]);

  useEffect(() => {
    sidebarOpen ? setViewAreaWidth(window.innerWidth - 300) : setViewAreaWidth(window.innerWidth - 80);
  }, [sidebarOpen]);

  return (
    <SidebarContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        navItems,
        setNavItems,
        viewAreaWidth,
        setViewAreaWidth,
        windowViewportWidth,
        setWindowViewportWidth,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
