import { styled } from '@linaria/react';
import { Breadcrumbs } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { newCOLORS } from '../../styles/colors';
import styles from './breadcrumb.module.css';
import { CrumbItem } from './breadcrumb.type';

export type BreadcrumbProps = {
  crumbItems: CrumbItem[];
  separator?: string;
};

export function Breadcrumb({ crumbItems, separator = '>' }: BreadcrumbProps) {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleOnClick = (hrefLink = '', state?: {}, onNavigate?: () => void) => {
    onNavigate && onNavigate();
    if (hrefLink) {
      navigate(hrefLink, { state });
    }
  };

  const items = crumbItems.map((item, index) => (
    <BreadCrumbTextContainer
      data-testid={item.labelName}
      onClick={() => handleOnClick(item?.href, item?.state, item?.onNavigate)}
      key={index}
      className={crumbItems.indexOf(item) !== crumbItems.length - 1 ? styles.crumbItem : styles.crumbLastItem}
    >
      {item.labelName}
    </BreadCrumbTextContainer>
  ));

  return (
    <Breadcrumbs separator={separator} style={{ overflow: 'hidden' }}>
      {items}
    </Breadcrumbs>
  );
}

const BreadCrumbTextContainer = styled.div`
  color: ${newCOLORS.darkGray};
  font-family: 'Figtree-bold';
  text-decoration: none;
  line-height: 120%;
`;
