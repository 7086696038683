import { GithubReducerAction, GithubState } from './github.type';

/** The reducer for the Github integration.
 *
 * This reducer is responsible for updating the state of the Github integration.
 * This reducer is also responsible for showing/hiding errors based on data validation.
 *
 * @param state: GithubState- the current state of the Github integration
 * @param action: GithubReducerAction- the action to be performed on the state
 * @returns: GithubState- the updated state of the Github integration
 */
export function githubReducer(state: GithubState, action: GithubReducerAction) {
  switch (action.type) {
    case 'init':
      return { ...state, initialized: true };
    case 'organization':
      return { ...state, organization: action.payload };
    case 'repository':
      return { ...state, repository: action.payload };
    case 'projects':
      return { ...state, projects: action.payload };
    case 'options':
      return { ...state, options: { ...state.options, ...action.payload } };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}
