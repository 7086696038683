import { styled } from '@linaria/react';
import React from 'react';

export type ProgressBarProps = {
  percent: number;
  color: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ percent, color }) => {
  const safePercent = Math.min(Math.max(percent, 0), 100);

  return (
    <Container>
      <Bar
        style={{
          width: `${safePercent}%`,
          backgroundColor: color,
        }}
      ></Bar>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: gray;
  height: 5px;
  border-radius: 6px;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 100%;
`;
