import { Link } from 'react-router-dom';
import { TitleHeadingTag } from '../../styles/shared-styled-components';

type LinkedHeadingTagProps = {
  link: string;
  title: string;
};

export function LinkedHeadingTag(props: LinkedHeadingTagProps) {
  const linkStyle = {
    color: 'black',
  };

  return (
    <Link to={props.link} style={linkStyle}>
      <TitleHeadingTag>{props.title}</TitleHeadingTag>
    </Link>
  );
}
