import { SetStateAction } from 'react';
import { Project } from '../../api/projects-client/projects-client.type';
import { NavItem } from './side-bar.type';

/**
 * Method to reset ProjectContext and all non-general NavItems in SidebarContext
 * @param setProject setProject function from ProjectContext
 * @param navItems navItems state from SidebarContext
 * @param setNavItems setNavItems function from SidebarContext
 */
const onPortfolioBreadcrumbClick = (
  setProject: { (value: SetStateAction<Project | null>): void; (arg0: null): void },
  navItems: NavItem[],
  setNavItems: { (value: SetStateAction<NavItem[]>): void; (arg0: NavItem[]): void }
) => {
  setProject(null);
  const newNavItems: NavItem[] = navItems.map((item: NavItem) => {
    const navItem: NavItem = { ...item };
    if (!navItem.general) {
      navItem.show = false;
    }
    return navItem;
  });
  setNavItems(newNavItems);
};

export { onPortfolioBreadcrumbClick };
