import { styled } from '@linaria/react';
import { Modal } from '@mantine/core';
import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { PortfolioForm } from './portfolio-form';
import { Mode } from './portfolio-form.type';

type PortfolioModalProps = {
  opened: boolean;
  title: string;
  mode: Mode;
  handleClose: () => void;
  handleSubmit: (portfolio: Portfolio) => void;
};

export function PortfolioModal({ opened, title, mode, handleClose, handleSubmit }: PortfolioModalProps) {
  return (
    <CustomModal size={'45rem'} opened={opened} onClose={handleClose} title={title} centered>
      <PortfolioForm onClose={handleClose} onSubmit={handleSubmit} mode={mode} />
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .mantine-Modal-header {
    padding: 24px 24px 0 24px;
    h2 {
      font-size: 1.5em;
    }
  }

  .mantine-Modal-body {
    padding: 24px;
  }
`;
