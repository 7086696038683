import { newCOLORS } from '../../styles/colors';

const assessmentDetails = [
  {
    id: 'backlog',
    label: 'Ready',
    description: 'not started',
    color: newCOLORS.indigo,
  },
  {
    id: 'blocked',
    label: 'Blocked',
    description: 'held up',
    color: newCOLORS.coral,
  },
  {
    id: 'wip',
    label: 'WIP',
    description: 'started',
    color: newCOLORS.darkYellow,
  },
  {
    id: 'review',
    label: 'In Review',
    description: 'code review',
    color: newCOLORS.lightYellow,
  },
  {
    id: 'test',
    label: 'In Test',
    description: 'acceptance',
    color: newCOLORS.pink,
  },
  {
    id: 'deployable',
    label: 'To Deploy',
    description: 'staged',
    color: newCOLORS.aqua,
  },
  {
    id: 'done',
    label: 'Done',
    description: 'work finished',
    color: newCOLORS.green,
  },
];

const defaultDataPoint = {
  backlog: 0,
  blocked: 0,
  deployable: 0,
  done: 0,
  review: 0,
  test: 0,
  wip: 0,
};

export { assessmentDetails, defaultDataPoint };
