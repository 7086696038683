import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';
import { HealthGauge } from '../../../../components/health-gauge/health-gauge';
import { newCOLORS } from '../../../../styles/colors';

export const ScoreCard = ({ health, status }: { health: number; status: 'loading' | 'error' | 'success' }) => {
  return (
    <Container>
      <HealthGaugeBox
        style={{
          marginBottom: 'inherit',
        }}
      >
        <HealthGauge
          heading="Work Period Performance Score"
          tooltip="The Work Period Performance Score measures the daily difference between actual and ideal progress, considering the planned size of the work period in points."
          health={health}
          shouldAnimate
          shouldFade
          status={status}
          delay
        ></HealthGauge>
      </HealthGaugeBox>
      <AuditLogLink to={'audit-log'}>Sprint Audit Log</AuditLogLink>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HealthGaugeBox = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuditLogLink = styled(Link)`
  color: ${newCOLORS.indigo};
  font-weight: bold;
`;
