import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBitbucket,
  faConfluence,
  faFigma,
  faGithub,
  faGitlab,
  faNapster,
  faStickerMule,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalService } from '../../api/integrations-client/integrations-client.type';
import { newCOLORS } from '../../styles/colors';
import { iconsIntegrations } from './assets';

type Props = {
  service: ExternalService;
  width?: number;
  height?: number;
  size?: SizeProp;
};

const assets = {
  [ExternalService.ADO]: iconsIntegrations.iconADO,
  [ExternalService.Jira]: iconsIntegrations.iconJiraCloud,
  [ExternalService.JDC]: iconsIntegrations.iconJiraDataCenter,
};

const faNames = {
  [ExternalService.Github]: faGithub,
  [ExternalService.Figma]: faFigma,
  [ExternalService.Gitlab]: faGitlab,
  [ExternalService.Aha]: faStickerMule, // I used StickerMule as placeholder, replace it with the correct icon.
  [ExternalService.Bitbucket]: faBitbucket,
  [ExternalService.Confluence]: faConfluence,
  [ExternalService.Notion]: faNapster, // I used Napster as placeholder, replace it with the correct icon.
};

export function IntegrationIcon({ service, width, height, size }: Props) {
  const isSVG = [ExternalService.ADO, ExternalService.Jira, ExternalService.JDC].includes(service);

  if (isSVG) {
    return <img src={assets[service as keyof typeof assets]} width={width} height={height} />;
  }

  return (
    <FontAwesomeIcon icon={faNames[service as keyof typeof faNames]} size={size} style={{ color: newCOLORS.black }} />
  );
}
