enum IndicatorType {
  BLANK = 'blank',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

type IndicatorProps = {
  type: IndicatorType;
  tooltip?: string;
};

export { IndicatorType };
export type { IndicatorProps };
