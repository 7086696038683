import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../components/button/button';
import styles from '../views/views.module.css';
import { ConfluenceState } from './confluence.type';

type SummaryProps = {
  confluenceState: ConfluenceState;
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export const Summary = ({ confluenceState, setActiveStep }: SummaryProps) => {
  return (
    <div id={styles.viewContainer}>
      <h3>Review your Selections: </h3>
      <div id={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowSpaceBetween}>
            <h4 className={styles.summaryLabel}>Backlog</h4>
            <Button variant="subtle" onClick={() => setActiveStep(0)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          {(confluenceState.selectedSpaces || []).map((space) => (
            <span key={space.id} className={styles.summaryValue}>
              {space.name || ''}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
