import { styled } from '@linaria/react';
import { Fragment, useContext } from 'react';
import { IssueDetailsTask } from '../../api/issues-client/issues-client.type';
import { ChangeRequest } from '../../api/sprints-client/sprints-client.type';
import { icons } from '../../assets/icons/icons';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import {
  AnalyticsDimensionsProps,
  AnalyticsEventType,
  CustomDimensionIssueType,
  CustomDimensionProjectType,
} from '../../helpers/analytics-event/analytics-event.type';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { newCOLORS } from '../../styles/colors';

type MeasureRelatedDetailsCardProps = {
  task?: IssueDetailsTask;
  project: CustomDimensionProjectType;
  issue: CustomDimensionIssueType;
  github: boolean;
  changeRequest?: ChangeRequest;
};

export const MeasureRelatedDetailsCard = ({
  task,
  changeRequest,
  project,
  issue,
  github,
}: MeasureRelatedDetailsCardProps) => {
  const { user } = useContext(UserContext);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dimensionProps: AnalyticsDimensionsProps = { userContext: user, project, issue };

  const sharedTaskAction = () => {
    trackEvent(AnalyticsEventType.TaskActionTapped, dimensionProps);
  };
  const cleanDescription = (text: string): string => {
    const maxDescriptionLength = 120;
    const re = RegExp('{color[a-zA-Z0-9_:#]{0,10}}|h[0-9].|\\*', 'g');
    text = text.replaceAll(re, '');
    const splitDescription = text?.split('\n', 1);
    let cutDescription = splitDescription && splitDescription.length > 0 ? splitDescription[0] : '';
    if (cutDescription && cutDescription.length > maxDescriptionLength) {
      cutDescription = cutDescription.slice(0, maxDescriptionLength);
      cutDescription = cutDescription.substring(
        0,
        Math.min(cutDescription.length, cutDescription.lastIndexOf(' ') + 1)
      );
    }
    return cutDescription;
  };

  return (
    <RelatedTaskCardTag>
      {github ? (
        <Fragment>
          <TaskHeadingGroup
            onClick={() => {
              sharedTaskAction();
              window.open(changeRequest?.html_url, '_blank', 'noopener, noreferrer');
            }}
          >
            <TaskHeading>
              {changeRequest?.number} - {changeRequest?.title}
            </TaskHeading>
            <img alt="icon" src={icons.iconShare} />
          </TaskHeadingGroup>
          <TaskDescriptionGroup>
            <TaskSubheading>
              {changeRequest?.commits} commits | added {changeRequest?.additions} lines of code | removed{' '}
              {changeRequest?.deletions} lines of code |{' '}
              {changeRequest?.net_lines_of_change && changeRequest?.net_lines_of_change > 0 ? '+' : ''}
              {changeRequest?.net_lines_of_change} lines of code (net)
            </TaskSubheading>
            {changeRequest?.body ? <TaskDescription>{cleanDescription(changeRequest?.body)} ...</TaskDescription> : '-'}
          </TaskDescriptionGroup>
        </Fragment>
      ) : (
        <Fragment>
          <TaskHeadingGroup
            onClick={() => {
              sharedTaskAction();
              window.open(task?.url, '_blank', 'noopener, noreferrer');
            }}
          >
            <TaskHeading>{task?.name}</TaskHeading>
            <img alt="icon" src={icons.iconShare} />
          </TaskHeadingGroup>
          <TaskDescriptionGroup>
            <TaskSubheading>
              {(task?.type[0]?.toUpperCase() as string) + task?.type.slice(1)} |{' '}
              {task?.points === null || task?.points === undefined ? '-' : task?.points} Story Points | Created{' '}
              {formatDate(task?.created_date as string, timezone, DateTemplate.MMDDYYYY)}
            </TaskSubheading>
            {task?.description ? <TaskDescription>{cleanDescription(task?.description)} ...</TaskDescription> : '-'}
          </TaskDescriptionGroup>
        </Fragment>
      )}
    </RelatedTaskCardTag>
  );
};

const RelatedTaskCardTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid RGBA(0, 0, 0, 0.2);
  padding-bottom: 8px;
`;

const CommonText = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const TaskHeadingGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  cursor: pointer;
`;

const TaskDescriptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.65;
`;

const TaskHeading = styled(CommonText)`
  color: ${newCOLORS.darkPurple};
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px; /* 140% */
`;

const TaskSubheading = styled(CommonText)`
  color: ${newCOLORS.darkGray};
  text-align: left;
  font-size: 12px;
  font-weight: 300;
`;

const TaskDescription = styled(CommonText)`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-weight: 300;
  line-height: 21px; /* 140% */
  padding-top: 8px;
  webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;
