const bloomfilterStatusOptions = [
  { name: 'backlogStatuses', label: 'Backlog Sections' },
  { name: 'inProgressStatuses', label: 'In Progress Section' },
  { name: 'blockedStatuses', label: 'Blocked Section' },
  { name: 'inReviewStatuses', label: 'In Review Section' },
  { name: 'inQAStatuses', label: 'In QA Section' },
  { name: 'readyForDeploymentStatuses', label: 'Ready for Deployment Section' },
  { name: 'doneStatuses', label: 'Done Sections' },
];

export { bloomfilterStatusOptions };
