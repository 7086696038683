import { newCOLORS } from '../../styles/colors';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { Legal } from '../setup/terms-of-service';

export function TermsAndCondtions() {
  return (
    <MenuDrivenContainer>
      <div style={{ backgroundColor: newCOLORS.white, padding: '3em' }}>
        <h1>Terms and Conditions</h1>
        <Legal />
      </div>
    </MenuDrivenContainer>
  );
}
