import { Spending } from '../../api/financials-client/financials-client.type';
import { Team } from '../../api/projects-client/projects-client.type';
import { getFinancialsEndDate, getFinancialsStartDate } from '../../helpers/storage/storage';

/**
 * Method to determine if budget data is present for all months
 * @param data spending data
 * @param startDate selected start date
 * @param endDate selected end date
 * @returns boolean of whether all months are present
 */
const areAllMonthsPresent = (data: Spending[], startDate: Date, endDate: Date): boolean => {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const endMonth = endDate.getMonth() + 1;

  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonth : 1;
    const end = year === endYear ? endMonth : 12;

    for (let month = start; month <= end; month++) {
      const found = data.some((entry) => entry.year === year && entry.month === month);
      if (!found) {
        return false;
      }
    }
  }
  return true;
};

/**
 * Method to fetch the unique project ids from the spending data
 * @param projects list of spending data
 * @returns a list of unique project ids
 */
const getUniqueProjectIds = (projects: Spending[]): string[] => {
  return Array.from(new Set(projects.map((project) => project.project_id)));
};

/**
 * Method to return the appropriate message pop up when user is in financials view
 * @param team selected team
 * @param spendingData spending data
 * @returns message text
 */
const getMessageText = (team: Team, spendingData: Spending[], totalTeamsCount: number): string => {
  if (spendingData && spendingData.length > 0) {
    if (team?.id === 'aggregate') {
      const uniqueProjectIds = getUniqueProjectIds(spendingData);

      // Scenario where there is no spending data for some teams in the portfolio
      if (uniqueProjectIds.length !== totalTeamsCount) {
        return 'The All Teams view includes months/teams that do not have a budget amount entered. Edit your financial data to see the most complete picture.';
      }

      // Scenario where there are spending data for multiple teams but with missing some months
      for (const projectId in uniqueProjectIds) {
        const filteredData = spendingData.filter((item) => item.project_id === uniqueProjectIds[projectId]);

        if (filteredData && !areAllMonthsPresent(filteredData, getFinancialsStartDate(), getFinancialsEndDate())) {
          return 'The All Teams view includes months/teams that do not have a budget amount entered. Edit your financial data to see the most complete picture.';
        }
      }
    } else {
      const filteredData = spendingData.filter((item) => item.project_id === team?.id);

      if (
        filteredData.length > 0 &&
        !areAllMonthsPresent(filteredData, getFinancialsStartDate(), getFinancialsEndDate())
      ) {
        return 'This view includes months that do not have a budget amount entered. Edit your financial data to see the most complete picture.';
      }
    }
  }
  return '';
};

/**
 * Method to calculate the number of years between the start and end date
 *
 * @returns a string of years between the start and end date
 */
const getYearsBetween = (): string => {
  const startYear = getFinancialsStartDate().getFullYear();
  const endYear = getFinancialsEndDate().getFullYear();

  // Check if the end year is before the start year
  if (endYear < startYear) {
    return '';
  }

  const years = [];

  if (getFinancialsStartDate().getMonth() > 0 || getFinancialsEndDate().getDate() > 1) {
    years.push(startYear);
  }

  for (let year = startYear + 1; year <= endYear; year++) {
    years.push(year);
  }

  return years.join(',');
};

export { areAllMonthsPresent, getMessageText, getUniqueProjectIds, getYearsBetween };
