import { IndicatorType } from './indicator.type';

/**
 * Returns the background color associated with the given view indicator.
 *
 * @param {IndicatorType} indicator - the view indicator
 * @returns {string} the background color for the indicator
 */
const getIndicatorBgColor = (indicator: IndicatorType): string => {
  const colors = {
    [IndicatorType.BLANK]: '#FFFFFF',
    [IndicatorType.SUCCESS]: '#66CC33',
    [IndicatorType.WARNING]: '#FFB340',
    [IndicatorType.ERROR]: '#E7004C',
  };

  return colors[indicator];
};

/**
 * Returns the border color associated with the given view indicator
 *
 * @param {IndicatorType} indicator - the view indicator
 * @returns {string} the border color for the indicator
 */
const getIndicatorBorderColor = (indicator: IndicatorType): string => {
  const colors = {
    [IndicatorType.BLANK]: '#666666',
    [IndicatorType.SUCCESS]: '#66CC33',
    [IndicatorType.WARNING]: '#FFB340',
    [IndicatorType.ERROR]: '#E7004C',
  };

  return colors[indicator];
};

export { getIndicatorBgColor, getIndicatorBorderColor };
