import { useEffect, useState } from 'react';
import { Sprint, SprintBurnsAndFlows } from '../../api/sprints-client/sprints-client.type';
import { DayCount } from '../../helpers';

/**
 * Calculates the new day and current day based on the sprint and burns data.
 *
 * @param {Sprint} sprint - The sprint object
 * @param {SprintBurnsAndFlows} burns - The sprint burns and flows data
 * @return {{ newDay: string | null, setNewDay: (value: string | null) => void, currentDay: string | null }} Object containing newDay, setNewDay function, and currentDay
 */
const useDayCalculation = (
  sprint: Sprint,
  burns: SprintBurnsAndFlows
): { newDay: string | null; setNewDay: (value: string | null) => void; currentDay: string | null } => {
  const [newDay, setNewDay] = useState<string | null>(null);
  const [currentDay, setCurrentDay] = useState<string | null>(null);

  useEffect(() => {
    const dayCount = DayCount(sprint, burns);
    setNewDay(String(dayCount));
    setCurrentDay(String(dayCount));
  }, [sprint, burns]);

  return { newDay, setNewDay, currentDay };
};

export { useDayCalculation };
