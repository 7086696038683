import { newCOLORS } from '../../styles/colors';
import { icons } from './assets';
import { Grouping } from './tasks-table.type';

const groupingData = [
  {
    label: 'Initiative',
    value: Grouping.Initiative,
    icon: icons.iconGroupingInitiative,
    bgColor: newCOLORS.lightOrange2,
    labelColor: newCOLORS.brown,
  },
  {
    label: 'Epic',
    value: Grouping.Epic,
    icon: icons.iconGroupingEpic,
    bgColor: newCOLORS.lighterPurple,
    labelColor: newCOLORS.darkBlue,
  },
  {
    label: 'Points',
    value: Grouping.Points,
    icon: icons.iconGroupingPoints,
    bgColor: newCOLORS.lighterGray2,
    labelColor: newCOLORS.darkerGray2,
  },
  {
    label: 'Type',
    value: Grouping.Type,
    icon: icons.iconGroupingType,
    bgColor: newCOLORS.lighterGray2,
    labelColor: newCOLORS.darkerGray2,
  },
];

export { groupingData };
