import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Markdown from 'markdown-to-jsx';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IssueDetailsResult } from '../../api/issues-client/issues-client.class';
import { IssueDescribable } from '../../api/issues-client/issues-client.type';
import { UserInfo } from '../../api/user-client/user-client.type';
import { IssueHistoryState } from '../../containers/issue-details/issue-details.type';
import { SidebarContext } from '../../contexts';
import { newCOLORS } from '../../styles/colors';
import { SubtitleHeadingTag } from '../../styles/shared-styled-components';

type ExtendedIssueCardProps = {
  historyState: IssueHistoryState;
  user: UserInfo;
  issue: IssueDetailsResult;
  onClick: (issue: IssueDescribable) => void;
};

export function ExtendedIssueCard(props: ExtendedIssueCardProps) {
  const handleIssueClick = () => {
    props.onClick(props.issue);
  };
  const [opened, { toggle }] = useDisclosure(true);
  const { sidebarOpen } = useContext(SidebarContext);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const splitSummary = props.issue.summary?.split(
    props.historyState.selectedMenuItem == 'Project' ? 'Project' : 'Sprint',
    2
  );
  let firstHalf = '';
  let secondHalf = '';
  if (splitSummary && splitSummary.length == 2) {
    firstHalf = splitSummary[0];
    secondHalf = splitSummary[1];
  }
  const navigate = useNavigate();
  return (
    <IssueCardContainer onClick={handleIssueClick}>
      <IssueInformationRow>
        <CollapseIcon>
          {opened ? (
            <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.indigo} onClick={toggle} />
          ) : (
            <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.indigo} onClick={toggle} />
          )}
        </CollapseIcon>
        <IssueAmountContainer>
          <IssueCardAmount>{props.issue.amount}</IssueCardAmount>
        </IssueAmountContainer>
        <IssueTextContainer>
          <IssueCardHeading>
            {props.issue.title}
            {props.issue.task_count ? ` out of ${props.issue.task_count} non-stale tasks` : ''}
          </IssueCardHeading>
          <IssueSubTextContainer>
            <IssueCardSubHeading>{firstHalf}</IssueCardSubHeading>
            {firstHalf || secondHalf ? (
              props.historyState.selectedMenuItem == 'Project' ? (
                <ProjectName onClick={() => navigate(`/application/project/${props.historyState.project.id}`)}>
                  {props.historyState.project.name}
                </ProjectName>
              ) : (
                <ProjectName
                  onClick={() =>
                    navigate(
                      `/application/project/${props.historyState.project.id}/sprint-assessment/${props.historyState.sprint?.id}`
                    )
                  }
                >
                  {props.historyState.sprint?.name}
                </ProjectName>
              )
            ) : (
              <IssueCardSubHeading>{props.issue.summary}</IssueCardSubHeading>
            )}
            <IssueCardSubHeading>{secondHalf}</IssueCardSubHeading>
          </IssueSubTextContainer>
        </IssueTextContainer>
      </IssueInformationRow>
      <Collapse in={opened}>
        <ZoneCollapseContent
          style={{
            flexDirection: windowWidth > (sidebarOpen ? 1700 : 1400) ? 'row' : 'column',
            alignItems: windowWidth > (sidebarOpen ? 1700 : 1400) ? 'start' : 'center',
          }}
        >
          <IssueDescriptionRow>
            <InformationColumn>
              <TextHeadingTag>What does this mean?</TextHeadingTag>
              <DescriptionTextTag>
                <Markdown>{props.issue.description}</Markdown>
              </DescriptionTextTag>
            </InformationColumn>
            <InformationColumn>
              {props.issue.calculation && (
                <div>
                  <TextHeadingTag>How is it calculated?</TextHeadingTag>
                  <DescriptionTextTag>
                    <Markdown>{props.issue.calculation}</Markdown>
                  </DescriptionTextTag>
                </div>
              )}
            </InformationColumn>
            <InformationColumn>
              {props.issue.solution && (
                <div>
                  <TextHeadingTag>How can I fix it?</TextHeadingTag>
                  <DescriptionTextTag>
                    <Markdown>{props.issue.solution}</Markdown>
                  </DescriptionTextTag>
                </div>
              )}
            </InformationColumn>
          </IssueDescriptionRow>
        </ZoneCollapseContent>
      </Collapse>
    </IssueCardContainer>
  );
}

const IssueCardContainer = styled.div`
  display: flex;
  position: relative;
  padding: 10px;
  margin-top: 20px;
  background-color: ${newCOLORS.white};
  box-shadow: 0px 2px 3px 0px ${newCOLORS.lightGray};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 117px;
  border-radius: 4px;
`;

const IssueInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 10px;
`;

const IssueDescriptionRow = styled.div`
  flex-direction: row;
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
`;

const IssueAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.025;
  padding-left: 10px;
  border-left: 1px solid RGBA(0, 0, 0, 0.2);
`;

const IssueCardAmount = styled.div`
  margin: 0;
  padding-right: 15px;
  padding-left: 5px;
  font-family: 'Figtree-bold';
  font-size: xxx-large;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  align-items: center;
`;

const IssueTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const IssueSubTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
`;

const InformationColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const IssueCardText = styled.div`
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const IssueCardHeading = styled(IssueCardText)`
  font-size: medium;
  font-weight: 900;
`;

const IssueCardSubHeading = styled(IssueCardText)`
  font-size: small;
  font-weight: 200;
  padding-top: 5px;
  margin-right: 3px;
`;

const DescriptionTextTag = styled(SubtitleHeadingTag)`
  font-family: 'Figtree-light';
  font-size: medium;
  color: ${newCOLORS.black};
  padding-right: 40px;
`;

const TextHeadingTag = styled.div`
  font-family: 'Figtree-bold';
  font-size: large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 5px;
`;

const ProjectName = styled(IssueCardSubHeading)`
  color: ${newCOLORS.purple};
  cursor: pointer;
  flex-direction: row;
`;

const CollapseIcon = styled.div`
  display: flex;
  font-weight: 800;
  cursor: pointer;
  padding-top: 30px;
  padding-right: 16px;
`;

const ZoneCollapseContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
`;
