import { styled } from '@linaria/react';
import BFMark from '../../assets/images/Bloomfilter-Mark-Color.svg';
import { FallbackOptions } from './data-fallback-message.type';

type DataFallbackMessageProps = {
  text: string;
  suggestions?: FallbackOptions;
};

export const DataFallbackMessage = ({ text, suggestions }: DataFallbackMessageProps) => {
  return (
    <TextContainer>
      <p>{text}</p>
      {suggestions ? (
        <>
          <p>{suggestions.instructions}</p>
          <OptionContainer>
            {suggestions.options?.map((x: string) => (
              <p>{x}</p>
            ))}
          </OptionContainer>
        </>
      ) : (
        <img src={BFMark} style={{ margin: '20px', maxWidth: '15%', maxHeight: '15%', opacity: '40%' }} />
      )}
    </TextContainer>
  );
};

const TextContainer = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
`;
