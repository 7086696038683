import { Button, TextInput } from '@mantine/core';
import { Dispatch, MouseEventHandler, SetStateAction, useState, useEffect, useCallback } from 'react';
import { Brand } from '../../../components/brand/brand';
import { clearAuthenticationStore } from '../../../helpers/storage/storage';
import { newCOLORS } from '../../../styles/colors';
import { Password, ConfirmPassword } from '../sign-in';
import styles from '../sign-in.module.css';
import { icons } from '../assets';

type SignUpViewProps = {
  title?: string;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password: string;
  confirmPassword: string;
  setPassword: Dispatch<SetStateAction<string>>;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  handleSignUp: MouseEventHandler;
  error: string;
};

export function SignUpView({
  email,
  setEmail,
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  handleSignUp,
  error,
}: SignUpViewProps) {
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  // clear refresh & access tokens issued from any previous sign up attempt
  useCallback(clearAuthenticationStore, []);

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  return (
    <>
      <div id={styles.signUpContainer} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          id={styles.signUpInner}
          style={{ maxWidth: '523px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}
        >
          <Brand style={{ minWidth: '60%' }} darkMode={true} clickable={false} />
          <h1 style={{ marginTop: '48px', color: newCOLORS.white }}>Get a real-time, objective view into your SDLC</h1>
          <div id={styles.signUpChecks}>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '12px' }}>
              <img src={icons.iconCheckCircle} width={24} height={24} />
              <div>
                <p className={styles.signUpCheckHeading} style={{ color: newCOLORS.white }}>
                  Create your free account
                </p>
                <p className={styles.signUpCheckSubHeading} style={{ color: newCOLORS.white }}>
                  No credit card required
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '12px' }}>
              <img src={icons.iconCheckCircle} width={24} height={24} />
              <div>
                <p className={styles.signUpCheckHeading} style={{ color: newCOLORS.white }}>
                  Integrate your data sources
                </p>
                <p className={styles.signUpCheckSubHeading} style={{ color: newCOLORS.white }}>
                  Jira, GitHub, Azure DevOps
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '12px' }}>
              <img src={icons.iconCheckCircle} width={24} height={24} />
              <div>
                <p className={styles.signUpCheckHeading} style={{ color: newCOLORS.white }}>
                  Start mining for insights
                </p>
                <p className={styles.signUpCheckSubHeading} style={{ color: newCOLORS.white }}>
                  Get a clear view of your SDLC
                </p>
              </div>
            </div>
          </div>
          <div id={styles.signUpSeperator}></div>
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '12px' }}>
            <img src={icons.infoCircle} width={16} height={16} />
            <div>
              <p
                style={{
                  fontSize: '16px',
                  color: newCOLORS.white,
                  marginBottom: '0px',
                  lineHeight: '100%',
                  fontWeight: 500,
                }}
              >
                Not ready to onboard?
              </p>
              <a
                style={{ marginTop: '8px', display: 'flex', gap: '8px' }}
                href="https://www.thebloomfilter.com/contact-expert"
                target="_blank"
              >
                <p
                  style={{
                    fontSize: '16px',
                    textDecoration: 'underline',
                    color: newCOLORS.white,
                    opacity: '.8',
                    marginBottom: '0px',
                    lineHeight: '130%',
                    fontWeight: 500,
                  }}
                >
                  Talk to an expert to learn more
                </p>
                <img src={icons.arrowCorner} width={12} height={12} />
              </a>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '467px' }} id={styles.signInContainer}>
          <div>
            <h3 style={{ textAlign: 'center', color: newCOLORS.white, marginBottom: '8px' }}>Create Account</h3>
            <p className={styles.signUpCheckSubHeading} style={{ textAlign: 'center', color: newCOLORS.white }}>
              Fill in details to get started
            </p>
          </div>
          <div id={styles.formContainer}>
            <TextInput
              label="Work Email"
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              error={!!error}
              classNames={{ input: styles.textInput, label: styles.textInputLabel }}
            />
            <Password password={password} formLabel="Create Password" setPassword={setPassword} error={error} />
            <ConfirmPassword
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              passwordsMatch={passwordsMatch}
              error={error}
            />
            <Button
              className={`${styles.secondaryButton} ${!passwordsMatch ? styles.disabledButton : ''}`}
              onClick={handleSignUp}
              disabled={!passwordsMatch}
            >
              Continue
            </Button>
            <div>
              <p id={styles.legal}>{`© ${new Date().getFullYear()} Bloomfilter. All rights reserved.`}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
