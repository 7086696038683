/**
 * Returns the CSS border-radius variable based on the given radius value.
 *
 * @param { 'none' | 'sm' | 'md' | 'lg' | 'xl' } radius - The radius value.
 * @return { string } The CSS border-radius variable.
 */
const getBorderRadius = (radius: 'none' | 'sm' | 'md' | 'lg' | 'xl'): string => {
  switch (radius) {
    case 'sm':
      return 'var(--border-radius-xxtiny)';
    case 'md':
      return 'var(--border-radius-xtiny)';
    case 'lg':
      return 'var(--border-radius-tiny)';
    case 'xl':
      return 'var(--border-radius-xlarge)';
    case 'none':
      return 'var(--border-radius-none)';
  }
};

/**
 * Returns the height in pixels based on the given size.
 *
 * @param { 'xs' | 'sm' | 'md' | 'lg' | 'xl' } size - The size of the element.
 * @return { number } The height in pixels.
 */
const getHeight = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): number => {
  switch (size) {
    case 'xs':
      return 24;
    case 'sm':
      return 30;
    case 'md':
      return 36;
    case 'lg':
      return 42;
    case 'xl':
      return 50;
  }
};

/**
 * Returns the padding in pixels based on the given size.
 *
 * @param { 'xs' | 'sm' | 'md' | 'lg' | 'xl' } size - The size of the element.
 * @return { number } The padding in pixels.
 */
const getPadding = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): number => {
  switch (size) {
    case 'xs':
      return 8;
    case 'sm':
      return 12;
    case 'md':
      return 14;
    case 'lg':
      return 14;
    case 'xl':
      return 16;
  }
};

/**
 * Returns the font style object based on the given size.
 *
 * @param { 'xs' | 'sm' | 'md' | 'lg' | 'xl' } size - The size of the element.
 * @return { object } The font style object with properties `fontSize`, `lineHeight`, and `fontWeight`.
 */
const getFontStyle = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): object => {
  switch (size) {
    case 'xs':
    case 'sm':
      return {
        fontSize: 'var(--font-size-xsmall)',
        lineHeight: 'var(--line-height-xsmall)',
        fontWeight: 'var(--font-weight-medium)',
      };
    case 'md':
    case 'lg':
      return {
        fontSize: 'var(--font-size-small)',
        lineHeight: 'var(--line-height-xsmall)',
        fontWeight: 'var(--font-weight-medium)',
      };
    case 'xl':
      return {
        fontSize: 'var(--font-size-regular)',
        lineHeight: 'var(--line-height-xsmall)',
        fontWeight: 'var(--font-weight-medium)',
      };
  }
};

export { getBorderRadius, getFontStyle, getHeight, getPadding };
