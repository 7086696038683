import { AppShell } from '@mantine/core';
import { ReactNode, useContext } from 'react';
import { Project } from '../../api/projects-client/projects-client.type';
import { GlobalContext, SidebarContext } from '../../contexts';
import { newCOLORS } from '../../styles/colors';
import { HeaderBar } from '../header/header';
import { SideBar } from '../side-bar/side-bar';

type MenuDrivenContainerProps = {
  project: Project;
  children: ReactNode;
  padding: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  contentStyles: React.CSSProperties;
  containerStyles: React.CSSProperties;
};

export const MenuDrivenContainer = ({
  children,
  padding,
  contentStyles,
  containerStyles,
}: Partial<MenuDrivenContainerProps>) => {
  const { sidebarOpen } = useContext(SidebarContext);
  const { globalMessage } = useContext(GlobalContext);

  const handleRef = (ref: HTMLDivElement | null) => {
    if (ref && ref.parentNode instanceof HTMLElement) {
      ref.parentNode.style.width = 'auto';
    }
  };

  return (
    <AppShell
      style={{ maxWidth: '100vw', ...containerStyles }}
      header={{ height: globalMessage ? 140 : 90, offset: true }}
      navbar={{ width: sidebarOpen ? 250 : 70, breakpoint: 'sm' }}
      padding={padding || 'sm'}
    >
      <HeaderBar />
      <SideBar />
      <AppShell.Main style={{ minHeight: 'fit-content', height: '100vh', boxSizing: 'border-box' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 'auto',
            backgroundColor: newCOLORS.lightishGray,
            paddingLeft: 32,
            ...contentStyles,
          }}
          ref={handleRef}
        >
          {children}
        </div>
      </AppShell.Main>
    </AppShell>
  );
};
