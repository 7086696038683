import { Node as ReactFlowNode } from 'reactflow';
import { GraphData, ProcessMapping, Stages, WorkflowStatus } from '../../../api/process-client/process-client.type';
import { findByName, findByOrder, getStageIndex, getStageTitle, getStages } from '../process.helpers';

const X_GRID_STEP = 120;
const Y_GRID_STEP = 100;

/**
 * Generates an array of ReactFlowNode objects representing the stage nodes in the process map.
 *
 * @param {Stages[]} stages - An array of stages.
 * @param {ProcessMapping} mapping - The process mapping object.
 * @returns {ReactFlowNode[]} - An array of ReactFlowNode objects representing the stage nodes.
 */
const getStageNodes = (stages: Stages[], mapping: ProcessMapping): ReactFlowNode[] => {
  const gridY = getGridY(mapping);

  return stages.map((stage) => {
    return {
      id: `node-stage-${stage}`,
      type: 'stageNode',
      position: { x: 0, y: gridY[stage] },
      data: {
        title: getStageTitle(stage),
        stage,
        customerStages: mapping.bf_to_customer[stage] || [],
      },
    };
  });
};

/**
 * Returns an array of ReactFlowNodes representing the workflow nodes based on the provided graph data, stages, and process mapping.
 *
 * @param {GraphData} graphData - The graph data containing the nodes and edges of the workflow.
 * @param {Stages[]} stages - An array of stages representing the workflow stages.
 * @param {ProcessMapping} mapping - The process mapping used to determine the grid Y position of each node.
 * @returns {ReactFlowNode[]} An array of ReactFlowNodes representing the workflow nodes.
 */
const getWorkflowNodes = (graphData: GraphData, stages: Stages[], mapping: ProcessMapping): ReactFlowNode[] => {
  const gridY = getGridY(mapping);

  return stages.reduce((resultNodes, stage) => {
    const skipped = getSkippedNodes(stage, graphData, mapping, gridY);
    const backwards = getBackwardsNodes(stage, graphData, mapping, gridY);

    return [...resultNodes, ...skipped, ...backwards];
  }, [] as ReactFlowNode[]);
};

/**
 * Returns an array of skipped workflow nodes based on the given stage, graph data, and grid Y positions. These are the nodes
 * that represent the number of tasks that have been skipped 1 or more stages onward in the workflow.
 *
 * @param {Stages} stage - The stage for which to calculate skipped workflow nodes.
 * @param {GraphData} graphData - The graph data.
 * @param {ProcessMapping} mapping - The process mapping.
 * @param {Record<Stages, number>} gridY - The grid Y positions for each stage.
 * @returns {ReactFlowNode[]} - An array of skipped workflow nodes.
 */
const getSkippedNodes = (
  stage: Stages,
  { nodes, edges }: GraphData,
  mapping: ProcessMapping,
  gridY: Record<Stages, number>
): ReactFlowNode[] => {
  const sourceIndex = getStageIndex(stage, mapping);
  const sourceNode = findByName(nodes, stage);

  if (!sourceNode) {
    return [];
  }

  const relations = edges[sourceNode.order] || {};

  const destinations = Object.keys(relations).filter((nodeOrder) => {
    const destination = findByOrder(nodes, Number(nodeOrder));
    const destinationIndex = getStageIndex(destination?.name as Stages, mapping);

    if (!destination || destinationIndex < 0) {
      return false;
    }

    return destinationIndex > sourceIndex + 1;
  });

  return destinations
    .map((nodeOrder) => {
      const destination = findByOrder(nodes, Number(nodeOrder));
      const taskCount = edges[sourceNode.order]?.[Number(nodeOrder)] || 0;

      if (!destination || !taskCount) {
        return null;
      }

      const destinationStage = destination.name as Stages;

      const y = (gridY[destinationStage] - gridY[stage]) / 2 + gridY[stage];
      const destinationIndex = getStageIndex(destinationStage, mapping);
      const distance = destinationIndex - sourceIndex;
      const x = getOffsetX(distance) * distance;

      const workflowMapping = {
        [stage]: mapping.bf_to_customer[stage],
        [destinationStage]: mapping.bf_to_customer[destinationStage],
      };

      return {
        id: `node-skip-${stage}-${destinationStage}`,
        type: 'workflowNode',
        position: { x, y },
        data: {
          value: taskCount,
          status: WorkflowStatus.Skipped,
          source: `node-stage-${stage}`,
          target: `node-stage-${destinationStage}`,
          sourceStage: stage,
          destinationStage,
          mapping: workflowMapping,
        },
      };
    })
    .filter(Boolean) as ReactFlowNode[];
};

/**
 * Returns an array of backwards workflow nodes based on the given stage, graph data, and grid Y positions. These are the nodes
 * that represent the number of tasks that have been returned 1 or more stages backward in the workflow.
 *
 * @param {Stages} stage - The stage for which to calculate backwards workflow nodes.
 * @param {GraphData} graphData - The graph data.
 * @param {ProcessMapping} mapping - The process mapping.
 * @param {Record<Stages, number>} gridY - The grid Y positions of each stage.
 * @returns {ReactFlowNode[]} - An array of backwards workflow nodes.
 */
const getBackwardsNodes = (
  stage: Stages,
  { nodes, edges }: GraphData,
  mapping: ProcessMapping,
  gridY: Record<Stages, number>
): ReactFlowNode[] => {
  const sourceIndex = getStageIndex(stage, mapping);
  const sourceNode = findByName(nodes, stage);

  if (!sourceNode) {
    return [];
  }

  const relations = edges[sourceNode.order] || {};

  const destinations = Object.keys(relations).filter((nodeOrder) => {
    const destination = findByOrder(nodes, Number(nodeOrder));

    if (!destination) {
      return false;
    }

    const destinationIndex = getStageIndex(destination.name as Stages, mapping);

    if (destinationIndex < 0) {
      return false;
    }

    return destinationIndex < sourceIndex;
  });

  return destinations
    .map((nodeOrder) => {
      const destination = findByOrder(nodes, Number(nodeOrder));
      const taskCount = edges[sourceNode.order]?.[Number(nodeOrder)] || 0;

      if (!destination || !taskCount) {
        return null;
      }

      const destinationStage = destination.name as Stages;

      const y = (gridY[stage] - gridY[destinationStage]) / 2 + gridY[destination.name as Stages];
      const destinationIndex = getStageIndex(destinationStage, mapping);
      const distance = sourceIndex - destinationIndex;

      const offset = distance === 1 ? -X_GRID_STEP + 20 : -getOffsetX(distance);
      const x = offset * distance;

      const workflowMapping = {
        [stage]: mapping.bf_to_customer[stage],
        [destinationStage]: mapping.bf_to_customer[destinationStage],
      };

      return {
        id: `node-backwards-${stage}-${destinationStage}`,
        type: 'workflowNode',
        position: { x, y },
        data: {
          value: taskCount,
          status: WorkflowStatus.Backwards,
          source: `node-stage-${stage}`,
          target: `node-stage-${destination.name}`,
          sourceStage: stage,
          destinationStage,
          mapping: workflowMapping,
        },
      };
    })
    .filter(Boolean) as ReactFlowNode[];
};

/**
 * Calculates the Y-coordinate for each stage in the grid based on the provided mapping.
 *
 * @param {ProcessMapping} mapping - The process mapping object.
 * @returns {Record<Stages, number>} - An object that maps each stage to its corresponding Y-coordinate in the grid.
 */
const getGridY = (mapping: ProcessMapping): Record<Stages, number> => {
  const stages = getStages(mapping);

  return stages.reduce((acc, stage, index) => {
    const prevStage = stages[index - 1];
    const prevStageY = acc[prevStage] || 0;
    const base = prevStageY + Y_GRID_STEP;
    const offset =
      (mapping.bf_to_customer[stage]?.length || 0) * 10 + (mapping.bf_to_customer[prevStage]?.length || 0) * 10;

    return {
      ...acc,
      [stage]: base + offset,
    };
  }, {} as Record<Stages, number>);
};

/**
 * Calculates the offset on the X-axis based on the given distance between stages.
 *
 * @param {number} distance - The distance between stages used to calculate the offset.
 * @returns {number} The calculated offset on the X-axis.
 */
const getOffsetX = (distance: number): number => {
  const coefficients: Record<number, number> = {
    2: 1.4,
    3: 1.35,
    4: 1.25,
    5: 1.2,
    6: 1.15,
  };

  if (distance < 2) {
    return X_GRID_STEP / 1.5;
  }

  if (distance > 6) {
    return X_GRID_STEP;
  }

  return X_GRID_STEP / coefficients[distance];
};

export { getBackwardsNodes, getGridY, getOffsetX, getSkippedNodes, getStageNodes, getWorkflowNodes };
