import { styled } from '@linaria/react';
import { Badge, Divider, InputBase, Select, Skeleton, Textarea } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';

import { Button } from '../../../components/button/button';

import { Icon } from '@iconify/react/dist/iconify.js';
import { useContext, useState } from 'react';
import { useCreatePortfolio, useUpdatePortfolio } from '../../../api/portfolio-client/portfolio-client.hooks';
import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { PortfolioContext } from '../../../contexts';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { newCOLORS } from '../../../styles/colors';
import { formatProjectsData, handleSelectProject } from './portfolio-form.helpers';
import { FormValues, Mode, Variant } from './portfolio-form.type';

type PortfolioModalProps = {
  mode: Mode;
  onClose: () => void;
  onSubmit: (portfolio: Portfolio) => void;
};

export function PortfolioForm({ mode, onClose, onSubmit }: PortfolioModalProps) {
  const { portfolios = [], teams: projects = [] } = useGlobalStore();
  const { portfolio } = useContext(PortfolioContext);

  const initialTeams = portfolio && 'projects' in portfolio ? portfolio.projects : [];

  const [selectedProjects, setSelectedProjects] = useState<ProjectsResponse[]>(mode === 'edit' ? initialTeams : []);

  const createPortfolio = useCreatePortfolio();
  const updatePortfolio = useUpdatePortfolio();

  const initialValues = {
    name: mode === 'edit' ? portfolio?.name || '' : '',
    description: mode === 'edit' ? portfolio?.description || '' : '',
    variant: 'teams' as Variant,
  };

  const form = useForm<FormValues>({
    validateInputOnChange: true,
    initialValues,
    validate: {
      name: isNotEmpty(),
      description: isNotEmpty(),
      variant: isNotEmpty(),
    },
  });

  const handleSubmit = async () => {
    if (portfolio) {
      const data = {
        name: form.values.name,
        description: form.values.description,
        organization: portfolio.organization.id,
        projects: selectedProjects.map((project) => project.id),
        is_default: false, // If we are ever to let the user decide their master/default portfolio, this line has to be updated
      };

      if (mode === 'edit') {
        const updatedPortfolio = await updatePortfolio.mutateAsync({ id: portfolio.id, ...data });
        onSubmit(updatedPortfolio as Portfolio);
      } else {
        const newPortfolio = await createPortfolio.mutateAsync(data);
        onSubmit(newPortfolio as Portfolio);
      }
    }
    onClose();
  };

  const disableNext = !form.values.name || !form.values.description || !selectedProjects.length;

  return (
    <form>
      <ContentContainer>
        <NewPortfolioInputArea>
          <PortfolioValues>
            <InputBase label="Name" maxLength={150} withAsterisk {...form.getInputProps('name')} />
            <Textarea label="Description" maxLength={1000} withAsterisk {...form.getInputProps('description')} />
          </PortfolioValues>
        </NewPortfolioInputArea>

        <Divider size="sm" style={{ margin: '16px 0px' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5em' }}>
          <div>
            <Heading>{`${form.values.variant} included in this Portfolio`}</Heading>
            {selectedProjects.length ? (
              <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>
                {selectedProjects.map((project) => (
                  <Badge
                    key={project.id}
                    variant="light"
                    size="lg"
                    m={5}
                    pr={3}
                    rightSection={
                      <Icon
                        icon="jam:close"
                        width={24}
                        height={24}
                        color={newCOLORS.indigo}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const rest = selectedProjects.filter((p) => p.id !== project.id);
                          setSelectedProjects(rest);
                        }}
                      />
                    }
                    styles={{ root: { color: newCOLORS.indigo, width: 'fit-content' } }}
                  >
                    {project.name}
                  </Badge>
                ))}
              </div>
            ) : null}
            {projects?.length && portfolios?.length ? (
              <Select
                placeholder={`Add ${form.values.variant} to this Portfolio`}
                searchable
                withAsterisk
                nothingFoundMessage="Nothing Found..."
                value={null}
                data={formatProjectsData(projects, selectedProjects)}
                style={{ width: '67%', marginBottom: 16 }}
                onChange={(value) => {
                  handleSelectProject(value, projects, selectedProjects, setSelectedProjects);
                }}
              />
            ) : (
              <Skeleton height={32} radius="md" width="70%" />
            )}
          </div>
        </div>

        <Divider size="sm" style={{ margin: '16px 0px' }} />
        <ModalButtons>
          <Button disabled={disableNext} onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalButtons>
      </ContentContainer>
    </form>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  .mantine-InputWrapper-label {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px;
  }
`;

const Heading = styled.p`
  margin-bottom: 0px;
`;

const NewPortfolioInputArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3em;
`;

const PortfolioValues = styled.div`
  width: 67%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ModalButtons = styled.div`
  display: flex;
  gap: 1em;
`;
