import { styled } from '@linaria/react';
import { Button, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDeepCompareEffect } from 'react-use';
import { icons } from '../../../assets/icons/icons';
import { TeamMultiselect } from '../../../components/multiselect/team-multiselect';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { setIsDirtyTeams, setTeamIds } from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { teamFormActions, useTeamForm } from './scope-explorer.hooks';

export const TeamForm = () => {
  const { teamForm, handleSubmitTeamForm } = useTeamForm();
  const teams = useGlobalStore((state) => state.teams);
  const teamIds = useProcessAnalysisStore((state) => state.teamIds);

  useDeepCompareEffect(() => {
    if (!teams.length) {
      return setTeamIds([]);
    }

    const isValidSelection = teamIds.every((id) => teams.some((team) => team.id === id));

    if (!isValidSelection || !teamIds.length) {
      setTeamIds([teams[0].id]);
      teamFormActions.setFieldValue('teams', [teams[0].id]);
      teamFormActions.resetDirty();
    }
  }, [teams, teamIds]);

  return (
    <form onSubmit={teamForm.onSubmit(() => handleSubmitTeamForm(teamForm))}>
      <Panel>
        <TeamMultiselect
          required
          {...teamForm.getInputProps('teams')}
          onChange={(ids) => {
            teamForm.setFieldValue('teams', ids);
            setIsDirtyTeams(true);
          }}
        />
        <Divider orientation="vertical" />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...teamForm.getInputProps('startDate')}
          maxDate={teamForm.values.endDate}
          pointer
          miw="fit-content"
        />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...teamForm.getInputProps('endDate')}
          minDate={teamForm.values.startDate}
          pointer
          miw="fit-content"
        />
        <Button
          disabled={!teamForm.isDirty() || !teamForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
};

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
