enum ViewName {
  SignIn,
  SignUp,
  SendResetPasswordLink,
  SentResetPasswordLink,
  ResetPassword,
  ResetPasswordSuccessful,
}

export { ViewName };
