import { styled } from '@linaria/react';
import { Fragment } from 'react';
import { newCOLORS } from '../../styles/colors';

export const BurnFlowChartLegend = () => {
  return (
    <div>
      <ChartLegendRow>
        <Fragment>
          <LegendBox>
            <CommitedWork />
            {'Committed Work'}
          </LegendBox>
          <LegendBox>
            <Blocked />
            {'Blocked'}
          </LegendBox>
          <LegendBox>
            <WorkInProgress />
            {'Work In Progress'}
          </LegendBox>
          <LegendBox>
            <InReview />
            {'In Review'}
          </LegendBox>
          <LegendBox>
            <InTest />
            {'In Test'}
          </LegendBox>
          <LegendBox>
            <StagingReady />
            {'Staging Ready'}
          </LegendBox>
          <LegendBox>
            <WorkDone />
            {'Work Done'}
          </LegendBox>
        </Fragment>
      </ChartLegendRow>
    </div>
  );
};

const ChartLegendRow = styled.div`
  flex-direction: row;
`;

const LegendBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px 0px;
`;

const CommitedWork = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.indigo};
  margin: 0px 10px;
`;

const Blocked = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.coral};
  margin: 0px 10px;
`;

const WorkInProgress = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.darkYellow};
  margin: 0px 10px;
`;

const InReview = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.lightYellow};
  margin: 0px 10px;
`;

const InTest = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.pink};
  margin: 0px 10px;
`;

const StagingReady = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.purple};
  margin: 0px 10px;
`;

const WorkDone = styled.div`
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${newCOLORS.green};
  margin: 0px 10px;
`;
