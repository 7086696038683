import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { ActionIcon, Select, Space, TextInput } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { Dispatch, useRef } from 'react';
import { ADOReducerAction, ADOState } from '../ado/ado.type';
import { JDCReducerAction, JDCState } from '../jdc/jdc.type';
import { JiraReducerAction, JiraState } from '../jira/jira.type';
import styles from './views.module.css';

type SettingsProps = {
  state: JiraState | ADOState | JDCState;
  dispatch: Dispatch<JiraReducerAction> | Dispatch<ADOReducerAction> | Dispatch<JDCReducerAction>;
};

export function SettingsView({ state, dispatch }: SettingsProps) {
  const { settings } = state;
  const workingHoursStartRef = useRef<HTMLInputElement>(null);
  const workingHoursEndRef = useRef<HTMLInputElement>(null);

  return (
    <div id={styles.viewContainer}>
      <h3>Settings</h3>
      <div className={styles.projectSettingsContainer}>
        <TextInput
          required
          label="Team Size"
          placeholder="e.g. 5"
          value={settings.devTeamSize}
          error={state.errors?.devTeamSize}
          onChange={(e) => {
            const value = parseInt(e.currentTarget.value);
            // Only update state if the value is a number, but allow the input to be empty
            // reset errors if the input is valid
            if (e.currentTarget.value === '' || !isNaN(value)) {
              dispatch({
                type: 'settings',
                payload: {
                  devTeamSize: e.currentTarget.value === '' ? e.currentTarget.value : value,
                },
              });
              dispatch({ type: 'errors', payload: { devTeamSize: '' } });
            } else {
              dispatch({ type: 'errors', payload: { devTeamSize: 'Team size must be a number.' } });
            }
          }}
        />
        <Select
          label="Does your team assign points to bugs?"
          data={[
            { label: 'Yes, we assign points to bugs.', value: 'yes' },
            { label: 'No, we leave bugs un-pointed.', value: 'no' },
          ]}
          value={settings.pointBugs ? 'yes' : 'no'}
          onChange={(value) => dispatch({ type: 'settings', payload: { pointBugs: value === 'yes' } })}
        />
        <Select
          label="Does your team expect every card to have a label?"
          data={[
            { label: 'Yes, we label cards', value: 'yes' },
            { label: "No, we don't expect cards to be labeled.", value: 'no' },
          ]}
          value={settings.requireLabels ? 'yes' : 'no'}
          onChange={(value) => dispatch({ type: 'settings', payload: { requireLabels: value === 'yes' } })}
        />
        <TimeComponent>
          <TimeInput
            style={{ margin: '0' }}
            label="Please indicate when your working hours START each day"
            withAsterisk
            ref={workingHoursStartRef}
            rightSection={
              <ActionIcon onClick={() => workingHoursStartRef.current?.showPicker()}>
                <Icon icon="il:clock" />
              </ActionIcon>
            }
            maw={400}
            onChange={(event) =>
              dispatch({ type: 'settings', payload: { workingHoursStart: event.currentTarget.value } })
            }
            value={state.settings.workingHoursStart}
          />
          <Space h="xl" />
          <TimeInput
            style={{ margin: '0' }}
            label="Please indicate when your working hours END each day"
            withAsterisk
            ref={workingHoursEndRef}
            rightSection={
              <ActionIcon onClick={() => workingHoursEndRef.current?.showPicker()}>
                <Icon icon="il:clock" />
              </ActionIcon>
            }
            maw={400}
            onChange={(event) => {
              dispatch({ type: 'settings', payload: { workingHoursEnd: event.currentTarget.value } });
            }}
            value={state.settings.workingHoursEnd}
          />
        </TimeComponent>
      </div>
    </div>
  );
}

const TimeComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
`;
