import { styled } from '@linaria/react';
import { newCOLORS } from '../../../styles/colors';
import { LegendItem } from './initiative-performance.type';

type Props = {
  items: LegendItem[];
};

export function Legend({ items }: Props) {
  return (
    <LegendContainer>
      {items.map((item, index) => (
        <Item key={`${item.title}-${index}`} item={item} />
      ))}
    </LegendContainer>
  );
}

function Item({ item }: { item: LegendItem }) {
  return (
    <LegendItemContainer style={{ borderLeft: `3px solid ${item.color}` }}>
      <TextContainer>
        <Title>{item.title}</Title>
        {item.description && <Description>{item.description}</Description>}
      </TextContainer>
    </LegendItemContainer>
  );
}

const LegendContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 325px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

const LegendItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 0px;
  justify-content: space-between;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
`;

const Title = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

const Description = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
