enum AllocationOfCostsCategory {
  ASSIGNEE = 'assignee',
  COMPONENTS = 'component',
  DUE_DATE = 'due_date',
  EPIC = 'epic',
  LABELS = 'label',
  POINTS = 'points',
  PRIORITY = 'priority',
  REPORTER = 'reporter',
  SPRINT = 'sprint',
  TYPE = 'type',
}

enum ViewBy {
  TASKS = 'tasks',
  POINTS = 'points',
  TASKS_IN_USD = 'tasks_in_usd',
  POINTS_IN_USD = 'points_in_usd',
}

type Spending = {
  year: number;
  month: number;
  budget: number | null;
  portfolio_id: string;
  project_id: string;
  valid?: boolean;
  autofilled?: boolean;
};

type SpendingDetail = {
  month: number;
  budget: number | null;
};

type ProjectSpending = {
  [key: string]: SpendingDetail[];
};

type SpendingBulkPostRequest = {
  portfolio_id: string;
  year: number;
  projects: ProjectSpending;
};

type SpendingBulkDeleteRequest = {
  portfolio_id: string;
  year: number;
  project_id: string;
};

type CostVsBudgetSummary = {
  cost: number;
  daily_burn: number;
  tasks_completed: number;
  tasks_committed: number;
  cost_per_task: number;
  points_completed: number;
  points_committed: number;
  cost_per_point: number;
};

type CostVsBudgetChartTaskDate = {
  id: string;
  points: number | null;
};

type CostVsBudgetChartProjectData = {
  id: string;
  name: string;
  cost: number;
  grade: number;
  daily_burn: number;
  completed_tasks: CostVsBudgetChartTaskDate[];
  committed_tasks: CostVsBudgetChartTaskDate[];
};

type CostVsBudgetChartProjectDataV2 = {
  id: string;
  name: string;
  cost: number;
  grade: number;
  daily_burn: number;
  completed_tasks: number;
  committed_tasks: number;
  completed_points: number;
  committed_points: number;
};

type CostVsBudgetChartData = {
  month: number;
  year: number;
  grade: number;
  projects: CostVsBudgetChartProjectData[];
};

type CostVsBudgetChartDataV2 = {
  month: number;
  year: number;
  grade: number;
  projects: CostVsBudgetChartProjectDataV2[];
};

type CostVsBudgetChartResponse = {
  last: CostVsBudgetSummary;
  average: CostVsBudgetSummary;
  chart_data: CostVsBudgetChartData[];
};

type CostVsBudgetChartResponseV2 = {
  last: CostVsBudgetSummary;
  average: CostVsBudgetSummary;
  chart_data: CostVsBudgetChartDataV2[];
};

type AllocationOfCostsSummary = {
  field: string;
  tasks: number;
  cost_per_task: number;
  points: number;
  cost_per_point: number;
};

type AllocationOfCostsSummaryV2 = {
  field: string;
  id: string;
  tasks: number;
  tasks_in_usd: number;
  points: number;
  points_in_usd: number;
};

type AllocationOfCostsTaskDate = {
  id: string;
  points: number | null;
  weight: number;
};

type AllocationOfCostsProjectField = {
  field: string;
  tasks: AllocationOfCostsTaskDate[];
};

type AllocationOfCostsProjectData = {
  id: string;
  name: string;
  cost: number | null;
  fields: AllocationOfCostsProjectField[];
};

type AllocationOfCostsChartData = {
  month: number;
  year: number;
  projects: AllocationOfCostsProjectData[];
};

type AllocationOfCostsChartDataV2 = {
  [timeAllocation: string]: {
    [factorOption: string]: {
      total: Omit<AllocationOfCostsSummaryV2, 'field'>;
      teams: {
        [team: string]: Omit<AllocationOfCostsSummaryV2, 'field'>;
      };
    };
  };
};

type AllocationOfCostsChartRequest = {
  portfolioId: string | null;
  teamId: string | null;
  startDate: Date | null;
  endDate: Date | null;
};

type AllocationOfCostsChartResponseV2ByCategory = {
  [K in AllocationOfCostsCategory]: AllocationOfCostsChartResponseV2ByCategoryDetails;
};

type AllocationOfCostsChartResponseV2ByCategoryDetails = {
  options: {
    [factorOption: string]: {
      total: Omit<AllocationOfCostsSummaryV2, 'field'>;
      average: Omit<AllocationOfCostsSummaryV2, 'field'>;
    };
  };
  chart_data: AllocationOfCostsChartDataV2;
};

type AllocationOfCostsChartResponseV2 = {
  cost_allocation: AllocationOfCostsChartResponseV2ByCategory;
};

type AllocationOfCostsChartResponse = {
  total: AllocationOfCostsSummary[];
  average: AllocationOfCostsSummary[];
  chart_data: AllocationOfCostsChartData[];
};

type CostByInitiativeTeamData = {
  id: string;
  name: string;
  costs: { [date: string]: number };
};

type CostByInitiativeChartData = {
  initiative: { [date: string]: number };
  teams: CostByInitiativeTeamData[];
};

type CostByInitiativeResponse = {
  id: string;
  name: string;
  cost_to_date: number;
  chart_data: CostByInitiativeChartData;
};

export { AllocationOfCostsCategory, ViewBy };

export type {
  AllocationOfCostsChartData,
  AllocationOfCostsChartDataV2,
  AllocationOfCostsChartRequest,
  AllocationOfCostsChartResponse,
  AllocationOfCostsChartResponseV2,
  AllocationOfCostsChartResponseV2ByCategory,
  AllocationOfCostsChartResponseV2ByCategoryDetails,
  AllocationOfCostsSummary,
  AllocationOfCostsSummaryV2,
  CostByInitiativeResponse,
  CostVsBudgetChartData,
  CostVsBudgetChartDataV2,
  CostVsBudgetChartResponse,
  CostVsBudgetChartResponseV2,
  CostVsBudgetSummary,
  ProjectSpending,
  Spending,
  SpendingBulkDeleteRequest,
  SpendingBulkPostRequest,
};
