import { SprintMetricsBurnFlowOfWork } from '../../api/sprints-client/sprints-client.type';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';

enum WorkDoneEnum {
  onTrack = 'on track',
  atRisk = 'at risk',
  offTrack = 'off track',
}

export type WorkOnTrackProps = {
  burn_commitment: SprintMetricsBurnFlowOfWork;
  start_date: string;
  data_type: ViewByType;
};

export { WorkDoneEnum };
