import { NavIconType } from '../../components/nav-icon/nav-icon.type';

type NavItem = {
  link: string;
  label: GeneralViewNames | ViewNames;
  icon: NavIconType;
  path: string;
  general: boolean;
  show: boolean;
};

enum GeneralViewNames {
  Summary = 'Summary',
  Strategy = 'Strategy',
  Portfolio = 'Portfolio',
  Integrations = 'Integrations',
  Process = 'Process Map',
  ProcessAnalysis = 'Process Analysis',
}

enum ViewNames {
  Project = 'Project',
  CurrentSprint = 'Current Sprint',
  History = 'History',
  Financials = 'Financials',
  Help = 'Help',
}

export { GeneralViewNames, ViewNames };
export type { NavItem };
