import { styled } from '@linaria/react';
import { Checkbox, Divider } from '@mantine/core';
import { formatAllocationOfCostTitle } from '../../helpers';
import { formatCurrency } from '../../helpers/financial/financial';
import { newCOLORS } from '../../styles/colors';
import { StandardText, StandardTextBold } from '../../styles/new-shared-styled-components/new-shared-styled-components';

type CostByInitiativeItemProps = {
  title: string;
  id: string;
  color: string;
  current: number;
  average: number;
  checked: boolean;
  disabled: boolean;
  updateShowChartLines: (checked: boolean, id: string, currentColor: string) => void;
};

export const CostByInitiativeItem = ({
  title,
  id,
  color,
  current,
  average,
  checked,
  disabled,
  updateShowChartLines,
}: CostByInitiativeItemProps) => {
  return (
    <CostByInitiativeContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Divider orientation="vertical" size="md" style={{ marginRight: 4 }} color={color} />
        <Checkbox
          style={{ margin: 4 }}
          size="xs"
          checked={checked}
          onChange={(event) => updateShowChartLines(event.currentTarget.checked, id, color)}
          disabled={disabled}
        />
        <div style={{ margin: 4 }}>
          <StandardText style={{ color: checked ? newCOLORS.black : newCOLORS.darkerGray }}>
            {formatAllocationOfCostTitle(title)}
          </StandardText>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <StandardTextBold style={{ width: 125, textAlign: 'right', color: checked ? newCOLORS.black : newCOLORS.gray }}>
          {formatCurrency(current)}
        </StandardTextBold>
        <StandardText style={{ width: 125, textAlign: 'right', color: checked ? newCOLORS.black : newCOLORS.gray }}>
          {formatCurrency(average)}
        </StandardText>
      </div>
    </CostByInitiativeContainer>
  );
};

const CostByInitiativeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: 8px 0px;
`;
