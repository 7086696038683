import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../components/button/button';
import styles from '../views/views.module.css';
import { NotionState } from './notion.type';

type SummaryProps = {
  notionState: NotionState;
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export const Summary = ({ notionState, setActiveStep }: SummaryProps) => {
  return (
    <div id={styles.viewContainer}>
      <h3>Review your Selections: </h3>
      <div id={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <div className={styles.flexRowDatabaseBetween}>
            <h4 className={styles.summaryLabel}>Backlog</h4>
            <Button variant="subtle" onClick={() => setActiveStep(0)}>
              <span className={styles.editButtonText}>Edit</span>
            </Button>
          </div>
          {(notionState.selectedDatabases || []).map((database) => (
            <span key={database.id} className={styles.summaryValue}>
              {database.name || ''}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
