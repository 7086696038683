import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Table, Tooltip, UnstyledButton } from '@mantine/core';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Initiative, InitiativeStatus } from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { newCOLORS } from '../../../styles/colors';
import { getSourceIcon, getSourceLabel, toLocalDate } from './initiatives-list.helpers';
import { sortInitiatives } from './initiatives-table.helpers';
import { SortedColumn } from './initiatives-table.type';

type Props = {
  initiatives: Initiative[];
  portfolioId: string;
};

export function InitiativesTable({ initiatives, portfolioId }: Props) {
  const [sortBy, setSortBy] = useState<SortedColumn>('name');
  const [sortDesc, setSortDesc] = useState<boolean>(false);
  const navigate = useNavigate();

  const setSorting = useCallback(
    (field: SortedColumn) => {
      const desc = field === sortBy ? !sortDesc : false;
      setSortDesc(desc);
      setSortBy(field);
    },
    [sortBy, sortDesc]
  );

  const handleClickInitiative = (initiativeId: string) =>
    navigate(`/application/strategy/${portfolioId}/${initiativeId}`);

  return (
    <StyledTable verticalSpacing="md">
      <colgroup>
        <col style={{ width: '34%' }} />
        <col style={{ width: '4%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '14%' }} />
        <col style={{ width: '18%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '8%' }} />
      </colgroup>
      <Table.Thead>
        <Table.Tr>
          <SortingTableHeader
            columnName="name"
            className="align-left"
            onSort={() => setSorting('name')}
            sortedColumnName={sortBy}
            sortByDesc={sortDesc}
          >
            Name
          </SortingTableHeader>
          <Table.Th className="align-center">Source</Table.Th>
          <SortingTableHeader
            columnName="status"
            className="align-right"
            onSort={() => setSorting('status')}
            sortedColumnName={sortBy}
            sortByDesc={sortDesc}
          >
            Status
            <Tooltip
              multiline
              label="An evaluative measure that weighs tasks done, team size, and historical pace against the end date of the Initiative"
            >
              <Icon icon="mdi:information-outline" width={12} height={12} color={newCOLORS.blue} />
            </Tooltip>
          </SortingTableHeader>
          <SortingTableHeader
            columnName="completion"
            className="align-right"
            onSort={() => setSorting('completion')}
            sortedColumnName={sortBy}
            sortByDesc={sortDesc}
          >
            Completion %
            <Tooltip
              multiline
              label="Completed tasks out of total tasks from all Epics that contribute to this Initiative."
            >
              <Icon icon="mdi:information-outline" width={12} height={12} color={newCOLORS.blue} />
            </Tooltip>
          </SortingTableHeader>
          <SortingTableHeader
            columnName="relative_business_value"
            className="align-center left-pad"
            onSort={() => setSorting('relative_business_value')}
            sortedColumnName={sortBy}
            sortByDesc={sortDesc}
          >
            Business Value
          </SortingTableHeader>
          <SortingTableHeader
            columnName="start_date"
            className="align-center"
            onSort={() => setSorting('start_date')}
            sortedColumnName={sortBy}
            sortByDesc={sortDesc}
          >
            Start Date
          </SortingTableHeader>
          <SortingTableHeader
            columnName="end_date"
            className="align-right"
            onSort={() => setSorting('end_date')}
            sortedColumnName={sortBy}
            sortByDesc={sortDesc}
          >
            End Date
          </SortingTableHeader>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {sortInitiatives(initiatives, { sortBy, sortDesc }).map((initiative, index) => (
          <Row key={`${initiative.id}-${index}`} onClickInitiative={handleClickInitiative} initiative={initiative} />
        ))}
      </Table.Tbody>
    </StyledTable>
  );
}

const SortingTableHeader = ({
  children,
  className,
  columnName,
  sortedColumnName,
  sortByDesc,
  onSort,
}: {
  children: React.ReactNode;
  className: string;
  columnName: string;
  sortedColumnName: string;
  sortByDesc: boolean;
  onSort: () => void;
}) => (
  <Table.Th className={className}>
    <UnstyledButton component="div" onClick={onSort}>
      {children}
      <SortableColumnIndicator columnName={columnName} sortedColumnName={sortedColumnName} sortByDesc={sortByDesc} />
    </UnstyledButton>
  </Table.Th>
);

const Row = ({
  initiative,
  onClickInitiative,
}: {
  initiative: Initiative;
  onClickInitiative: (initiativeId: string) => void;
}) => (
  <Table.Tr key={initiative.id}>
    <Table.Td>
      <Tooltip
        label={initiative.description}
        multiline
        style={{ maxWidth: 300, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
      >
        <UnstyledButton className="nav-link" onClick={() => onClickInitiative(initiative.id)}>
          {initiative.name}
        </UnstyledButton>
      </Tooltip>
    </Table.Td>
    <Table.Td className="align-center">
      <Tooltip label={getSourceLabel(initiative.source)}>
        <img
          src={getSourceIcon(initiative.source)}
          width={20}
          height={20}
          alt={initiative.source}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    </Table.Td>
    <Table.Td className="align-right">
      <Status status={initiative.status?.status} />
    </Table.Td>
    <Table.Td className="align-right">
      {initiative.status?.percent_subtasks_complete ? `${initiative.status?.percent_subtasks_complete}%` : ''}
    </Table.Td>
    <Table.Td className="align-center left-pad">
      {initiative.relative_business_value ? initiative.relative_business_value.toLocaleString() : null}
    </Table.Td>
    <Table.Td className="align-center">
      {initiative.start_date ? toLocalDate(initiative.start_date).format('MM/DD/YYYY') : 'not provided'}
    </Table.Td>
    <Table.Td className="align-right">{toLocalDate(initiative.end_date).format('MM/DD/YYYY')}</Table.Td>
  </Table.Tr>
);

const statusMapping = {
  [InitiativeStatus.OnTrack]: {
    text: 'On Track',
    icon: icons.iconCheckmark,
  },
  [InitiativeStatus.AtRisk]: {
    text: 'At Risk',
    icon: icons.iconYellowAlert,
  },
  [InitiativeStatus.OffTrack]: {
    text: 'Off Track',
    icon: icons.iconRedWarning,
  },
};

export function Status({ status }: { status?: InitiativeStatus }) {
  if (!status) {
    return null;
  }

  return (
    <div>
      {statusMapping[status].text} &nbsp;
      <img src={statusMapping[status].icon} width={20} height={20} />
    </div>
  );
}

export function SortableColumnIndicator({
  columnName,
  sortedColumnName,
  sortByDesc,
}: {
  columnName: string;
  sortedColumnName: string;
  sortByDesc: boolean;
}) {
  if (columnName !== sortedColumnName) {
    return <Icon icon="prime:sort-alt" width={24} height={24} color={newCOLORS.gray} />;
  }

  return <Icon icon={sortByDesc ? 'octicon:sort-desc-24' : 'octicon:sort-asc-24'} width={24} height={24} />;
}

const StyledTable = styled(Table)`
  thead > tr {
    height: 21px;
    align-self: stretch;
    border: none;
  }

  thead > tr > th {
    border: none;
    padding: 0;
    color: ${newCOLORS.darkGray};
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  thead > tr > th > div {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: ${newCOLORS.darkGray};
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .mantine-Tooltip-tooltip {
    display: flex;
    padding: 16px;
    width: 318px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    text-transform: none;
    text-align: left;
    white-space: normal;
  }

  tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
    color: ${newCOLORS.darkerGray};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;

    .nav-link {
      color: ${newCOLORS.indigo};
      font-family: Figtree;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  tbody > tr > td > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }

  .left-pad {
    padding-left: 30px;
  }
`;
