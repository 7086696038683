import { OrganizationResponse } from '../../api/organization-client/organization-client.type';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { setPortfolioIds } from '../process-analysis-store/process-analysis-store.actions';
import { useGlobalStore } from './global-store';

/**
 * Sets the organization in the global store.
 *
 * @param {string | null} organization - The organization to set. Can be null.
 * @return {void}
 */
const setOrganization = (organization: string | null): void => {
  useGlobalStore.setState({ organization }, false, '[Global Store] setOrganization');
};

/**
 * Sets the organizations in the global store.
 *
 * @param {OrganizationResponse[]} organizations - An array of OrganizationResponse objects representing the organizations to be set.
 * @return {void}
 */
const setOrganizations = (organizations: OrganizationResponse[]): void => {
  useGlobalStore.setState({ organizations }, false, '[Global Store] setOrganizations');
};

/**
 * Sets the portfolio in the global store.
 *
 * @param {Portfolio | null} portfolio - The portfolio to set. Can be null.
 * @return {void}
 */
const setPortfolio = (portfolio: Portfolio | null): void => {
  useGlobalStore.setState({ portfolio }, false, '[Global Store] setPortfolio');
  setPortfolioIds(portfolio ? [portfolio.id] : []);
};

/**
 * Sets the portfolios in the global store.
 *
 * @param {Portfolio[]} portfolios - An array of Portfolio objects representing the portfolios to be set.
 * @return {void}
 */
const setPortfolios = (portfolios: Portfolio[]): void => {
  useGlobalStore.setState({ portfolios }, false, '[Global Store] setPortfolios');
};

/**
 * Sets the teams in the global store.
 *
 * @param {ProjectsResponse[]} teams - An array of ProjectsResponse objects representing the teams to be set.
 * @return {void}
 */
const setTeams = (teams: ProjectsResponse[]): void => {
  useGlobalStore.setState({ teams }, false, '[Global Store] setTeams');
};

/**
 * Initializes the global state with the given organizations and portfolios on application load.
 *
 * @param {OrganizationResponse[]} organizations - An array of OrganizationResponse objects representing the organizations.
 * @param {Portfolio[]} portfolios - An array of Portfolio objects representing the portfolios.
 * @return {void}
 */
const initGlobalState = (organizationsData: OrganizationResponse[], portfolios: Portfolio[]): void => {
  const organizations = organizationsData.filter((organization) =>
    portfolios.some((portfolio) => portfolio.organization.name === organization.name)
  );

  const defaultPayload = { organizations, portfolios, initialized: true };
  useGlobalStore.setState(defaultPayload, false, '[Global Store] initGlobalState');

  const currentOrganization = useGlobalStore.getState().organization;
  const currentPortfolio = useGlobalStore.getState().portfolio;

  const isValidScope =
    currentOrganization &&
    currentPortfolio &&
    organizationsData.some(({ name }) => name === currentOrganization) &&
    currentOrganization === currentPortfolio.organization.name;

  if (!isValidScope) {
    const defaultPortfolio =
      portfolios.find((portfolio) =>
        organizations.some((organization) => organization.name === portfolio.organization.name)
      ) || null;

    if (!defaultPortfolio) {
      return;
    }

    setOrganization(defaultPortfolio.organization.name || null);
    setPortfolio(defaultPortfolio || null);
  }
};

export { initGlobalState, setOrganization, setOrganizations, setPortfolio, setPortfolios, setTeams };
