import { ComboboxItem } from '@mantine/core';
import { IntegrationEntry, IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { IndicatorProps } from '../../../components/indicator/indicator.type';
import { getIntegrationIndicatorProps, getIntegrationState } from '../context/integrations.context.helpers';
import { Integration } from './integrations-list.type';

/**
 * Maps integrations to select data items.
 *
 * @param {Integration[]} integrations - The list of available integrations.
 * @param {IntegrationEntry[]} integrationEntries - The list of integration entries.
 * @returns {Object[]} The array of select data objects
 */
const getSelectData = (
  integrations: Integration[],
  integrationEntries: IntegrationEntry[]
): (ComboboxItem & { indicatorProps: IndicatorProps })[] => {
  const labelMapping = {
    [IntegrationType.JIRA]: 'Atlassian Jira',
    [IntegrationType.GITHUB]: 'GitHub',
    [IntegrationType.ADO]: 'Azure DevOps',
  };

  return integrations.map((integration) => {
    const integrationName = integration.name as IntegrationType.JIRA | IntegrationType.GITHUB | IntegrationType.ADO;

    return {
      label: labelMapping[integrationName],
      value: integration.name,
      indicatorProps: getIndicatorProps(integration, integrationEntries),
    };
  });
};

/**
 * Retrieves the indicator based on the provided integration and integration entries.
 *
 * @param {Integration} integration - The integration for which to retrieve the indicator.
 * @param {IntegrationEntry[]} integrationEntries - The list of integration entries.
 * @returns {IndicatorProps} The indicator props object for the specified integration.
 */
const getIndicatorProps = (integration: Integration, integrationEntries: IntegrationEntry[]): IndicatorProps => {
  const filteredIntegrationEntries = integrationEntries.filter((entry) => {
    if (integration.name === IntegrationType.JIRA) {
      return [IntegrationType.JIRA, IntegrationType.JDC].includes(entry.integration_type);
    }

    return entry.integration_type === integration.name;
  });

  const integrationState = getIntegrationState(filteredIntegrationEntries);

  return getIntegrationIndicatorProps(integrationState);
};

export { getIndicatorProps, getSelectData };
