import { Location } from 'react-router-dom';
import { IssueDetailsResult } from '../../api/issues-client/issues-client.class';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { IssueHistoryState } from './issue-details.type';

export const breadcrumbNavigationIssueDetails = (
  location: Location,
  historyState: IssueHistoryState,
  selectedIssueDetails: IssueDetailsResult,
  onPortfolioClick: () => void
) => {
  const breadCrumbitems: CrumbItem[] = [];
  /* 
     NOTE: This logic has to be refactored once we have issues occuring at portfolio level 
  */
  breadCrumbitems.push({
    labelName: 'Portfolio',
    href: '/application/dashboard',
    onNavigate: () => onPortfolioClick(),
  });
  const state = location.state.historyState || location.state;
  if (location.state) {
    switch (location.state.selectedMenuItem) {
      case 'Project':
        breadCrumbitems.push({
          labelName: `${historyState.project.name}`,
          href: `/application/project/${historyState.project.id}`,
        });
        if (historyState.subproject) {
          breadCrumbitems.push({
            labelName: `${historyState.subproject.name}`,
            href: `/application/project/${historyState.project.id}/subproject/${historyState.subproject.id}`,
          });
        }
        breadCrumbitems.push({
          labelName: `${selectedIssueDetails.title}`,
          state: { historyState: historyState },
        });
        break;

      case 'Current Sprint':
        breadCrumbitems.push({
          labelName: `${historyState.project.name}`,
          href: `/application/project/${historyState.project.id}`,
        });
        breadCrumbitems.push({
          labelName: 'Current Sprint',
          href: `/application/project/${historyState.project.id}/sprint-assessment/`,
        });
        breadCrumbitems.push({
          labelName: `${selectedIssueDetails.title}`,
          state: { historyState: historyState },
        });
        break;

      case 'History':
        breadCrumbitems.push({
          labelName: `${historyState.project.name}`,
          href: `/application/project/${historyState.project.id}`,
        });
        breadCrumbitems.push({
          labelName: `${historyState.sprintName}`,
          href: `/application/project/${historyState.project.id}/sprint/${historyState.sprintId}`,
          state: { from: 'Sprint History' },
        });
        breadCrumbitems.push({
          labelName: `${selectedIssueDetails.title}`,
        });
        break;

      default:
        if (state.project && state.sprint) {
          breadCrumbitems.push({
            labelName: `${state.project.name}`,
            href: `/application/project/${state.project.id}`,
          });
          breadCrumbitems.push({
            labelName: `${state.sprint.name}`,
            href: `/application/project/${state.project.id}/sprint-assessment/${state.sprint.id}`,
          });
        }
        breadCrumbitems.push({
          labelName: `${selectedIssueDetails.title}`,
          state: { historyState: historyState },
        });
    }
  } else {
    breadCrumbitems.push({
      labelName: `${selectedIssueDetails.title}`,
    });
  }

  return breadCrumbitems;
};
