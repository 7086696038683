import { HistoricalSprintDataKind, Sprint } from '../../api/sprints-client/sprints-client.type';

enum AnalyticsEventType {
  OpenApp = 'open_app',
  LoginButtonTapped = 'login_button_tapped',
  SignUpTapped = 'signup_tapped',
  CreateProfileTapped = 'create_profile_tapped',
  CreateCompanyTapped = 'create_company_tapped',
  TermsofServiceTapped = 'terms_of_service_tapped',
  SetupFinished = 'setup_finished',
  CreateAccountTapped = 'create_account_tapped',
  ForgotPasswordTapped = 'forgot_password_tapped',
  ResetPasswordTapped = 'reset_password_tapped',
  ShowPasswordTapped = 'show_password_tapped',
  ChangePasswordTapped = 'change_password_tapped',
  ChangeEmailTapped = 'change_email_tapped',
  SummaryViewed = 'summary_viewed',
  PortfolioViewed = 'portfolio_viewed',
  ProcessViewed = 'process_viewed',
  FinancialsViewed = 'financials_viewed',
  ProjectTapped = 'project_tapped',
  ProjectIssueTapped = 'project_issue_tapped',
  KeywordTapped = 'keyword_tapped',
  ScheduleMeetingTapped = 'schedule_meeting_tapped',
  TaskTapped = 'task_tapped',
  TaskActionTapped = 'task_action_tapped',
  SortTapped = 'sort_tapped',
  StatisticTapped = 'statistic_tapped',
  SettingsTapped = 'settings_tapped',
  FeedbackTapped = 'feedback_tapped',
  LogoutTapped = 'logout_tapped',
  ProjectNavigation = 'project_navigation',
  CurrentSprintNavigation = 'current_sprint_navigation',
  WIPNavigation = 'wip_navigation',
  InitiativeNavigation = 'initiative_navigation',
  HistoryNavigation = 'history_navigation',
  SprintIssueTapped = 'sprint_issue_tapped',
  WIPIssueTapped = 'wip_issue_tapped',
  HistoricalSprintTapped = 'historical_sprint_tapped',
  HistoricalWIPTapped = 'historical_wip_tapped',
  ProjectsTapped = 'projects_tapped',
  CumulativeFlowTapped = 'cumulative_flow_tapped',
  IntegrationsViewed = 'integrations_viewed',
  JiraIntegrationTapped = 'jira_integration_tapped',
  GithubIntegrationTapped = 'github_integration_tapped',
  IntegrationExcelDownloadTapped = 'integration_excel_download_tapped',
  IntegrationExcelUploadTapped = 'integration_excel_download_tapped',
  JiraProjectsViewed = 'integration_jira_projects_viewed',
  JiraBoardsViewed = 'integration_jira_boards_viewed',
  JiraStatuesViewed = 'integration_jira_statuses_viewed',
  JiraSettingsViewed = 'integration_jira_settings_viewed',
  JiraSummaryViewed = 'integration_jira_summary_viewed',
  JiraIntegrationSuccessful = 'integration_jira_integration_successful',
  SprintViewed = 'sprint_viewed',
  ProjectHistoryViewed = 'project_history_viewed',
  TermsAndConditionsViewed = 'terms_and_conditions_viewed',
  OutOfBloomViewed = 'out_of_bloom_viewed',
  GlobalMessageModalOpened = 'global_message_modal_opened',
  GlobalMessageBarClosed = 'global_message_bar_closed',
  FutureIntegrationsClicked = 'future_integrations_clicked',
  NewPortfolioClicked = 'new_portfolio_clicked',
  OldPortfolioClicked = 'old_portfolio_clicked',
}

type CustomDimensionProjectType = {
  name: string;
};

type CustomDimensionIssueType = {
  title: string;
};

type AnalyticsDimensionsProps = {
  userContext: any;
  project?: CustomDimensionProjectType;
  issue?: CustomDimensionIssueType;
  statistic?: HistoricalSprintDataKind;
  sprint?: Sprint;
};

export { AnalyticsEventType };
export type { AnalyticsDimensionsProps, CustomDimensionIssueType, CustomDimensionProjectType };
