import { styled } from '@linaria/react';
import { ComboboxItem, Select } from '@mantine/core';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProjectBoardSprints, Subproject } from '../../api/projects-client/projects-client.type';
import { useProjectBoardSprints } from '../../api/sprints-client/sprints-client.hooks';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { LinkedHeadingTag } from '../../components/linked-heading-tag/linked-heading-tag';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { ProjectContext, SidebarContext } from '../../contexts';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { CurrentSprintMark, DatePeriod } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { BreadcrumbContainer } from '../../styles/shared-styled-components';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import { DropDownData } from './sprint-assessment.type';
type SprintAssessmentHeaderProps = {
  sprint: Sprint | null;
  sprints: DropDownData[];
  setSprints: (data: DropDownData[]) => void;
};

export const SprintAssessmentHeader = ({ sprint, sprints, setSprints }: SprintAssessmentHeaderProps) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  const location = useLocation();
  const { project, setProject } = useContext(ProjectContext);
  const { viewAreaWidth, navItems, setNavItems } = useContext(SidebarContext);
  const [currentBoard, setCurrentBoard] = useState<string | null>(null);
  const [currentSprint, setCurrentSprint] = useState<string | null>(sprintId || null);
  const [projectBoardSprints, setProjectBoardSprints] = useState<ProjectBoardSprints | null>(null);
  const [boards, setBoards] = useState<string[]>([]);
  const navigate = useNavigate();

  const navigateToSelectedSprint = useCallback(
    (sprintId: string) => {
      navigate(`/application/project/${projectId}/sprint-assessment/${sprintId}`);
      setCurrentSprint(sprintId);
    },
    [projectId, navigate]
  );

  const { data } = useProjectBoardSprints(projectId);

  // Initial data loading
  useEffect(() => {
    if (data) {
      setProjectBoardSprints(data);
      const dataBoards = Object.keys(data.subproject_sprints);
      setBoards(dataBoards);

      // This means that the user is coming from the history page which has the sprint ID in the params
      if (sprintId && location?.state?.from === 'History') {
        const board = dataBoards.find((board) => data.subproject_sprints[board].find((s) => s[0] === sprintId));
        setCurrentBoard(board || '');
        navigateToSelectedSprint(sprintId);
      } else {
        // This means the user is clicking within the sprint assessment and changing stuff
        if (!currentBoard && dataBoards.length > 0) {
          setCurrentBoard(dataBoards[0]);
        } else {
          // This means the user is already in the sprint assessment page and tries to click the current sprint icon
          if (currentBoard && currentSprint) {
            navigateToSelectedSprint(currentSprint);
          }
        }
      }
    }
  }, [data, currentBoard, location?.state?.from, navigateToSelectedSprint, sprintId, currentSprint]);

  // Update sprints when currentBoard changes
  useEffect(() => {
    if (currentBoard && projectBoardSprints) {
      const boardSprints = projectBoardSprints.subproject_sprints[currentBoard] || [];
      const formattedSprints = boardSprints.map(([value, label]) => ({ value, label }));
      setSprints(formattedSprints);

      if (formattedSprints.length > 0 && (!currentSprint || !formattedSprints.find((s) => s.value === currentSprint))) {
        navigateToSelectedSprint(formattedSprints[0].value);
      }
    }
  }, [currentBoard, projectBoardSprints, setSprints, navigateToSelectedSprint, currentSprint]);

  const onCurrentBoardChange = (value: string) => {
    setCurrentBoard(value);
  };

  const breadCrumbitems: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
    { labelName: (project?.name as string) || 'Project', href: `/application/project/${projectId}` },
    { labelName: sprint?.name || 'Sprint' },
  ];

  const auditLogURL = `/application/project/${project?.id}/sprint-assessment/${sprint?.id}/audit-log`;

  //Boundary message flags
  //TODO: move these to the backend
  const displayConcurrentSprintMessage = project?.subprojects.find(
    (x: Subproject) => x.name === currentBoard
  )?.concurrent_sprint_flag;

  const displayNoActiveSprintMessage = !project?.sprints?.some((sprint) => sprint.is_active);

  const sprintActivePastEndDate =
    sprint?.current_sprint && sprint?.end_date ? new Date(sprint.end_date) < new Date() : false;

  return (
    <Fragment>
      <BreadcrumbContainer data-testid="breadcrumb">
        <Breadcrumb crumbItems={breadCrumbitems} />
      </BreadcrumbContainer>
      <Fragment>
        {displayNoActiveSprintMessage && <PageHeaderMessage message="This team has no active sprints." color="red" />}
        {sprintActivePastEndDate && (
          <PageHeaderMessage message="This sprint is still active beyond its projected due date." color="red" />
        )}
        {displayConcurrentSprintMessage && sprint?.current_sprint && (
          <PageHeaderMessage
            message="There is more than one sprint open for this team. Bloomfilter will not display information for concurrent sprints until they are closed."
            color="red"
          />
        )}
        <HeaderContainer viewAreaWidth={viewAreaWidth}>
          {sprints.length > 0 ? (
            <LinkedHeadingTag link={auditLogURL} title={sprint?.name || ''} />
          ) : (
            <LinkedHeadingTag link={auditLogURL} title={''} />
          )}
          <DropDownContainer>
            <Select
              data-testid="selectBoard"
              value={currentBoard}
              onChange={(value: string | null, _: ComboboxItem) => {
                if (value) {
                  onCurrentBoardChange(value);
                }
              }}
              data={boards}
            />
            {sprints.length > 0 ? (
              <Select
                data-testid="selectSprint"
                value={currentSprint}
                onChange={(value: string | null) => {
                  if (value) {
                    navigateToSelectedSprint(value);
                  }
                }}
                data={sprints}
                style={{ width: 275, marginLeft: 20 }}
              />
            ) : (
              <Select data-testid="selectSprintDisabled" value={null} style={{ width: 275, marginLeft: 20 }} disabled />
            )}
          </DropDownContainer>
        </HeaderContainer>
        {sprints.length > 0 ? (
          <div
            style={{
              display: 'flex',
              marginTop: '12px',
              justifyContent: viewAreaWidth > 1200 ? 'start' : 'center',
            }}
          >
            {sprint ? (
              <DatePeriod>
                {formatDate(sprint.start_date, timezone, DateTemplate.MMDDYYYY)}
                {' to '}
                {formatDate(sprint.end_date, timezone, DateTemplate.MMDDYYYY)}
              </DatePeriod>
            ) : null}
            {sprint?.current_sprint && <CurrentSprintMark>CURRENT SPRINT</CurrentSprintMark>}
          </div>
        ) : null}
      </Fragment>
    </Fragment>
  );
};

const HeaderContainer = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1200 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
`;

const DropDownContainer = styled.div`
  display: flex;
`;
